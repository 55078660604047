import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const Barchart = () => {
    const chartRef = useRef(null);

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');
        const backgroundColors = ['#f5564a', '#1db2f5', '#eb3573', '#ffc720', '#cccccc', '#e5e5e5', '#fefefe', '#eeeeee', '#333333', '#000000', '#eeeeee', '#333333'];


        const gradient = ctx.createLinearGradient(0, 0, 0, 400); // Create a linear gradient from top to bottom
        gradient.addColorStop(0, 'rgba(252, 131, 64, 1)'); // Start color
        gradient.addColorStop(1, 'rgba(240, 154, 24, 1)'); // End color


        
        const myChart = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [{
              label: 'Last 12 months',
              data: [12, 19, 3, 5, 2, 3, 100, 15, 22, 13, 30, 50],
              backgroundColor: gradient, // Solid color without transparency
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 0
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                    // callback: function(value, index, values) {
                    //   return '$' + value ;
                    // }

                    stepSize: 10000, // Customize the step size
                    callback: function(value, index, values) {
                        if (value === 0) return '$' + value; // Show '0' instead of '0 units'
                        // else if (value >= 10000) return value / 10000 + 'k';

                        else if (value === 50) return '$' + value;
                        else if (value === 100) return '$' + value;
                        else if (value === 1000) return '$' + value;
                        else if (value === 10000) return '$' + value;
                        else return '$' + value;
                    }
                  }
              }
            }
          }
        });

    return () => {
      myChart.destroy(); // Clean up chart on unmount
    };
  }, []);
  
    return (
      <div className='barChartbg'>
        <canvas ref={chartRef} />
      </div>
    );
}

export default Barchart