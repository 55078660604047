import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card'
import { getCustomerList } from "../../../../Service/Customer/CustomerService";
const Listviewsupplier = () => {
    const [customerList, setAllCustomerList] = useState([]);


    useEffect(() => {
        getInvoiceDataList();
    }, []);

    async function getInvoiceDataList() {
        getCustomerList().then(function (result) {
            const response = result?.data;
            console.log(response, "rrrr")
            setAllCustomerList(response?.data);
        });
    }

    return (
        <div className='listViews'>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Customer Type</th>
                        <th scope="col">Customer Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Country</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {customerList?.map((d) => (
                        <tr>
                            <td>{d?.type}</td>
                            <td>{d?.fname} {d?.lname}</td>
                            <td>{d?.email}</td>
                            <td>{d?.phone}</td>
                            <td> {d?.country}</td>
                            <td>
                                <span className='viewIcon'><i class="fa-light fa-eye"></i></span>
                                <span className='viewIcon orange'><i class="fa-light fa-pen"></i></span>
                                <span className='viewIcon'><i class="fa-regular fa-trash-can"></i></span>
                            </td>
                        </tr>
                    ))}


                </tbody>
            </table>
        </div>

    )
}

export default Listviewsupplier