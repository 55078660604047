import { Outlet, Link } from "react-router-dom";
import React, {useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../../../logo.png';
// import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Navigation = () => {

    const [scrolltopdata, setscrolltopdata] = useState('');


  useEffect(() => {
    window.addEventListener('scroll', () => {
        if (window.scrollY < 15) {
            setscrolltopdata('');
        } else {
            setscrolltopdata('scrolled');
        }
    });
}, []);

const navigate = useNavigate();

const onLogin=()=>{

  localStorage.clear()
  navigate('/login')
}

const onSignup=()=>{

    localStorage.clear()
    navigate("/signup")
  }

    return (
        <div className={scrolltopdata ? "stickyHead" : ""}>
            <Navbar collapseOnSelect expand="lg" className="navigationBg">
                <Container>
                    <Navbar.Brand><Link to="/"><img src={logo} /></Link></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className="navbarResponsive" style={{flexGrow: "inherit"}}>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Nav className="">                            
                            <NavDropdown title="Features" id="collapsible-nav-dropdown">
                                <Link to="/salesmodule">Sales Module</Link>
                                <Link to="/purchasemodule">Purchase Module</Link>
                                <Link to="/paymentmodule">Payment Module</Link>
                                <Link to="/accountingmodule">Accounting Module</Link>
                                <Link to="/professionalservice">Professional Services</Link>
                                <Link to="">Payroll Module</Link>
                                {/* <Link to="/Featureinvoice">Invoice</Link>
                                <Link to="/">Sales Order</Link>
                                <Link to="/">Sales Receipt</Link>
                                <Link to="/">Purchase Management</Link>
                                <Link to="/">Quote</Link>                                 */}
                                {/* <NavDropdown.Item href="">Quote</NavDropdown.Item> */}
                            </NavDropdown>                            
                            <Link to="/pricinghome">Pricing</Link>
                            <Link to="/bloglist">Blog</Link>
                            <Link to="/aboutus">Company</Link>
                        </Nav>
                        <Nav className="respNav">                         
                            <button className='commonButton hollowBtn1' onClick={onLogin}>Login</button>&nbsp;&nbsp;
                            <button className='commonButton hollowBtn2' onClick={onSignup}>Join</button>
                        </Nav>
                        {/* <Nav>
                            <div>dsfdsfs</div>
                        </Nav> */}
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Outlet />
        </div>
    )
}

export default Navigation