import { React, useState } from 'react'
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import Form from 'react-bootstrap/Form';
import Headtxt from '../Headtxt';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';
import { Link } from 'react-router-dom';

const Addrefund = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [file, setFile] = useState(null);

    const handleDrop = (event) => {
        event.preventDefault();

        const droppedFile = event.dataTransfer.files[0];
        handleFile(droppedFile);
    };

    const handleFile = (selectedFile) => {
        setFile(selectedFile);

        // You can perform additional actions with the file, such as uploading it to a server.
        // For simplicity, we're just logging the file information here.
        console.log('File selected:', selectedFile);
    };
    const handleDragOver = (event) => {
        event.preventDefault();
    };





    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

  


    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                    <div className='productServList'>
                        <div className='dbTopsec pb-4 pt-2 noBackbtn'>
                            <Headtxt link="/refundlist" title="Add Refund" />

                            {/* <div className='custButton'>
                                <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp;
                                <button className='commonButton expBtn'><i class="fa-light fa-download"></i> Export Excel</button>
                            </div> */}
                        </div>

                        <div className='commonWhite addCustomerForm p-3 mb-3'>
                            <Form>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="6" className='mb-3'>
                                        <Form.Select aria-label="Default select example">
                                            <option selected hidden>Select Customer</option>
                                            <option value="Company">Company</option>
                                            <option value="Individual">Individual</option>

                                        </Form.Select>
                                    </Col>
                                    <Col md="6" className='mb-3'>
                                        <Form.Select aria-label="Default select example">
                                            <option selected hidden>Select Sales Receipt ID</option>
                                            <option value="Company">Company</option>
                                            <option value="Individual">Individual</option>

                                        </Form.Select>
                                    </Col>
                                </Form.Group>

                                <Form.Group className="row" controlId="exampleForm.ControlInput1">                                    
                                    <Col md="6" className='datepickr mb-3'>
                                        <DatePicker
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            dateFormat="dd/MM/yyyy" // You can customize the date format
                                            isClearable
                                            placeholderText="Date of Refund"
                                        />
                                    </Col>
                                    <Col md="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="Amount of Refund" />
                                    </Col>
                                </Form.Group>

                                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                                    <Col md="12">
                                        <Form.Control as="textarea" placeholder="Notes" style={{height: "100px"}} rows={3} />
                                    </Col>
                                </Form.Group>


                                <label>Attachments</label>
                                <div className='adsDrag'>
                                    <div
                                        onDrop={handleDrop}
                                        onDragOver={handleDragOver}
                                        className='drgdrop'
                                    >
                                        <i class="fa-light fa-cloud-arrow-up"></i>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <p>Drag &amp; drop files or </p>
                                            <input type="file" onChange={(e) => handleFile(e.target.files[0])} style={{ display: 'none' }} />
                                            <button onClick={() => document.querySelector('input[type="file"]').click()}>Browse</button>
                                        </div>

                                        {file && <p>Selected File: {file.name}</p>}
                                    </div>
                                </div>








                            </Form>
                        </div>
                        <button className='commonButton expBtn'>Done</button>&nbsp;&nbsp;
                        <button className='commonButton addBtn'><Link to="/refundlist">Cancel</Link></button>


                    </div>
                </div>
            </Sidebar>




            <Modal size="xl" show={show} onHide={handleClose} centered className='customerModal sloModal'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>View Leads and Customers</h5>
                        <div className='slSearch'>
                            <i class="fa-regular fa-magnifying-glass"></i>
                            <Form.Control
                                type="search"
                                placeholder="Search This List"
                                className=" mr-sm-2"
                            />
                        </div>
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <div className='listViews salesPopTable'>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Customer Name</th>
                                    <th scope="col">Notes</th>
                                    <th scope="col">City</th>
                                    <th scope="col">Zip Code(Optional)</th>
                                    <th scope="col">Country</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Alex Brown</td>
                                    <td>CUS - 003</td>
                                    <td></td>
                                    <td>lorem</td>
                                    <td>258965</td>
                                    <td>India</td>
                                </tr>
                                <tr>
                                    <td>Alex Brown</td>
                                    <td>CUS - 003</td>
                                    <td></td>
                                    <td>lorem</td>
                                    <td>258965</td>
                                    <td>India</td>
                                </tr>


                            </tbody>
                        </table>
                    </div>

                    <Pagination className='pgns'>
                        <Pagination.Prev />
                        <Pagination.Item>{1}</Pagination.Item>                        
                        <Pagination.Item>{2}</Pagination.Item>
                        <Pagination.Next />
                    </Pagination>


                </Modal.Body>
                <Modal.Footer>
                    <button className='commonButton expBtn' onClick={handleClose}>Close</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Addrefund