import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import ans from '../../images/ans.png';

const Faq = () => {
    return (
        <div className='homefaqBg'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h2 className='mb-4'>Answers to most popular questions</h2>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>What is 1simpleClick Accounting software, and how can it benefit my business?</Accordion.Header>
                                <Accordion.Body>
                                    <div className='faqDesc'>
                                        <img src={ans} alt='' />
                                        <p>1simpleClick Accounting software is an intuitive and user-friendly accounting solution designed to simplify financial management for businesses. It offers features like automated bookkeeping, invoicing, expense tracking, and financial reporting, streamlining your accounting processes for increased efficiency and accuracy.</p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>How secure is my data with 1simpleClick Accounting software?</Accordion.Header>
                                <Accordion.Body>
                                    <div className='faqDesc'>
                                        <img src={ans} alt='' />
                                    <p>We prioritize the security of your data. 1simpleClick Accounting software employs advanced encryption, secure servers, and regular data backups to ensure your financial information is kept confidential and protected from unauthorized access.</p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Can multiple team members collaborate using 1simpleClick Accounting software?</Accordion.Header>
                                <Accordion.Body>
                                    <div className='faqDesc'>
                                        <img src={ans} alt='' />
                                        <p>Yes, our software supports collaboration by allowing multiple users. You can assign different roles and permissions to control access, ensuring that each team member has the appropriate level of access based on their responsibilities.</p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Does 1simpleClick Accounting software integrate with banks for seamless transaction management?</Accordion.Header>
                                <Accordion.Body>
                                <div className='faqDesc'>
                                        <img src={ans} alt='' />
                                        <p>Absolutely. Our software seamlessly integrates with bank feeds, allowing for the automatic import of transactions. Reconciling accounts is made easy with features that match transactions and automatically categorize them, saving you time and reducing errors.</p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Can I customize invoices and reports to match my brand using 1simpleClick Accounting software?</Accordion.Header>
                                <Accordion.Body>
                                <div className='faqDesc'>
                                        <img src={ans} alt='' />
                                        <p>Yes, customization is a key feature. You can personalize invoices, reports, and other documents to align with your brand. This includes adding your logo, choosing colors, and tailoring layouts to create a professional and consistent brand image.</p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>




                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Does 1simpleClick Accounting software support international transactions and multiple currencies?</Accordion.Header>
                                <Accordion.Body>
                                <div className='faqDesc'>
                                        <img src={ans} alt='' />
                                        <p>Certainly. Our software supports multiple currencies, making it an ideal solution for businesses involved in international transactions. Exchange rates are automatically updated to ensure accurate financial reporting.</p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="6">
                                <Accordion.Header>How does 1simpleClick Accounting software assist with tax compliance?</Accordion.Header>
                                <Accordion.Body>
                                <div className='faqDesc'>
                                        <img src={ans} alt='' />
                                        <p>Our software includes features to aid in tax compliance, such as automated tax calculations, generation of tax reports, and reminders for filing deadlines. Integration with tax software is also available for a seamless and stress-free tax season.</p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="7">
                                <Accordion.Header> Is customer support available for assistance with any issues or questions?</Accordion.Header>
                                <Accordion.Body>
                                <div className='faqDesc'>
                                        <img src={ans} alt='' />
                                        <p>Yes, we provide excellent customer support through various channels, including live chat, email, and phone. Additionally, we offer an extensive knowledge base and tutorials to help users navigate the software effectively.</p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="8">
                                <Accordion.Header>Can I access 1simpleClick Accounting software on my mobile device?</Accordion.Header>
                                <Accordion.Body>
                                <div className='faqDesc'>
                                        <img src={ans} alt='' />
                                        <p>Absolutely. Our software is designed to be mobile-friendly, allowing you to access key features and financial data from your smartphone or tablet, providing flexibility and convenience on the go.</p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="9">
                                <Accordion.Header>How often is 1simpleClick Accounting software updated, and are updates automatic?</Accordion.Header>
                                <Accordion.Body>
                                <div className='faqDesc'>
                                        <img src={ans} alt='' />
                                        <p>We regularly update our software to enhance features, security, and performance. Updates are typically automatic, ensuring that you always have access to the latest improvements without any manual intervention.</p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>


                        </Accordion>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Faq