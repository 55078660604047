import webApi, { baseURL } from "../../WebApi/WebApi";


export const getProfileCreation = async (data, onSuccess, onFailure) => {
    console.log(data, "formData")
    try {
        const res = await webApi.post(
            `/user-company-info-post`, data
        );
        if (res.status === 200) {
            const r = res.data;
            console.log(r, "rerere")
            return onSuccess(r);
        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};