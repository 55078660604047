import { React, useState, useEffect } from 'react'
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import bclogo from '../../../images/bclogo.png';
// import Headtxt from '../Headtxt';
import moment from "moment"
// import Listview from '../../sales/Listview';
// import Gridview from '../../sales/Gridview';
import Card from 'react-bootstrap/Card'
import Headtxt from '../../dbstructure/Headtxt';
import { getRefundDateSearch, getRefundList } from '../../../../Service/Customer/CustomerService';
import DatePicker from 'react-datepicker';
import getSymbolFromCurrency from 'currency-symbol-map'
import cals from '../../../images/cals.png';
const Refundlist = () => {

    const [show, setShow] = useState(false);
    const [quotation, setQuotation] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [shows, setShows] = useState(false);
    const [querySearch, setQuerySearch] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



    const [selectedPage, setSelectedPage] = useState('/');
    const navigate = useNavigate();

    const handlePageChange = (page) => {
        setSelectedPage(page);
        navigate(page);
    };


    const [supply, setSupply] = useState(false)
    const toggleCompany = (e) => {
        e.preventDefault();
        setSupply(false)
    }
    const toggleIndividual = (e) => {
        e.preventDefault();
        setSupply(true)
    }


const [view, setView] = useState(false)
  const toggleHandler = (e) => {
    e.preventDefault();
    setView(true)
  }
  const toggleHandler1 = (e) => {
    e.preventDefault();
    setView(false)
  }


  // custom-select-drupdown
const [isOpen, setIsOpen] = useState(false);
const [selectedOption, setSelectedOption] = useState('Refund');

const toggleDropdown = () => {
  setIsOpen(!isOpen);
};

const handleOptionClick = (option) => {
  setSelectedOption(option);
  setIsOpen(false);
};



const onDownload = (id) => {
    window.open(id, '_blank'); // Open PDF in a new tab

}
// FOR-LOAD-MORE
const [items, setItems] = useState(['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6', 'Item 7', 'Item 8', 'Item 9', 'Item 10', 'Item 11', 'Item 12', 'Item 13', 'Item 14', 'Item 15']);
const [visibleItems, setVisibleItems] = useState(5);
const [loading, setLoading] = useState(false);


const loadMoreItems = () => {
    setLoading(true);
    setTimeout(() => {
      // Simulate loading delay
      setVisibleItems((prev) => prev + 5);
      setLoading(false);
    }, 1000); // Adjust the delay as needed
  };


const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      // Auto-load more items when scrolling to the bottom
      loadMoreItems();
    }
  };

useEffect(() => {
  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);
const handleFromDateChange = (e) => {
    const value = e;
    setFromDate(value);
    triggerSearch(querySearch, e, toDate);
};

const handleToDateChange = (e) => {
    const value = e;
    setToDate(value);
    triggerSearch(querySearch, fromDate,e);
};
const onStatus = (e) => {
    getRefundList(`searchText=${e.target.value}`).then(function (result) {
        const response = result?.data;
        console.log(response, "newrrrr")
        setQuotation(response?.data ? response?.data : []);
    });


}
const getSearchList = () => {
    // Construct the filter criteria based on fromDate and toDate
    const filterCriteria = {
        fromDate: fromDate ? moment(fromDate).format('DD-MM-YYYY') : '',
        toDate: toDate ? moment(toDate).format('DD-MM-YYYY') : ''
    };
    console.log('filter---', filterCriteria);
    const queryString = new URLSearchParams(filterCriteria).toString();
    const finalQueryString = querySearch ? `searchText=${querySearch}&${queryString}` : `searchText=&${queryString}`;
    console.log(finalQueryString, 'finalQueryString');
    getRefundList(finalQueryString).then(function (result) {
        const response = result?.data;
      //  console.log('resultttt---', response);
        setQuotation(response?.data);
    });
};

const onSearch = (e) => {
    const query = e.target.value;
    setQuerySearch(query);
    triggerSearch(query, fromDate, toDate);
};

useEffect(() => {
    getSearchList();
}, [fromDate, toDate]);

const triggerSearch = (query, from, to) => {
    console.log(query,'query====')
    const filterCriteria = {
       // query: query || '',
        fromDate: from ? moment(from).format('DD-MM-YYYY') : '',
        toDate: to ? moment(to).format('DD-MM-YYYY') : ''
    };
    const queryString = new URLSearchParams(filterCriteria).toString();
    const finalQueryString = query ? `searchText=${query}&${queryString}` : `searchText=&${queryString}`;
    console.log(finalQueryString, 'finalQueryString');
    getRefundList(finalQueryString).then(function (result) {
        const response = result?.data;
        setQuotation(response?.data);
    });
};
async function getInvoiceDataList() {
    getRefundList("").then(function (result) {
        const response = result?.data;
        console.log(response, "rrrr")
        setQuotation(response?.data);
    });
}

    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                <div className='custTop'>
                        <Link to='/customerlisting'>Customer</Link>
                        <Link to='/quote'>Quote</Link>
                        <Link to="/salesorderlisting">Sales Orders</Link>
                        <Link to='/invoice'>Invoice</Link>
                        {/* <Form.Select aria-label="Default select example">
                            <option>More</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </Form.Select> */}
                        <div className="custom-dropdown-container customDropdown ms-4">
                            <div className="custom-dropdown-header active" onClick={toggleDropdown}>
                                <span>{selectedOption}</span>
                                <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}> <i class="fa-regular fa-angle-down"></i></span>
                            </div>

                            {isOpen && (
                                <ul className="custom-dropdown-options">
                                  <li
                                        className={`option ${selectedOption === 'Option 1' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 1')} 
                                    >
                                        <Link to='/productservicelisting'>Product &amp; Service</Link>
                                        
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 2' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 2')} 
                                    >
                                        <Link to="/salesreceiptlist">Sales Receipts</Link>
                                        
                                    </li>
                                    {/* <li
                                        className={`option ${selectedOption === 'Option 3' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 3')} hidden
                                    >
                                        <Link to="/salesreceiptlist">Sales Receipts</Link>
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 4' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 4')}
                                    >
                                        <Link to="/refundlist">Refunds</Link>
                                    </li> */}
                                </ul>
                            )}

                            {/* <p>You selected: {selectedOption}</p> */}
                        </div>
                    </div>
                    <div className='productServList'>
                        <div className='dbTopsec pb-4 pt-2 noBackbtn'>
                            <Headtxt link="/customerlisting" title="Refund" />
                            
                            {/* <div className='custButton'>
                                <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp;
                                <button className='commonButton expBtn'><i class="fa-light fa-download"></i> Export Excel</button>
                            </div> */}
                        </div>

                        <div className='customerSrchTop pb-3 poResponsive'>
              <div className='customSrchfield'>
                <div>
                <span><i class="fa-solid fa-magnifying-glass"></i></span><input type='search' placeholder='Search by keywords' onChange={onSearch} value={querySearch}/>
                </div>&nbsp;&nbsp;
                <div className='invDatepicker'>
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={handleFromDateChange}
                                        selectsStart
                                        startDate={fromDate}
                                        endDate={toDate}
                                        placeholderText="From Date"
                                        isClearable={fromDate ? true : false}
                                    />
                                </div>
                                <div className='invDatepicker' style={{ marginLeft: '10px' }}>
                                    <DatePicker
                                        selected={toDate}
                                        onChange={handleToDateChange}
                                        selectsEnd
                                        startDate={fromDate}
                                        endDate={toDate}
                                        placeholderText="To Date"
                                        isClearable={toDate ? true : false}
                                        minDate={fromDate}

                                    />
                                </div>
                </div>
              {/* <div className='custButton flexfield'>
                
            <button className='commonButton addBtn'><Link to="/addrefund"><i class="fa-regular fa-circle-plus"></i> Add Refunds</Link></button>&nbsp;&nbsp;
                <button className='commonButton expBtn' onClick={handleShow}><i class="fa-light fa-upload"></i> Export Excel</button>
              </div> */}
            </div>


                        {/* <div className='addsuppBtn'>
                            <button className={`${!supply ? "commonButton active" : "commonButton "} `} onClick={toggleCompany}>Product</button>
                            <button className={`${supply ? "commonButton active" : "commonButton "} `} onClick={toggleIndividual}>Service</button>
                        </div> */}
                        <div className='mt-2'>
                            <div className='gridList mb-3 flexfield' style={{justifyContent: "flex-end"}}>
                                {/* <div className='srtBy'>
                                <label>Sort by: </label>&nbsp;
              <Form.Select aria-label="Default select example" className='sortby'>
              <option>Pending</option>
              <option>Delivered</option>
              <option>Closed</option>
            </Form.Select>&nbsp;
                                </div> */}
                                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler1} className={!view? "listactive" : "" }><i class="fa-solid fa-list"></i></button>
                                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler} className={view? "listactive" : "" }><i class="fa-solid fa-grid"></i></button>
                            </div>
                            {!view ? 
                            <div className='listViews'>

                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Refund Date</th>
                                        <th scope="col">Invoiced ID</th>
                                        <th scope="col">Customer Name</th>
                                        <th scope="col">Refund Amount</th>
                                        <th scope="col">Invoiced Amount</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                            {quotation?.slice(0, visibleItems)?.map((item, index) => (
                                                <tr key={index} text={item}>
                                                    <td><Link>{item?.return_date}</Link></td>
                                                    <td><Link to={`/viewinvoicedetails?id=${item.InvoiceID}`}>{item?.invoice_id}</Link></td>
                                                    <td>{item?.type == "Company" ? item?.company_name : item?.fname + " " + item?.lname}</td>
                                                    <td>{getSymbolFromCurrency(item.currency)} {item?.return_total}</td>
                                                    <td>{getSymbolFromCurrency(item.currency)} {item?.invoice_total}</td>
                                                    
                                                    <td>
                                                <div className='tdFlex'>
                                                    {/* <span className='viewIcon'><Link to={`/customerdetails`}><i class="fa-light fa-eye"></i></Link></span> */}
                                                {/* <span className='viewIcon orange'><Link to={`/`}><i class="fa-light fa-pen"></i></Link></span> */}
                                                {/* <span className='viewIcon'><i class="fa-regular fa-trash-can"></i></span>&nbsp;&nbsp; */}
                                                    <Dropdown className='dropRow'>
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="fa-light fa-ellipsis-vertical"></i>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>                                                            
                                                            <Dropdown.Item onClick={()=>onDownload(item.return_pdf)} ><i class="fa-light fa-download"></i> &nbsp; Download</Dropdown.Item>                                                            
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </td>
                                                </tr>
                                            ))}                                     
                                        
                                                     
                
                                </tbody>
                            </table>

                                    {loading && 
                                    <div className='loadItems'>
                                        <button className="commonButton addBtn">
                                            Loading...
                                        </button>
                                    </div>
                                    }
                                    {!loading && (
                                        <div className='loadItems'>
                                            <button onClick={loadMoreItems} className="commonButton addBtn">
                                               No More Return & Refund
                                            </button>
                                        </div>
                                    )}
                                    
                        </div>
                         : 
                         <div className='gridView'>
                              {quotation?.slice(0, visibleItems)?.map((item, index) => (
                                        <Card>
                                            <Card.Body>
                                                <div className='commonFlex'>
                                                    <h6>{item.invoice_id}</h6>
                                                    <span><img src={cals} alt='' className='mb-2' />&nbsp; {item.return_date}</span>
                                                </div>
                                                <div className='commonFlex'>
                                                    <span className='customr'>{item?.type == "Company" ? item?.company_name : item?.fname + " " + item?.lname}</span>
                                                    <span>&nbsp;</span>
                                                </div>
                                                <div className='commonFlex'>
                                                 
                                                    {/* <h6>{getSymbolFromCurrency(item.currency)} {item?.invoice_total}</h6> */}
                                                    <h6>{getSymbolFromCurrency(item.currency)} {item?.return_total}</h6>
                                                </div>
                                                <div className='commonFlex gridFooter'>

                                                    <div className='invgridicn'>
                                                        {/* <Link to=""><i class="far fa-envelope"></i></Link>&nbsp; */}
                                                        <div className='tdFlex'>
                                                    {/* <span className='viewIcon'><Link to={`/customerdetails`}><i class="fa-light fa-eye"></i></Link></span> */}
                                                {/* <span className='viewIcon orange'><Link to={`/`}><i class="fa-light fa-pen"></i></Link></span> */}
                                                {/* <span className='viewIcon'><i class="fa-regular fa-trash-can"></i></span>&nbsp;&nbsp; */}
                                                    <Dropdown className='dropRow'>
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="fa-light fa-ellipsis-vertical"></i>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>                                                            
                                                            <Dropdown.Item onClick={()=>onDownload(item.return_pdf)} ><i class="fa-light fa-download"></i> &nbsp; Download</Dropdown.Item>                                                            
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                                    </div>
                                                </div>
                                                {/* <div className='cardBlock actn'>
                                                <div>
                                                    <span className='viewIcon'><Link to={`/customerdetails`}><i class="fa-light fa-eye"></i></Link></span>
                                                    <span className='viewIcon orange'><Link to={`/editcustomer`}><i class="fa-light fa-pen"></i></Link></span>
                                                    <span className='viewIcon'><i class="fa-regular fa-trash-can"></i></span>
                                                </div>
                                            </div> */}
                                            </Card.Body>
                                        </Card>
                                    ))}
        </div>
        }

                        </div>
                    </div>
                </div>
            </Sidebar>



            <Modal size="lg" show={show} onHide={handleClose} className="salesExport">
                <div className='salesExpCol'>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                    </Modal.Header>
                    <div className='explft'>
                        <div className='salesModtop'>
                            <div className='salesModLft'>
                                <img src={bclogo} alt='' className='mb-2'/>
                                <h5>ABC Company</h5>
                                <small>15 / 12 Lorem ipsum lfjfghyti <br/>Ney ypork. Hilton </small>
                            </div>
                            <div className='salesModRgt'>
                                <h5>SALES ORDER</h5>
                                <small>no. ClNV-NZ</small><br/>
                                <small>24/11/2023</small><br/>
                                <strong>Due on receipt</strong>
                            </div>
                        </div>
                        <div className='bsOrder'>
                            <div className='bsOrderlft'>
                                <h5>Billing address</h5>
                                <h6>Test Company</h6>
                                <small>15 / 12 Lorem ipsum lfjfghyti<br/>
Ney ypork. Hilton </small>
                            </div>
                            <div className='bsOrderRgt'>
                            <h5>Shipping address</h5>
                                <h6>Test Company</h6>
                                <small>15 / 12 Lorem ipsum lfjfghyti<br/>
Ney ypork. Hilton </small>
                            </div>
                        </div>
                        <div className='bsTables'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Item name & description</th>
                                        <th>VAT</th>
                                        <th>Qty</th>
                                        <th>Unit Price</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <h6>ABC Product</h6>
                                            em ipsum lfjfghyti
                                        </td>
                                        <td>20%</td>
                                        <td>1</td>
                                        <td>$20.00/price</td>
                                        <td>$20.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td colSpan="3">
                                            <h6>Subtotal</h6>
                                        </td>                                        
                                        <td>$20.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td colSpan="3">
                                            standard rate($20.00)
                                        </td>                                        
                                        <td>$4.00</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                        <td>&nbsp;</td>
                                        <td colSpan="3">Total</td>
                                        <td>$24</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className='expRgt'>                    
                    <button className='commonButton expBtn btnshadow'><i class="fa-regular fa-envelope"></i> Email</button>
                    <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-print"></i> Print</button>
                    <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-down-to-bracket"></i> Download</button>
                    </div>
                </div>                
            </Modal>
        </div>
    )
}

export default Refundlist