const sampleServiceCsv=[

    {
         
        "Service_code":"123",
        "Name": "Room Decoration",
        "Description": "Room decoration Test",
        "Unit": "Day",
        "Price": "59000",
        "Purchase_tax": "20",
    }
]

export default sampleServiceCsv;