import { React, useState } from 'react'
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import Form from 'react-bootstrap/Form';
import Headtxt from '../Headtxt';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';
import { Link } from 'react-router-dom';
import Customdropdown from '../../Customdropdown';

const Profitloss = () => {

    const options = ['All accounts', 'Company', 'Individuals'];  
    const options2 = ['All contacts', 'Company', 'Individuals'];  


    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const [selectedDateb, setSelectedDateb] = useState(null);

    const handleDateChangeb = (date) => {
        setSelectedDateb(date);
    };



    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                <div className='custTop'>
                    <Link to='/balancesheet' >Balance Sheet</Link>
                    <Link to='' className='active' >Income (Profit & Loss)</Link>
                    <Link to='/cashflow'>Cashflow Statement</Link>
                    
                </div>



                    <div className='productServList'>
                        <div className='dbTopsec pb-4 pt-2 noBackbtn'>
                            <Headtxt link="" title="Profit &amp; Loss" />

                            <div className='custButton'>
                                {/* <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp; */}
                                <button className='commonButton expBtn'><i class="fa-light fa-upload"></i> Export</button>
                            </div>
                        </div>


                        <div className='commonWhite addCustomerForm mb-3 p-3'>
                            <h6>Date Range</h6>
                        <Form>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                <Col md="5" className='datepickr mb-3'>
                                        <DatePicker
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            dateFormat="dd/MM/yyyy" // You can customize the date format
                                            isClearable
                                            placeholderText="Date"
                                        />
                                    </Col>
                                    <Col md="5" className='datepickr mb-3'>
                                        <DatePicker
                                            selected={selectedDateb}
                                            onChange={handleDateChangeb}
                                            dateFormat="dd/MM/yyyy" // You can customize the date format
                                            isClearable
                                            placeholderText="Date"
                                        />
                                    </Col>
                                    <Col md="2" className='datepickr'>
                                        <button className='commonButton expBtn'>Apply</button>
                                    </Col>
                                    
                                </Form.Group>                                

                                </Form>
                        </div>
                        <div className='cash'>
                            <div className='cashblock'>
                                <div className='cashtag'>Income</div>
                                <div className='cashTxt'>$2,094.82 &nbsp;<i class="fa-duotone fa-square-plus"></i></div>
                            </div>
                            <div className='cashblock'>
                                <div className='cashtag'>Cost of Goods Sold</div>
                                <div className='cashTxt'>$27,300.00 &nbsp;<i class="fa-duotone fa-square-plus"></i></div>
                            </div>
                            <div className='cashblock'>
                                <div className='cashtag'>To be paid out</div>
                                <div className='cashTxt'>$1,399.75 &nbsp;</div>
                            </div>
                            <div className='cashblock'>
                                <div className='cashtp'>
                                    <i class="fa-solid fa-equals"></i>
                                    <h2>$27,995.07</h2>
                                </div>
                            </div>
                        </div>
                        <div>
                              <div className='salesOrdTable'>
                                    <div className='listViews'>
                                        <table class="table balanceSheet"> 
                                            <tbody>
                                                <tr>
                                                  <td className='actTop'>Accounts</td>
                                                  <td className='actTop sheetdate'>Jan 01, 2023 - Dec 07, 2023</td>
                                                </tr>
                                                <tr>
                                                    <td>Income</td>
                                                    <td>&nbsp;</td>                                                   
                                                </tr>
                                                <tr>
                                                    <td>Sales</td>
                                                    <td>$27,300.00</td>                                                   
                                                </tr> 
                                                <tr>
                                                    <td>Gain on Foreign Exchange</td>
                                                    <td>$0.00</td>                                                   
                                                </tr>                                                
                                                <tr>
                                                    <td>
                                                      <h6>Total Income</h6>                                                      
                                                    </td>
                                                    <td><strong>$27,300.00</strong></td>                                                    
                                                </tr>
                                                <tr>
                                                  <td className='actTop'>Total Cost of Goods Sold</td>
                                                  <td className='actTop sheetdate'>$28,000.01</td>
                                                </tr>
                                                <tr>
                                                  <td className='actTop'>Gross Profit <p><small>As a percentage of Total Income</small></p></td>
                                                  <td className='actTop sheetdate'>$28,000.01 <p><small>100.00%</small></p></td>
                                                </tr>

                                                <tr>
                                                    <td>Operating Expenses</td>
                                                    <td>&nbsp;</td>                                                   
                                                </tr>
                                                <tr>
                                                    <td>Computer - Software</td>
                                                    <td>$27,300.00</td>                                                   
                                                </tr> 
                                                <tr>
                                                    <td>Merchant Account Fees</td>
                                                    <td>$0.00</td>                                                   
                                                </tr>                                                
                                                <tr>
                                                    <td>
                                                      <h6>Total Operating Expenses</h6>                                                      
                                                    </td>
                                                    <td><strong>$27,300.00</strong></td>                                                    
                                                </tr>
                                                <tr>
                                                  <td className='actTop'>Net Profit <p><small>As a percentage of Total Income</small></p></td>
                                                  <td className='actTop sheetdate'>$28,000.01 <p><small>100.00%</small></p></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                              </div>
                        </div>



                    </div>
                </div>
            </Sidebar>




            
        </div>
    )
}


export default Profitloss