import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import dashblue from '../../images/dashblue.png';
import Navigation from '../layout/Navigation';
import Masonry from 'react-masonry-css';

import bs1 from '../../images/bs1.jpg';
import bsbnr from '../../images/bsbnr.jpg';
import cals from '../../images/cals.png';
import Footer2 from '../layout/Footer2';



const Bloglist = (props) => {
        const { logo } = props;

        //FOR-MASONRY-TRANSFORM
        const breakpointColumnsObj = {
            default: 3,
            1100: 2,
            700: 2,
            500: 1
          };

        

 // FOR-LOAD-MORE
 const [items, setItems] = useState(['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6', 'Item 7', 'Item 8', 'Item 9', 'Item 10', 'Item 11', 'Item 12', 'Item 13', 'Item 14', 'Item 15']);
 const [visibleItems, setVisibleItems] = useState(5);
 const [loading, setLoading] = useState(false);

 const loadMoreItems = () => {
     setLoading(true);
     setTimeout(() => {
         // Simulate loading delay
         setVisibleItems((prev) => prev + 5);
         setLoading(false);
     }, 1000); // Adjust the delay as needed
 };

 const handleScroll = () => {
     if (
         window.innerHeight + document.documentElement.scrollTop ===
         document.documentElement.offsetHeight
     ) {
         // Auto-load more items when scrolling to the bottom
         loadMoreItems();
     }
 };

 useEffect(() => {
     window.addEventListener('scroll', handleScroll);
     return () => {
         window.removeEventListener('scroll', handleScroll);
     };
 }, []);


      
            
    
        return (
    
            <>
            <div className='authBg pricinghome'>
                <header>
                    <Navigation/>
                </header>

                {/* <div className='proflogo'><Link to="/"><img src={logo} /></Link></div> */}
                <div className='authLogo pt-lg-5 mt-5 pricingLogo'>&nbsp;</div>
    
                <div className='signForm pricingshadow container'>
                     <div className='choosePlanTop pricingA pb-4'>
                        <h2>Blog</h2>
                        <img src={dashblue} />
                        <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur...</p>
                     </div>
                     <div className=''>
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                            >
                            {items.slice(0, visibleItems).map((item, index) => (
                            <div className='masBlock' key={index} text={item}>
                                <img src={bs1} />
                                <div className='masCard'>
                                    <h5>News &nbsp;&nbsp;|&nbsp;&nbsp; <span>21 jan 2023</span></h5>
                                    <h3>Lorem Ipsum is simply dummy text here. Lorem Ipsum is simply dummy text here.</h3>
                                    <Link to="/blogdetails">Read More</Link>
                                </div>
                            </div>
                            ))}                           


                            {/* <div className='masBlock'>
                                <img src={bsbnr} />
                                <div className='masCard'>
                                    <h5>News &nbsp;&nbsp;|&nbsp;&nbsp; <span>21 jan 2023</span></h5>
                                    <h3>Lorem Ipsum is simply dummy text here. Lorem Ipsum is simply dummy text here.</h3>
                                    <Link>Read More</Link>
                                </div>
                            </div>
                            <div className='masBlock'>
                                <img src={bs1} />
                                <div className='masCard'>
                                    <h5>News &nbsp;&nbsp;|&nbsp;&nbsp; <span>21 jan 2023</span></h5>
                                    <h3>Lorem Ipsum is simply dummy text here. Lorem Ipsum is simply dummy text here.</h3>
                                    <Link>Read More</Link>
                                </div>
                            </div>
                            <div className='masBlock'>
                                <img src={bsbnr} />
                                <div className='masCard'>
                                    <h5>News &nbsp;&nbsp;|&nbsp;&nbsp; <span>21 jan 2023</span></h5>
                                    <h3>Lorem Ipsum is simply dummy text here. Lorem Ipsum is simply dummy text here.</h3>
                                    <Link>Read More</Link>
                                </div>
                            </div>
                            
                            <div className='masBlock'>
                                <img src={bsbnr} />
                                <div className='masCard'>
                                    <h5>News &nbsp;&nbsp;|&nbsp;&nbsp; <span>21 jan 2023</span></h5>
                                    <h3>Lorem Ipsum is simply dummy text here. Lorem Ipsum is simply dummy text here.</h3>
                                    <Link>Read More</Link>
                                </div>
                            </div>
                            <div className='masBlock'>
                                <img src={bs1} />
                                <div className='masCard'>
                                    <h5>News &nbsp;&nbsp;|&nbsp;&nbsp; <span>21 jan 2023</span></h5>
                                    <h3>Lorem Ipsum is simply dummy text here. Lorem Ipsum is simply dummy text here.</h3>
                                    <Link>Read More</Link>
                                </div>
                            </div>
                            <div className='masBlock'>
                                <img src={cals} />
                                <div className='masCard'>
                                    <h5>News &nbsp;&nbsp;|&nbsp;&nbsp; <span>21 jan 2023</span></h5>
                                    <h3>Lorem Ipsum is simply dummy text here. Lorem Ipsum is simply dummy text here.</h3>
                                    <Link>Read More</Link>
                                </div>
                            </div> */}                           
                            
                        </Masonry>

                        {loading &&
                                <div className='loadItems'>
                                    <button className="commonButton addBtn">
                                        Loading...
                                    </button>
                                </div>
                            }
                            {!loading && (
                                <div className='loadItems'>
                                    <button onClick={loadMoreItems} className="commonButton addBtn">
                                        Load More
                                    </button>
                                </div>
                            )}

                            
                     </div>
                </div>


            </div>
            <Footer2/>
            </>
        )
    }
    


export default Bloglist