import React from 'react'
import { Link, Navigate } from 'react-router-dom';
import done from '../../images/done.png'
import { useNavigate } from 'react-router-dom';

const Subscribe = (props) => {
    const { logo } = props;
    const navigate = useNavigate();



const onSubscribeNow=()=>{
    navigate("/subscriptionplan")
}
    return (

        <div className='authBg'>
            <div className='proflogo'><Link to="/"><img src={logo} /></Link></div>
            <div className='authLogo pt-5'>&nbsp;</div>

            <div className='signForm'>
                    <div className='signFormbase py-4'>
                        <div className='subscribe'>
                            <img src={done} />
                            <h5 className='mt-3'>Registration Complete!</h5>
                            <p>You are now ready to explore the amazing features we have in store for you.</p>
                            <div className='accountTxt'>Click “<Link>Subscribe Now</Link>” to start 1SimpleClick</div>
                        </div>
                    </div>
                    <div className='pflex py-3'>
                        <Link>&nbsp;</Link>
                        &nbsp;
                        <button className='commonButton buttonSubmit w-auto' onClick={onSubscribeNow}>Subscribe Now</button>
                    </div>
            </div>
        </div>
    )
}

export default Subscribe