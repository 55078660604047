import React from 'react';
import bsbnr from '../../images/bsbnr.jpg';
import bs1 from '../../images/bs1.jpg';
import Navigation from '../layout/Navigation';
import cfobanner from '../../images/cfobnr.png';
import cfo1 from '../../images/cfo1.jpg';
import cfo2 from '../../images/cfo2.jpg';
import Footer2 from '../layout/Footer2';

const Managebusinessdetails = (props) => {  


  return (
    <>
    <div className='authBg manageBusinessBlock'>
      <header>
        <Navigation/>
        </header>


        <div className='authLogo cfoLogo'>&nbsp;</div>
      <section className='bannerBg cfoBnr pt-0'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <h1 className='mb-0'>Professional Book Keeping</h1>
                    <h6>Affordable services customized to your need</h6>
                    <p>Our affordable bookkeeping services will ensure you stay on top of your business. Budget friendly options to choose that will guarantee satisfaction.</p>
                    <button className='commonButton getStartbtn'><i class="fa-regular fa-paper-plane"></i> Get In Touch</button>
                </div>
                <div className='col-lg-6'>
                    <img src={cfobanner} alt='' />
                </div>
            </div>
        </div>
    </section>
    <section className='cfoBtm'>
        <div className='container'>
          <div className='row mb-5'>
            <div className='col-lg-6 mb-3'>
              <img src={cfo1} alt='' />
            </div>
            <div className='col-lg-6'>
              {/* <h5>Affordable services customized to your need</h5> */}
              
              <h5 className='mb-3'>Option #A : Basic Bookkeeping Services</h5>
              {/* <p></p>              
              <p></p>
              <p></p>
              <p></p>
              <p></p> */}

              <ul className='taxTxt'>
                <li>Focus on your business and let's the experts take care of the numbers for you</li>
                <li>Entering, Coding and Paying Bills</li>
                <li>Creating and Sending Customer Invoices</li>
                <li>Collecting Past Due Accounts Receivable</li>
                <li>Maintenance of Vendors and Customer Accounts</li>
                <li>Reconciling Bank and Credit Card Accounts Generate basic reports</li>
              </ul>
              
            </div>
          </div>






        </div>
    </section>

    </div>
    <Footer2/>
    </>
  )
}

export default Managebusinessdetails