import webApi, { baseURL } from "../../WebApi/WebApi";


export const manualPayment = async (id,data, onSuccess, onFailure) => {
    
    console.log(data, "rerereoo");
    try {
        const res = await webApi.post(`/bill-manual-payment-details_store/${id}`,data);
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};