import { useEffect, useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import pf from '../../images/pf.png';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import {getUserAllList} from "../../../Service/Dashboard/DashboardService";
const Leftmenu = () => {

  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);


  useEffect(() => {
    // Retrieve the JSON string from localStorage
    const jsonString = localStorage.getItem('1Simpleclick_User'); // Replace 'yourStorageKey' with the actual key.
console.log("localdataaa",localStorage.getItem('1Simpleclick_User'))
    // Parse the JSON string into an object
    const data = JSON.parse(jsonString);

    // Update the state with the retrieved data
    setUserData(data);
  }, []);

  const onLogout = () => {

    localStorage.clear()
    navigate("/")
  }



  const location = useLocation();
  const pathnameParts = location.pathname.split('/'); // Split the pathname into parts
  const lastPageName = pathnameParts[pathnameParts.length - 1];

  const [currentpage, setCurrentpage] = useState("");
  const [collapse, setCollapsed] = useState(false);
  const [userList, setAllUserList] = useState("");
  const handleResize = () => {
    setCollapsed(window.innerWidth <= 991);
  };

  useEffect(() => {
    console.log(window.innerWidth, "smallscreen")
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

  });

  console.log(lastPageName, currentpage, "Somnath")

  useEffect(() => {
    if (currentpage) {
      setCurrentpage(lastPageName)
    }

  }, []);

  useEffect(() => {
    getUserDataList();

  }, []);

  async function getUserDataList() {
    getUserAllList().then(function (result) {
      const response = result?.data;
      // console.log(response, "rrrr")
      setAllUserList(response?.data);
    });
  }


  return (

    <>
      {/* <button onClick={() => setCollapsed(!collapse)} className='menuToggle'>{!collapse? <i class="fa-regular fa-arrows-minimize"></i> : <i class="fa-regular fa-arrows-maximize"></i> }</button> */}
      <Sidebar collapsed={collapse} className='dbsidemenu'>
        <div className='sideLogin'>
          <Dropdown className="userBase ms-0">
            <Dropdown.Toggle variant="" id="">
              <Link to="/myprofile"><img src={userList?.logo_url} /> <span className="john">{userData?.fname} {userData?.lname}</span></Link>
            </Dropdown.Toggle>
            {/* <Dropdown.Menu>
            <Dropdown.Item onClick={onLogout}>Logout <i class="fa-solid fa-right-from-bracket"></i></Dropdown.Item>
          </Dropdown.Menu> */}
          </Dropdown>
        </div>
        <Menu menuItemStyles={{
          button: ({ level, active, disabled }) => {
            // only apply styles on first level elements of the tree
            if (level === 0)
              return {
                color: disabled ? '#f5d9ff' : '#d359ff',
                backgroundColor: active ? '#eecef9' : undefined,
              };
          },
        }}>
          <button onClick={() => setCollapsed(!collapse)} className='menuToggle'>{!collapse ? <i class="fa-regular fa-arrows-minimize"></i> : <i class="fa-regular fa-arrows-maximize"></i>}</button>
          <MenuItem className={lastPageName === "dashboard" ? "show" : ""} component={<Link to="/dashboard" />}><i class="fa-light fa-house"></i> Home </MenuItem>
          <MenuItem
            className={lastPageName === "customerlisting" ? "show" : ""
              || lastPageName === "addcustomer" ? "show" : ""
                || lastPageName === "productservicelisting" ? "show" : ""
                  || lastPageName === "addproductservice" ? "show" : ""
                    || lastPageName === "productservicedetails" ? "show" : ""
                      || lastPageName === "salesorders" ? "show" : ""
                        || lastPageName === "salesorderlisting" ? "show" : ""
                          || lastPageName === "salesordergeneration" ? "show" : ""
                            || lastPageName === "salesreceiptlist" ? "show" : ""
                              || lastPageName === "salesreceiptadd" ? "show" : ""
                                || lastPageName === "refundlist" ? "show" : ""
                                  || lastPageName === "addrefund" ? "show" : ""
                                    || lastPageName === "refunddetails" ? "show" : ""
                                      || lastPageName === "invoice" ? "show" : ""
                                        || lastPageName === "newinvoicecreation" ? "show" : ""
                                          || lastPageName === "quote" ? "show" : ""
            }
            component={<Link to="/customerlisting" />}>
            <i class="fa-light fa-badge-percent"></i>
            Sales </MenuItem>
          <MenuItem
            className={lastPageName === "supplierlisting" ? "show" : ""
              || lastPageName === "addsuppliers" ? "show" : ""
              || lastPageName === "productservicelistings" ? "show" : ""
                  || lastPageName === "addproductservice2" ? "show" : ""
                    || lastPageName === "productservicedetails" ? "show" : ""
                || lastPageName === "purchaseorderlisting" ? "show" : ""
                  || lastPageName === "addpurchaseorder" ? "show" : ""
                    || lastPageName === "quotelisting" ? "show" : "" 
                    || lastPageName === "addsupplierquote" ? "show" : "" 
                    || lastPageName === "bill" ? "show" : "" 
                    || lastPageName === "billdetails" ? "show" : "" 
                    || lastPageName === "billpayment" ? "show" : "" 
                    || lastPageName === "billpaymentadd" ? "show" : "" 
                    || lastPageName === "billadd" ? "show" : ""
            } component={<Link to="/supplierlisting" />}>
            <i class="fa-light fa-bag-shopping"></i>
            Purchase </MenuItem>
          <MenuItem 
          className={lastPageName === "accounting" ? "show" : "" 
          || lastPageName === "transactions" ? "show" : "" 
          || lastPageName === "accountchart" ? "show" : ""
          
          } component={<Link to="/accounting" />}><i class="fa-light fa-user"></i> Accounting </MenuItem>
          <MenuItem 
          className={lastPageName === "balancesheet" ? "show" : "" 
          || lastPageName === "profitloss" ? "show" : "" 
          || lastPageName === "cashflow" ? "show" : "" 
        } component={<Link to="/balancesheet" />}><i class="fa-light fa-file-lines"></i> Reports </MenuItem>

          <SubMenu
            label="Professional Service"
            icon={<i class="fa-light fa-user-tie" />}
            className={lastPageName === "cfoservice" ? "show" : "" || lastPageName === "prfbooking" ? "show" : "" || lastPageName === "advconsulting" ? "show" : "" || lastPageName === "icimprovement" ? "show" : "" || lastPageName === "auditpreparation" ? "show" : ""  || lastPageName === "cfoservice" ? "show" : ""}
          >
            <MenuItem className={lastPageName === "cfoservice" ? "show" : "" || lastPageName === "ceoservice" ? "show" : ""} component={<Link to="/cfoservice" />}><i class="fa-regular fa-angle-right"></i> <span>CFO Services</span> </MenuItem>
            <MenuItem className={lastPageName === "prfbooking" ? "show" : ""} component={<Link to="/prfbooking" />}><i class="fa-regular fa-angle-right"></i> <span>Professional Bookkeeping Services</span> </MenuItem>
            <MenuItem className={lastPageName === "advconsulting" ? "show" : ""} component={<Link to="/advconsulting" />}><i class="fa-regular fa-angle-right"></i> Advisory and Consulting </MenuItem>
            <MenuItem className={lastPageName === "icimprovement" ? "show" : ""} component={<Link to="/icimprovement" />}><i class="fa-regular fa-angle-right"></i> Internal Control Improvements </MenuItem>
            <MenuItem className={lastPageName === "auditpreparation" ? "show" : ""} component={<Link to="/auditpreparation" />}><i class="fa-regular fa-angle-right"></i> Audit Preparations </MenuItem>
            <MenuItem className={lastPageName === "budgetforecasting" ? "show" : ""} component={<Link to="/budgetforecasting" />}><i class="fa-regular fa-angle-right"></i> Budgeting and Forecasting </MenuItem>
          </SubMenu>

          <MenuItem className={lastPageName === "aboutus" ? "show" : ""} component={<Link to="/aboutus" />}><i class="fa-light fa-memo"></i> About Us </MenuItem>
          <MenuItem className={lastPageName === "termsconditions" ? "show" : ""} component={<Link to="/termscondition" />}><i class="fa-light fa-circle-check"></i> Terms &amp; Conditions </MenuItem>
          <MenuItem className={lastPageName === "privacypolicy" ? "show" : ""} component={<Link to="/privacy" />}><i class="fa-light fa-layer-group"></i> Privacy Policy </MenuItem>
          <MenuItem className={lastPageName === "contactus" ? "show" : ""} component={<Link to="/contactus" />}><i class="fa-light fa-phone"></i> Contact Us </MenuItem>

          {/* <SubMenu label="Charts" className={lastPageName === "accounting" ? "show" : "" || lastPageName === "addcustomer" ? "show" : ""}>
          <MenuItem className={lastPageName === "accounting" ? "show" : ""} component={<Link to="/accounting" />}> Accounting </MenuItem>
          <MenuItem className={lastPageName === "addcustomer" ? "show" : ""} component={<Link to="/addcustomer" />}> Addcustomer </MenuItem>
        </SubMenu> */}

          {/* <SubMenu label="Charts" className={lastPageName === "accounting" ? "show" : ""}>
          <MenuItem className={lastPageName === "accounting" ? "show" : ""} component={<Link to="/accounting" />}> Accounting </MenuItem>
          <MenuItem> Line charts </MenuItem>
        </SubMenu>
        <SubMenu label="demo" className={lastPageName === "demo" ? "show" : ""} >
          <MenuItem className={lastPageName === "demo" ? "show" : ""} component={<Link to="/demo" />}> Piechart </MenuItem>
          <MenuItem> Line charts </MenuItem>
        </SubMenu> */}


        </Menu>
        <div className='logoutLeft'>
          <button className='commonButton' onClick={onLogout}><i class="fa-regular fa-arrow-right-from-bracket"></i> Logout</button>
        </div>
      </Sidebar>
    </>
  )
}

export default Leftmenu