import webApi, { baseURL } from "../../WebApi/WebApi";


export const getAddSuppliers = async (
    companyType,
    companyName,
    firstName,
    lastName,
    email,
    phone,
    webUrl,
    vatNumber,
    billAddress,
    country,
    state,
    city,
    currency,
    zipCode,
    products_details = [],  // Default to empty array
    service_details = [],   // Default to empty array
    countrycode,
    onSuccess = () => {},   // Default no-op function
    onFailure = () => {}    // Default no-op function
  ) => {
    try {
      // Ensure products_details and service_details are arrays
      const products = Array.isArray(products_details) ? products_details.map(item => item.id) : [];
      const services = Array.isArray(service_details) ? service_details.map(item => item.id) : [];
  
      const res = await webApi.post(
        `/supplier-details-store`,
        {
          type: companyType,
          company_name: companyName,
          fname: firstName,
          lname: lastName,
          email: email,
          phone: phone,
          website_url: webUrl,
          vat_number: vatNumber,
          billing_address: billAddress,
          country: country,
          state: state,
          city: city,
          currency: currency,
          zipcode: zipCode,
          products: products,
          services: services,
          country_code: countrycode
        }
      );
      if (res.status === 200) {
        const r = res?.data;
        console.log('r',res)
        return onSuccess(r);
    } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
    }}
    catch (error) {
        console.log("fdgdf")
    }
  };

export const getSupplierList = async (data) => {
    return await webApi.get(`supplier-list`, {

    });
}


export const deleteSupplier = async (supplierId, onSuccess, onFailure) => {
    console.log(supplierId,"supplierId")
    try {
        const res = await webApi.get(
            `/supplier-delete/${supplierId}`, 
        );
        if (res.status === 200) {
            const r = res.data;
            return onSuccess(r);
        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const getSearchSupplier = async (data) => {
    return await webApi.get( `/supplier-search?searchText=${data}`, 
{

    });
}


export const getSuppliereListById = async (data) => {
    return await webApi.get(`/supplier-details/${data}`, {

    });
}


export const getEditSupplier = async (companyType, companyName, firstName, lastName, email, phone, webUrl,
    vatNumber, billAddress, country, state, city, zipCode,
    id, products_details,service_details, onSuccess, onFailure) => {
console.log(products_details,"cmcmcmcmcm")

    try {
        const res = await webApi.post(

            `/supplier-edit`,
            {
                type: companyType,
                company_name: companyName,
                fname: firstName,
                lname: lastName,
                email: email,
                phone: phone,
                website_url: webUrl,
                vat_number: vatNumber,
                billing_address: billAddress,
                country: country,
                state: state,
                city: city,
                zipcode: zipCode,               
                id:id,
         products:products_details.map(item=>item.id),
         services:service_details.map(item=>item.id)
           
            }
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};


export const getExcelSupplier = async () => {
    return await webApi.get( `/supplier-list-export`, 
{

    });
}


export const getImportSupplier = async ( jsonData,onSuccess, onFailure) => {


    try {
        const res = await webApi.post(

            `/supplier-details-import`,
            {supplier:jsonData}
           
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};