import React from 'react'
import Footer2 from '../layout/Footer2';
import Navigation from '../layout/Navigation';
import Banner from './Banner';
import Videosection from './Videosection';
import Need from './Need';
import Smartaccounting from './Smartaccounting';
import Managebusiness from './Managebusiness';
import Taxseason from './Taxseason';
import Faq from './Faq';
import Testimonial from './Testimonial';
import Resttous from './Resttous';
// import Route from '../router/index';

const index = () => {
  return (
    <div className='mainBase'>
        <header>
        <Navigation/>
        </header>
        <Banner/>
        <Need/>
        <Smartaccounting/>
        <Managebusiness/>
        <Taxseason/>
        <Faq/>
        <Videosection/>   
        <Testimonial/>  
        <Resttous />   
        <Footer2/>
    </div>
  )
}

export default index