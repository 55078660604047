import  React, { useState, useEffect } from 'react'
import Dbheader from '../../layout/Dbheader'
import Sidebar from '../../layout/Sidebar'
import { Link } from 'react-router-dom'
import Headtxt from '../Headtxt';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dropdown from 'react-bootstrap/Dropdown';
import Customdropdown from '../../Customdropdown';
import { getAccountDateSearch, getAccountTransactionsList } from '../../../../Service/Account/AccountService';
import moment from "moment"
const Transactions = () => {

    const options = ['Option 1', 'Option 2', 'Option 3'];   



    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
    };
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [transactionList, setTransactionList] = useState([]);
    // RANGE-DATEPICKER
    const [dateRange, setDateRange] = useState([null, null]);
    const handleFromDateChange = (e) => {
        const value  = e;
        setFromDate(value);
        // Perform filtration based on the selected from date
        // You can put your filtration logic here
        console.log('Filter data based on from date:',value);
      };
    
      const handleToDateChange = (e) => {
        const value  = e;
        setToDate(value);
        // Perform filtration based on the selected to date
        // You can put your filtration logic here
     
        console.log('Filter data based on to date:', value);
     
      };
     

    const handleDateChangeb = (dates) => {
        // Set the time to midnight (00:00:00) to avoid time-related issues
        const midnightDates = dates.map(date => (date ? new Date(date.setHours(0, 0, 0, 0)) : null));
        setDateRange(midnightDates);
    };


    // FOR-LOAD-MORE
    const [items, setItems] = useState([]);
    const [visibleItems, setVisibleItems] = useState(5);
    const [loading, setLoading] = useState(false);

    async function getTransactionList() {
        getAccountTransactionsList("").then(function (result) {
            const response = result?.data;
          console.log(response, "rrrr")
         //setAddAccountType(response?.data?.Accounts);
         setItems(response?.data)
        });
    }

    useEffect(() => {
        getTransactionList();
        
      }, []);
      useEffect(() => {
        getSearchList();
    }, [fromDate, toDate]);
      const getSearchList = () => {
        // Construct the filter criteria based on fromDate and toDate
        const filterCriteria = {
            fromDate: fromDate ? moment(fromDate).format('DD-MM-YYYY') : '',
            toDate: toDate ? moment(toDate).format('DD-MM-YYYY') : ''
        };
        console.log('filter---', filterCriteria);
        const queryString = new URLSearchParams(filterCriteria).toString();
        console.log(queryString, 'cre');
        getAccountDateSearch(queryString).then(function (result) {
            const response = result?.data;
            console.log('resultttt---', response);
            setItems(response?.data);
        });
    };
      const onSearch = (e) => {
        getAccountTransactionsList(e.target.value).then(function (result) {
            const response = result?.data;
            // console.log(response, "rrrr")
            setItems(response?.data);
        });
    }

    const loadMoreItems = () => {
        setLoading(true);
        setTimeout(() => {
            // Simulate loading delay
            setVisibleItems((prev) => prev + 5);
            setLoading(false);
        }, 1000); // Adjust the delay as needed
    };

    const handleScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop ===
            document.documentElement.offsetHeight
        ) {
            // Auto-load more items when scrolling to the bottom
            loadMoreItems();
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    



    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                    <div className='custTop'>
                        <Link to='/accounting'>GL_Account Transactions</Link>
                        <Link to='/transactions' className='active'>Transactions</Link>
                        <Link to='/accountchart'>Chart of Accounts</Link>

                    </div>



                    <div className='productServList'>
                        <div className='dbTopsec pb-4 pt-2 noBackbtn'>
                            <Headtxt link="" title="Transactions" />

                            <div className='custButton'>

                            <Link  className='commonButton addBtn'>Add income</Link>&nbsp;&nbsp;
                                <Link className='commonButton addBtn'>Add expense</Link>&nbsp;&nbsp;
                                <button className='commonButton expBtn' onClick={handleShow}><i class="fa-light fa-upload"></i> Export</button>
                                {/* <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp; */}
                                {/* <button className='commonButton expBtn'><i class="fa-light fa-upload"></i> Export</button> */}
                            </div>
                        </div>

                        <div className='customerSrchTop pb-5 poResponsive'>
                            <div className='customSrchfield'>
                                <div>
                                    <span><i class="fa-solid fa-magnifying-glass"></i></span><input type='search' placeholder='Search by keywords' onChange={onSearch}/>
                                </div>&nbsp;&nbsp;
                                <div className='invDatepicker'>
                                    
                                     <DatePicker
                    selected={fromDate}
                    onChange={handleFromDateChange}
                    selectsStart
                    startDate={fromDate}
                    endDate={toDate}
                    placeholderText="From Date"
                    isClearable={fromDate?true:false}
                />
                 </div>
                   <div className='invDatepicker' style={{ marginLeft: '10px' }}>
                <DatePicker
                    selected={toDate}
                    onChange={handleToDateChange}
                    selectsEnd
                    startDate={fromDate}
                    endDate={toDate}
                    placeholderText="To Date"
                    isClearable={toDate?true:false}
                    
                />
                                </div>
                  
                            </div>                          
                              

                            <div className='srtBy circldropdown'>
                                    <label>Sort By:</label>&nbsp;
                                    {/* <Form.Select aria-label="Default select example" className='sortby'>
                                        <option>No ordered</option>
                                        <option>Delivered</option>
                                        <option>Closed</option>
                                    </Form.Select>&nbsp; */}
                                    <Customdropdown options={options} placeholdr="Select"/>
                                </div>
                        </div>
                    

                    <div className='listViews'>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Date</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Account</th>
                                                <th scope="col">Bill Id / Invoice ID</th>
                                                <th scope="col">Amount</th>
                                                {/* <th scope="col">Actions</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {items?.slice(0, visibleItems)?.map((item, index) => (
                                                <tr key={index} text={item}>
                                                    <td>{item.payment_date}</td>
                                                    <td> {item.notes}</td>
                                                    <td>{item.account_name}</td>
                                                    <td>{item.bill_id===null?item.invoice_id:item.bill_id}</td>
                                                    <td style={{color:item.payment_type==='debit'?'red':'green'}} >{item.payment_type==='debit'? '-' :'+'} {item.amount}</td>                                                    
                                                    {/* <td>
                                                        <div className='tdFlex'>
                                                            {/* <span className='viewIcon'><Link to={`/customerdetails`}><i class="fa-light fa-eye"></i></Link></span> 
                                                            <span className='viewIcon orange'><Link to={`/`}><i class="fa-light fa-pen"></i></Link></span>
                                                            <span className='viewIcon'><i class="fa-regular fa-trash-can"></i></span>&nbsp;&nbsp;
                                                            <Dropdown className='dropRow'>
                                                                <Dropdown.Toggle id="dropdown-basic">
                                                                    <i class="fa-light fa-ellipsis-vertical"></i>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="#/action-1"><i class="fa-regular fa-circle-xmark"></i> &nbsp; Cancel</Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><i class="fa-light fa-share"></i> &nbsp; Resend</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </td> */}
                                                </tr>

                                            ))}
                                        </tbody>
                                    </table>

                                    {loading &&
                                        <div className='loadItems'>
                                            <button className="commonButton addBtn">
                                                Loading...
                                            </button>
                                        </div>
                                    }
                                    {!loading && (
                                        <div className='loadItems'>
                                            <button onClick={loadMoreItems} className="commonButton addBtn">
                                            No Transactions Found
                                            </button>
                                        </div>
                                    )}

                                </div>
                                </div>
                </div>
            </Sidebar>
        </div>
    )
}

export default Transactions