import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import Usr from '../../images/usr.png';
import Lock from '../../images/lock.png';
import { toast } from 'react-toastify';
import {getForgotPassword} from "../../../Service/ForgotPassword/ForgotPasswordService";
import { useNavigate } from 'react-router-dom';

const Forgotpassword = (props) => {
  const { logo } = props;
  const navigate = useNavigate();
  const [buttonChange, setButtonChange] = useState(false)


  const formik = useFormik({
    initialValues: {
        email: ''
    },
    validationSchema: yup.object({
        email: yup.string().email().required(),
    }),
    onSubmit: async(values, {resetForm}) => {   
      setButtonChange(true)

      await getForgotPassword(values.email, (res) => {
        console.log(res, "koko")
        if (res.success === true) {
          setButtonChange(false)

          const userdata = {
            
            email: values.email,
            
          }
        
          localStorage.setItem("1Simpleclick_forgot_email", JSON.stringify(userdata))
 // setAuthed({isAuth: true, token: res.data.token, user: userdata})
          toast.success(res.message)

          navigate("/verifyotp");
          // window.location.reload();
          // toast.success(res.message)

          formik.resetForm();
        } else {
          toast.error(res.message)

        }
      })
    }
})


console.error(formik.errors);

const renderEmailError = formik.touched.email && formik.errors.email && (<span className='errorTxt'>{formik.errors.email}</span>);



     // FOR-GET-LOGIN-RESPONSE
     const url = "https://reqres.in/api/users";

     const login = async(data) => {
        const response =await axios.post(url,data)
        // const getresponse =await axios.get(url)
        // console.log(getresponse, "getResponse")
        if ([200,201,202,203,204].includes(response.status)){
          toast.success("Successfully");
        }
        else{
          toast.success("Error");
          console.log(response, "somnath")
        }
        
     }



  return (
    <div className='authBg'>
      <div className='authLogo'><Link to="/"><img src={logo} /></Link></div>
      <div className='signForm'>
        <div className='signFormbase'>
          <div className='signHdn'>Forgot Password</div>
          <div className='text-center pt-4 px-5'><p>Please enter your email below and we will send you instructions for resetting your password.</p></div>
          <div className='signMainform'>
          <form onSubmit={buttonChange ? formik.handleSubmit : formik.handleSubmit}>
              <div className='mb-4'>
                <label><img src={Usr}/>&nbsp; Email</label>
                  <input type="email" id="email" name="email" className='form-control' placeholder='Enter your email' onChange={formik.handleChange} value={formik.values.email} />
                  {renderEmailError}
              </div>


              {/* <div className='forgotPass'><Link href='/authentication/Forgotpassword'>Forgot Password?</Link></div> */}
              {buttonChange ?
                      <button type="button" className='commonButton buttonSubmit'>Processing...</button>
                      :
                      <button type="submit" className='commonButton buttonSubmit' >Submit</button>
                    }
              {/* <button type="submit" className='commonButton buttonSubmit'>Submit</button> */}
          </form>
          </div>
        </div>
        {/* <div className='accountTxt'>Don't have an account? &nbsp; <Link to="/signup">Sign up Now .</Link></div> */}
      </div>
    </div>
  )
}

export default Forgotpassword