import webApi, { baseURL } from "../../WebApi/WebApi";

//FOR LOGIN 

export const getOtpVerification = async (otp, onSuccess, onFailure) => {
    console.log(otp, "otp")

    try {
        const res = await webApi.post(

            `/check-reset-otp`,
            {
                otp: otp,

            }
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
