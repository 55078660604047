import React from 'react';
import Navigation from '../layout/Navigation';
import Footer2 from '../layout/Footer2';
import maskimg from '../../images/maskimg.jpg';
import regdtemplate from '../../images/regdtemplate.png';

const Aboutus = () => {  


  return (
    <>
    <div className='authBg manageBusinessBlock'>
      <header>
        <Navigation/>
        </header>
        <div className='aboutBnr'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h2>About Us</h2>
                    </div>
                </div>
            </div>
        </div>


        
    <section className='cfoBtm'>
        <div className='container'> 
        <div className='row'>
                    <div className='col-lg-6 accRight'>
                        <img src={maskimg} alt='' />
                    </div>
                    <div className='col-lg-6 pt-3'>
                        <h2>Repre ea vol henderit qui in ea voluptate Quis aut....</h2>                        
                        <p>Beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.
Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur xercitationem ullam corporis suscipit lab.</p>
                        
                    </div>                    
                </div>      
        </div>
    </section>


    <div className='smartAccountbg'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 pt-3'>
                        <h2>Lorem Ipsum is simply dummy text of 
the printing dummy text....</h2>
                        
                        <p>Beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, 

sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, Ut enim ad minima veniam, quis nostrum exercitationem 

ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.</p>
                        
                    </div>
                    <div className='col-lg-6 accRight'>
                        <img src={regdtemplate} alt='' />
                    </div>
                </div>
            </div>
        </div>

    </div>
    <Footer2/>
    </>
  )
}


export default Aboutus