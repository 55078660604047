import webApi, { baseURL } from "../../WebApi/WebApi";



export const getTax = async (tax, onSuccess, onFailure) => {
console.log(tax,"tatataa")
    try {
        const res = await webApi.post(
            `/user-tax-info-post`, 
            tax
        );
        if (res.status === 200) {
            const r = res.data;
            console.log(r, "rerere")
            return onSuccess(r);
        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};