import { React, useState, useEffect, useCallback } from 'react'
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import bclogo from '../../../images/bclogo.png';
import cals from '../../../images/cals.png';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Pagination from 'react-bootstrap/Pagination';
import moment from "moment"
import { CSVLink } from 'react-csv';
// import Headtxt from '../Headtxt';

// import Listview from '../../sales/Listview';
// import Gridview from '../../sales/Gridview';
import Card from 'react-bootstrap/Card'
import Headtxt from '../../dbstructure/Headtxt';
import { getdeleteinvoice, getinvoiceCancelResend, getinvoiceDateSearch, getInvoiceExcel, getInvoiceList, manualSalesReceipt } from '../../../../Service/Customer/CustomerService';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import getSymbolFromCurrency from 'currency-symbol-map'
import axios from 'axios';
import { getAccounttId } from '../../../../Service/Quotation/QuotationService';
import { manualPayment } from '../../../../Service/payment/payment';
import { getAddInventoryaccount } from '../../../../Service/Account/AccountService';
const Invoice = ({ setIsSpinner }) => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [quotation, setQuotation] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [shows, setShows] = useState(false);
    const [querySearch, setQuerySearch] = useState('');
    const [invoiceExport, setInvoiceExport] = useState([]);

    const [manulalPaymentMethod, setManulaPaymentMethod] = useState("");
    const [amoutnt, setAmount] = useState();
    const [manualPaymentDate, setManualPaymentDate] = useState("");
    const [manualPaymentAccount, setManualPaymentAccount] = useState("");
    const [manualPaymentNotes, setManualPaymentNotes] = useState("");
    const [selectedaccountId, setSelectedAccountId] = useState('');
    const [Account, setAccount] = useState([]);
    const [currency, setcurrency] = useState();
    const [currencyData, setCurrencyData] = useState(null);
    const [selectedCurrency, setSelectedCurrency] = useState('KES');
    const [selectedCurrencyValue, setSelectedCurrencyValue] = useState('');
    const [error, setError] = useState(null);
    const [formateDate, setformatedDate] = useState('');
    const [showrecord, setShowRecord] = useState(false);
    const [billId, setBillId] = useState(0);
    const [addaccount, setAddaccount] = useState(false);
    const [select, setSelect] = useState(false);
    const accountpop = () => { setAddaccount(true); };
    const addaccountClose = () => setAddaccount(false);
    const [accountId, setAccountId] = useState("");
    const [accountName, setAccountName] = useState("");
    const [accountDate, setAccountDate] = useState("");
    const [accountdescription, setAccountDescription] = useState("");
    const onAccountName = (e) => {
        setAccountName(e.target.value)
    }
    const onAccountId = (e) => {
        setAccountId(e.target.value)
    }
    const onAccountDescription = (e) => {
        setAccountDescription(e.target.value)
    }
    const CustomDropdown = ({ placeholder, value, }) => {
        console.log('options==', value)
        return (
            <select value={value} className="form-control">

                <option key='1234' value={value}>Cash</option>



            </select>
        );
    };
    const onSave2 = () => {
        setSelect(true)
        
        getAddInventoryaccount(1, accountName, accountId, accountdescription,
            (r) => {
                console.log(r, "response")
                // if (r.success === true) {
                //   toast.success(r.message)
                //   // setAddaccount(false)
                //   // getUserAccounts();
                //   // setSelectedSubtype('')
                //   addaccountClose()
                //   getInventoryDataList();
                // }
                if (r.success === true) {
                    Swal.fire({
                        title: r.message,
                        //  text: 'The product has been added successfully!',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'my-confirm-button'
                        }
                    }).then(() => {
                        addaccountClose()
                        getAccount();
                       
                        
                    })
                }
                else {
                    console.log("nononononono")
                    toast.error(r.message)
                    // setSelectedSubtype('')
                }
            })

    }
    const handleShowRecord = (e, id) => {
        e.preventDefault();
        setBillId(id)
        setShowRecord(true);
    }
    const handleCloseRecord = () => {
        // e.preventDefault();
        setMsg("");
        setShowRecord(false);
        setSelectedAccountId('');
        setAmount('');
        setSelectedCurrencyValue('')
        setSelectedCurrency('')
        setSelect(false)
    };

    const onManulalPaymentMethod = (e) => {
        // e.preventDefault();
        setManulaPaymentMethod(e.target.value)
    }

    const onAmoutnt = (e) => {
        // e.preventDefault();
        setAmount(e.target.value)
    }
    const handleAccountChange = (e) => {
        const selectedId = e.target.value;
        setSelectedAccountId(selectedId);

        console.log(e.target, 'billlamont===');

    };
    const handleCurrencyChange = (e) => {
        const selectedId = e.target.value;
        setSelectedCurrencyValue(selectedId);

        console.log(e.target.value, 'billlamont===');

    };

    console.log('select',select);
    const getAccount=useCallback( async()=> {
        getAccounttId("").then(function (result) {
            const response = result?.data;
            console.log(response, "rrrr")
            setAccount(response?.data?.accounts);
            console.log("select------>",select)
            if(select){
                console.log("select------>if",select)
                setSelectedAccountId(response?.data?.accounts[response?.data?.accounts.length-1]?.account_id)
            }
          
        });
    },[select])
    useEffect(()=>{
        if(select){
            getAccount()
        }
    },[select])
    const onManualPaymentNotes = (e) => {
        // e.preventDefault();
        setManualPaymentNotes(e.target.value)
    }
console.log("selectedaccountId",selectedaccountId)
    const _SUBMIT_RECORD_PAYMENT = async (e) => {
        e.preventDefault();
        console.log('selectedDate', amoutnt)

        if (selectedaccountId === '') {
            toast.error('please select any account')
        }
        else if (amoutnt === '') {
            toast.error('please add amount')
        }
        else if (amoutnt <= 0) {
            toast.error('please add valid amount')
        }
        else if (formateDate === '') {
            toast.error('please add Date')
        }
        else {
            setIsSpinner(true)
            let data = {
                manual_payment_method: manulalPaymentMethod ? manulalPaymentMethod : 'Credit Cards',
                amount: selectedCurrencyValue * amoutnt,
                manual_payment_date: formateDate,
                manual_payment_account: selectedaccountId,
                manual_payment_notes: manualPaymentNotes,
                manual_payment_type: "debit",
                customerCurrencyAmount: amoutnt
            }
            console.log('selectedDate==', selectedDate, data)
            await manualSalesReceipt(billId, data, (res) => {
                console.log(res.message, "????????????????")
                if ("Paid amount should not be more than due amount" === res.message || "Sorry. You can't pay total amount in a single installation!" === res.message) {
                    setIsSpinner(false)
                    setMsg(res.message)
                }
                else {
                    setIsSpinner(false)
                    // toast.success(res.message)

                    Swal.fire({
                        title: res.message,
                        //  text: 'The product has been added successfully!',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'my-confirm-button'
                        }
                    }).then(() => {
                        handleCloseRecord();
                        getInvoiceDataList()
                    })
                }

            })
        }
    }

    const fetchCurrencyData = async () => {
        const UserCurrency = localStorage.getItem("1Simpleclick_Currency");
        setCurrencyData(UserCurrency)
        //setIsSpinner(true)
        try {
            const apiId = 'onesimpleclick809194384'; // Replace with your actual API ID
            const apiKey = 'omumk49ld03teh0h681go0jmqp'; // Replace with your actual API Key
            const encodedCredentials = btoa(`${apiId}:${apiKey}`);

            const response = await axios.get('https://xecdapi.xe.com/v1/convert_from.json/', {
                params: {
                    from: selectedCurrency ? selectedCurrency : "CAD", // base currency
                    to: UserCurrency, // target currency
                    amount: 1,
                },
                headers: {
                    'Authorization': `Basic ${encodedCredentials}`
                }
            });
            console.log('UserCurrency', UserCurrency);
            console.log('selectedCurrency', selectedCurrency)
            setSelectedCurrencyValue(response.data.to[0].mid);
            setIsSpinner(false)
        } catch (error) {
            setError(error.message);
            console.error(error);
            setIsSpinner(false)
        }
    };
    useEffect(() => {
        if (selectedCurrency) {
            fetchCurrencyData();
            getSymbolFromCurrency('')
        }
    }, [selectedCurrency]);
    const refreshExchangeRate = () => {
        fetchCurrencyData();
    };
    const [msg, setMsg] = useState("");
    const handleShowinv = () => {
        navigate("/newinvoicecreation")
    }
    const handleCloseinv = () => {
        setShows(false);
        setShowsvat(true);
    }

    const [showsvat, setShowsvat] = useState(false);
    const handleClosevat = () => setShowsvat(false);



    const [selectedPage, setSelectedPage] = useState('/');


    const handlePageChange = (page) => {
        setSelectedPage(page);
        navigate(page);
    };


    const [supply, setSupply] = useState(false)
    const toggleCompany = (e) => {
        e.preventDefault();
        setSupply(false)
    }
    const toggleIndividual = (e) => {
        e.preventDefault();
        setSupply(true)
    }


    const [view, setView] = useState(false)
    const toggleHandler = (e) => {
        e.preventDefault();
        setView(true)
    }
    const toggleHandler1 = (e) => {
        e.preventDefault();
        setView(false)
    }


    // custom-select-drupdown
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('More');

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };


    // FOR-LOAD-MORE
    const [items, setItems] = useState(['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6', 'Item 7', 'Item 8', 'Item 9', 'Item 10', 'Item 11', 'Item 12', 'Item 13', 'Item 14', 'Item 15']);
    const [visibleItems, setVisibleItems] = useState(5);
    const [loading, setLoading] = useState(false);

    const loadMoreItems = () => {
        setLoading(true);
        setTimeout(() => {
            // Simulate loading delay
            setVisibleItems((prev) => prev + 5);
            setLoading(false);
        }, 1000); // Adjust the delay as needed
    };

    const handleScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop ===
            document.documentElement.offsetHeight
        ) {
            // Auto-load more items when scrolling to the bottom
            loadMoreItems();
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    // DATE-PICKER
    const [selectedDate, setSelectedDate] = useState(null);
    const handleDateChange = (date) => {
        setSelectedDate(date);
        setformatedDate(moment(date).format('DD-MM-YYYY'))
    };

    // RANGE-DATEPICKER
    const [dateRange, setDateRange] = useState([null, null]);

    const handleDateChangeb = (dates) => {
        // Set the time to midnight (00:00:00) to avoid time-related issues
        const midnightDates = dates.map(date => (date ? new Date(date.setHours(0, 0, 0, 0)) : null));
        setDateRange(midnightDates);
    };


    // For toggle
    const [isToggled, setToggled] = useState(false);

    const handleToggleOn = () => {
        setToggled(true);
    };
    const handleToggleOff = () => {
        setToggled(false);
    };
    const handleFromDateChange = (e) => {
        const value = e;
        setFromDate(value);
        triggerSearch(querySearch, e, toDate);
    };

    const handleToDateChange = (e) => {
        const value = e;
        setToDate(value);
        triggerSearch(querySearch, fromDate, e);
    };
    const onStatus = (e) => {
        getInvoiceList(`searchText=${e.target.value}`).then(function (result) {
            const response = result?.data;
            console.log(response, "newrrrr")
            setQuotation(response?.data ? response?.data : []);
        });


    }
    const getSearchList = () => {
        // Construct the filter criteria based on fromDate and toDate
        const filterCriteria = {
            fromDate: fromDate ? moment(fromDate).format('DD-MM-YYYY') : '',
            toDate: toDate ? moment(toDate).format('DD-MM-YYYY') : ''
        };
        console.log('filter---', filterCriteria);
        const queryString = new URLSearchParams(filterCriteria).toString();
        console.log(queryString, 'cre');
        const finalQueryString = querySearch ? `searchText=${querySearch}&${queryString}` : `searchText=&${queryString}`;
        getinvoiceDateSearch(finalQueryString).then(function (result) {
            const response = result?.data;
            console.log('resultttt---', response);
            setQuotation(response?.data);
        });
    };

    const onSearch = (e) => {
        const query = e.target.value;
        setQuerySearch(query);
        triggerSearch(query, fromDate, toDate);
    };

    useEffect(() => {
        getAccount();
        onExcelExport();
        getSearchList();
    }, [fromDate, toDate]);

    const triggerSearch = (query, from, to) => {
        const filterCriteria = {
            // query: query || '',
            fromDate: from ? moment(from).format('DD-MM-YYYY') : '',
            toDate: to ? moment(to).format('DD-MM-YYYY') : ''
        };
        const queryString = new URLSearchParams(filterCriteria).toString();
        const finalQueryString = query ? `searchText=${query}&${queryString}` : `searchText=&${queryString}`;
        console.log(finalQueryString, 'finalQueryString');
        getInvoiceList(finalQueryString).then(function (result) {
            const response = result?.data;
            setQuotation(response?.data);
        });
    };
    async function getInvoiceDataList() {
        getInvoiceList("").then(function (result) {
            const response = result?.data;
            console.log(response, "rrrr")
            setQuotation(response?.data);
        });
    }
    const onDelete = (id) => {
        setIsSpinner(true)
        getdeleteinvoice(id,
            (r) => {
                console.log(r, "rererererere")
                // if (r.success === true) {
                //     toast.success(r.message)
                //     getQuotationDataList();

                // }
                if (r.success === true) {
                    setIsSpinner(false)
                    Swal.fire({
                        title: r.message,
                        //  text: 'The product has been added successfully!',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'my-confirm-button'
                        }
                    }).then(() => {
                        getInvoiceDataList();
                    })
                }
                else {
                    console.log("nononononono")
                    toast.error(r.message)
                    setIsSpinner(false)
                }
            })
    }
    const onCancel = (id) => {
        setIsSpinner(true)
        getinvoiceCancelResend(id, "cancel",
            (r) => {
                console.log(r, "response")
                // if (r.success === true) {
                //     toast.success(r.message)
                //     getQuotationDataList();

                // }
                if (r.success === true) {
                    setIsSpinner(false)
                    Swal.fire({
                        title: r.message,
                        //  text: 'The product has been added successfully!',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'my-confirm-button'
                        }
                    }).then(() => {

                        getInvoiceDataList();
                    })
                }
                else {
                    console.log("nononononono")
                    toast.error(r.message)
                    setIsSpinner(false)
                }
            })
    }

    const onResend = (id) => {
        setIsSpinner(true)
        getinvoiceCancelResend(id, "resend",
            (r) => {
                console.log(r, "rererererere")
                // if (r.success === true) {
                //     toast.success(r.message)
                //     getQuotationDataList();

                // } 
                if (r.success === true) {
                    setIsSpinner(false)
                    Swal.fire({
                        title: r.message,
                        //  text: 'The product has been added successfully!',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'my-confirm-button'
                        }
                    }).then(() => {
                        getInvoiceDataList();
                    })
                }
                else {
                    console.log("nononononono")
                    toast.error(r.message)
                    setIsSpinner(false)
                }
            })
    }
    const onExcelExport = () => {
        getInvoiceExcel().then(function (result) {
            const response = result?.data?.data
            console.log(response, "response")
            setInvoiceExport(response);
        });

    }
    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                    <div className='custTop'>
                        <Link to='/customerlisting'>Customer</Link>
                        <Link to='/quote'>Quote</Link>
                        <Link to="/salesorderlisting">Sales Orders</Link>
                        <Link to='/invoice' className='active'>Invoice</Link>
                        {/* <Form.Select aria-label="Default select example">
                            <option>More</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </Form.Select> */}
                        <div className="custom-dropdown-container customDropdown ms-4">
                            <div className="custom-dropdown-header" onClick={toggleDropdown}>
                                <span>{selectedOption}</span>
                                <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}> <i class="fa-regular fa-angle-down"></i></span>
                            </div>

                            {isOpen && (
                                <ul className="custom-dropdown-options">
                                    <li
                                        className={`option ${selectedOption === 'Option 1' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 1')} hidden
                                    >
                                        Option 1
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 2' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 2')}
                                    >

                                        <Link to='/productservicelisting'>Product &amp; Service</Link>
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 3' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 3')}
                                    >
                                        <Link to="/salesreceiptlist">Sales Receipts</Link>
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 4' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 4')}
                                    >
                                        <Link to="/refundlist">Refunds</Link>
                                    </li>
                                </ul>
                            )}

                            {/* <p>You selected: {selectedOption}</p> */}
                        </div>
                    </div>
                    <div className='productServList'>
                        <div className='dbTopsec pb-2 pt-2 noBackbtn'>
                            <Headtxt link="/" title="Invoice" />

                            {/* <div className='custButton'>
                                <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp;
                                <button className='commonButton expBtn'><i class="fa-light fa-download"></i> Export Excel</button>
                            </div> */}
                        </div>

                        <div className='customerSrchTop pb-3 poResponsive'>
                            <div className='customSrchfield'>
                                <div>
                                    <span><i class="fa-solid fa-magnifying-glass"></i></span><input type='search' placeholder='Search by keywords' onChange={onSearch} value={querySearch} />
                                </div>&nbsp;&nbsp;

                                <div className='invDatepicker'>
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={handleFromDateChange}
                                        selectsStart
                                        startDate={fromDate}
                                        endDate={toDate}
                                        placeholderText="From Date"
                                        isClearable={fromDate ? true : false}
                                    />
                                </div>
                                <div className='invDatepicker' style={{ marginLeft: '10px' }}>
                                    <DatePicker
                                        selected={toDate}
                                        onChange={handleToDateChange}
                                        selectsEnd
                                        startDate={fromDate}
                                        endDate={toDate}
                                        placeholderText="To Date"
                                        isClearable={toDate ? true : false}
                                        minDate={fromDate}

                                    />
                                </div>
                            </div>
                            <div className='custButton flexfield'>

                                <button className='commonButton addBtn' onClick={handleShowinv}><i class="fa-regular fa-circle-plus"></i> Create Invoice</button>&nbsp;&nbsp;
                                <CSVLink data={invoiceExport} filename={"invoice.csv"}>
                                    <button className='commonButton expBtn'><i class="fa-light fa-upload"></i> Export Excel</button>
                                </CSVLink>
                            </div>
                        </div>


                        {/* <div className='addsuppBtn'>
                            <button className={`${!supply ? "commonButton active" : "commonButton "} `} onClick={toggleCompany}>Product</button>
                            <button className={`${supply ? "commonButton active" : "commonButton "} `} onClick={toggleIndividual}>Service</button>
                        </div> */}
                        <div className='mt-2'>
                            <div className='gridList mb-3 flexfield gridListResp' style={{ justifyContent: "flex-end" }}>
                                <div className='srtBy'>
                                    <label>Sort by: </label>&nbsp;
                                    <Form.Select aria-label="Default select example" className='sortby' onChange={onStatus}>
                                        <option value="">Select Status</option>
                                        <option>Cancelled</option>
                                        <option>Paid</option>
                                        <option>Unpaid</option>
                                        <option>Partially Paid</option>
                                        <option>Fully Returned</option>
                                    </Form.Select>&nbsp;
                                </div>
                                <div>
                                    <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler1} className={!view ? "listactive" : ""}><i class="fa-solid fa-list"></i></button>
                                    <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler} className={view ? "listactive" : ""}><i class="fa-solid fa-grid"></i></button>
                                </div>
                            </div>
                            {!view ?
                                <div className='listViews'>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Invoice Date</th>
                                                <th scope="col">Ivoice Id</th>
                                                <th scope="col">Customer Name</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Due Date</th>
                                                <th scope="col">Due Amount</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {quotation?.slice(0, visibleItems)?.map((item, index) => (
                                                <tr key={index} text={item}>
                                                    <td>{item.invoice_date}</td>
                                                    <td><Link to={`/viewinvoicedetails?id=${item.id}`}>{item.invoice_id}</Link></td>
                                                    <td>{item?.type == "Company" ? item?.company_name : item?.fname + " " + item?.lname}</td>
                                                    <td>{item.customer_currency} {item.customerCurrency_totalAmount}</td>
                                                    <td>{item.expiration_date}</td>
                                                    <td>{item.customer_currency} {item.customerCurrency_dueAmount}</td>
                                                    <td>
                                                        <span className='pending' style={{
                                                            backgroundColor: item?.status === 'Cancelled' ? '#f2b8be' :
                                                                item?.status === 'Not Ordered' ? '#f8d5c9' :
                                                                    item?.status === 'Paid' || item?.status === 'Billed' ? '#d7f4b2' :
                                                                        item?.status === 'Partially Paid' ? '#cfdcf8' :
                                                                            //  item?.status === 'Billed' ? '#ce7ac' :
                                                                            item?.status === 'Unpaid' || item?.status === 'Not Billed' ? '#f0caee' : '',
                                                            color: item?.status === 'Cancelled' ? '#e51c0e' :
                                                                item?.status === 'Partially Paid' ? '#1650ce' :
                                                                    item?.status === 'Not Ordered' ? '#FF6C3C' :
                                                                        item?.status === 'Paid' || item?.status === 'Invoiced' ? '#638D2C' :
                                                                            // item?.status === 'Billed' ? '#ce7ac' :
                                                                            item?.status === 'Unpaid' || item?.status === 'Not Invoiced' ? '#e61cdf' : ''
                                                        }}>
                                                            {item?.status}
                                                        </span></td>
                                                    <td>
                                                        <div className='tdFlex'>
                                                            {/* <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span> */}
                                                            {item?.status == 'Unpaid' ? <span className='viewIcon orange'>

                                                                <Link to={`/editinvoice?id=${item.id}`} ><i className="fa-light fa-pen"></i></Link></span> : " "}
                                                            {/* {item?.status == "Unpaid" ? <span className='viewIcon' style={{marginRight: "10px"}} onClick={() => onDelete(item?.id)}><i className="fa-regular fa-trash-can"></i></span>:
                                                            <span className='viewIcon' style={{marginRight: "10px"}} onClick={() =>onDelete(item?.id)}></span>} */}

                                                            <Dropdown className='dropRow'>
                                                                <Dropdown.Toggle id="dropdown-basic">
                                                                    {
                                                                        item?.status != "Cancelled" ?
                                                                            <i className="fa-light fa-ellipsis-vertical"></i> : ""}
                                                                </Dropdown.Toggle>


                                                                <Dropdown.Menu>
                                                                    {item?.status === 'Unpaid' || item?.status === 'Partially Paid' ? (
                                                                        <Dropdown.Item
                                                                            onClick={async (e) => {
                                                                                setAmount(item?.customerCurrency_dueAmount);
                                                                                setSelectedCurrency(item?.currency);
                                                                                handleShowRecord(e, item?.id);
                                                                            }}
                                                                        >
                                                                            <i className="fa-regular fa-file"></i> &nbsp; Record a Payment
                                                                        </Dropdown.Item>
                                                                    ) : null}

                                                                    {item?.status === 'Unpaid' && (
                                                                        <Dropdown.Item onClick={() => onCancel(item?.id)}>
                                                                            <i className="fa-regular fa-circle-xmark"></i> &nbsp; Cancel
                                                                        </Dropdown.Item>
                                                                    )}

                                                                    <Dropdown.Item onClick={() => onResend(item?.id)}>
                                                                        <i className="fa-light fa-share"></i> &nbsp; Resend
                                                                    </Dropdown.Item>

                                                                    {item?.status === 'Paid' || item?.status === 'Partially Returned' ? (
                                                                        <>
                                                                            <Dropdown.Item onClick={e => navigate(`/refunddetails?id=${item.id}`)}>
                                                                                <Link to={`/refunddetails?id=${item.id}`}>
                                                                                    <i className="fa-regular fa-file"></i>
                                                                                </Link>
                                                                                &nbsp; Returns & Refund
                                                                            </Dropdown.Item>
                                                                        </>
                                                                    ) : null}


                                                                    <Dropdown.Item onClick={e => navigate(`/viewinvoicedetails?id=${item.id}`)}>
                                                                        <Link to={`/viewinvoicedetails?id=${item.id}`}>
                                                                            <i className="fa-light fa-eye"></i>
                                                                        </Link>
                                                                        &nbsp; View Invoice Details
                                                                    </Dropdown.Item>

                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </div>
                                                    </td>
                                                </tr>

                                            ))}
                                        </tbody>
                                    </table>

                                    {loading &&
                                        <div className='loadItems'>
                                            <button className="commonButton addBtn">
                                                Loading...
                                            </button>
                                        </div>
                                    }
                                    {!loading && (
                                        <div className='loadItems'>
                                            <button onClick={loadMoreItems} className="commonButton addBtn">
                                                No More Invoice
                                            </button>
                                        </div>
                                    )}

                                </div>
                                :
                                <div className='gridView'>
                                    {quotation?.slice(0, visibleItems)?.map((item, index) => (
                                        <Card>
                                            <Card.Body>
                                                <div className='commonFlex'>
                                                    <h6>{item.invoice_id}</h6>
                                                    <span><img src={cals} alt='' className='mb-2' />&nbsp;&nbsp;{item.invoice_date}</span>
                                                </div>
                                                <div className='commonFlex'>
                                                    <span className='customr'>{item?.type == "Company" ? item?.company_name : item?.fname + " " + item?.lname}</span>
                                                    <span>&nbsp;</span>
                                                </div>
                                                <div className='commonFlex'>
                                                    <span className='pending' style={{
                                                        backgroundColor: item?.status === 'Cancelled' ? '#f2b8be' :
                                                            item?.status === 'Not Ordered' ? '#f8d5c9' :
                                                                item?.status === 'Paid' || item?.status === 'Billed' ? '#d7f4b2' :
                                                                    item?.status === 'Partially Paid' ? '#cfdcf8' :
                                                                        //  item?.status === 'Billed' ? '#ce7ac' :
                                                                        item?.status === 'Unpaid' || item?.status === 'Not Billed' ? '#f0caee' : '',
                                                        color: item?.status === 'Cancelled' ? '#e51c0e' :
                                                            item?.status === 'Partially Paid' ? '#1650ce' :
                                                                item?.status === 'Not Ordered' ? '#FF6C3C' :
                                                                    item?.status === 'Paid' || item?.status === 'Billed' ? '#638D2C' :
                                                                        // item?.status === 'Billed' ? '#ce7ac' :
                                                                        item?.status === 'Unpaid' || item?.status === 'Not Billed' ? '#e61cdf' : ''
                                                    }}>
                                                        {item?.status}
                                                    </span>
                                                    <h6>{item.customer_currency}{item.customerCurrency_dueAmount}</h6>
                                                </div>
                                                <div className='commonFlex gridFooter'>

                                                    <div className='invgridicn'>
                                                        {/* <Link to=""><i class="far fa-envelope"></i></Link>&nbsp; */}

                                                        <div className='tdFlex'>
                                                            {/* <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span> */}
                                                            {item?.status == 'Unpaid' ? <span className='viewIcon orange'>

                                                                <Link to={`/customereditquote?id=${item.id}`} ><i className="fa-light fa-pen"></i></Link></span> : " "}
                                                            {/* {item?.status === 'Cancelled' || item?.status === 'Paid' || item?.status === 'Partially Paid' || item?.status === 'Billed' || item?.status === 'Unpaid' ? <span className='viewIcon' style={{ borderRight: "0" }} onClick={() => onDelete(item?.id)}><i className="fa-regular fa-trash-can"></i></span> : <span className='viewIcon' onClick={() => onDelete(item?.id)} style={{ marginRight: "10px" }}><i className="fa-regular fa-trash-can"></i></span>} */}
                                                            {!(item?.status === 'Cancelled' || item?.status === 'Paid' || item?.status === 'Partially Paid' || item?.status === 'Billed') &&
                                                                <Dropdown className='dropRow'>
                                                                    <Dropdown.Toggle id="dropdown-basic">
                                                                        {
                                                                            item?.status === "Unpaid" ?
                                                                                <i className="fa-light fa-ellipsis-vertical"></i> : ""}
                                                                    </Dropdown.Toggle>


                                                                    <Dropdown.Menu>
                                                                        {item?.status == 'Unpaid' ?
                                                                            <Dropdown.Item onClick={() => onCancel(item?.id)}><i className="fa-regular fa-circle-xmark"></i> &nbsp; Cancel</Dropdown.Item> : ''}
                                                                        <Dropdown.Item onClick={() => onResend(item?.id)}><i className="fa-light fa-share" ></i> &nbsp; Resend</Dropdown.Item>
                                                                        {item?.status == 'Unpaid' ? <Dropdown.Item><i className="fa-light fa-share"></i> &nbsp; Generate Order</Dropdown.Item> : ''}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className='cardBlock actn'>
                                                <div>
                                                    <span className='viewIcon'><Link to={`/customerdetails`}><i class="fa-light fa-eye"></i></Link></span>
                                                    <span className='viewIcon orange'><Link to={`/editcustomer`}><i class="fa-light fa-pen"></i></Link></span>
                                                    <span className='viewIcon'><i class="fa-regular fa-trash-can"></i></span>
                                                </div>
                                            </div> */}
                                            </Card.Body>
                                        </Card>
                                    ))}
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </Sidebar>



            <Modal size="lg" show={show} onHide={handleClose} className="salesExport">
                <div className='salesExpCol'>
                    <Modal.Header closeButton>
                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                    </Modal.Header>
                    <div className='explft'>
                        <div className='salesModtop'>
                            <div className='salesModLft'>
                                <img src={bclogo} alt='' className='mb-2' />
                                <h5>ABC Company</h5>
                                <small>15 / 12 Lorem ipsum lfjfghyti <br />Ney ypork. Hilton </small>
                            </div>
                            <div className='salesModRgt'>
                                <h5>SALES ORDER</h5>
                                <small>no. ClNV-NZ</small><br />
                                <small>24/11/2023</small><br />
                                <strong>Due on receipt</strong>
                            </div>
                        </div>
                        <div className='bsOrder'>
                            <div className='bsOrderlft'>
                                <h5>Billing address</h5>
                                <h6>Test Company</h6>
                                <small>15 / 12 Lorem ipsum lfjfghyti<br />
                                    Ney ypork. Hilton </small>
                            </div>
                            <div className='bsOrderRgt'>
                                <h5>Shipping address</h5>
                                <h6>Test Company</h6>
                                <small>15 / 12 Lorem ipsum lfjfghyti<br />
                                    Ney ypork. Hilton </small>
                            </div>
                        </div>
                        <div className='bsTables'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Item name & description</th>
                                        <th>VAT</th>
                                        <th>Qty</th>
                                        <th>Unit Price</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <h6>ABC Product</h6>
                                            em ipsum lfjfghyti
                                        </td>
                                        <td>20%</td>
                                        <td>1</td>
                                        <td>$20.00/price</td>
                                        <td>$20.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td colSpan="3">
                                            <h6>Subtotal</h6>
                                        </td>
                                        <td>$20.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td colSpan="3">
                                            standard rate($20.00)
                                        </td>
                                        <td>$4.00</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colSpan="3">Total</td>
                                        <td>$24</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className='expRgt'>
                        <button className='commonButton expBtn btnshadow'><i class="fa-regular fa-envelope"></i> Email</button>
                        <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-print"></i> Print</button>
                        <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-down-to-bracket"></i> Download</button>
                    </div>
                </div>
            </Modal>

            <Modal size="lg" show={shows} onHide={handleCloseinv} centered className='customerModal sloModal civModal'>
                <Modal.Header closeButton>
                    <Modal.Title className='px-5'>
                        <h5 className='mb-2'>What is the information to put on the invoices?</h5>
                        <p>Invoice numbers occur in a continuing sequence. What is the last Invoice number that
                            you have sent a customer?</p>
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                            <Col md="6">
                                <span className='unAtr'>*</span>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Invoice Prefix"
                                    className="mb-3"
                                >
                                    <Form.Control type="text" placeholder="Invoice Prefix" />
                                </FloatingLabel>
                            </Col>
                            <Col md="6">
                                <Form.Control type="text" placeholder="001" />
                            </Col>
                        </Form.Group>
                    </Form>


                </Modal.Body>
                <Modal.Footer>
                    <button className='commonButton expBtn' onClick={handleCloseinv}>Next</button>
                </Modal.Footer>
            </Modal>



            <Modal size="md" show={showsvat} onHide={handleClosevat} centered className='customerModal sloModal civModal vatModal'>
                <Modal.Header closeButton>
                    <Modal.Title className='px-5'>
                        <h5 className='mb-2'>Does your company collect VAT?</h5>&nbsp;
                        <div className='radioline yesNo'>
                            <input type="radio" checked id="yes" name="radio-group" onClick={handleToggleOff} />
                            <label for="yes">Yes</label>
                            <input type="radio" id="no" name="radio-group" onClick={handleToggleOn} />
                            <label for="no">No</label>
                        </div>
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                            <Col md="12">
                                <h6>Accounting basis</h6>
                                <div className='radioline invBasis'>
                                    {/* <span>Use same address for delivery</span> */}
                                    <div className='invLft'>
                                        <input type="radio" id="test1" name="radio-group" onClick={handleToggleOff} />
                                        <label for="test1">Cash</label>
                                    </div>
                                    <div className='invrgt'>
                                        <input type="radio" id="test2" name="radio-group" onClick={handleToggleOn} />
                                        <label for="test2">Accural</label>
                                    </div>
                                </div>
                            </Col>

                        </Form.Group>
                        <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                            <Col md="6">
                                <label>VAT registration number</label>
                                <Form.Control type="text" placeholder="Enter VAT registration number" />
                            </Col>
                        </Form.Group>
                    </Form>


                </Modal.Body>
                <Modal.Footer>
                    <button className='backBtn' onClick={handleShowinv}><i class="fa-light fa-circle-arrow-left"></i> Back</button>
                    <button className='commonButton expBtn' onClick={handleClosevat}><Link to="/newinvoicecreation">Next</Link></button>
                </Modal.Footer>
            </Modal>


            <Modal size="lg" show={showrecord} onHide={handleCloseRecord} className="customerModal sloModal">

                <Modal.Header closeButton>
                    <Modal.Title><h5>Record A Manual Payment</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='commonWhite addCustomerForm p-3 mb-3'>
                        <Form>
                            <Form.Group className="row mb-2" style={{ display: 'flex', alignItems: "center" }} controlId="exampleForm.ControlInput1">
                                <Col md="6">
                                    <Form.Select aria-label="Default select example" onChange={onManulalPaymentMethod} >
                                        <option selected value='Credit Cards'>Credit Cards</option>
                                        <option value="Debit Cards">Debit Cards</option>
                                        <option value="NetBanking">NetBanking</option>
                                        <option value="Mobile Payments">Mobile Payments</option>
                                        <option value="Cash">Cash</option>
                                    </Form.Select>
                                </Col>
                                <Col md="2" style={{ display: 'flex', width: '17%' }}>
                                    <p style={{ fontSize: 14 }}>{`Main Amount : ${getSymbolFromCurrency(selectedCurrency)}`}</p>
                                </Col >
                                <Col md="3" className='mb-2'>
                                    <Form.Control type="number" placeholder="Amount" onChange={onAmoutnt} value={amoutnt} />
                                </Col>
                            </Form.Group>

                            <Form.Group className="row" style={{ display: "flex", alignItems: "center" }}>
                                <Col md="2"  >
                                    <p style={{ fontSize: 14 }}>Exchange Rate :</p>

                                </Col>

                                <Col md="2">
                                    <input
                                        id="c474_exchange_rate"
                                        // style={{ marginBottom: '15px', marginRight: '40px' }}
                                        name="exchange_rate"
                                        className="input-medium"
                                        type="text"
                                        tabIndex="7"
                                        value={selectedCurrencyValue}
                                        onChange={handleCurrencyChange}
                                    />

                                </Col>

                                <Col md="3" style={{ marginLeft: '75px' }}>
                                    <p style={{ fontSize: 14 }}>Converted Amount :</p>
                                </Col>
                                <Col md="2">
                                    <input
                                        id="c474_exchange_rate"
                                        style={{ marginBottom: '15px' }}
                                        name="exchange_rate"
                                        readOnly
                                        className="input-medium"
                                        type="text"
                                        tabIndex="7"
                                        value={`${getSymbolFromCurrency(currencyData)} ${(selectedCurrencyValue * amoutnt).toFixed(2)}`} // Assuming you want a fixed two decimal places
                                    />
                                </Col>
                                <Col md="1" style={{ marginLeft: '95px', marginTop: '10px' }}>
                                    {/* <i className="fas fa-sync-alt" style={{ cursor: 'pointer' }} onClick={refreshExchangeRate}></i> */}
                                </Col>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', alignSelf: 'center' }}>
                                    <p>{getSymbolFromCurrency(selectedCurrency)} 1 {selectedCurrency} = {getSymbolFromCurrency(currencyData)} {selectedCurrencyValue} {currencyData}</p>
                                    <i className="fas fa-sync-alt" style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={refreshExchangeRate}></i>
                                </div>
                            </Form.Group>
                            <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                <Col md="6" className='datepickr mb-2'>
                                    <DatePicker
                                        selected={selectedDate}
                                        onChange={handleDateChange}
                                        dateFormat="dd/MM/yyyy" // You can customize the date format
                                        // isClearable
                                        placeholderText="Date"
                                    />
                                </Col>
                                <Col md="6" className='mb-3'>
                                    <Form.Select aria-label="Default select example" onChange={(e) => { e.target.value === "add" ? accountpop() : handleAccountChange(e) }}>
                                        <option selected hidden>Payment Account</option>

                                        <option value="add"><i className="fa-solid fa-plus"></i>+ Add an Account</option>
                                        {Account?.map((item, i) => {

                                            return (
                                                <option
                                                    key={i}
                                                    selected={select && Account.length === i + 1}
                                                    value={item.account_id}
                                                >
                                                    {item.account_name}
                                                </option>
                                            );
                                        })}
                                    </Form.Select>
                                </Col>

                            </Form.Group>
                            <Form.Group className="row billTextarea" controlId="exampleForm.ControlInput1">
                                <Col md="12" className='mb-2'>
                                    <Form.Control as="textarea" placeholder="Notes" rows={3} onChange={onManualPaymentNotes} />
                                </Col>
                            </Form.Group>

                            <p style={{ color: 'red' }}>{msg}</p>
                        </Form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='commonButton addBtn' onClick={handleCloseRecord}>Cancel</button>
                    <button className='commonButton expBtn' onClick={_SUBMIT_RECORD_PAYMENT}>Save</button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" show={addaccount} onHide={addaccountClose} centered className='customerModal sloModal'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Add an Account</h5>
                    </Modal.Title>


                </Modal.Header>
                <Modal.Body>
                    <div className='commonWhite addCustomerForm mb-3'>
                        <Form>
                            <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                <Col md="12" className='mb-3'>
                                    <CustomDropdown placeholder="Account Type" value="Inventory" />
                                </Col>
                            </Form.Group>
                            <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                <Col md="6" className='mb-3'>
                                    <Form.Control type="text" placeholder="Account name" onChange={onAccountName} />
                                </Col>
                                <Col md="6" className='mb-3'>
                                    <Form.Control type="text" placeholder="Account ID" onChange={onAccountId} />
                                </Col>
                            </Form.Group>
                            <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                <Col md="12" className='mb-0'>
                                    <Form.Control as="textarea" placeholder="Description" rows={3} onChange={onAccountDescription} />
                                </Col>
                            </Form.Group>
                        </Form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='commonButton addBtn' onClick={addaccountClose}>Cancel</button>
                    <button className='commonButton expBtn' onClick={onSave2} >Save</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}


export default Invoice