import React, { useState, useEffect } from 'react';
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import Form from 'react-bootstrap/Form';
import Headtxt from '../Headtxt';
import Col from 'react-bootstrap/Col';
import { useParams, useSearchParams, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { getEditCustomer, getCustomerListById } from "../../../../Service/Customer/CustomerService";
import { PhoneInput } from 'react-international-phone';
import { City, Country, State } from "country-state-city";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import {getEditSupplier,getSuppliereListById} from "../../../../Service/Suppliers/SuppliersService";
import { getProductServiceList } from "../../../../Service/Customer/CustomerService";
import Swal from 'sweetalert2';
const Suppliersdetails = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const customerId = new URLSearchParams(location.search);
    console.log(customerId.get(`id`), "customerId")
    const [cityData, setCityData] = useState([]);
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [stateData, setStateData] = useState([]);
    const [deliveryState, setDeliveryState] = useState("");
    const [deliveryCity, setDeliveryCity] = useState("");
    const [productServiceList, setAllProductServiceList] = useState([]);
    const [productServiceList2, setAllProductServiceList2] = useState([]);
    const [show, setShow] = useState(false);
    const [getCustomerId, setCustomerId] = useState([]);
    const [phone, setPhone] = useState("");
    const [deliveryStateData, setDeliveryStateData] = useState([]);
    const [deliveryCityData, setDeliveryCityData] = useState([]);
    const [DeliveryCountry, setDeliveryCountry] = useState("");
    const [show2, setShow2] = useState(false);
    const [deleteId, setDeleteId] = useState([]);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleShow2 = (e) =>{e.preventDefault();setShow2(true);}
    const handleClose2 = () => setShow2(false);
    const [allServiceData, setAllServiceData] = useState([]);
    const [allProductServiceData, setAllProductServiceData] = useState([]);
    const [companyType, setCompanyType] = useState("");

    // For toggle
    const [isToggled, setToggled] = useState(false);
    const [allData, setAllData] = useState(
        {
            fname: '',
            lname: '',
            companyname: '',
            accountingKey: '',
            industry: '',
            industryId: '',
            country: '',
            state: '',
            city: '',
            zipcode: '',
            address: '',

        }
    )

    let countryData = Country.getAllCountries();
    let deliveryCountryData = Country.getAllCountries();


    const handleToggleOn = () => {
        setToggled(true);
    };
    const handleToggleOff = () => {
        setToggled(false);
    };


    // For drag-drop
    const [file, setFile] = useState(null);

    const handleDrop = (event) => {
        event.preventDefault();

        const droppedFile = event.dataTransfer.files[0];
        handleFile(droppedFile);
    };

    const handleFile = (selectedFile) => {
        setFile(selectedFile);

        // You can perform additional actions with the file, such as uploading it to a server.
        // For simplicity, we're just logging the file information here.
        console.log('File selected:', selectedFile);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };


    useEffect(() => {
        getallCustomerList()
    }, [])

    async function getallCustomerList() {
        getSuppliereListById(customerId.get(`id`)).then(function (result) {
            const response = result.data;
            console.log(response, "response")
            setAllData(response?.data?.supplier_details)
            setAllProductServiceData(response?.data?.products);
            setAllServiceData(response?.data?.services);
            // setCustomerId(response?.data);

            // setDepartmentId(response?.data?.businessUnit?.Departments);
        });
    }
    useEffect(() => {
   
        getProductServiceDataList();
    
      }, []);
    async function getProductServiceDataList() {
        getProductServiceList("").then(function (result) {
          const response = result?.data;
          // console.log(response, "rrrr")
          setAllProductServiceList2(response?.data);
          setAllProductServiceList(response?.data);
        });
      }

      const onSearchByProSer = (e) => {
        if (e.target.value != "") {
    
          getProductServiceList(e.target.value).then(function (result) {
            const response = result?.data;
            // console.log(response, "rrrr")
    
            setAllProductServiceList(response?.data);
    
          }
          )
        } else {
          setAllProductServiceList(productServiceList2)
        }
      }
      const onCheck = (data) => {
        if (data.type === 'service') {
          if (allServiceData.includes(data)) {
            const filterData = allServiceData.filter(d => d !== data);
            setAllServiceData(filterData, () => {
              console.log(allServiceData, "allServiceData");
            });
          } else {
            const updatedData = [...allServiceData, data];
            console.log(updatedData, "updatedData")
            setAllServiceData(updatedData);
          }
        } else {
          if (allProductServiceData.includes(data)) {
            const filterData = allProductServiceData.filter(d => d !== data);
            setAllProductServiceData(filterData, () => {
              console.log(allProductServiceData, "allProductServiceData");
            });
          } else {
            const updatedData = [...allProductServiceData, data];
            console.log(updatedData, "updatedData")
            setAllProductServiceData(updatedData);
          }
        }
      };
    const [qtyIndex,setQtyIndex]=useState([]);
    const onCheckDelete = (id,indexValue) => {
      // console.log(indexValue,"indexValue")
      setDeleteId((prevDeleteId) => {
        if (prevDeleteId.includes(id)) {
          let tempQtyIndex = qtyIndex.filter(item=>item!==indexValue)
          setQtyIndex(tempQtyIndex)
          // Remove the id from the array
          return prevDeleteId.filter((d) => d !== id);
        } else {
          setQtyIndex([...qtyIndex,indexValue]);
          // Add the id to the array
          return [...prevDeleteId, id];
        }
      }, () => {
        console.log("hihihihihihihi", deleteId);
      });
    };
    const combinedData = [...allProductServiceData, ...allServiceData];
    console.log(allProductServiceData, "allProductServiceDataoutside");
    //edit part
    const onfName = (e) => {
        setAllData({ ...allData, fname: e.target.value })
    }
    const onlName = (e) => {
        setAllData({ ...allData, lname: e.target.value })
    }

    const onCompanyName = (e) => {
        setAllData({ ...allData, company_name: e.target.value })
    }
    const onEmail = (e) => {
        setAllData({ ...allData, email: e.target.value })
    }
    const onPhone = (e) => {
        setAllData({ ...allData, phone: e.target.value })
    }

    const onWebUrl = (e) => {
        setAllData({ ...allData, website_url: e.target.value })
    }

    const onVat = (e) => {
        setAllData({ ...allData, vat_number: e.target.value })
    }
    const onAddress = (e) => {
        setAllData({ ...allData, billing_address: e.target.value })
    }
    const onZipCode = (e) => {
        setAllData({ ...allData, zipcode: e.target.value })
    }

    const onDeliveryAddress = (e) => {
        setAllData({ ...allData, delivery_address: e.target.value })
    }

    const onDeliveryZipCode = (e) => {
        setAllData({ ...allData, delivery_zipcode: e.target.value })
    }
    console.log(allData, "Alldata")

    const onCountry = (e) => {
        // setAllData({ ...allData, lastname: e.target.value })
        setCountry(e.target.value)
        setStateData(State.getStatesOfCountry(e.target.value));
    }

    const onState = (e) => {
        setState(e.target.value);
        setCityData(City.getCitiesOfState(country, e.target.value));
    }

    const onCity = (e) => {
        setCity(e.target.value)
    }

    const onDeliveryCountry = (e) => {
        console.log(e.target.value, "cdscds");
        setDeliveryCountry(e.target.value)
        setDeliveryStateData(State.getStatesOfCountry(e.target.value));
    }

    const onDeliveryState = (e) => {
        console.log(e.target.value, "stateiso")
        setDeliveryState(e.target.value);
        setDeliveryCityData(City.getCitiesOfState(DeliveryCountry, e.target.value));
    }

    const onDeliveryCity = (e) => {
        setDeliveryCity(e.target.value)
    }

    const onCompanyType = (e) => {
        setCompanyType(e.target.value)
    }



    const onSave = () => {
       
        let products_details = [];
        let service_details = [];
        allProductServiceData.map((d, i) => (
          products_details.push({
            "type": d?.type,
            "id":d?.id,
          })
        ))
        allServiceData.map((d, i) => (
            service_details.push({
              "type": d?.type,
              "id":d?.id,
            })
          ))
        if ( allData?.type === "Company" && allData?.company_name === null) {
            toast.error("Please type company name")

        }
        else if ( allData?.type === "Individual" && allData?.fname === "") {
            toast.error("Please type first name")

        } else if ( allData?.type === "Individual" && allData?.lname === "") {
            toast.error("Please type last name")

        } else if (allData?.email === "") {
            toast.error("Please type valid email")
        } else if (allData?.phone === "" || phone.length < 7) {
            toast.error("Please type phone number")
        } 
        // else if ( allData?.type === "Company" && allData?.vat_number <= 0) {
        //     toast.error("Please type a valid VAT Number(Optional)")
        // }
        // else if (allData?.billing_address === "") {
        //     toast.error("Please type billing address")
        // }
        else if (allData?.country === "") {
            toast.error("Please select country")

        } else if (allData?.state === "") {
            toast.error("Please select state")
        } else if (allData?.city === "") {
            toast.error("Please select city")
        }
        // else if (allData?.zipcode === "") {
        //     toast.error("Please type zipcode")
        // }
        // else if (allData?.zipcode <= 0) {
        //     toast.error("Please type a valid zipcode")
        // }
        
        else {
            console.log(products_details)
        getEditSupplier(companyType ? companyType : allData?.type, allData?.company_name, allData?.fname, allData?.lname, allData?.email, phone ? phone : allData?.phone, allData?.website_url
            , allData?.vat_number, allData?.billing_address,
            countryData.find(c => c.isoCode === country)?.name ? countryData.find(c => c.isoCode === country)?.name : allData?.country,
            stateData.find(c => c.isoCode === state)?.name ? stateData.find(c => c.isoCode === state)?.name : allData?.state
            , city ? city : allData?.city, allData?.zipcode,customerId.get(`id`),products_details,service_details,
            (r) => {
                console.log(r, "response")
                // if (r.success === true) {
                //     toast.success(r.message)
                //     navigate("/supplierlisting")
                // }
                if (r.success === true) {
                  Swal.fire({
                   title: r.message,
                 //  text: 'The product has been added successfully!',
                   icon: 'success',
                   confirmButtonText: 'OK',
                   customClass: {
                       confirmButton: 'my-confirm-button'
                   }
                }).then(() => {
                  navigate("/supplierlisting")
                })
                }
                else {
                    console.log("nononononono")
                    toast.error(r.message)

                }
            })
        }
    }

    return (
        <div className="customerListing pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                    <div className='custBtm'>
                        <div className='dbTopsec pb-3'>
                            <Headtxt link="/supplierlisting" title="Supplier Details" />
                            {/* <div className='custButton'>
                <button className='commonButton expBtn' onClick={handleShow}><i class="fa-light fa-upload"></i> Import</button>
              </div> */}
                        </div>

                        <div className='commonWhite addCustomerForm p-3 mb-3'>

                            <Form>
                                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                                    <Col md="4">
                                        <Form.Select aria-label="Default select example" onChange={onCompanyType} disabled>
                                            <option selected hidden>{allData?.type}</option>
                                            <option value="Company">Company</option>
                                            <option value="Individual">Individual</option>
                                            {/* <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option> */}
                                        </Form.Select>
                                    </Col>
                                    {companyType === "" && allData?.type === "Individual" || companyType === "Individual" ? <><Col md="4">
                                        <Form.Control type="text" name='fname' placeholder="First Name" value={allData?.fname} onChange={onfName} disabled/>
                                    </Col>
                                        <Col md="4">
                                            <Form.Control type="text" name='lname' placeholder="Last Name" onChange={onlName} value={allData?.lname} disabled/>
                                        </Col></>
                                        : <Col md="8">
                                            <Form.Control type="text" name='companyname' placeholder="Company Name" onChange={onCompanyName} value={allData?.company_name} disabled />
                                        </Col>
                                    }
                                </Form.Group>

                                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                                    <Col md="6">
                                        <Form.Control type="email" placeholder="Email address" value={allData?.email} onChange={onEmail} disabled/>
                                    </Col>
                                    <Col md="6" disabled>
                                        {/* <Form.Control type="text" placeholder="Phone Number" value={allData?.phone} onChange={onPhone} /> */}
                                        <PhoneInput
                                            defaultCountry="ua"
                                            value={allData?.phone}
                                            className='phInp'
                                            onChange={(phone) => setPhone(phone)}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                                    <Col md="6">
                                        <Form.Control type="text" placeholder="Website URL (Optional)" value={allData?.website_url} onChange={onWebUrl}disabled />
                                    </Col>
                                    <Col md="6">
                                        <Form.Control type="text" placeholder="VAT Number(Optional)" value={allData?.vat_number} onChange={onVat} disabled/>
                                    </Col>
                                </Form.Group>

                                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                                    <Col md="6">
                                        <Form.Control type="text" placeholder="Billing Address (Optional)" value={allData?.billing_address} onChange={onAddress} disabled/>
                                    </Col>
                                    <Col md="6">
                                        <Form.Select aria-label="Default select example" onChange={onCountry} disabled>
                                            <option selected hidden>{country ? country : allData?.country}</option>
                                            {countryData.map((con) => (
                                                <option value={con.isoCode}>{con.name}</option>

                                            ))}

                                        </Form.Select>
                                    </Col>
                                </Form.Group>

                                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1" >
                                    <Col md="4">
                                        <Form.Select aria-label="Default select example" onChange={onState} disabled>
                                            <option selected hidden>{state ? state : allData?.state}</option>
                                            {stateData.map((sta) => (
                                                <option value={sta.isoCode}>{sta.name}</option>

                                            ))}

                                        </Form.Select>
                                    </Col>
                                    <Col md="4">
                                        <Form.Select aria-label="Default select example" onChange={onCity} disabled>
                                            <option selected hidden>{city ? city : allData?.city}</option>
                                            {cityData.map((cit) => (
                                                <option value={cit.name}>{cit.name}</option>

                                            ))}

                                        </Form.Select>
                                    </Col>
                                    <Col md="4">
                                        <Form.Control type="text" placeholder="Zip Code(Optional)" value={allData?.zipcode} onChange={onZipCode} disabled/>
                                    </Col>
                                </Form.Group>                        
                            </Form>
                        </div>
                        <div className='salesOrdTable'>
                  <div className='listViews'>
                    <table class="table">
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th scope="col">Product/Service name</th>
                          <th scope="col">Qty</th>
                          <th scope="col">Unit</th>
                          <th scope="col">Price</th>
                          {/* <th scope="col">Disc.%</th> */}
                          {/* <th scope="col">Total</th>
                          <th scope="col">VAT%</th> */}
                        </tr>
                      </thead>
                      <tbody>
                      {combinedData.length !== 0 && combinedData.map((d, i) => (
          <tr key={d.id}>
            <td>
              <input 
                type='checkbox' 
                checked={deleteId.includes(d.id)} 
                onChange={() => onCheckDelete(d.id, i + 1)} 
                disabled
              />
            </td>
            <td>
              <span className='lightBlock' style={{border: 'none'}}>{d.name}</span>
            </td>
            <td>
              {d.type === "service" ?  <input className='lightBlock' style={{border: 'none'}} readOnly name='quantity' value={d.quantity} />:
               d.qty 
                ? <input className='lightBlock' style={{border: 'none'}} readOnly name='quantity' value={d.quantity} />
                : <input className='lightBlock' name='quantity' style={{border: 'none'}} value={d.quantity} />
              }
            </td>
            <td>
              <input className='lightBlock' value={d.unit} style={{border: 'none'}} readOnly />
            </td>
            <td>
              <input className='lightBlock' style={{border: 'none'}} value={d.type === "service" ? d.price : d.purchase_price} readOnly />
            </td>
          </tr>
        ))}


                      </tbody>
                    </table>
                  </div>
                </div>

                        {/* <button className='commonButton expBtn' onClick={onSave}>Save</button>

                        <button className='commonButton expBtn' onClick={(e)=>handleShow2(e)}><i class="fa-regular fa-magnifying-glass"></i> Add Product or Service</button>&nbsp;&nbsp; */}

                    </div>
                </div>
            </Sidebar>

            <Modal show={show} onHide={handleClose} centered className='customerModal'>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Import Products</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='modalContent'>
                        <div
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            className='drgdrop'
                        >
                            <i class="fa-light fa-cloud-arrow-up"></i>
                            <div style={{ display: "flex" }}>
                                <p>Drag &amp; drop files or </p>
                                <input type="file" onChange={(e) => handleFile(e.target.files[0])} style={{ display: 'none' }} />
                                <button onClick={() => document.querySelector('input[type="file"]').click()}>Browse</button>
                            </div>

                            {file && <p>Selected File: {file.name}</p>}
                        </div>
                    </div>
                    <div>
                        <p><button className='commonButton modalsubmit'>Submit</button></p>
                        <p><button className='commonButton downloadformat'>Download format</button></p>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
            </Modal>
            <Modal size="xl" show={show2} onHide={handleClose2} centered className='customerModal sloModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>List of Products and Services</h5>
            <div className='slSearch'>
              <i class="fa-regular fa-magnifying-glass"></i>
              <Form.Control
                type="search"
                placeholder="Search Product and Service"
                className=" mr-sm-2"
                onChange={onSearchByProSer}
              />
            </div>
          </Modal.Title>
          {/* <button className='commonButton expBtn modAddbtn' onClick={productServiceShow2}><i class="fa-regular fa-plus"></i> Add</button> */}

        </Modal.Header>


        <Modal.Body>
          <div className='listViews salesPopTable listScroll'>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Type</th>
                  <th scope="col">Name</th>
                  <th scope="col">description</th>
                  <th scope="col">Price</th>
                  <th scope="col">Unit</th>
                  <th scope="col">Tax</th>
                </tr>
              </thead>
              <tbody>
                {productServiceList?.map((d) => (
                  allProductServiceData.some(item => item.id === d.id) ||allServiceData.some(item => item.id === d.id) ?null:
                  <tr>
                    <td>
    {
        d?.type === 'product' ? 
        <>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
            <input 
                type='checkbox' 
                onChange={() => onCheck(d)} 
              //  //disabled={allProductServiceData.length !== 0 ? d?.type !== allProductServiceData[0].type : false} 
                checked={allProductServiceData.includes(d)}
                style={{marginTop:'6px'}} 
            />
        </>
        :
        <input 
            type='checkbox' 
            onChange={() => onCheck(d)} 
           // //disabled={allProductServiceData.length !== 0 ? d?.type !== allProductServiceData[0].type : false} 
           checked={allServiceData.includes(d)} 
            style={{marginTop:'6px'}}
        />
    }
    &nbsp;&nbsp;&nbsp;{d?.type}
</td>
                    <td>{d?.name}</td>
                    <td>{d?.description}</td>
                    <td>{d?.type === "service" ? d?.price : d?.purchase_price}</td>
                    <td>{d?.unit}</td>
                    <td>{d?.tax}</td>
                  </tr>
                ))}




              </tbody>
            </table>
          </div>

          {/* */}


        </Modal.Body>
        <Modal.Footer>
          {/* <button className='commonButton expBtn' onClick={handleClose}>Submit</button> */}
          <button className='commonButton expBtn' onClick={handleClose2}>Close</button>
        </Modal.Footer>
      </Modal>
        </div>
    )
}

export default Suppliersdetails