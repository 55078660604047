import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Customdropdown = ({ options, placeholdr }) => {
    
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  
    const handleOptionClick = (option) => {
      setSelectedOption(option);
      setIsOpen(false);
    };

    const handlePop = () => {
      setIsOpen(false);
    }


  return (
    <div className="custom-dropdown">
      <div className="dropdown-toggle" onClick={toggleDropdown}>
        {selectedOption ? selectedOption : placeholdr}
      </div>
      {isOpen && (
        <div className="dropdown-menu">
          <ul >
          {options && options.map((option, index) => (
            <li key={index} 
           // onClick={() => handleOptionClick(option)}
            >
              <Link>{option}</Link>
            </li>
          ))}
        </ul>
        {/* <button onClick={handlePop}>fsdfsdf</button> */}

        </div>
      )}
    </div> 
  );
};

export default Customdropdown