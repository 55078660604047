import React from 'react';
import Navigation from '../layout/Navigation';
import invoicebnr from '../../images/invoicebnr.png';
import laptop from '../../images/laptop.png';
import Footer2 from '../layout/Footer2';
import { Link } from 'react-router-dom';

const Featureinvoice = (props) => {  


  return (
    <>
    <div className='manageBusinessBlock'>
      <header>
        <Navigation/>
        </header>


        <div className='authLogo cfoLogo featinvoiceHeight'>&nbsp;</div>
      <section className='bannerBg cfoBnr pt-0 featInvBnr'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <h1 className='mb-0'>Invoicing software that's easy and free</h1>                    
                    <p>Rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quo tinctio. </p>
              
                    <h6 className='mb-5'>Rerum facilis est et expedita distinctio. Nam libero tempore...</h6>
                    <Link to="/login" className='commonButton getStartbtn'><i class="fa-regular fa-paper-plane"></i> Get Started Now</Link>
                </div>
                <div className='col-lg-6'>
                    <img src={invoicebnr} alt='' />
                </div>
            </div>
        </div>
    </section>
    <section className='cfoBtm featInvChasing'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-4'>
                    <h4>Stop chasing clients</h4>
                    <h2>around</h2>
                    <p>Rerum facilis est et expedita distinctio. Nam
 libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.</p> 

                </div>
                <div className='col-md-4'>
                    <div className='seamLess'>
                        <span className='smCircle'><i class="fa-light fa-memo-pad"></i></span>
                        <div>
                            <h5>Seamless Invoicing</h5>
                            <p>Stay on top of your billing. Create, customize and send invoices.</p>
                        </div>
                    </div>
                    <div className='seamLess'>
                        <span className='smCircle'><i class="fa-light fa-paper-plane"></i></span>
                        <div>
                            <h5>Up-To-Date Inventory</h5>
                            <p>Stay on top of your billing. Create, customize and send invoices.</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='seamLess'>
                        <span className='smCircle'><i class="fa-regular fa-file-invoice-dollar"></i></span>
                        <div>
                            <h5>Stress- Free Accounting</h5>
                            <p>Stay on top of your billing. Create, customize and send invoices.</p>
                        </div>
                    </div>
                    <div className='seamLess'>
                        <span className='smCircle'><i class="fa-light fa-file-chart-column"></i></span>
                        <div>
                            <h5>Up-To-Date Inventory</h5>
                            <p>Stay on top of your billing. Create, customize and send invoices.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='manageInvaccount'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'>
                    <h2>Manage accounts receivable effortlessly</h2>
                    <div className='mvTrack mb-2 mt-2'>Access all customer information in one place</div>
                    <p>Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas</p>
                    <div className='mvTrack mb-2 mt-2'>Track payments and communications</div>
                    <p>Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas</p>
                </div> 
                <div className='col-md-6'><img src={laptop} alt='' className='img-fluid' /></div>
            </div>
        </div>
    </section>

    </div>
    <Footer2/>
    </>
  )
}

export default Featureinvoice