import React from 'react';
import gpa from '../../images/gp1.png';
import gpb from '../../images/gp2.png';
import inv from '../../images/needinvoice.png';
import stress from '../../images/stress.png';
import update from '../../images/update.png';
import crm from '../../images/crm.png';

const Need = () => {
  return (
    <div className='needBg'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12 mb-3'>
            <h6>1SimpleClick.com</h6>
            {/* <h3>Everything you need</h3> */}
            <h3>Accounting Simplified</h3>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-3'>
            <div className='need_a'>
              <img src={inv} alt='' />
              <h6>Real Time Invoicing:</h6>
              <p>Create personalized and professional invoices effortlessly and send them in one simple click.  </p>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='need_a'>
              <img src={stress} alt='' />
              <h6>Automated and Smart Accounting:</h6>
              <p>Achieve precision in every financial transaction and gain real-time insights into your financial health. Get comprehensive overview of your financial performance, account balances, and key financial measures. </p>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='need_a'>
              <img src={update} alt='' />
              <h6>Fast and Secure Payment Solutions:</h6>
              <p>Payments made simple, transactions made easy with 1SimpleClick- where speed meets security in every payment. Create purchase orders, run vendor reports, review outstanding invoices, and record payments with our automated accounting  entries. </p>
            </div>
          </div>

          <div className='col-lg-3'>
            <div className='need_a'>
              <img src={crm} alt='' />
              <h6>Insightful Financial Analytics:</h6>
              <p>See your financial data come to life with our visualized dashboards and create comprehensive reports at the click of a button. Generate Generates balance sheets, income statements, and cash flow statements, and create sub-ledger reports for timely decision making. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Need