import { React, useState } from 'react'
import Sidebar from '../layout/Sidebar'
import Dbheader from '../layout/Dbheader'
import Form from 'react-bootstrap/Form';
import Headtxt from './Headtxt';
import Col from 'react-bootstrap/Col';
import 'react-datepicker/dist/react-datepicker.css';
import Customdropdown from '../Customdropdown';
import usrimg from '../../images/profimg.png';
import { Link } from 'react-router-dom';

const Myprofile = () => {

    const [image, setImage] = useState({ preview: "", raw: "" });
    const [getImage, setGetImage] = useState(null);
    const [getImageresult, setGetImageresult] = useState("")

    const onFile = (event) => {

        if (event["target"].files.length > 0) {
          const file = event["target"].files[0];
        //   setFieldValue("browse", file.name)
    
          // setUserData({...userData, profileImage : file});
          setImage({
            preview: URL.createObjectURL(event.target.files[0]),
            raw: event.target.files[0]
          });
    
          setGetImage(file)
          setGetImageresult(file.name)
    
          //     const reader = new FileReader();
          //     reader.readAsDataURL(file);
          //     reader.onload = (event) => {
          //         setGetImageresult(reader.result);
          //   };
        }
    
      }


    const options = ['All accounts', 'Company', 'Individuals'];



    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                    {/* <div className='custTop'>
                    <Link to='/balancesheet' >Balance Sheet</Link>
                    <Link to='/profitloss' >Income (Profit & Loss)</Link>
                    <Link to='' className='active'>Cashflow Statement</Link>
                    
                </div> */}



                    <div className='productServList'>
                        <div className='dbTopsec pb-4 pt-2 noBackbtn'>
                            <Headtxt link="" title="My Profile" />

                            <div className='custButton'>
                                {/* <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp; */}
                                <Link className='commonButton expBtn' to="/changepassword"><i class="fa-light fa-lock"></i> Change Password</Link>
                            </div>
                        </div>


                        <div className='commonWhite addCustomerForm mb-3 p-3'>
                            {/* <h6>Date Range</h6> */}
                            <Form>
                                <div className='profileTop'>
                                    <div className='profileUpload'>
                                        <div className='profileUpblock'>
                                            <label htmlFor="upload-button" className='uploadBtn'>
                                                {image.preview ? (
                                                    <img src={image.preview} alt="dummy" width="100" height="100" />
                                                ) : (
                                                    <>
                                                        <span className="fa-stack fa-2x">
                                                            <i class="fa-thin fa-image"></i>
                                                        </span>
                                                    </>
                                                )}
                                                <span className="profEdit m-0"><i class="fa-light fa-pen-line"></i></span>
                                            </label>

                                            <input
                                                type="file"
                                                accept="image/png, image/jpeg"
                                                id="upload-button"
                                                style={{ display: "none" }}
                                                onChange={onFile}
                                            />
                                        </div>
                                        <div className='brwseInst'>
                                            <h6>&nbsp; ABC Company</h6>
                                        </div>
                                    </div>
                                    <Link to="/mysubscription" className='commonButton expBtn'>My Subscription</Link>


                                </div>
                                {/* <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="12" className='mb-3'>
                                        <Customdropdown options={options} placeholdr="Consulting for" />
                                    </Col>
                                </Form.Group> */}
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="John" />
                                    </Col>
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="Doe" />
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="Abc company" />
                                    </Col>
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="mail" placeholder="abc@dummy.com" />
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="123456789" />
                                    </Col>
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="North America" />
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="12" className='mb-3'>
                                        <Form.Control as="textarea" placeholder="15 / 12 Lorem ipsum lfjfghytiNey ypork. Hilton " rows={3} style={{ height: "40px" }} />
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="Canada" />
                                    </Col>
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="Toronto" />
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="123456789" />
                                    </Col>
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="www.abc.com" />
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col lg="12" className='mb-3'>
                                        <Form.Control type="text" placeholder="Financial" />
                                    </Col>
                                </Form.Group>
                                <h5>VAT Settings</h5>
                                <h6>Sales Tax</h6>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="Tax Label" />
                                    </Col>
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="12.00" />
                                    </Col>
                                </Form.Group>
                                <Link><i class="fa-regular fa-circle-plus"></i> Add a tax</Link>
                                <h6 className='mt-3'>Purchase Tax</h6>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="Tax Label" />
                                    </Col>
                                    <Col lg="6" className='mb-3'>
                                        <Form.Control type="text" placeholder="12.00" />
                                    </Col>
                                </Form.Group>
                                <Link><i class="fa-regular fa-circle-plus"></i> Add a tax</Link>

                                <Form.Group className="row mt-3" controlId="exampleForm.ControlInput1">
                                    <Col md="12" className='datepickr'>
                                        <button className='commonButton expBtn'>Save</button>&nbsp;&nbsp;
                                        <button className='commonButton addBtn'>Cancel</button>
                                    </Col>

                                </Form.Group>

                            </Form>
                        </div>




                    </div>
                </div>
            </Sidebar>





        </div>
    )
}


export default Myprofile