import { React, useState,useEffect } from 'react'
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import bclogo from '../../../images/bclogo.png';
// import Headtxt from '../Headtxt';
import moment from "moment"
// import Listview from '../../sales/Listview';
// import Gridview from '../../sales/Gridview';
import Card from 'react-bootstrap/Card'
import Headtxt from '../../dbstructure/Headtxt';
import { getSalesOrderList, getSalesReceiptDateSearch, getSalesReceiptDownload, getSalesReceiptList } from '../../../../Service/Customer/CustomerService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import getSymbolFromCurrency from 'currency-symbol-map'
import { toast } from 'react-toastify';
import cals from '../../../images/cals.png';
const Salesreceiptlist = () => {

    const [show, setShow] = useState(false);
    const [quotation, setQuotation] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [querySearch, setQuerySearch] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  const [visibleItems, setVisibleItems] = useState(5);
  useEffect(() => {
    // Initial load of items
    loadMoreItems();
}, []);

const loadMoreItems = () => {
  setLoading(true);
  setTimeout(() => {
      // Simulate loading delay
      setVisibleItems((prev) => prev + 5);
      setLoading(false);
  }, 1000); // Adjust the delay as needed
};

    const [selectedPage, setSelectedPage] = useState('/');
    const navigate = useNavigate();

    const handlePageChange = (page) => {
        setSelectedPage(page);
        navigate(page);
    };


    const [supply, setSupply] = useState(false)
    const toggleCompany = (e) => {
        e.preventDefault();
        setSupply(false)
    }
    const toggleIndividual = (e) => {
        e.preventDefault();
        setSupply(true)
    }


const [view, setView] = useState(false)
  const toggleHandler = (e) => {
    e.preventDefault();
    setView(true)
  }
  const toggleHandler1 = (e) => {
    e.preventDefault();
    setView(false)
  }


  // custom-select-drupdown
const [isOpen, setIsOpen] = useState(false);
const [selectedOption, setSelectedOption] = useState('Sales Receipts');

const toggleDropdown = () => {
  setIsOpen(!isOpen);
};

const handleOptionClick = (option) => {
  setSelectedOption(option);
  setIsOpen(false);
};
const handleFromDateChange = (e) => {
    const value = e;
    setFromDate(value);
    triggerSearch(querySearch, e, toDate);
};

const handleToDateChange = (e) => {
    const value = e;
    setToDate(value);
    triggerSearch(querySearch, fromDate,e);
};
const onStatus = (e) => {
    getSalesReceiptList(`searchText=${e.target.value}`).then(function (result) {
        const response = result?.data;
        console.log(response, "newrrrr")
        setQuotation(response?.data ? response?.data : []);
    });


}
const getSearchList = () => {
    // Construct the filter criteria based on fromDate and toDate
    const filterCriteria = {
        fromDate: fromDate ? moment(fromDate).format('DD-MM-YYYY') : '',
        toDate: toDate ? moment(toDate).format('DD-MM-YYYY') : ''
    };
    console.log('filter---', filterCriteria);
    const queryString = new URLSearchParams(filterCriteria).toString();
    console.log(queryString, 'cre');
    getSalesReceiptDateSearch(`searchText=&${queryString}`).then(function (result) {
        const response = result?.data;
        console.log('resultttt---', response);
        setQuotation(response?.data);
    });
};

const onSearch = (e) => {
    const query = e.target.value;
    setQuerySearch(query);
    triggerSearch(query, fromDate, toDate);
};

useEffect(() => {
    // getAccount();
    // onExcelExport();
    getSearchList();
}, [fromDate, toDate]);

const triggerSearch = (query, from, to) => {
    const filterCriteria = {
       // query: query || '',
        fromDate: from ? moment(from).format('DD-MM-YYYY') : '',
        toDate: to ? moment(to).format('DD-MM-YYYY') : ''
    };
    const queryString = new URLSearchParams(filterCriteria).toString();
    const finalQueryString = query ? `searchText=${query}&${queryString}` : `searchText=&${queryString}`;
    console.log(finalQueryString, 'finalQueryString');
    getSalesReceiptList(finalQueryString).then(function (result) {
        const response = result?.data;
        setQuotation(response?.data);
    });
};
async function getInvoiceDataList() {
    getSalesOrderList("").then(function (result) {
        const response = result?.data;
        console.log(response, "rrrr")
        setQuotation(response?.data);
    });
}

const onDownload = (id) => {
  
    getSalesReceiptDownload(id,
  (r) => {
    console.log(r, "response")
    if (r.success === true) {
    //   toast.success(r.message)
    //   navigate('/bill')
   // setDataPdf(r)
   // const pdfUrl = dataPdf?.data?.pdf;
      window.open(r?.data?.pdf, '_blank'); // Open PDF in a new tab
  
    } else {
      console.log("nononononono")
      toast.error(r.message)

    }
  })
}
    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                <div className='custTop'>
                        <Link to='/customerlisting'>Customer</Link>
                        <Link to='/quote'>Quote</Link>
                        <Link to="/salesorderlisting">Sales Orders</Link>
                        <Link to='/invoice'>Invoice</Link>
                        {/* <Form.Select aria-label="Default select example">
                            <option>More</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </Form.Select> */}
                        <div className="custom-dropdown-container customDropdown ms-4">
                            <div className="custom-dropdown-header active" onClick={toggleDropdown}>
                                <span>{selectedOption}</span>
                                <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}> <i class="fa-regular fa-angle-down"></i></span>
                            </div>

                            {isOpen && (
                                <ul className="custom-dropdown-options">
                                    <li
                                        className={`option ${selectedOption === 'Option 1' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 1')} hidden
                                    >
                                        Option 1
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 2' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 2')} 
                                    >
                                        <Link to='/productservicelisting'>Product &amp; Service</Link>
                                        
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 3' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 3')} hidden
                                    >
                                        <Link to="/salesreceiptlist">Sales Receipts</Link>
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 4' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 4')}
                                    >
                                        <Link to="/refundlist">Refunds</Link>
                                    </li>
                                </ul>
                            )}

                            {/* <p>You selected: {selectedOption}</p> */}
                        </div>
                    </div>
                    <div className='productServList'>
                        <div className='dbTopsec pb-4 pt-2'>
                            <Headtxt link="/customerlisting" title="Sales Receipts" />
                            
                            {/* <div className='custButton'>
                                <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp;
                                <button className='commonButton expBtn'><i class="fa-light fa-download"></i> Export Excel</button>
                            </div> */}
                        </div>

                        <div className='customerSrchTop pb-3 poResponsive'>
                            <div className='customSrchfield'>
                                <div>
                                    <span><i class="fa-solid fa-magnifying-glass"></i></span><input type='search' placeholder='Search by keywords' onChange={onSearch} value={querySearch} />
                                </div>&nbsp;&nbsp;
                           
                                <div className='invDatepicker'>
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={handleFromDateChange}
                                        selectsStart
                                        startDate={fromDate}
                                        endDate={toDate}
                                        placeholderText="From Date"
                                        isClearable={fromDate ? true : false}
                                    />
                                </div>
                                <div className='invDatepicker' style={{ marginLeft: '10px' }}>
                                    <DatePicker
                                        selected={toDate}
                                        onChange={handleToDateChange}
                                        selectsEnd
                                        startDate={fromDate}
                                        endDate={toDate}
                                        placeholderText="To Date"
                                        isClearable={toDate ? true : false}
                                        minDate={fromDate}

                                    />
                                </div>
                            </div>
              <div className='custButton flexfield'>

              {/* <div className='srtBy'>
                                <label>Sort by: </label>&nbsp;
              <Form.Select aria-label="Default select example" className='sortby'>
              <option>Pending</option>
              <option>Delivered</option>
              <option>Closed</option>
            </Form.Select>&nbsp;
                                </div> */}
                
            {/* <button className='commonButton addBtn'><Link to="/"><i class="fa-regular fa-circle-plus"></i> Add Refunds</Link></button>&nbsp;&nbsp; */}
                {/* <button className='commonButton expBtn' onClick={handleShow}>Add Sales Receipts</button> */}
                <button className='commonButton expBtn'><Link to="/salesreceiptadd">Add Sales Receipts</Link></button>
              </div>
            </div>


                        {/* <div className='addsuppBtn'>
                            <button className={`${!supply ? "commonButton active" : "commonButton "} `} onClick={toggleCompany}>Product</button>
                            <button className={`${supply ? "commonButton active" : "commonButton "} `} onClick={toggleIndividual}>Service</button>
                        </div> */}
                        <div className='mt-2'>
                            <div className='gridList mb-3 flexfield' style={{justifyContent: "flex-end"}}>
                                
                                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler1} className={!view? "listactive" : "" }><i class="fa-solid fa-list"></i></button>
                                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler} className={view? "listactive" : "" }><i class="fa-solid fa-grid"></i></button>
                            </div>
                            {!view ? 
                            <div className='listViews'>
                            <table class="table">
                                <thead>
                                    <tr>
                                        {/* <th scope="col">Sales Receipt ID</th> */}
                                        <th scope="col">Receipt Date</th>
                                        <th scope="col">Invoice ID</th>
                                        <th scope="col">Customer Name</th>
                                        <th scope="col">Amount Received</th>
                                        <th scope="col">Status</th>
                                        
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {quotation?.map((item, index) => (
                                        <tr>
                                            {/* <td>{item?.invoice_id}</td> */}
                                            <td>{item?.payment_date}</td>
                                            <td><Link to={`/viewinvoicedetails?id=${item.InvoiceID}`}>{item?.invoice_id}</Link></td>
                                            <td>{item?.type == "Company" ? item?.company_name : item?.fname + " " + item?.lname}</td>
                                            <td>{`${item.user_currency}  ${item.amount}`}</td>
                                            <td>
                                            <span className='pending' style={{ backgroundColor: item?.status === 'Cancelled' ?  '#f2b8be': item?.status === 'Not Ordered' ? '#f8d5c9' : item?.status === 'Partially Paid' ? '#cfdcf8': item?.status === 'Paid' ? '#d7f4b2': item?.status === 'Billed' ? '#ce7ac': item?.status === 'Unpaid'||'Not Billed' ? '#f0caee' : '#ce7ac',
    color: item?.status === 'Cancelled' ? '#e51c0e' : item?.status === 'Partially Paid' ? '#1650ce': item?.status === 'Not Ordered' ? '#FF6C3C': item?.status === 'Paid' ? '#ce7ac': item?.status === 'Billed' ? '#ce7ac' : item?.status === 'Unpaid'||'Not Billed' ? '#e61cdf': '#6b992c' }}>
        {item?.status}
    </span></td>
                                            <td>
                                            <div className='tdFlex'>
                                            <Dropdown className='dropRow'>
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="fa-light fa-ellipsis-vertical"></i>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>                                                            
                                                            <Dropdown.Item onClick={()=>onDownload(item.id)}><i class="fa-light fa-download"></i> &nbsp; Download</Dropdown.Item>                                                            
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    </div>
                                            </td>
                                        </tr>
                                ))}
                                        
                                </tbody>
                            </table>

                            {loading &&
                                        <div className='loadItems'>
                                            <button className="commonButton addBtn">
                                                Loading...
                                            </button>
                                        </div>
                                    }
                                    {!loading && (
                                        <div className='loadItems'>
                                            <button onClick={loadMoreItems} className="commonButton addBtn">
                                                No More Sales Receipt
                                            </button>
                                        </div>
                                    )}
                        </div>
                         : 
                         <div className='gridView'>
               {quotation?.map((item) => (

<Card>
    <Card.Body>
        <div className='commonFlex'>
            <h6>{item?.invoice_id}</h6>
            <span><img src={cals} alt='' className='mb-2' /> &nbsp;&nbsp;{item?.payment_date}</span>
        </div>
        <div className='commonFlex'>
            <span className='customr'>{item?.type == "Company" ? item?.company_name : item?.fname + " " + item?.lname}</span>
            <span>&nbsp;</span>
        </div>
        <div className='commonFlex'>
        <span className='pending' style={{
backgroundColor: item?.status === 'Cancelled' ? '#f2b8be' :
item?.status === 'Not Ordered' ? '#f8d5c9' :
item?.status === 'Paid'||item?.status === 'Billed' ? '#d7f4b2' :
item?.status === 'Partially Paid' ? '#cfdcf8' :
//  item?.status === 'Billed' ? '#ce7ac' :
item?.status === 'Unpaid' || item?.status === 'Not Billed' ? '#f0caee' : '',
color: item?.status === 'Cancelled' ? '#e51c0e' :
item?.status === 'Partially Paid' ? '#1650ce' :
item?.status === 'Not Ordered' ? '#FF6C3C' :
item?.status === 'Paid' ||item?.status === 'Billed'? '#638D2C' :
// item?.status === 'Billed' ? '#ce7ac' :
item?.status === 'Unpaid' || item?.status === 'Not Billed' ? '#e61cdf' : ''
}}>{item?.status}</span>
            <h6>{`${getSymbolFromCurrency(item.currency)}  ${item.amount}`}</h6>
        </div>
        <div className='commonFlex gridFooter'>
            <div>
                {/* <span className='viewIcon orange'><Link to={`/`}><i className="fa-light fa-pen"></i></Link></span> */}
                {/* <span className='viewIcon'><i className="fa-regular fa-trash-can"></i></span> */}
            </div>
            <div className='invgridicn'>
                {/* <Link to=""><i className="far fa-envelope"></i></Link>&nbsp; */}
                <div className='tdFlex'>
                    {/* <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span> */}
                    {/* <span className='viewIcon orange'><Link to={`/`}><i className="fa-light fa-pen"></i></Link></span>
                    <span className='viewIcon'><i className="fa-regular fa-trash-can"></i></span>&nbsp;&nbsp; */}
                    <Dropdown className='dropRow'>
                           <Dropdown.Toggle id="dropdown-basic">
                           <i class="fa-light fa-ellipsis-vertical"></i>
                           </Dropdown.Toggle>

                           <Dropdown.Menu>                                                            
                               <Dropdown.Item onClick={()=>onDownload(item.id)} ><i class="fa-light fa-download"></i> &nbsp; Download</Dropdown.Item>                                                            
                           </Dropdown.Menu>
                       </Dropdown>
                </div>
            </div>
        </div>
        {/* <div className='cardBlock actn'>
        <div>
            <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span>
            <span className='viewIcon orange'><Link to={`/editcustomer`}><i className="fa-light fa-pen"></i></Link></span>
            <span className='viewIcon'><i className="fa-regular fa-trash-can"></i></span>
        </div>
    </div> */}
    </Card.Body>
</Card>
))}

        </div>
        }

                        </div>
                    </div>
                </div>
            </Sidebar>



            <Modal size="lg" show={show} onHide={handleClose} className="salesExport">
                <div className='salesExpCol'>
                    <div className='explft'>
                        <div className='salesModtop'>
                            <div className='salesModLft'>
                                <img src={bclogo} alt='' className='mb-2'/>
                                <h5>ABC Company</h5>
                                <small>15 / 12 Lorem ipsum lfjfghyti <br/>Ney ypork. Hilton </small>
                            </div>
                            <div className='salesModRgt'>
                                <h5>SALES ORDER</h5>
                                <small>no. ClNV-NZ</small><br/>
                                <small>24/11/2023</small><br/>
                                <strong>Due on receipt</strong>
                            </div>
                        </div>
                        <div className='bsOrder'>
                            <div className='bsOrderlft'>
                                <h5>Billing address</h5>
                                <h6>Test Company</h6>
                                <small>15 / 12 Lorem ipsum lfjfghyti<br/>
Ney ypork. Hilton </small>
                            </div>
                            <div className='bsOrderRgt'>
                            <h5>Shipping address</h5>
                                <h6>Test Company</h6>
                                <small>15 / 12 Lorem ipsum lfjfghyti<br/>
Ney ypork. Hilton </small>
                            </div>
                        </div>
                        <div className='bsTables'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Item name & description</th>
                                        <th>VAT</th>
                                        <th>Qty</th>
                                        <th>Unit Price</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <h6>ABC Product</h6>
                                            em ipsum lfjfghyti
                                        </td>
                                        <td>20%</td>
                                        <td>1</td>
                                        <td>$20.00/price</td>
                                        <td>$20.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td colSpan="3">
                                            <h6>Subtotal</h6>
                                        </td>                                        
                                        <td>$20.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td colSpan="3">
                                            standard rate($20.00)
                                        </td>                                        
                                        <td>$4.00</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                        <td>sfsdf</td>
                                        <td>sfsdf</td>
                                        <td>sfsdf</td>
                                        <td>sfsdf</td>
                                        <td>sfsdf</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className='expRgt'>
                    <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                    </Modal.Header>
                    <button className='commonButton expBtn btnshadow'><i class="fa-regular fa-envelope"></i> Email</button>
                    <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-print"></i> Print</button>
                    <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-down-to-bracket"></i> Download</button>
                    </div>
                </div>                
            </Modal>
        </div>
    )
}

export default Salesreceiptlist