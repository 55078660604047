import { React, useState,useEffect } from 'react'
import Sidebar from '../../../layout/Sidebar'
import Dbheader from '../../../layout/Dbheader'
import { Link,useNavigate } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
// import Headtxt from '../Headtxt';

import Listview from '../../sales/Listview';
import Gridview from '../../sales/Gridview';
import Card from 'react-bootstrap/Card'
import Headtxt from '../../Headtxt';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import bclogo from '../../../../images/bclogo.png';
import { listPurchaseOrder,getExcelOrder,getPurchaseOrderResend,getPurchaseOrderDelete } from "../../../../../Service/PurchaseOrder/PurchaseOrder";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import getSymbolFromCurrency from 'currency-symbol-map'
import Swal from 'sweetalert2';
const Purchaseorderlisting = ({setIsSpinner}) => {
    const [loading, setLoading] = useState(false);
    const [visibleItems, setVisibleItems] = useState(0);
    const navigate = useNavigate();
    const [orderFilterStatus,setOrderFilterStatus] = useState('');
    const [quoteExport,setQuoteExport]=useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [purchaseOrderList,setPurchaseOrderList] = useState([]);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        setViews(false)
    };

//listPurchaseOrder
  const _LIST_PURCHASE_ORDER = async (e)=>{
    await listPurchaseOrder().then(res=>{
        if(res.data.success===true){
            setPurchaseOrderList(res.data.data);
        }else{
            setPurchaseOrderList([]);
        }
        
    }).catch(err=>{
      console.log(err);
    })
  }

  useEffect(()=>{
    _LIST_PURCHASE_ORDER();
  },[])

    const [supply, setSupply] = useState(false)
    const toggleCompany = (e) => {
        e.preventDefault();
        setSupply(false)
    }
    const toggleIndividual = (e) => {
        e.preventDefault();
        setSupply(true)
    }


const [view, setView] = useState(false)
  const toggleHandler = (e) => {
    e.preventDefault();
    setView(true)
  }
  const toggleHandler1 = (e) => {
    e.preventDefault();
    setView(false)
  }

  const [views, setViews] = useState(false)
  const toggleHandlerb = (e) => {
    e.preventDefault();
    setViews(true)
  }


  // custom-select-drupdown
const [isOpen, setIsOpen] = useState(false);
const [selectedOption, setSelectedOption] = useState('More');

const toggleDropdown = () => {
  setIsOpen(!isOpen);
};

const handleOptionClick = (option) => {
  setSelectedOption(option);
  setIsOpen(false);
};

const [shows, setShows] = useState(false);
const handleShowinv = () => {
    setShows(true);
    setShowsvat(false);
}
const handleCloseinv = () => {
    setShows(false);
    setShowsvat(true);
}

const [showsvat, setShowsvat] = useState(false);
const handleClosevat = () => setShowsvat(false);

const handleFromDateChange = (e) => {
    // console.log(moment(e).format('DD-MM-YYYY'),"LLLLLLLLLLLLLLLLLLLLLL")
    const value  = e;
    setFromDate(value);
    // Perform filtration based on the selected from date
    // You can put your filtration logic here
    // console.log('Filter data based on from date:',value);
    listPurchaseOrder('',moment(e).format('DD-MM-YYYY'),toDate?moment(toDate).format('DD-MM-YYYY'):'').then(function (result) {
        const response = result?.data;
        if(response.success){
            setPurchaseOrderList(response.data);
        }else{
            setPurchaseOrderList([]);
        }
        // console.log(response, "rrrr")
        // 
      });
  };

  const handleToDateChange = (e) => {
    console.log(e,":::::::::::::::::::")
    const value  = e;
    setToDate(value);
    // Perform filtration based on the selected to date
    // You can put your filtration logic here
    listPurchaseOrder('',moment(fromDate).format('DD-MM-YYYY'),moment(e).format('DD-MM-YYYY')).then(function (result) {
        const response = result?.data;
        if(response.success){
            setPurchaseOrderList(response.data);
        }else{
            setPurchaseOrderList([]);
        }
        // console.log(response, "rrrr")
        // 
      });
 
    console.log('Filter data based on to date:', value);
  //  getSearchList()
    //setToDate('')
  };

  const onExcelExport = () => {
    getExcelOrder().then(function (result) {
        const response = result?.data?.data
        console.log(response,"response")
        setQuoteExport(response);
    });



}


const onSearch = (e) => {
    if(e.target.name==='searchKeyword'){
      listPurchaseOrder(e.target.value,'','').then(function (result) {
        const response = result?.data;
        if(response.success){
            setPurchaseOrderList(response.data);
        }else{
            setPurchaseOrderList([]);
        }
        // console.log(response, "rrrr")
        // 
      });
    }
}

const _STATUS_FILTER = (e)=>{
    // console.log(e.target.value)
    setOrderFilterStatus(e.target.value)
    
}

const onCancel = (id) => {
    setIsSpinner(true)
    getPurchaseOrderResend(id, "cancel",
        (r) => {
            console.log(r, "response")
            // if (r.success === true) {
            //     toast.success(r.message)
            //     _LIST_PURCHASE_ORDER();

            // }
            if (r.success === true) {
                setIsSpinner(false)
                Swal.fire({
                 title: r.message,
               //  text: 'The product has been added successfully!',
                 icon: 'success',
                 confirmButtonText: 'OK',
                 customClass: {
                     confirmButton: 'my-confirm-button'
                 }
             }).then(() => {
                _LIST_PURCHASE_ORDER();
             })
           }  
            else {
                console.log("nononononono")
                toast.error(r.message)
                setIsSpinner(false)
            }
        })
}

const onResend = (id) => {
    setIsSpinner(true)
    getPurchaseOrderResend(id, "resend",
        (r) => {
            console.log(r, "rererererere")
            // if (r.success === true) {
            //     toast.success(r.message)
            //     _LIST_PURCHASE_ORDER();

            // }
            if (r.success === true) {
                setIsSpinner(false)
                Swal.fire({
                 title: r.message,
               //  text: 'The product has been added successfully!',
                 icon: 'success',
                 confirmButtonText: 'OK',
                 customClass: {
                     confirmButton: 'my-confirm-button'
                 }
             }).then(() => {
                _LIST_PURCHASE_ORDER();
             })
           }  
            else {
                console.log("nononononono")
                toast.error(r.message)
                setIsSpinner(false)
            }
        })
}

// getPurchaseOrderDelete
const _DELETE_PURCHASE_ORDER = async (e,id)=>{
    e.preventDefault();
    setIsSpinner(true)
    getPurchaseOrderDelete(id,(res)=>{
        console.log(res,">>>>")
        setIsSpinner(false)
        toast.success(res.message)
        _LIST_PURCHASE_ORDER();
    }).catch(err=>{
        console.log(err);
        setIsSpinner(false)
    })
}

const handleGenerateBill = (e,item) => {
    e.preventDefault();
    navigate(`/billaddsubmit?id=${item.id}`);
};

const backgroundColor = key =>{
//    e.preventDefault();
let bakcgroundColor = "";
   switch (key) {
    case 'Cancelled':
        bakcgroundColor ="#f2b8be"
        break;
    case 'Not Ordered':
        bakcgroundColor= "#f8d5c9"
        break;
    case 'Partially Paid':
        bakcgroundColor= "#cfdcf8"
        break;
    case 'Paid':
        bakcgroundColor= "rgb(215, 244, 178)"
        break;
    case 'Billed':
        bakcgroundColor= "rgb(215, 244, 178)"
        break;
    case 'Unpaid':
        bakcgroundColor= "#f0caee"
        break;
    case 'Not Billed':
        bakcgroundColor= "#f0caee"
        break;
    default:
        console.log('default')
        bakcgroundColor= '#ce7ac'
        break;
   }
   return bakcgroundColor;
}

const [hasMore, setHasMore] = useState(true);
useEffect(() => {
    // Initial load of items
    loadMoreItems();
}, []);

const loadMoreItems = () => {
    if (!hasMore || loading) return;
    setLoading(true);
    setTimeout(() => {
        const newItems = fetchMoreItems(visibleItems);
        if (newItems.length > 0) {
            //setItems((prevItems) => [...prevItems, ...newItems]);
            setVisibleItems((prev) => prev + newItems.length);
        } else {
            setHasMore(false);
        }
        setLoading(false);
    }, 1000); // Adjust the delay as needed
};

const handleScroll = () => {
    if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 90
    ) {
        // Auto-load more items when scrolling close to the bottom
        loadMoreItems();
    }
};

useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, [loading, hasMore]);

const fetchMoreItems = (start) => {
    // Simulate fetching more items from an API
    const itemsPerPage = 5;
    const totalItems = 20; // Adjust as needed for your use case
    const newItems = [];

    for (let i = start; i < start + itemsPerPage; i++) {
        if (i < totalItems) {
            newItems.push(`Item ${i + 1}`);
        }
    }

    return newItems;
};

    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                <div className='custTop'>
            <Link to='/supplierlisting'>Supplier</Link>
            <Link to='/productservicelistings'>Product &amp; Service</Link>
            <Link to='/quotelisting'>Quote</Link>
            <Link to='/purchaseorderlisting' className='active'>Purchase Order</Link>
            <Link to='/bill'>Bill</Link>
            <div className="custom-dropdown-container customDropdown ms-4">
                            <div className="custom-dropdown-header" onClick={toggleDropdown}>
                                <span>{selectedOption}</span>
                                <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}> <i class="fa-regular fa-angle-down"></i></span>
                            </div>

                            {isOpen && (
                                <ul className="custom-dropdown-options">
                                    <li
                                        className={`option ${selectedOption === 'Option 1' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 1')} hidden
                                    >
                                        Option 1
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 2' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 2')}
                                    >
                                        <Link to="/billpayment"><i class="fa-light fa-calendar-lines-pen"></i> Bill Payment</Link>
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 3' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 3')} hidden
                                    >
                                        <Link to="">Option 3</Link>
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 4' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 4')}
                                    >
                                         <Link to="/purchaserefundlist"><i className="fa-light fa-calendar-lines-pen"></i> Refunds</Link>
                                    </li>
                                </ul>
                            )}

{/* <p>You selected: {selectedOption}</p> */}
                        </div>
          </div>
                    <div className='productServList'>
                        <div className='dbTopsec pb-4 pt-2'>
                            <Headtxt link="/supplierlisting" title="Purchase Order" />
                            
                            {/* <div className='custButton'>
                                <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp;
                                <button className='commonButton expBtn'><i class="fa-light fa-download"></i> Export Excel</button>
                            </div> */}
                        </div>

                        <div className='customerSrchTop pb-3 poResponsive'>
              <div className='customSrchfield'>
                <div>
                <span><i class="fa-solid fa-magnifying-glass"></i></span><input type='search' placeholder='Search by keywords' onChange={onSearch} name='searchKeyword'/>
                </div>&nbsp;&nbsp;
                {/* <Form.Select aria-label="Default select example" className='moreDrop'>
              <option>Date</option>
            </Form.Select> */}
                                     <div className='invDatepicker'>
                                    {/* <DatePicker
                                        selected={dateRange[0]}
                                        onChange={(dates) => handleDateChangeb(dates)}
                                        selectsRange
                                        startDate={dateRange[0]}
                                        endDate={dateRange[1]}
                                        isClearable
                                        placeholderText="Date"
                                    /> */}
                                     <DatePicker
                    selected={fromDate}
                    onChange={handleFromDateChange}
                    selectsStart
                    startDate={fromDate}
                    endDate={toDate}
                    placeholderText="From Date"
                    isClearable={fromDate?true:false}
                />
                 </div>
                   <div className='invDatepicker' style={{ marginLeft: '10px' }}>
                <DatePicker
                    selected={toDate}
                    onChange={handleToDateChange}
                    selectsEnd
                    startDate={fromDate}
                    endDate={toDate}
                    placeholderText="To Date"
                    isClearable={toDate?true:false}
                    
                />
                             {/* <label htmlFor="fromDate">From Date:</label> */}
                            
       
            {/* <input
              type="date" 
              id="fromDate" 
              value={fromDate} 
              onChange={handleFromDateChange} 
              placeholder={!fromDate ? 'Select From Date' : undefined}
            />
       

      {/* <label htmlFor="toDate">To Date:</label> */}
                    
                {/*  <input type="date" id="toDate" value={toDate} onChange={handleToDateChange} style={{marginLeft:10}} placeholder="Select From Date" /> */}
                                </div>
                </div>
              <div>
                
            <Link to='/addpurchaseorder' className='commonButton addBtn'><i class="fa-regular fa-circle-plus"></i>Add Order</Link>&nbsp;&nbsp;
                {/* <button className='commonButton expBtn' onClick={onExcelExport}><i class="fa-light fa-upload"></i> Export PDF</button> */}
                <CSVLink data={quoteExport} filename={"purchaseorders.csv"} onClick={onExcelExport}>
                                     <button className='commonButton expBtn'><i class="fa-light fa-upload"></i> Export</button>
                                     </CSVLink>
              </div>
                        </div>


                        {/* <div className='addsuppBtn'>
                            <button className={`${!supply ? "commonButton active" : "commonButton "} `} onClick={toggleCompany}>Product</button>
                            <button className={`${supply ? "commonButton active" : "commonButton "} `} onClick={toggleIndividual}>Service</button>
                        </div> */}
                        <div className='mt-2'>
                            
                            <div className='gridList mb-3 flexfield gridListResp' style={{justifyContent: "flex-end"}}>
                                <div className='srtBy'>
                                    <label>Sort by: </label>&nbsp;
                                    <Form.Select aria-label="Default select example" className='sortby' onChange={_STATUS_FILTER}>
                                    {/* <option>Pending</option>
                                    <option>Cancelled</option>
                                    <option>Bill Generated</option> */}
                                    <option value="">Select Status</option>
                                    {/* <option value="Not Ordered">Not Ordered</option> */}
                                    <option value="Not Billed">Not Billed</option>
                                    <option value="Billed">Billed</option>
                                    <option value="Cancelled">Cancelled</option>
                                    <option value="Paid">Paid</option>
                                    <option value="Partially Paid">Partially Paid</option>
                                    </Form.Select>&nbsp;
                                </div>
                                <div>
                                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler1} className={!view? "listactive" : "" }><i class="fa-solid fa-list"></i></button>
                                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler} className={view? "listactive" : "" }><i class="fa-solid fa-grid"></i></button>
                                </div>
                            </div>
                            {!view ? 
                            <div className='listViews'>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Order Id</th>
                                        <th scope="col">Supplier Name</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Due Date</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                       purchaseOrderList.length >0 && !orderFilterStatus &&
                                       purchaseOrderList?.slice(0, visibleItems)?.map(item=>{return(<tr>
                                        <td>{item.date}</td>
                                        <td><Link to={`/viewpurchaseorder?id=${item.id}`}>{item?.order_id}</Link></td>
                                        <td>{item?.type==='Company'?item?.company_name:`${item?.fname} ${item?.lname}`}</td>
                                        <td>{item?.supplier_currency}{`${Number(item?.total).toFixed(2)}`}</td>
                                        <td>{item?.expiration_date}</td>
                                        <td>  <span className='pending' style={{ 
                                            backgroundColor: backgroundColor(item?.status),
                                             color: item?.status === 'Cancelled' ? '#e51c0e' : item?.status === 'Partially Paid' ? '#1650ce': item?.status === 'Not Ordered' ? '#FF6C3C': item?.status === 'Paid' ? "rgb(99, 141, 44)": item?.status === 'Billed' ? "rgb(99, 141, 44)" : item?.status === 'Unpaid'||'Not Billed' ? '#e61cdf': '#6b992c' }}>
        {item?.status}
    </span></td>
                                        <td>
                                        <div className='tdFlex'>
                                            {/* <span className='viewIcon'><Link to={`/customerdetails`}><i class="fa-light fa-eye"></i></Link></span> */}
                                            {(item?.status === "Not Billed") ?
                                            <span className='viewIcon orange'><Link to={`/editproductservice?id=${item.id}`}><i class="fa-light fa-pen"></i></Link></span>:''}

                                            <span className= {item?.status === "Not Billed" ?'viewIcon':''} ><i class="fa-regular fa-trash-can" onClick={e=>_DELETE_PURCHASE_ORDER(e,item.id)}></i></span>
                                            {!(item?.status === 'Cancelled' || item?.status === 'Paid' || item?.status === 'Partially Paid' || item?.status==='Billed') &&
                                               <Dropdown className='dropRow'>
                                                <Dropdown.Toggle id="dropdown-basic">
                                                                    {
                                                                        item?.status === "Not Billed" ?
                                                                            <i className="fa-light fa-ellipsis-vertical"></i> : ""}
                                                </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => onCancel(item?.id)}><i className="fa-regular fa-circle-xmark"></i> &nbsp; Cancel</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => onResend(item?.id)}><i className="fa-light fa-share"></i> &nbsp; Resend</Dropdown.Item>
                                                        <Dropdown.Item onClick={e=>handleGenerateBill(e,item)}><i class="fa-thin fa-file-invoice"></i> &nbsp; Bill Generate</Dropdown.Item>
                                                        <Dropdown.Item onClick={e=>{ navigate(`/viewpurchaseorder?id=${item.id}`)}}><Link to={`/viewpurchaseorder?id=${item.id}`}><i className="fa-light fa-eye"></i></Link>&nbsp; View Order Details</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>}
                                              
                                        </div>
                                        </td>
                                    </tr>)})
                                    }
                                    {
                                       purchaseOrderList.length >0 && orderFilterStatus==='Cancelled' &&
                                       purchaseOrderList.filter(item=>orderFilterStatus?item.status===orderFilterStatus:item)?.slice(0, visibleItems)?.map(item=>{return(<tr>
                                        <td>{item.date}</td>
                                        <td><Link to={`/viewpurchaseorder?id=${item.id}`}>{item?.order_id}</Link></td>
                                        <td>{item?.type==='Company'?item?.company_name:`${item?.fname} ${item?.lname}`}</td>
                                        <td>${Number(item?.total).toFixed(2)}</td>
                                        <td>{item?.expiration_date}</td>
                                        <td>  <span className='pending' style={{ 
                                            backgroundColor: "#f2b8be",
                                             color: "#e51c0e" }}>
        {item?.status}
    </span></td>
                                        <td>
                                        <div className='tdFlex'>
                                    
                                            {(item?.status === "Not Billed") ?
                                            <span className='viewIcon orange'><Link to={`/editproductservice?id=${item.id}`}><i class="fa-light fa-pen"></i></Link></span>:''}

                                            <span className= {item?.status === "Not Billed" ?'viewIcon':''} ><i class="fa-regular fa-trash-can" onClick={e=>_DELETE_PURCHASE_ORDER(e,item.id)}></i></span>&nbsp;&nbsp;
                                            {!(item?.status === 'Cancelled' || item?.status === 'Paid' || item?.status === 'Partially Paid' || item?.status==='Billed') &&
                                                <Dropdown className='dropRow'>
                                                <Dropdown.Toggle id="dropdown-basic">
                                                                    {
                                                                        item?.status === "Not Billed" ?
                                                                            <i className="fa-light fa-ellipsis-vertical"></i> : ""}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => onCancel(item?.id)}><i className="fa-regular fa-circle-xmark"></i> &nbsp; Cancel</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => onResend(item?.id)}><i className="fa-light fa-share"></i> &nbsp; Resend</Dropdown.Item>
                                                        <Dropdown.Item onClick={e=>handleGenerateBill(e,item)}><i class="fa-thin fa-file-invoice"></i> &nbsp; Bill Generate</Dropdown.Item>
                                                </Dropdown.Menu>
                                                </Dropdown>
                                    }
                                        </div>
                                        </td>
                                    </tr>)})
                                    }
                                    {
                                       purchaseOrderList.length >0 && orderFilterStatus==='Paid' &&
                                       purchaseOrderList.filter(item=>orderFilterStatus?item.status===orderFilterStatus:item)?.slice(0, visibleItems)?.map(item=>{return(<tr>
                                        <td>{item.date}</td>
                                        <td>{item?.order_id}</td>
                                        <td>{item?.type==='Company'?item?.company_name:`${item?.fname} ${item?.lname}`}</td>
                                        <td>${Number(item?.total).toFixed(2)}</td>
                                        <td>{item?.expiration_date}</td>
                                        <td>  <span className='pending' style={{ 
                                            backgroundColor: "rgb(215, 244, 178)",
                                             color: "#638D2C" }}>
        {item?.status}
    </span></td>
                                        <td>
                                        <div className='tdFlex'>
                                            {/* <span className='viewIcon'><Link to={`/customerdetails`}><i class="fa-light fa-eye"></i></Link></span> */}
                                            {(item?.status === "Not Billed") ?
                                            <span className='viewIcon orange'><Link to={`/editproductservice?id=${item.id}`}><i class="fa-light fa-pen"></i></Link></span>:''}

                                            <span className= {item?.status === "Not Billed" ?'viewIcon':''} ><i class="fa-regular fa-trash-can" onClick={e=>_DELETE_PURCHASE_ORDER(e,item.id)}></i></span>&nbsp;&nbsp;
                                            {!(item?.status === 'Cancelled' || item?.status === 'Paid' || item?.status === 'Partially Paid' || item?.status==='Billed') &&
                                                <Dropdown className='dropRow'>
                                                <Dropdown.Toggle id="dropdown-basic">
                                                                    {
                                                                        item?.status === "Not Billed" ?
                                                                            <i className="fa-light fa-ellipsis-vertical"></i> : ""}
                                                </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => onCancel(item?.id)}><i className="fa-regular fa-circle-xmark"></i> &nbsp; Cancel</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => onResend(item?.id)}><i className="fa-light fa-share"></i> &nbsp; Resend</Dropdown.Item>
                                                        <Dropdown.Item onClick={e=>handleGenerateBill(e,item)}><i class="fa-thin fa-file-invoice"></i> &nbsp; Bill Generate</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                       }
                                        </div>
                                        </td>
                                    </tr>)})
                                    }
                                    {
                                       purchaseOrderList.length >0 && orderFilterStatus==='Partially Paid' &&
                                       purchaseOrderList.filter(item=>orderFilterStatus?item.status===orderFilterStatus:item)?.slice(0, visibleItems)?.map(item=>{return(<tr>
                                        <td>{item.date}</td>
                                        <td>{item?.order_id}</td>
                                        <td>{item?.type==='Company'?item?.company_name:`${item?.fname} ${item?.lname}`}</td>
                                        <td>${Number(item?.total).toFixed(2)}</td>
                                        <td>{item?.expiration_date}</td>
                                        <td>  <span className='pending' style={{ 
                                            backgroundColor: "rgb(207, 220, 248)",
                                             color: "rgb(22, 80, 206)" }}>
        {item?.status}
    </span></td>
                                        <td>
                                        <div className='tdFlex'>
                                            {/* <span className='viewIcon'><Link to={`/customerdetails`}><i class="fa-light fa-eye"></i></Link></span> */}
                                            {(item?.status === "Not Billed") ?
                                            <span className='viewIcon orange'><Link to={`/editproductservice?id=${item.id}`}><i class="fa-light fa-pen"></i></Link></span>:''}

                                            <span className= {item?.status === "Not Billed" ?'viewIcon':''} ><i class="fa-regular fa-trash-can" onClick={e=>_DELETE_PURCHASE_ORDER(e,item.id)}></i></span>&nbsp;&nbsp;
                                            {!(item?.status === 'Cancelled' || item?.status === 'Paid' || item?.status === 'Partially Paid' || item?.status==='Billed') &&
                                                <Dropdown className='dropRow'>
                                                <Dropdown.Toggle id="dropdown-basic">
                                                                    {
                                                                        item?.status === "Not Billed" ?
                                                                            <i className="fa-light fa-ellipsis-vertical"></i> : ""}
                                                </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => onCancel(item?.id)}><i className="fa-regular fa-circle-xmark"></i> &nbsp; Cancel</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => onResend(item?.id)}><i className="fa-light fa-share"></i> &nbsp; Resend</Dropdown.Item>
                                                        <Dropdown.Item onClick={e=>handleGenerateBill(e,item)}><i class="fa-thin fa-file-invoice"></i> &nbsp; Bill Generate</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                       }
                                        </div>
                                        </td>
                                    </tr>)})
                                    }
                                    {
                                       purchaseOrderList.length >0 && orderFilterStatus==='Billed' &&
                                       purchaseOrderList.filter(item=>orderFilterStatus?item.status===orderFilterStatus:item)?.slice(0, visibleItems)?.map(item=>{return(<tr>
                                        <td>{item.date}</td>
                                        <td>{item?.order_id}</td>
                                        <td>{item?.type==='Company'?item?.company_name:`${item?.fname} ${item?.lname}`}</td>
                                        <td>${Number(item?.total).toFixed(2)}</td>
                                        <td>{item?.expiration_date}</td>
                                        <td>  <span className='pending' style={{ 
                                            backgroundColor: "rgb(215, 244, 178)",
                                            color: "#638D2C"}}>
        {item?.status}
    </span></td>
                                        <td>
                                        <div className='tdFlex'>
                                            {/* <span className='viewIcon'><Link to={`/customerdetails`}><i class="fa-light fa-eye"></i></Link></span> */}
                                            {(item?.status === "Not Billed") ?
                                            <span className='viewIcon orange'><Link to={`/editproductservice?id=${item.id}`}><i class="fa-light fa-pen"></i></Link></span>:''}

                                            <span className= {item?.status === "Not Billed" ?'viewIcon':''} ><i class="fa-regular fa-trash-can" onClick={e=>_DELETE_PURCHASE_ORDER(e,item.id)}></i></span>&nbsp;&nbsp;
                                            {!(item?.status === 'Cancelled' || item?.status === 'Paid' || item?.status === 'Partially Paid' || item?.status==='Billed') &&
                                                <Dropdown className='dropRow'>
                                                <Dropdown.Toggle id="dropdown-basic">
                                                                    {
                                                                        item?.status === "Not Billed" ?
                                                                            <i className="fa-light fa-ellipsis-vertical"></i> : ""}
                                                </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => onCancel(item?.id)}><i className="fa-regular fa-circle-xmark"></i> &nbsp; Cancel</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => onResend(item?.id)}><i className="fa-light fa-share"></i> &nbsp; Resend</Dropdown.Item>
                                                        <Dropdown.Item onClick={e=>handleGenerateBill(e,item)}><i class="fa-thin fa-file-invoice"></i> &nbsp; Bill Generate</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                       }
                                        </div>
                                        </td>
                                    </tr>)})
                                    }
                                    {
                                       purchaseOrderList.length >0 && orderFilterStatus==='Not Billed' &&
                                       purchaseOrderList.filter(item=>orderFilterStatus?item.status===orderFilterStatus:item)?.slice(0, visibleItems)?.map(item=>{return(<tr>
                                        <td>{item.date}</td>
                                        <td>{item?.order_id}</td>
                                        <td>{item?.type==='Company'?item?.company_name:`${item?.fname} ${item?.lname}`}</td>
                                        <td>${Number(item?.total).toFixed(2)}</td>
                                        <td>{item?.expiration_date}</td>
                                        <td>  <span className='pending' style={{ 
                                            backgroundColor: "rgb(240, 202, 238)",
                                            color: "rgb(230, 28, 223)"}}>
        {item?.status}
    </span></td>
                                        <td>
                                        <div className='tdFlex'>
                                            {/* <span className='viewIcon'><Link to={`/customerdetails`}><i class="fa-light fa-eye"></i></Link></span> */}
                                            {(item?.status === "Not Billed") ?
                                            <span className='viewIcon orange'><Link to={`/editproductservice?id=${item.id}`}><i class="fa-light fa-pen"></i></Link></span>:''}

                                            <span className= {item?.status === "Not Billed" ?'viewIcon':''} ><i class="fa-regular fa-trash-can" onClick={e=>_DELETE_PURCHASE_ORDER(e,item.id)}></i></span>&nbsp;&nbsp;
                                            {!(item?.status === 'Cancelled' || item?.status === 'Paid' || item?.status === 'Partially Paid' || item?.status==='Billed') &&
                                                <Dropdown className='dropRow'>
                                                <Dropdown.Toggle id="dropdown-basic">
                                                                    {
                                                                        item?.status === "Not Billed" ?
                                                                            <i className="fa-light fa-ellipsis-vertical"></i> : ""}
                                                </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => onCancel(item?.id)}><i className="fa-regular fa-circle-xmark"></i> &nbsp; Cancel</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => onResend(item?.id)}><i className="fa-light fa-share"></i> &nbsp; Resend</Dropdown.Item>
                                                        <Dropdown.Item onClick={e=>handleGenerateBill(e,item)}><i class="fa-thin fa-file-invoice"></i> &nbsp; Bill Generate</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                       }
                                        </div>
                                        </td>
                                    </tr>)})
                                    }














                                    
                                        {/* <tr>
                                            <td><Link to="/addpurchaseorder">06-11-2023</Link></td>
                                            <td>ID9856125</td>
                                            <td>john Doe</td>
                                            <td>$263</td>
                                            <td>05-11-2023</td>
                                            <td><span className='pending'>Pending</span></td>
                                            <td>
                                                <div className='tdFlex'>
                                                    <span className='viewIcon'><Link to={`/customerdetails`}><i class="fa-light fa-eye"></i></Link></span>
                                                <span className='viewIcon orange'><Link to={`/`}><i class="fa-light fa-pen"></i></Link></span>
                                                <span className='viewIcon'><i class="fa-regular fa-trash-can"></i></span>&nbsp;&nbsp;
                                                    <Dropdown className='dropRow'>
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="fa-light fa-ellipsis-vertical"></i>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#/action-1"><i class="fa-thin fa-file-invoice"></i> &nbsp; Bill Generate</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Link to="/addpurchaseorder">06-11-2023</Link></td>
                                            <td>ID9856125</td>
                                            <td>john Doe</td>
                                            <td>$263</td>
                                            <td>05-11-2023</td>
                                            <td><span className='cancelled'>Cancelled</span></td>
                                            <td>
                                            <div className='tdFlex'>
                                                <span className='viewIcon'><Link to={`/customerdetails`}><i class="fa-light fa-eye"></i></Link></span>
                                                <span className='viewIcon orange'><Link to={`/`}><i class="fa-light fa-pen"></i></Link></span>
                                                <span className='viewIcon'><i class="fa-regular fa-trash-can"></i></span>&nbsp;&nbsp;
                                                    <Dropdown className='dropRow'>
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="fa-light fa-ellipsis-vertical"></i>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#/action-1"><i class="fa-thin fa-file-invoice"></i> &nbsp; Bill Generate</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Link to="/addpurchaseorder">06-11-2023</Link></td>
                                            <td>ID9856125</td>
                                            <td>john Doe</td>
                                            <td>$263</td>
                                            <td>05-11-2023</td>
                                            <td><span className='generated'>Generated</span></td>
                                            <td>
                                            <div className='tdFlex'>
                                                <span className='viewIcon'><Link to={`/customerdetails`}><i class="fa-light fa-eye"></i></Link></span>
                                                <span className='viewIcon orange'><Link to={`/`}><i class="fa-light fa-pen"></i></Link></span>
                                                <span className='viewIcon'><i class="fa-regular fa-trash-can"></i></span>&nbsp;&nbsp;
                                                    <Dropdown className='dropRow'>
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="fa-light fa-ellipsis-vertical"></i>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#/action-1"><i class="fa-thin fa-file-invoice"></i> &nbsp; Bill Generate</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    </div>
                                            </td>
                                        </tr> */}
                
                
                                </tbody>
                            </table>
                            {loading ?
                                    (
                                        <div className='loadItems'>                
                    <button className="commonButton addBtn" disabled>
                        Loading...
                    </button>
                    </div>
                ) : hasMore ? (
                    <div className='loadItems'>
                    <button onClick={loadMoreItems} className="commonButton addBtn">
                    No More Purchase Order
                    </button>
                    </div>
                ) : (
                    <div className='loadItems'>
                    <button className="commonButton addBtn" disabled>
                        No More Purchase Order
                    </button>
                    </div>
                )
                }
                        </div>
                         : 
                         <div className='gridView'>
                            {
 purchaseOrderList.length >0 &&
 purchaseOrderList.filter(item=>orderFilterStatus?item.status===orderFilterStatus:item).map(item=>{return(

                <Card>
                    <Card.Body>
                        <div className='cardBlock'>
                            <h6>Date</h6>
                            <span>{item.date}</span>
                            
                        </div>
                        <div className='cardBlock'>
                            <h6>Order Id</h6>
                            <span>{item?.order_id}</span>
                        </div>
                        <div className='cardBlock'>
                            <h6>Supplier Name</h6>
                            <span>{item?.type==='Company'?item?.company_name:`${item?.fname} ${item?.lname}`}</span>
                        </div>
                        <div className='cardBlock'>
                            <h6>Due Date</h6>
                            <span>{item?.expiration_date}</span>
                        </div>
                        <div className='cardBlock'>
                            <h6>Status</h6>
                            <span><span className={item.status==='Billed'?'generated':'pending'} style={{
    backgroundColor: item?.status === 'Cancelled' ? '#f2b8be' :
                     item?.status === 'Not Ordered' ? '#f8d5c9' :
                     item?.status === 'Paid'||item?.status === 'Billed' ? '#d7f4b2' :
                     item?.status === 'Partially Paid' ? '#cfdcf8' :
                   //  item?.status === 'Billed' ? '#ce7ac' :
                     item?.status === 'Unpaid' || item?.status === 'Not Billed' ? '#f0caee' : '',
    color: item?.status === 'Cancelled' ? '#e51c0e' :
           item?.status === 'Partially Paid' ? '#1650ce' :
           item?.status === 'Not Ordered' ? '#FF6C3C' :
           item?.status === 'Paid' ||item?.status === 'Billed'? '#638D2C' :
          // item?.status === 'Billed' ? '#ce7ac' :
           item?.status === 'Unpaid' || item?.status === 'Not Billed' ? '#e61cdf' : ''
}}>{item.status}</span></span>
                        </div>
                        <div className='cardBlock'>
                            <h6>Action</h6>
                            
                        </div>
                        {/* <div className='cardBlock actn'>
                            <div>
                                <span className='viewIcon'><Link to={`/customerdetails`}><i class="fa-light fa-eye"></i></Link></span>
                                <span className='viewIcon orange'><Link to={`/editcustomer`}><i class="fa-light fa-pen"></i></Link></span>
                                <span className='viewIcon'><i class="fa-regular fa-trash-can"></i></span>
                            </div>
                        </div> */}
                         <div className='commonFlex gridFooter'>
                                                    <div>
                                                        <span className='viewIcon orange'><Link to={`/editproductservice?id=${item.id}`}><i className="fa-light fa-pen"></i></Link></span>
                                                        <span className='viewIcon'><i className="fa-regular fa-trash-can" onClick={e=>_DELETE_PURCHASE_ORDER(e,item.id)}></i></span>
                                                    </div>
                                                    <div className='invgridicn'>
                                                        {/* <Link to=""><i className="far fa-envelope"></i></Link>&nbsp; */}
                                                        <div className='tdFlex'>
                                                            {/* <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span> */}
                                                            {/* <span className='viewIcon orange'><Link to={`/`}><i className="fa-light fa-pen"></i></Link></span>
                                                            <span className='viewIcon'><i className="fa-regular fa-trash-can"></i></span>&nbsp;&nbsp; */}
                                                            <Dropdown className='dropRow'>
                                                                <Dropdown.Toggle id="dropdown-basic">
                                                                    <i className="fa-light fa-ellipsis-vertical"></i>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => onCancel(item?.id)}><i className="fa-regular fa-circle-xmark"></i> &nbsp; Cancel</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => onResend(item?.id)}><i className="fa-light fa-share"></i> &nbsp; Resend</Dropdown.Item>
                                                        <Dropdown.Item onClick={e=>handleGenerateBill(e,item)}><i class="fa-thin fa-file-invoice"></i> &nbsp; Bill Generate</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                         </div>
                    </Card.Body>
                </Card>
)})}

        </div>
 }    

                        </div>
                    </div>
                </div>
            </Sidebar>


            <Modal size="lg" show={show} onHide={handleClose} className="salesExport">
                <div className='salesExpCol'>
                    <Modal.Header closeButton>
                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                    </Modal.Header>
                    <div className='explft'>
                        <div className='salesModtop'>
                            <div className='salesModLft'>
                                <img src={bclogo} alt='' className='mb-2' />
                                <h5>ABC Company</h5>
                                <small>15 / 12 Lorem ipsum lfjfghyti <br />Ney ypork. Hilton </small>
                            </div>
                            <div className='salesModRgt'>
                                <h5>PURCHASE ORDER</h5>
                                <small>no. ClNV-NZ</small><br />
                                <small>24/11/2023</small><br />
                                <strong>Due on receipt</strong>
                            </div>
                        </div>
                        <div className='bsOrder'>
                            <div className='bsOrderlft'>
                                <h5>Billing address</h5>
                                <h6>Test Company</h6>
                                <small>15 / 12 Lorem ipsum lfjfghyti<br />
                                    Ney ypork. Hilton </small>
                            </div>
                            <div className='bsOrderRgt'>
                                <h5>Shipping address</h5>
                                <h6>Test Company</h6>
                                <small>15 / 12 Lorem ipsum lfjfghyti<br />
                                    Ney ypork. Hilton </small>
                            </div>
                        </div>
                        <div className='bsTables'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Item name & description</th>
                                        <th>VAT</th>
                                        <th>Qty</th>
                                        <th>Unit Price</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <h6>ABC Product</h6>
                                            em ipsum lfjfghyti
                                        </td>
                                        <td>20%</td>
                                        <td>1</td>
                                        <td>$20.00/price</td>
                                        <td>$20.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td colSpan="3">
                                            <h6>Subtotal</h6>
                                        </td>
                                        <td>$20.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td colSpan="3">
                                            standard rate($20.00)
                                        </td>
                                        <td>$4.00</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colSpan="3">Total</td>
                                        <td>$24</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className='expRgt'>
                        {/* <button className='commonButton expBtn btnshadow'><i class="fa-regular fa-envelope"></i> Email</button>
                        <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-print"></i> Print</button>
                        <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-down-to-bracket"></i> Download</button> */}

                        {!views?
                    <div>
                       <button className='commonButton expBtn btnshadow' onClick={toggleHandlerb}><i class="fa-regular fa-envelope"></i> Email</button>
                        <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-print"></i> Print</button>
                        <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-down-to-bracket"></i> Download</button>
                    </div> : 
                    <Form>
                    <Form.Group className="mb-3 salesRform" controlId="exampleForm.ControlInput1">
                        <Col md="12" className='mb-2'>
                            <label>To <span className='redColor'>*</span></label>
                            <Form.Control type="text" placeholder="Select Customer" />
                        </Col>
                        <Col md="12" className='mb-2'>
                        <label>Subject :</label>
                            <Form.Control type="text" placeholder="ABC Company" />
                        </Col>
                        <Col md="12" className='mb-3'>
                        <label>Message :</label>
                        <Form.Control as="textarea" placeholder="Billing Address" rows={3} value="" className='mb-3'/>
                        </Col>
                        <div className='salesCheck mb-3'><Form.Check type="checkbox"/> Send me a copy</div>
                        <button className='commonButton expBtn btnshadow'>Send</button>

                    </Form.Group>
                    </Form>
                    }


                    </div>
                </div>
            </Modal>


        </div>
    )
}

export default Purchaseorderlisting