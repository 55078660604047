import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import dashblue from '../../images/dashblue.png';
import Navigation from '../layout/Navigation';
import { useNavigate } from 'react-router-dom';

import { getSubscriptionPlanList, getSubscriptionPayment } from "../../../Service/Subscription/SubscriptionStripe";


const Pricinghome = (props) => {
    const { logo } = props;
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [subscriptionPlan, setAllSubscriptionPlanList] = useState([]);

        // const onSubscribeNow = (planId) => {
        //     console.log(planId, "planId")
        //     getSubscriptionPayment(planId, (res) => {
        //         console.log(res, "planREsponse")
        //         if (res.success === true) {
        //             window.location.href = res?.data?.url;
    
        //         } else {
    
        //         }
        //     })
        // }
    
        //subscription plan api call
        useEffect(() => {
            getSubscriptionPlanDataList();
    
        }, []);
    
        async function getSubscriptionPlanDataList() {
            getSubscriptionPlanList().then(function (result) {
                const response = result?.data;
                // console.log(response, "rrrr")
                setAllSubscriptionPlanList(response?.data?.data);
            });
        }
        console.log(subscriptionPlan, "subscriptionPlanssss")
      
            
    
        return (
    
            <div className='authBg pricinghome'>
                <header>
                    <Navigation/>
                </header>

                {/* <div className='proflogo'><Link to="/"><img src={logo} /></Link></div> */}
                <div className='authLogo pt-lg-5 mt-5 pricingLogo'>&nbsp;</div>
    
                <div className='signForm pricingshadow respnoMrgn newPricing'>
                     <div className='choosePlanTop pricingA'>
                        <h2>Pricing</h2>
                        <img src={dashblue} />
                        {/* <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur...</p> */}
                     </div>
                     <div className='choosePlanBtm pricingB'>
                        {/* <div className='row'>
                            <div className='col-md-6'>
                                <div className='planBg'>
                                    <div className='plantop orangebg'>
                                        <h5 className='m-0'>Monthly plan</h5>
                                        <button>Popular</button>
                                    </div>
                                    <div className='plancontent'>
                                        <h2>$29<small>,00/month</small></h2>
                                        <p>Lorem ipsum dolor sit amet,consectetur adipiscing elit.</p>
                                        <ul>
                                            <li>Lorem ipsum dolor sit amet</li>
                                            <li>Lorem ipsum dolor sit amet</li>
                                            <li>Lorem ipsum dolor sit amet</li>
                                        </ul>
                                        <button className='commonButton buttonSubmit'>Get Started</button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='planBg'>
                                    <div className='plantop'>
                                        <h5 className='m-0'>Annual plan</h5>
                                    </div>
                                    <div className='plancontent'>
                                        <h2>$70<small>,00/month</small></h2>
                                        <p>Lorem ipsum dolor sit amet,consectetur adipiscing elit.</p>
                                        <ul>
                                            <li>Lorem ipsum dolor sit amet</li>
                                            <li>Lorem ipsum dolor sit amet</li>
                                            <li>Lorem ipsum dolor sit amet</li>
                                        </ul>
                                        <button className='commonButton buttonSubmit'>Get Started</button>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className='row'>
                            {subscriptionPlan?.map((d) => (
                            <div className='col-md-4'>
                                <div className='planBg'>
                                        <div className={d?.name === "Yearly" ? 'plantop orangebg' : 'plantop'}>
                                            <h5 className='m-0'>{d?.name} </h5>
                                            {d?.name === "Yearly" ?
                                                <button>Popular</button> : ""}
                                        </div>

                                        <div className='plancontent'>
                                            <h2>${d?.plan_price}<small>/ Monthly</small></h2>

                                            {/* <p>Lorem ipsum dolor sit amet,consectetur adipiscing elit.</p> */}
                                            <ul>
                                                {d?.features?.map((s)=>(
                                                    <>

                                                <li>{s?.name}</li>
                                                
                                                </>
                                                ))}

                                            </ul>
                                            {/* <button className='commonButton buttonSubmit' onClick={onSubscribeNow.bind(d?.default_price, `${d?.default_price}`)}>Get Started</button> */}
                                            <Link to="/login" className='commonButton buttonSubmit' style={{display: "block", textAlign: "center"}}>Get Started</Link>
                                        </div>

                                    </div>
                            </div>
                            ))}
                            

                            <div className='col-md-4'>
                            <div className='planBg'>
                                <div className="plantop orangebg">
                                    <h5 className='m-0'>Professional Package</h5>

                                    <button>Popular</button>
                                </div>

                                <div className='plancontent'>
                                    {/* <h2>&nbsp;</h2> */}

                                    <h6 className='highlight mt-3' style={{paddingBottom: "21px"}}>Let the Professionals handle it for you</h6>
                                    <ul>
                                        <li>Full bookkeeping services</li>
                                        <li>Financial planning, budgeting & forecasting</li>
                                        <li>Financing and Cash flow planning</li>
                                        <li>Audit preparations</li>
                                        <li>Internal Control Improvements</li>
                                        <li>Risk Management <small>
                                            {/* <i class="fa-regular fa-bracket-round"></i>  <i class="fa-regular fa-bracket-round-right"></i> */}
                                            </small></li>
                                            <li><small>*For fees, contact us for a customized fee structure that meets your business needs*</small></li>
                                        <li>Financial Reporting and Advisory Services</li>
                                    </ul>
                                    <Link to="/login" className='commonButton buttonSubmit' style={{display: "block", textAlign: "center"}}>Get Started</Link>
                                </div>

                            </div>
                            </div>


                        </div>
                     </div>
                </div>


            </div>
        )
    }
    

export default Pricinghome