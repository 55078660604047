import { React, useState,useEffect } from 'react'
import Sidebar from '../../../layout/Sidebar'
import Dbheader from '../../../layout/Dbheader'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
// import Headtxt from '../Headtxt';
import moment from "moment"
import Listview from '../../sales/Listview';
import Gridview from '../../sales/Gridview';
import Card from 'react-bootstrap/Card'
import Headtxt from '../../Headtxt';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import bclogo from '../../../../images/bclogo.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getBillPaymentDownload, getBillPaymentList, getRefundPaymentList, getSearchBillPaymentList, getSearchRefundPaymentList } from '../../../../../Service/Quotation/QuotationService';
import cals from '../../../../images/cals.png';
import { ToastContainer, toast } from 'react-toastify';
import getSymbolFromCurrency from 'currency-symbol-map'
import axios from 'axios';
const RefundList = () => {

    const [selectedDate, setSelectedDate] = useState(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [formatedfromDate, setformatedFromDate] = useState(new Date());
    const [formatedtoDate, setformatedToDate] = useState(new Date());
    const [billExport,setBillExport]=useState([]);
    const [bill, setBill] = useState([]);
    const [loading, setLoading] = useState(false);
    const [visibleItems, setVisibleItems] = useState(5);
    const [currencyData, setCurrencyData] = useState(null);
    const [selectedCurrency, setSelectedCurrency] = useState('CAD');
    const [selectedCurrencyValue, setSelectedCurrencyValue] = useState(null);
    const [error, setError] = useState();
      const handleFromDateChange = (e) => {
        const value  = e;
        setFromDate(value);
        // Perform filtration based on the selected from date
        // You can put your filtration logic here
        console.log('Filter data based on from date:',value);
      };
    
      const handleToDateChange = (e) => {
        const value  = e;
        setToDate(value);
        // Perform filtration based on the selected to date
        // You can put your filtration logic here
     
        console.log('Filter data based on to date:', value);
      //  getSearchList()
        //setToDate('')
      };
   
      async function getBill() {
        getRefundPaymentList("").then(function (result) {
            const response = result?.data;
       console.log(response, "rrrr")
            setBill(response?.data);
            setSelectedCurrency(response?.data[0].currency)
        });
    }
    const getSearchList = () => {
        // Construct the filter criteria based on fromDate and toDate
        const filterCriteria = {
            fromDate: fromDate ? moment(fromDate).format('DD-MM-YYYY') : '',
            toDate: toDate ? moment(toDate).format('DD-MM-YYYY') : ''
        };
        console.log('filter---', filterCriteria);
        const queryString = new URLSearchParams(filterCriteria).toString();
        console.log(queryString, 'cre');
        getRefundPaymentList(queryString).then(function (result) {
            const response = result?.data;
            console.log('resultttt---', response);
            setBill(response?.data);
        });
    };
    const onSearch = (e) => {
        getSearchRefundPaymentList(e.target.value).then(function (result) {
            const response = result?.data;
            // console.log(response, "rrrr")
            setBill(response?.data);
        });}
    useEffect(() => {
        getBill();
       
    }, []);
    useEffect(() => {
        getSearchList();
    }, [fromDate, toDate]);

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        setViews(false)
    };


    const [showrecord, setShowRecord] = useState(false);
    const handleShowRecord  = () => setShowRecord(true);
    const handleCloseRecord = () => {
        setShowRecord(false);
    };


    const [supply, setSupply] = useState(false)
    const toggleCompany = (e) => {
        e.preventDefault();
        setSupply(false)
    }
    const toggleIndividual = (e) => {
        e.preventDefault();
        setSupply(true)
    }


    const loadMoreItems = () => {
        setLoading(true);
        setTimeout(() => {
            // Simulate loading delay
            setVisibleItems((prev) => prev + 5);
            setLoading(false);
        }, 1000); // Adjust the delay as needed
    };
const [view, setView] = useState(false)
  const toggleHandler = (e) => {
    e.preventDefault();
    setView(true)
  }
  const toggleHandler1 = (e) => {
    e.preventDefault();
    setView(false)
  }

  const [views, setViews] = useState(false)
  const [dataPdf, setDataPdf] = useState("");
  const toggleHandlerb = (e) => {
    e.preventDefault();
    setViews(true)
  }
 
  const onDownload = (id) => {
          window.open(id, '_blank'); // Open PDF in a new tab
      
  }
  // custom-select-drupdown
const [isOpen, setIsOpen] = useState(false);
const [selectedOption, setSelectedOption] = useState('More');

const toggleDropdown = () => {
  setIsOpen(!isOpen);
};

const handleOptionClick = (option) => {
  setSelectedOption(option);
  setIsOpen(false);
};

const [shows, setShows] = useState(false);
const handleShowinv = () => {
    setShows(true);
    setShowsvat(false);
}
const handleCloseinv = () => {
    setShows(false);
    setShowsvat(true);
}

const [showsvat, setShowsvat] = useState(false);
const handleClosevat = () => setShowsvat(false);
const handleScroll = () => {
    if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
    ) {
        // Auto-load more items when scrolling to the bottom
        loadMoreItems();
    }
};

useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);
    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                <div className='custTop'>
            <Link to='/supplierlisting'>Supplier</Link>
            <Link to='/productservicelistings'>Product &amp; Service</Link>
            <Link to='/quotelisting'>Quote</Link>
            <Link to='/purchaseorderlisting'>Purchase Order</Link>
            <Link to='/bill'>Bill</Link>
            <div className="custom-dropdown-container customDropdown ms-4">
                            <div className="custom-dropdown-header active" onClick={toggleDropdown}>
                                <span>{selectedOption}</span>
                                <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}> <i class="fa-regular fa-angle-down"></i></span>
                            </div>

                            {isOpen && (
                                <ul className="custom-dropdown-options">
                                    <li
                                        className={`option ${selectedOption === 'Option 1' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 1')} hidden
                                    >
                                        Option 1
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 2' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 2')} 
                                    >
                                        <Link to="/billpayment"><i class="fa-light fa-calendar-lines-pen"></i> Bill Payment</Link>
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 3' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 3')} hidden
                                    >
                                        <Link to="">Option 3</Link>
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 4' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 4')} hidden
                                    >
                                        <Link to="">Option 4</Link>
                                    </li>
                                </ul>
                            )}

{/* <p>You selected: {selectedOption}</p> */}
                        </div>
          </div>
                    <div className='productServList'>
                        <div className='dbTopsec pb-4 pt-2 noBackbtn'>
                            <Headtxt link="/" title="Refunds" />
                            
                            {/* <div className='custButton'>
                                <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp;
                                <button className='commonButton expBtn'><i class="fa-light fa-download"></i> Export Excel</button>
                            </div> */}
                        </div>

                        <div className='customerSrchTop pb-3 poResponsive'>
              <div className='customSrchfield'>
                <div>
                <span><i class="fa-solid fa-magnifying-glass"></i></span><input type='search' placeholder='Search by keywords'  onChange={onSearch}/>
                </div>&nbsp;&nbsp;
                <div className='invDatepicker' >
            <DatePicker
                    selected={fromDate}
                    onChange={handleFromDateChange}
                    selectsStart
                    startDate={fromDate}
                    endDate={toDate}
                    placeholderText="From Date"
                    isClearable={fromDate?true:false}
                />
                 </div>
                   <div className='invDatepicker' style={{ marginLeft: '10px' }}>
                <DatePicker
                    selected={toDate}
                    onChange={handleToDateChange}
                    selectsEnd
                    startDate={fromDate}
                    endDate={toDate}
                    placeholderText="To Date"
                    isClearable={toDate?true:false}
                    
                />
                </div>
                </div>
              <div>
                </div>
              
            </div>


                        {/* <div className='addsuppBtn'>
                            <button className={`${!supply ? "commonButton active" : "commonButton "} `} onClick={toggleCompany}>Product</button>
                            <button className={`${supply ? "commonButton active" : "commonButton "} `} onClick={toggleIndividual}>Service</button>
                        </div> */}
                        <div className='mt-2'>
                            
                            <div className='gridList mb-3 flexfield gridListResp' style={{justifyContent: "flex-end"}}>
                                {/* <div className='srtBy'>
                                    <label>Sort by: </label>&nbsp;
                                    <Form.Select aria-label="Default select example" className='sortby'>
                                    <option>Pending</option>
                                    <option>Cancelled</option>
                                    <option>Bill Generated</option>
                                    </Form.Select>&nbsp;
                                </div> */}
                                <div>
                                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler1} className={!view? "listactive" : "" }><i class="fa-solid fa-list"></i></button>
                                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler} className={view? "listactive" : "" }><i class="fa-solid fa-grid"></i></button>
                                </div>
                            </div>
                            {!view ? 
                            <div className='listViews'>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Refund Date</th>
                                        <th scope="col">Bill Id</th>
                                        <th scope="col">Supplier</th>
                                        <th scope="col">Refund Amount</th>
                                        <th scope="col">Bill Amount</th>
                                        {/* <th scope="col">Status</th> */}
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {bill?.length != 0 && bill?.slice(0, visibleItems)?.map((item, index) => (
                                        <tr>
                                            <td>{item.return_date}</td>
                                            <td><Link to={`/viewbilldetails?id=${item.BillID}`}>{item.bill_id}</Link></td>
                                            <td>{item.type==='Company'?item.company_name:`${item?.fname} ${item?.lname}`}</td>
                                            <td>{`${getSymbolFromCurrency(item.currency)}  ${item.return_total}`}</td>
                                            <td>{`${getSymbolFromCurrency(item.currency)}  ${item.bill_total}`}</td>
                                           
                                            <td>
                                                <div className='tdFlex'>
                                                    {/* <span className='viewIcon'><Link to={`/customerdetails`}><i class="fa-light fa-eye"></i></Link></span> */}
                                                {/* <span className='viewIcon orange'><Link to={`/`}><i class="fa-light fa-pen"></i></Link></span> */}
                                                {/* <span className='viewIcon'><i class="fa-regular fa-trash-can"></i></span>&nbsp;&nbsp; */}
                                                    <Dropdown className='dropRow'>
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="fa-light fa-ellipsis-vertical"></i>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>                                                            
                                                            <Dropdown.Item onClick={()=>onDownload(item.return_pdf)} ><i class="fa-light fa-download"></i> &nbsp; Download</Dropdown.Item>                                                            
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </td>
                                        </tr>
                               ) )}
                                        
                
                
                                </tbody>
                            </table>
                            {loading &&
                                        <div className='loadItems'>
                                            <button className="commonButton addBtn">
                                                Loading...
                                            </button>
                                        </div>
                                    }
                                    {!loading && (
                                        <div className='loadItems'>
                                            <button onClick={loadMoreItems} className="commonButton addBtn">
                                                No More Return & Refund
                                            </button>
                                        </div>
                                    )}
                        </div>
                         : 
                         <div className='gridView'>
                         {bill?.map((item) => (

                             <Card>
                                 <Card.Body>
                                     <div className='commonFlex'>
                                         <h6>{item?.bill_id}</h6>
                                         <span><img src={cals} alt='' className='mb-2' /> {item?.bill_date}</span>
                                     </div>
                                     <div className='commonFlex'>
                                         <span className='customr'>{item.type='Company'?item.company_name:`${item?.fname} ${item?.lname}`}</span>
                                         <span>&nbsp;</span>
                                     </div>
                                   
                                     <div className='commonFlex gridFooter'>
                                         <div>
                                             {/* <span className='viewIcon orange'><Link to={`/`}><i className="fa-light fa-pen"></i></Link></span> */}
                                             {/* <span className='viewIcon'><i className="fa-regular fa-trash-can"></i></span> */}
                                         </div>
                                         <div className='invgridicn'>
                                             {/* <Link to=""><i className="far fa-envelope"></i></Link>&nbsp; */}
                                             <div className='tdFlex'>
                                                 {/* <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span> */}
                                                 {/* <span className='viewIcon orange'><Link to={`/`}><i className="fa-light fa-pen"></i></Link></span>
                                                 <span className='viewIcon'><i className="fa-regular fa-trash-can"></i></span>&nbsp;&nbsp; */}
                                                 <Dropdown className='dropRow'>
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="fa-light fa-ellipsis-vertical"></i>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>                                                            
                                                            <Dropdown.Item onClick={()=>onDownload(item.id)} ><i class="fa-light fa-download"></i> &nbsp; Download</Dropdown.Item>                                                            
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                             </div>
                                         </div>
                                     </div>
                                     {/* <div className='cardBlock actn'>
                                     <div>
                                         <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span>
                                         <span className='viewIcon orange'><Link to={`/editcustomer`}><i className="fa-light fa-pen"></i></Link></span>
                                         <span className='viewIcon'><i className="fa-regular fa-trash-can"></i></span>
                                     </div>
                                 </div> */}
                                 </Card.Body>
                             </Card>
                         ))}

                     </div>
}

             </div>
         </div>
     </div>
            </Sidebar>




        </div>
    )
}

export default RefundList