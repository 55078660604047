import webApi, { baseURL } from "../../WebApi/WebApi";

//FOR LOGIN 

export const getLogin = async (email, password, onSuccess, onFailure) => {
    console.log(email, password, "login")

    try {
        const res = await webApi.post(

            `/login`,
            {
                email: email,
                password: password,

            }
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
