import { React, useState } from 'react'
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import Form from 'react-bootstrap/Form';
import Headtxt from '../Headtxt';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';
import { Link } from 'react-router-dom';
import Customdropdown from '../../Customdropdown';

const Cfoservice = () => {

    const options = ['All accounts', 'Company', 'Individuals'];  
    const options2 = ['All contacts', 'Company', 'Individuals'];  


    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const [selectedDateb, setSelectedDateb] = useState(null);

    const handleDateChangeb = (date) => {
        setSelectedDateb(date);
    };



    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                {/* <div className='custTop'>
                    <Link to='/balancesheet' >Balance Sheet</Link>
                    <Link to='/profitloss' >Income (Profit & Loss)</Link>
                    <Link to='' className='active'>Cashflow Statement</Link>
                    
                </div> */}



                    <div className='productServList'>
                        <div className='dbTopsec pb-4 pt-2 noBackbtn'>
                            <Headtxt link="" title="Fill out the form will get back to you within 24 hours." />

                            <div className='custButton'>
                                {/* <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp; */}
                                {/* <button className='commonButton expBtn'><i class="fa-light fa-upload"></i> Export</button> */}
                            </div>
                        </div>


                        <div className='commonWhite addCustomerForm mb-3 p-3'>
                            <h6>Date Range</h6>
                        <Form>
                                 <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="12" className='mb-3'>
                                    <Customdropdown options={options} placeholdr="Consulting for"/>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col lg="4" className='mb-3'>
                                        <Form.Control type="text" placeholder="Name" />
                                    </Col> 
                                    <Col lg="4" className='mb-3'>
                                        <Form.Control type="text" placeholder="Email" />
                                    </Col> 
                                    <Col lg="4" className='mb-3'>
                                        <Form.Control type="text" placeholder="Phone" />
                                    </Col> 
                                </Form.Group>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                <Col md="12" className='mb-3'>
                                    <Form.Control as="textarea" placeholder="Message" rows={3} style={{height: "180px"}} />
                                </Col>
                            </Form.Group>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="2" className='datepickr'>
                                        <button className='commonButton expBtn'>Submit</button>
                                    </Col>
                                    
                                </Form.Group>                                

                                </Form>
                        </div>
                        



                    </div>
                </div>
            </Sidebar>




            
        </div>
    )
}


export default Cfoservice