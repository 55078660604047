import webApi, { baseURL } from "../../WebApi/WebApi";
export const getUserAccount = async (data) => {
    return await webApi.get(`get-user-account`,
  
    );
}
export const getAccountType = async (data) => {
    return await webApi.get(`get-account-type`,
  
    );
}
export const getAccountSubType = async (data) => {
    return await webApi.get(`accountType-accountName-list`,
  
    );
}
// export const getExchangeRateAPI= async (data) => {
//     return await webApi.get(`https://api.exchangerate-api.com/v4/latest/`,
  
//     );
// }
export const getGLAccount = async (data) => {
    return await webApi.get(`all-glTransaction`,
    );
}
export const getSuppliername = async (data) => {
    return await webApi.get(`supplier-name-list`,
    );
}
export const getGlFiltration = async (data,filterCriteria,toDate,selectedSupplier) => {
    return await webApi.get(`filtration-glTransactions?id=${data}&fromDate=${filterCriteria}&toDate=${toDate}&userId=${selectedSupplier}`,
    );
}
export const getAccountTypeByID = async (data) => {
    return await webApi.get(`user-account-details/${data}`,
  
    );
}
export const getAccountTransactionsList = async (data) => {
    return await webApi.get(`account-bill-transactions?searchText=${data}`,
  
    );
}
export const getAccountDateSearch = async (data) => {
    
    return await webApi.get(`account-bill-transactions?searchText=&${data}`,
    );
}
export const getAddaccount = async (subType,  accountName,
    accountId,
    description,
    onSuccess, onFailure) => {
try {
        const res = await webApi.post(

            `add-update-user-account`,
            {
                subType: subType,
                accountId: accountId,
                accountName: accountName,
                description: description,
              //  amount:amount
            }
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const getAddInventoryaccount = async (subType,accountName,
    accountId,
    description,
    onSuccess, onFailure) => {
try {
        const res = await webApi.post(

            `add-update-user-account`,
            {
                subType: subType,
                accountId: accountId,
                accountName: accountName,
                description: description,
              //  amount:amount
            }
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const getUpdateaccount = async (id,subType,accountName,
    accountId,
    description,archive,
    onSuccess, onFailure) => {


    try {
        const res = await webApi.post(

            `add-update-user-account`,
            {
                id:id,
                subType: subType,
                accountId: accountId,
                accountName: accountName,
                description: description,
                archive:archive
              //  amount:amount
            }
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
}