import React from 'react'

const Nopage = () => {
  return (
    <div  className='pnf'>
        <div className='man'>
          <img style={{width: "212px"}} src="http://www.design-future.com/css-animation/Page-not-found/img/man-tube.png" alt="man" />
        </div>
        <div className='bannr'>
          <img style={{width: "200px"}} src="http://www.design-future.com/css-animation/Page-not-found/img/banner.png" alt="banner" />
        </div>
    </div>
    )
}

export default Nopage