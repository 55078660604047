import { Outlet, Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../../../logo.png';
import user from '../../images/user.png';
// import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

const Dbheader = () => {
  // const navigate = useNavigate();
  // const [userData, setUserData] = useState(null);


  // useEffect(() => {
  //   const jsonString = localStorage.getItem('1Simpleclick_User');

  //   const data = JSON.parse(jsonString);
  //   setUserData(data);
  // }, []);

  // const onLogout=()=>{

  //   localStorage.clear()
  //   navigate("/")
  // }

  return (
    <div className="dbHead">
      <div className="logoBg"><Link to="/" target="_blank"><img src={logo} /></Link></div>
      <div className="dbHeadRight">

      {/* <Dropdown className="chatBase">
          <Dropdown.Toggle variant="" id="">
          <i class="fa-regular fa-comment-dots"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>Msw</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}


        <Dropdown className="notifyBase">
          <Dropdown.Toggle variant="" id="">
          <i class="fa-light fa-bell"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>Notify</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* <Dropdown className="userBase">
          <Dropdown.Toggle variant="" id="">
          <img src={userData?.logo} /> <span className="john">{userData?.fname} {userData?.lname}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={onLogout}>Logout <i class="fa-solid fa-right-from-bracket"></i></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
      </div>
    </div>
  )
}

export default Dbheader