import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import dashblue from '../../images/dashblue.png';


const Pricing = (props) => {
        const { logo } = props;
      
    
        
    
        return (
    
            <div className='authBg'>
                <div className='proflogo'><Link to="/"><img src={logo} /></Link></div>
                <div className='authLogo pt-lg-5 pricingLogo'>&nbsp;</div>
    
                <div className='signForm pricingshadow'>
                     <div className='choosePlanTop pricingA'>
                        <h2>Pricing</h2>
                        <img src={dashblue} />
                        <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur...</p>
                     </div>
                     <div className='choosePlanBtm pricingB'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='planBg'>
                                    <div className='plantop orangebg'>
                                        <h5 className='m-0'>Monthly plan</h5>
                                        <button>Popular</button>
                                    </div>
                                    <div className='plancontent'>
                                        <h2>$29<small>,00/month</small></h2>
                                        <p>Lorem ipsum dolor sit amet,consectetur adipiscing elit.</p>
                                        <ul>
                                            <li>Lorem ipsum dolor sit amet</li>
                                            <li>Lorem ipsum dolor sit amet</li>
                                            <li>Lorem ipsum dolor sit amet</li>
                                        </ul>
                                        <button className='commonButton buttonSubmit'>Get Started</button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='planBg'>
                                    <div className='plantop'>
                                        <h5 className='m-0'>Annual plan</h5>
                                        {/* <button>Popular</button> */}
                                    </div>
                                    <div className='plancontent'>
                                        <h2>$70<small>,00/month</small></h2>
                                        <p>Lorem ipsum dolor sit amet,consectetur adipiscing elit.</p>
                                        <ul>
                                            <li>Lorem ipsum dolor sit amet</li>
                                            <li>Lorem ipsum dolor sit amet</li>
                                            <li>Lorem ipsum dolor sit amet</li>
                                        </ul>
                                        <button className='commonButton buttonSubmit'>Get Started</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                </div>


            </div>
        )
    }
    
    export default Pricing