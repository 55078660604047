import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import dash from '../../images/dash.png';
import cp from '../../images/callpay.png';
import mm from '../../images/mobmoney.png';
import cc from '../../images/credit.png';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getSubscriptionPlanList, getSubscriptionPayment } from "../../../Service/Subscription/SubscriptionStripe";
const Subscriptionplan = (props) => {
    const { logo } = props;
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [subscriptionPlan, setAllSubscriptionPlanList] = useState([]);



    const onSubscribeNow = (planId) => {
        console.log(planId, "planId")
        // window.scrollTo(0, 0);
        if(localStorage.getItem('1Simpleclick_AuthToken'))
            
       { getSubscriptionPayment(planId, (res) => {
            console.log(res, "planREsponse")
            if (res.success === true) {
                window.location.href = res?.data?.url;
                // navigate('https:\/\/checkout.stripe.com\/c\/pay\/cs_test_a1etc8MdjiIQOMOE1DvT98sxj4FnT3bGpxFuVL9p5HUevDefa4x9qnKjVk#fidkdWxOYHwnPyd1blpxYHZxWjA0SlV2M1xEckRoYWowd3U3U3RpUH1zQHBtTzx%2FNWlTUDUxUUpRfHQ3d2FGd3VddXVDYElAYkhWcnRxU39uVWJtY0dJb1Q1PTRiZGNzVUxSQTw0ME5pcGY2NTVydkJsbmhNXCcpJ2N3amhWYHdzYHcnP3F3cGApJ2lkfGpwcVF8dWAnPyd2bGtiaWBabHFgaCcpJ2BrZGdpYFVpZGZgbWppYWB3dic%2FcXdwYHgl')
                // toast.success(res.message)
                // setShowDelete(false)
                // getCustomerDataList();

            } else {
                // toast.error(res.message)

            }
        })}
    }

    //subscription plan api call
    useEffect(() => {
        getSubscriptionPlanDataList();

    }, []);

    async function getSubscriptionPlanDataList() {
        getSubscriptionPlanList().then(function (result) {
            const response = result?.data;
            // console.log(response, "rrrr")
            setAllSubscriptionPlanList(response?.data?.data);
        });
    }
    console.log(subscriptionPlan, "subscriptionPlan")
    return (

        <div className='authBg'>
            <div className='proflogo'><Link to="/"><img src={logo} /></Link></div>
            <div className='authLogo pt-5'>&nbsp;</div>

            <div className='signForm subsMain'>
                <div className='choosePlanTop'>
                    <h3>Choose Your Plan</h3>
                    <img src={dash} />
                    <p>Pay for the entire year and get two months absolutely free</p>
                </div>
                <div className='choosePlanBtm'>
                    <div className='row'>
                        {subscriptionPlan?.map((d) => (

                            <div className='col-md-4'>

                                <div className='planBg'>
                                    <div className={d?.name === "Yearly" ? 'plantop orangebg' : 'plantop'}>
                                        <h5 className='m-0'>{d?.name} </h5>
                                        {d?.name === "Yearly" ?
                                            <button>Popular</button> : ""}
                                    </div>

                                    <div className='plancontent'>
                                        <h2>${d?.plan_price}<small>/ Monthly</small></h2>

                                        {/* <p>Lorem ipsum dolor sit amet,consectetur adipiscing elit.</p> */}
                                        <ul>
                                            {d?.features?.map((s)=>(
                                                <>

                                            <li>{s?.name}</li>
                                            
                                            </>
                                            ))}

                                        </ul>
                                        <button className='commonButton buttonSubmit' onClick={onSubscribeNow.bind(d?.default_price, `${d?.default_price}`)}>Subscribe</button>
                                    </div>

                                </div>

                            </div>
                        ))}


                        <div className='col-md-4'>

                            <div className='planBg'>
                                <div className="plantop orangebg">
                                    <h5 className='m-0'>Professional Package</h5>

                                    <button>Popular</button>
                                </div>

                                <div className='plancontent'>
                                    {/* <h2>&nbsp;</h2> */}

                                    <h6 className='highlight pb-3'>Let the Professionals handle it for you</h6>
                                    <ul>
                                        <li>Full bookkeeping services</li>
                                        <li>Financial planning, budgeting & forecasting</li>
                                        <li>Financing and Cash flow planning</li>
                                        <li>Audit preparations</li>
                                        <li>Internal Control Improvements</li>
                                        <li>Risk Management <small>
                                            {/* <i class="fa-regular fa-bracket-round"></i>  <i class="fa-regular fa-bracket-round-right"></i> */}
                                            </small></li>
                                            <li><small>*For fees, contact us for a customized fee structure that meets your business needs*</small></li>
                                        <li>Financial Reporting and Advisory Services</li>
                                    </ul>
                                    <button className='commonButton buttonSubmit'>Contact Us</button>
                                </div>

                            </div>

                        </div>

                        {/* <div className='col-md-6'>
                            <div className='planBg'>
                                <div className='plantop'>
                                    <h5 className='m-0'>Annual plan</h5>
                                    <button>Popular</button>
                                </div>
                                <div className='plancontent'>
                                    <h2>$129<small>,00/month</small></h2>
                                    <p>Lorem ipsum dolor sit amet,consectetur adipiscing elit.</p>
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet</li>
                                        <li>Lorem ipsum dolor sit amet</li>
                                        <li>Lorem ipsum dolor sit amet</li>
                                    </ul>
                                    <button className='commonButton buttonSubmit' onClick={onSubscribeNow}>Subscribe</button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className='paymentShow'>
                    <Modal.Title>Choose Payment method</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <h3>Choose Payment method</h3> */}
                    <div className='subscriptionRado'>
                        <div className='radio'>
                            <input type="radio" id="test1" name="radio-group" checked />
                            <label for="test1"><img src={cc} /> Credit Card / Debit Cards</label>
                        </div>
                        <div className='radio'>
                            <input type="radio" id="test2" name="radio-group" />
                            <label for="test2"><img src={mm} /> Mobile Money Transfer </label>
                        </div>
                        <div className='radio'>
                            <input type="radio" id="test3" name="radio-group" />
                            <label for="test3"><img src={cp} /> Call 404 198 6754 to make payments </label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "flex-start" }}>
                    <button className='commonButton buttonSubmit w-auto' onClick={handleClose}>Pay Now</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Subscriptionplan