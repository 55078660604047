import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import * as yup from 'yup';
// import { useFormik } from 'formik';
// import axios from 'axios';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { getTax } from "../../../Service/Tax/TaxService";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Tax = (props) => {
  const { logo } = props;
  const navigate = useNavigate();

  // Define state to track an array of divs
  const [divs, setDivs] = React.useState([]);
  const [inputValues, setInputValues] = useState([]);

  const [count, setCount] = useState(["A"])
  const [flag, setFlag] = useState(false)
  const [popup, setPopup] = useState(true)


  // Function to handle div appending
  const handleAppendDiv = () => {
    count.push(String.fromCharCode(count[count.length - 1].charCodeAt() + 1));
    setCount(count)
    setFlag(!flag)
  };

  // Function to handle div removal at a specific index
  const handleRemoveDiv = (index) => {
    console.log(index, "index")
    const dataArray = Object.entries(inputValues);

    const filteredArray = dataArray.filter(([key]) => key !== index);

    const filteredData = Object.fromEntries(filteredArray);
    setInputValues(filteredData)
    // setCount(count - 1)\
    const filterKey = count.filter(data => data != index)
    setCount(filterKey)

    console.log(filteredData, "filteredData");
  };

  console.log(inputValues, "ipipipipip")


  const onTax = (e, key) => {
    const { name, value } = e.target;

    setInputValues((prevFormData) => ({
      ...prevFormData,
      [key]: {
        ...prevFormData[key],
        [name]: value,

      },
    }));
  };


  const onNext = () => {
    const DataArr = []
    Object.values(inputValues).map((data) => {
      return DataArr.push(data)
    })
    const data = { 'tax': DataArr }

    getTax(data, (res) => {
      console.log(res, "response")
      if (res.success === true) {
        toast.success(res.message)
        // setShowDelete(false)
        // getSegmentDataList();
        // window.location.reload();

        navigate("/subscribe");

      } else {
        toast.error(res.message)

      }
    })
    console.log(data, "DataArr")

  }

const onNextFalse =()=>{
  const data = {'tax':[{tax_label: "", tax_rate: 0}]}

  getTax(data, (res) => {
    console.log(res, "response")
    if (res.success === true) {
      // setShowDelete(false)
      // getSegmentDataList();
      // window.location.reload();

      navigate("/subscribe");

    } else {
      toast.error(res.message)

    }
  })


}

  const onNo = () => {
    setPopup(false)
  }

  const onYes = () => {
    setPopup(true)
  }
  return (

    <div className='authBg'>
      <div className='proflogo'><Link to="/"><img src={logo} /></Link></div>
      <div className='authLogo pt-5'><h5>VAT / TAX Settings</h5></div>

      <div className='signForm'>
        {/* <form> */}
        <div className='signFormbase py-4'>
          <div className='signMainform'>
            <div className='process mb-3'>Does your company process Tax or VAT?

              <div className='prcs'>
                <span className={popup?'rad1 activeColor':'rad1'}>
                  <input type="radio" id="test1" name="radio-group" onClick={onYes} />
                  <label for="test1">Yes</label>
                </span>
                <span className={!popup?'rad2 activeColor':'rad2'}>
                  <input type="radio" id="test2" name="radio-group" onClick={onNo} />
                  <label for="test2">No</label>
                </span>
              </div>

            </div>
            {popup ?             


            <>
            <h5>Sales Tax</h5>

            {count.length != 0 &&

              count.map((data) => (

                <div className='row' key={data}>

                  <div className='col-md-5'>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Tax Label"
                      className="mb-3"
                    >
                      <Form.Control name="tax_label" type="text" placeholder="Tax Label"
                        onChange={(event) => onTax(event, data)} />
                      <span className='needfield'>*</span>

                    </FloatingLabel>
                  </div>
                  <div className='col-md-5'>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Tax Rate"
                      className="mb-3"
                    >
                      <Form.Control name="tax_rate" type="number" placeholder="Tax Rate"
                        onChange={(event) => onTax(event, data)} />
                      <span className='needfield'>%</span>

                    </FloatingLabel>
                  </div>

                  {count.length > 1 &&
                    <div className='col-md-2'><button onClick={() => handleRemoveDiv(data)} className='delIcon'><i class="fa-regular fa-trash"></i></button></div>
                  }

                </div>
              ))


            }

            <button type='submit' onClick={() => handleAppendDiv()} className='appendbtn'><i class="fa-regular fa-circle-plus"></i> Add a tax</button>

            </>
            : "You have not added any Tax information !!!"}



          </div>
        </div>
        <div className='pflex'>
          {/* <Link>Back</Link> */}
          &nbsp;
          {popup?
          <button className='commonButton buttonSubmit w-auto' onClick={onNext}>Next</button>:
          <button className='commonButton buttonSubmit w-auto' onClick={onNextFalse} >Next</button>}
        </div>
        {/* </form> */}
      </div>
    </div>
  )
}

export default Tax