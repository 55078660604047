import { React, useState } from 'react'
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import Form from 'react-bootstrap/Form';
import Headtxt from '../Headtxt';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Salesordergenerationdetails = () => {

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    const [file, setFile] = useState(null);

    const handleDrop = (event) => {
        event.preventDefault();

        const droppedFile = event.dataTransfer.files[0];
        handleFile(droppedFile);
    };

    const handleFile = (selectedFile) => {
        setFile(selectedFile);

        // You can perform additional actions with the file, such as uploading it to a server.
        // For simplicity, we're just logging the file information here.
        console.log('File selected:', selectedFile);
    };
    const handleDragOver = (event) => {
        event.preventDefault();
    };





    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const [selectedDateb, setSelectedDateb] = useState(null);

    const handleDateChangeb = (date) => {
        setSelectedDateb(date);
    };



    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                    <div className='productServList'>
                        <div className='dbTopsec pb-4 pt-2'>
                            <Headtxt link="/salesordergeneration" title="Sales Order generation details" />

                            {/* <div className='custButton'>
                                <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp;
                                <button className='commonButton expBtn'><i class="fa-light fa-download"></i> Export Excel</button>
                            </div> */}
                        </div>

                        <div className='commonWhite addCustomerForm p-3 mb-3'>
                            <Form>
                                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                                    <Col md="12">
                                        <Form.Select aria-label="Default select example">
                                            <option selected hidden>Select Customer</option>
                                            <option value="Company">Company</option>
                                            <option value="Individual">Individual</option>

                                        </Form.Select>
                                    </Col>
                                </Form.Group>

                                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                                    <Col md="8">
                                        <Form.Control type="text" placeholder="Address" />
                                    </Col>
                                    <Col md="4" className='datepickr'>
                                        <DatePicker
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            dateFormat="dd/MM/yyyy" // You can customize the date format
                                            isClearable
                                            placeholderText="Select a date"
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                                    <Col md="4">
                                        <Form.Select aria-label="Default select example">
                                            <option selected hidden>Payment Terms </option>
                                            <option value="Company">Company</option>
                                            <option value="Individual">Individual</option>

                                        </Form.Select>
                                    </Col>
                                    <Col md="4" className='datepickr'>
                                        <DatePicker
                                            selected={selectedDateb}
                                            onChange={handleDateChangeb}
                                            dateFormat="dd/MM/yyyy" // You can customize the date format
                                            isClearable
                                            placeholderText="Select a date"
                                        />
                                    </Col>
                                </Form.Group>


                                <div className='salesOrdTable'>
                                    <div className='listViews'>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Qty</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">Disc.%</th>
                                                    <th scope="col">Total</th>
                                                    <th scope="col">VAT%</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><input type='checkbox' /></td>
                                                    <td><span className='lightBlock'>Lorem</span></td>
                                                    <td><span className='lightBlock'>1.00</span></td>
                                                    <td><span className='lightBlock'>1.00</span></td>
                                                    <td><span className='lightBlock'>1.00</span></td>
                                                    <td><span className='lightBlock'>20.00</span></td>
                                                    <td><span className='lightBlock'>0.0</span></td>
                                                </tr>
                                                <tr>
                                                    <td><input type='checkbox' /></td>
                                                    <td><span className='lightBlock'>Lorem</span></td>
                                                    <td><span className='lightBlock'>1.00</span></td>
                                                    <td><span className='lightBlock'>1.00</span></td>
                                                    <td><span className='lightBlock'>1.00</span></td>
                                                    <td><span className='lightBlock'>20.00</span></td>
                                                    <td><span className='lightBlock'>0.0</span></td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='mb-4'>
                                    <button className='commonButton expBtn' onClick={handleShow}><i class="fa-regular fa-magnifying-glass"></i> Add Product or Service</button>&nbsp;&nbsp;
                                    <button className='commonButton addBtn'>Delete</button>
                                </div>






                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="6" className='mb-3'>
                                        <Form.Control as="textarea" placeholder="Notes" rows={3} />
                                    </Col>
                                    <Col md="6" className='mb-3'>
                                        <div className='subTotal'>
                                            <div className='saflex'>
                                                <span>Sub Total</span>
                                                <span>20:00</span>
                                            </div>
                                            <div className='saflex'>
                                                <span>Discount Rate</span>
                                                <span>20:00</span>
                                            </div>
                                            <div className='saflex'>
                                                <span>Discount Amount</span>
                                                <span>20:00</span>
                                            </div>
                                            <div className='saflex'>
                                                <span>VAT</span>
                                                <span>20:00</span>
                                            </div>
                                            <div className='saflex totlbg'>
                                                <span>Total</span>
                                                <span>20:00</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Group>

                                <label>Attachments</label>
                                <div className='adsDrag'>
                                    <div
                                        onDrop={handleDrop}
                                        onDragOver={handleDragOver}
                                        className='drgdrop'
                                    >
                                        <i class="fa-light fa-cloud-arrow-up"></i>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <p>Drag &amp; drop files or </p>
                                            <input type="file" onChange={(e) => handleFile(e.target.files[0])} style={{ display: 'none' }} />
                                            <button onClick={() => document.querySelector('input[type="file"]').click()}>Browse</button>
                                        </div>

                                        {file && <p>Selected File: {file.name}</p>}
                                    </div>
                                </div>








                            </Form>
                        </div>
                        <button className='commonButton expBtn'>Done</button>&nbsp;&nbsp;
                        <button className='commonButton addBtn'>Cancel</button>


                    </div>
                </div>
            </Sidebar>




            
        </div>
    )
}

export default Salesordergenerationdetails