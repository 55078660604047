import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css'; // Import Owl Carousel CSS
import 'owl.carousel/dist/assets/owl.theme.default.css';
import circl from '../../images/circleimg.png';
import circl2 from '../../images/circl2.png';
import circl3 from '../../images/circl3.png';

const Testimonial = () => {

    const options = {
        items: 3,
        loop: true,
        margin: 0,
        center: true,
        autoplay: false,
        nav: false,
        navText: ["<i class='fa-light fa-arrow-left-long'></i>", "<i class='fa-light fa-arrow-right-long'></i>"],
        dots: true,
        responsive: {
            0: {
                items: 1, // Display one item on small screens
            },
            600: {
                items: 1, // Display two items on screens larger than 600px
            },
            1000: {
                items: 3, // Display three items on screens larger than 1000px
            },
        },
    };


    return (
        <div className='testimonial'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h2>What Our Client Says?</h2>

                        <OwlCarousel className="owl-theme testiTm" {...options}>
                            <div className="item">
                                <div className='testiBlock'>
                                    <img src={circl} alt='' />
                                    <h6>James Miller</h6>
                                    <p>1SimpleClick simplified my accounting transactions and made me understand my business better. I also appreciate the prompt guidances from their professional. You are not only dealing with a system but also professionals who will guide you to ensure you are fully utilizing the system. </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className='testiBlock'>
                                    <img src={circl2} alt='' />
                                    <h6>Ali H., Small Business Owner:</h6>
                                    <p>1SimpleClick has revolutionized the way I manage finances for my small business. The intuitive interface and automated features make accounting a breeze. I've gained valuable insights, and the customer support is top-notch!</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className='testiBlock'>
                                    <img src={circl3} alt='' />
                                    <h6>David L., CFO of Mid-sized Company:</h6>
                                    <p>Our company transitioned to 1SimpleClick for its accounting needs, and the results have been outstanding. The collaboration features and real-time data access are invaluable. It's a powerful tool that simplifies complex financial tasks.</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className='testiBlock'>
                                    <img src={circl3} alt='' />
                                    <h6>Michael H., International Business Owner:</h6>
                                    <p>Dealing with multiple currencies used to be a headache. 1SimpleClick's support for international transactions and automatic currency updates have made our financial operations seamless. It's truly a global business companion.</p>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonial