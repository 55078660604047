import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import pie from '../../../images/pie.png';



const Piechart = () => {


  const chartContainer = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    // Generate random data
    const labels = ['Rahmah Centre', 'HP Plus', 'Walmart', 'Timhortons'];
    const data = [5, 65, 15, 15];
    const backgroundColors = ['#f5564a', '#1db2f5', '#eb3573', '#ffc720'];

    // Destroy existing chart instance if it exists
    if (chartInstance.current !== null) {
      chartInstance.current.destroy();
    }

    // Create chart
    const ctx = chartContainer.current.getContext('2d');
    chartInstance.current = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [{
          data: data,
          backgroundColor: backgroundColors,
          hoverBackgroundColor: backgroundColors,
          // radius: ['103%', '10%'],
          radius: '103%',
          cutout: '40%',
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 100,
        layout: {
          padding: {
            top: 0, // Adjust top padding
            bottom: 0, // Adjust bottom padding
            left: 0,
            right: 0
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'right',
          },
        },
      }
    });



    return () => {
      if (chartInstance.current !== null) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  return (
    <div className='pieChartBg'>
      <canvas ref={chartContainer} width="100%" height="220px" />
    </div>
  );
};


 
  // return (
  //   <div><img src={pie} alt='' /></div>
  // )

export default Piechart