import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import dashblue from '../../images/dashblue.png';
import Navigation from '../layout/Navigation';
import bs1 from '../../images/bs1.jpg';
import detlbnr from '../../images/detailsbnr.jpg';
import quotemark from '../../images/quotemark.png';
import cfo1 from '../../images/cfo1.jpg';
import Footer2 from '../layout/Footer2';



const Blogdetails = (props) => {
        const { logo } = props;

        //FOR-MASONRY-TRANSFORM
        const breakpointColumnsObj = {
            default: 3,
            1100: 2,
            700: 2,
            500: 1
          };

        

 // FOR-LOAD-MORE
 const [items, setItems] = useState(['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6', 'Item 7', 'Item 8', 'Item 9', 'Item 10', 'Item 11', 'Item 12', 'Item 13', 'Item 14', 'Item 15']);
 const [visibleItems, setVisibleItems] = useState(5);
 const [loading, setLoading] = useState(false);

 const loadMoreItems = () => {
     setLoading(true);
     setTimeout(() => {
         // Simulate loading delay
         setVisibleItems((prev) => prev + 5);
         setLoading(false);
     }, 1000); // Adjust the delay as needed
 };

 const handleScroll = () => {
     if (
         window.innerHeight + document.documentElement.scrollTop ===
         document.documentElement.offsetHeight
     ) {
         // Auto-load more items when scrolling to the bottom
         loadMoreItems();
     }
 };

 useEffect(() => {
     window.addEventListener('scroll', handleScroll);
     return () => {
         window.removeEventListener('scroll', handleScroll);
     };
 }, []);


      
            
    
        return (
    
            <>
            <div className='authBg pricinghome'>
                <header>
                    <Navigation/>
                </header>

                {/* <div className='proflogo'><Link to="/"><img src={logo} /></Link></div> */}
                <div className='authLogo pt-lg-5 mt-5 pricingLogo'>&nbsp;</div>
    
                <div className='signForm pricingshadow container'>
                     <div className='choosePlanTop pricingA pb-4'>
                        <h2>Blog Details</h2>
                        <img src={dashblue} />
                        <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur...</p>
                     </div>
                     <div className='detlBnr'>
                        <img src={detlbnr} />                             
                     </div>
                     <div className='detlTxt'>
                        <div className='delRedtxt mb-3'>
                            <i class="fa-regular fa-user"></i>
                            <small>David Smith</small>
                            <i class="fa-regular fa-calendar"></i>
                            <small>21 jan 2023</small>
                        </div>
                        <h3>Lorem Ipsum is simply dummy..</h3>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum. sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                     </div>
                     <div className='blogQuote'>
                        <img src={quotemark} />
                        <div className='blogSlogan'>
                        “Efficiently pontificate strategic synergy without distributed growth strategies. 
Collaboratively restore.”<br/>
<span>- Md Sumon Mia</span>
                        </div>
                     </div>
                     <div className='detlTxt'>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum. sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                     </div>
                     <div className='relatedBlog'>
                        <h5 className='pb-2'>Related Blogs</h5>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='masBlock'>
                                    <img src={bs1} />
                                    <div className='masCard'>
                                        <h5>News &nbsp;&nbsp;|&nbsp;&nbsp; <span>21 jan 2023</span></h5>
                                        <h3>Lorem Ipsum is simply dummy text here. Lorem Ipsum is simply dummy text here.</h3>
                                        <Link to="/blogdetails">Read More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='masBlock'>
                                    <img src={cfo1} />
                                    <div className='masCard'>
                                        <h5>News &nbsp;&nbsp;|&nbsp;&nbsp; <span>21 jan 2023</span></h5>
                                        <h3>Lorem Ipsum is simply dummy text here. Lorem Ipsum is simply dummy text here.</h3>
                                        <Link to="/blogdetails">Read More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='masBlock'>
                                    <img src={bs1} />
                                    <div className='masCard'>
                                        <h5>News &nbsp;&nbsp;|&nbsp;&nbsp; <span>21 jan 2023</span></h5>
                                        <h3>Lorem Ipsum is simply dummy text here. Lorem Ipsum is simply dummy text here.</h3>
                                        <Link to="/blogdetails">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                     </div>


                </div>


            </div>
            <Footer2/>
            </>
        )
    }
    



export default Blogdetails