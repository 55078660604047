import React from 'react'
import dash from '../../images/dashorange.png';
import tximg from '../../images/tximg.png';
import tximg2 from '../../images/tximg2.png';

const Taxseason = () => {
    return (
        <div className='taxseason'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='taxbase'>
                            <div className='taxLft'><img src={tximg2} alt='' /></div>
                            <div className='taxRgt'>
                                {/* <h2>Tax season, minus<br/> the stress</h2> */}
                                <h2>1SimpleClick simplified your financial reporting needs:</h2>
                                <img src={dash} alt='' />
                                {/* <h6>Consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est</h6> */}
                                <ul className='taxTxt mt-3'>
                                    <li>Generate financial statements such as balance sheets, income statements, and cash flow statements</li>
                                    <li>Provides a comprehesive overview of financial performance, account balances, and key financial metrics.</li>
                                    <li>Offers customizable financial reports, trial balance, journal entries and audit trail reports.</li>
                                    <li>Displays hierarchila list of accounts used for categorizing financial transactions</li>
                                    <li>Shows a ledger view of all transactions recorded in the system, organized by accounts and dates.</li>
                            
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default Taxseason