import React, { useState ,useEffect} from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import Usr from '../../images/usr.png';
import Lock from '../../images/lock.png';
import { toast } from 'react-toastify';
import {getResetPassword} from "../../../Service/ResetPassword/ResetPasswordService";
import { useNavigate } from 'react-router-dom';

const Resetpassword = (props) => {
  const { logo } = props;
  const navigate = useNavigate();
  const [userData, setUserData] = useState('');


  const [showpassword, setShowPassword] = useState(false);
  const [showconfirmpassword, setShowConfirmPassword] = useState(false);



  useEffect(() => {
    // Retrieve the JSON string from localStorage
    const jsonString = localStorage.getItem('1Simpleclick_forgot_email'); // Replace 'yourStorageKey' with the actual key.

    // Parse the JSON string into an object
    const data = JSON.parse(jsonString);

    // Update the state with the retrieved data
    setUserData(data);
  }, []);
  console.log(userData?.email, "userData")

  const formik = useFormik({
    initialValues: {
        password: '',
        confirmpassword: ''
    },
    validationSchema: yup.object({
        password: yup.string().min(8, "Password must have atleast 8 character").required("Password is a required field"),
        confirmpassword: yup.string().required("Confirm Password is a required field").oneOf([yup.ref('password'), null], 'Must match "password" field value'),
    }),
    onSubmit: async(values, {resetForm}) => {      
      await getResetPassword(values.password,values.confirmpassword,userData?.email, (res) => {
        console.log(res, "koko")
        if (res.success === true) {
 // setAuthed({isAuth: true, token: res.data.token, user: userdata})
          toast.success(res.message)

          navigate("/login");
          // window.location.reload();
          // toast.success(res.message)

          formik.resetForm();
        } else {
          toast.error(res.message)

        }
      })
    }
})


console.error(formik.errors);
const renderPasswordError = formik.touched.password && formik.errors.password && (<span className='errorTxt'>{formik.errors.password}</span>);

const renderConfirmPasswordError = formik.touched.confirmpassword && formik.errors.confirmpassword && (<span className='errorTxt'>{formik.errors.confirmpassword}</span>);




     // FOR-GET-LOGIN-RESPONSE
     const url = "https://reqres.in/api/users";

     const login = async(data) => {
        const response =await axios.post(url,data)
        // const getresponse =await axios.get(url)
        // console.log(getresponse, "getResponse")
        if ([200,201,202,203,204].includes(response.status)){
          toast.success("Successfully");
        }
        else{
          toast.success("Error");
          console.log(response, "somnath")
        }
        
     }



  return (
    <div className='authBg'>
      <div className='authLogo'><Link to="/"><img src={logo} /></Link></div>
      <div className='signForm'>
        <div className='signFormbase'>
          <div className='signHdn'>Reset Password</div>
          <div className='signMainform'>
          <form onSubmit={formik.handleSubmit}>

                    <div className='mb-3'>
                      <label><img src={Lock} />&nbsp; New Password</label>
                      <div className="showHide">
                        <input className="form-control" name='password' type={showpassword ? "text" : "password"} id="password" placeholder="Enter your new password" onChange={formik.handleChange} value={formik.values.password} />                      
                        <div className="input-group-addon eyeAbs" onClick={() => { setShowPassword(!showpassword) }}>
                          {showpassword ? <span><i className="fa fa-eye" aria-hidden="true"></i></span> : <span><i className="fa fa-eye-slash" aria-hidden="true"></i></span>}
                        </div>
                        {renderPasswordError}
                      </div>
                    </div>

                    <div className='mb-3'>
                      <label><img src={Lock} />&nbsp; Confirm New Password</label>
                      <div className="showHide">                    
                        <input className="form-control" name="confirmpassword" type={showconfirmpassword ? "text" : "password"} id="confirmpassword" placeholder="Retype your new password" onChange={formik.handleChange} value={formik.values.confirmpassword} />                  
                        <div className="input-group-addon eyeAbs" onClick={() => { setShowConfirmPassword(!showconfirmpassword) }}>                          
                          {showconfirmpassword ? <span><i className="fa fa-eye" aria-hidden="true"></i></span> : <span><i className="fa fa-eye-slash" aria-hidden="true"></i></span>}
                        </div>
                        {renderConfirmPasswordError}                        
                      </div>
                    </div>



              <div className='forgotPass text-start'><span style={{color: "red"}}>*</span>Passwords should be at least 8 characters long</div>

              <button type="submit" className='commonButton buttonSubmit'>Submit</button>
          </form>
          </div>
        </div>
        <div className='accountTxt'>&nbsp;</div>
      </div>
    </div>
  )
}

export default Resetpassword