import React, { useEffect } from 'react';
import logo from '../../../logo.png';
import Appstore from '../../images/appstore.png';
import Googlestore from '../../images/googlestore.png';
import { Link } from "react-router-dom";

const Footer2 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    
    }, []);
    
  return (
    <div className='footerB'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-4 footerLogo'>
                    <img src={logo} alt='' />
                    <div className='socialBg'>
                        <Link to="/"><i class="fa-brands fa-facebook-f"></i></Link>
                        <Link to="/"><i class="fa-brands fa-linkedin-in"></i></Link>
                        <Link to="/"><i class="fa-brands fa-instagram"></i></Link>
                        <Link to="/"><i class="fa-light fa-circle-play"></i></Link>
                    </div>
                    <p>1SimpleClick is designed with simplicity in mind. The user interface is intuitive, making it easy for individuals and businesses, including those without extensive accounting knowledge, to navigate and use the platform.</p>
                </div>
                <div className='col-lg-3'>
                    <h4>Features</h4>
                    <ul className='mb-4'>
                        <li><Link to='/salesmodule'>Sales Module</Link></li>
                        <li><Link to='/purchasemodule'>Purchase Module</Link></li>
                        <li><Link to='/paymentmodule'>Payment Module</Link></li>
                        <li><Link to='/accountingmodule'>Accounting Module</Link></li>
                        <li><Link to='/professionalservice'>Professional Services</Link></li>
                        <li><Link>Payroll Module(coming soon)</Link></li>
                    </ul>
                </div>
                
                <div className='col-lg-2'>
                    <h4>Company</h4>
                    <ul className='mb-4'>
                        <li><Link to='/aboutus'>ABOUT</Link></li>
                        <li><Link to='/signup'>PRICING</Link></li>
                        <li><Link to='/contactus'>HELP</Link></li>
                        <li><Link to='/contactus'>CONTACT US</Link></li>
                        <li><Link to='/privacy'>PRIVACY POLICY</Link></li>
                        <li><Link to='/termscondition'>TERMS & CONDITIONS</Link></li>
                    </ul>
                </div>
                <div className='col-lg-3 footerStore'>
                    <h4>Download our App</h4>
                    <p>Monitor the financial position of your company from your phone!</p>
                    <div className='mb-3'><Link><img src={Appstore} alt='' /></Link></div>
                    <div><Link><img src={Googlestore} alt='' /></Link></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer2