import React from 'react';
import mob from '../../images/mobile.png';
import dash from '../../images/dashorange.png';
import apple from '../../images/appstore.png';
import gplay from '../../images/googlestore.png';
import { Link } from 'react-router-dom';

const Smartaccounting = () => {
    return (
        <div className='smartAccountbg'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <h2><span>smart</span> <br />
                            Accounting <span>system</span></h2>
                        <h5> Experience the difference with our solutions designed exclusively for you, ensuring unparalleled support and success in every aspect of your business journey.</h5>
                        <img src={dash} alt='' />
                        <p>Elevate your business with our cutting-edge Smart Accounting system, designed for seamless efficiency. Experience the power of fully integrated modules delivering the latest up-to-date financial reports at your fingertips. Enjoy the simplicity of our advanced</p>
                        {/* <p>Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas</p>
                        <p>consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..</p> */}
                        <div className='mt-4 mb-4'>
                            <Link><img src={apple} alt='' /></Link>&nbsp;&nbsp;
                            <Link><img src={gplay} alt='' /></Link>
                        </div>
                    </div>
                    <div className='col-lg-6 accRight'>
                        <img src={mob} alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Smartaccounting