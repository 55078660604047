import webApi, { baseURL } from "../../WebApi/WebApi";

//FOR LOGIN 

export const getResetPassword = async (newPassword, confirmPassword, email, onSuccess, onFailure) => {


    try {
        const res = await webApi.post(

            `/newPassword`,
            {
                email: email,
                password: newPassword,
                confirm_password: confirmPassword
            }
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
