import React from 'react'
import Leftmenu from '../dbstructure/Leftmenu'

const Sidebar = ({children}) => {
  return (
    <div className="container customContainer">
        <div className='maindbBase'>
        <Leftmenu/>
        {children}
        </div>
    </div>
  )
}

export default Sidebar