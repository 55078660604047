// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import { Link, Route, Switch, Routes } from 'react-router-dom';
//import Home from '../home/index';
import Accounting from './accounting/Accounting';
// import Sales from './sales/Sales';
import Leftmenu from './Leftmenu';
import Dbheader from '../layout/Dbheader';
import Dbcontent from './dbcontent/Dbcontent';
import Sidebar from '../layout/Sidebar';
import { useNavigate } from 'react-router-dom';
const Dashboard = () => {
  const [authToken, setAuthToken] = useState()
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('1Simpleclick_AuthToken');
    if (token) {
      setAuthToken(token);
    } else {
      navigate("/login");
    }
  }, [navigate]);
  console.log("token--->",localStorage.getItem('1Simpleclick_AuthToken'))

  return (
    authToken ? (
      <div className="dashboard pdt">
        <Dbheader />
        <Sidebar>
          <Dbcontent />
        </Sidebar>
      </div>
    ) : null
  );
};
export default Dashboard;
