import React, { useState ,useEffect} from 'react';
import OtpInput from 'react-otp-input';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {getForgotPassword} from "../../../Service/ForgotPassword/ForgotPasswordService";

import { getOtpVerification } from "../../../Service/OTPVerification/OTPVerificationService";
const Verifyotp = (props) => {
    const { logo } = props;
    const [otp, setOtp] = useState('');
    const [userData, setUserData] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Retrieve the JSON string from localStorage
        const jsonString = localStorage.getItem('1Simpleclick_forgot_email'); // Replace 'yourStorageKey' with the actual key.
    
        // Parse the JSON string into an object
        const data = JSON.parse(jsonString);
    
        // Update the state with the retrieved data
        setUserData(data);
      }, []);
      console.log(userData?.email, "userData")

    const onVerify = (e) => {
        getOtpVerification(otp, (res) => {
            console.log(res, "koko")
            if (res.success === true) {
                // setAuthed({isAuth: true, token: res.data.token, user: userdata})
                toast.success(res.message)

                navigate("/resetpassword");
                // window.location.reload();
                // toast.success(res.message)

            } else {
                toast.error(res.message)

            }
        })

    }


    const onResend = (e) => {
        getForgotPassword(userData?.email, (res) => {
            console.log(res, "koko")
            if (res.success === true) {              
     // setAuthed({isAuth: true, token: res.data.token, user: userdata})
              toast.success(res.message)  
           
    
           
            } else {
              toast.error(res.message)
    
            }
          })

    }
    console.log(otp, "otp")
    return (
        <div className='authBg'>
            <div className='authLogo'><Link to="/"><img src={logo} /></Link></div>
            <div className='signForm'>
                <div className='signFormbase'>
                    <div className='signHdn'>Verify OTP</div>
                    <div className='verifyOtp'>
                        <p className='mb-2'>Enter the verification code we sent to your registered email</p>
                        <small className='mb-3 securecode'><em>Type your 4 digit security code</em></small>
                        {/* <h6 className='mb-4'>*******@mail.com</h6> */}
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={4}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input {...props} />}
                        />
                        <button className='commonButton buttonSubmit' onClick={onVerify}>Verify</button>
                        <small>Didn’t get the code ? <button className='resend' onClick={onResend}>Resend</button> </small>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Verifyotp