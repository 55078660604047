import axios from "axios";

// const prodApi = "https://dev8.mydevfactory.com/1SimpleClick/api/";
// const devApi = "https://dev8.mydevfactory.com/1SimpleClick/api/";
const prodApi =  "https://1simpleclick.com/Backend/api";
const devApi = "https://1simpleclick.com/Backend/api";

export let baseURL;
const subdomain = window.location.host.split(".")[0];

if (process.env.NODE_ENV === "production" && subdomain === "live") {
  baseURL = prodApi;
} else {
  baseURL = devApi; //prodApi //
}

let instance = axios.create({
  baseURL: baseURL,
  responseType: "json",
});

// Request interceptor to add the Authorization header
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("1Simpleclick_AuthToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;

