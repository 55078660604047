import webApi, { baseURL } from "../../WebApi/WebApi";

export const getPurchaseOrder = async (id) => {
    return await webApi.get(`/purchase-order-quote-details/${id}`,

    );
}

export const editPurchaseOrderget = async (id) => {
    return await webApi.get(`/purchase-order-details/${id}`,

    );
}

// export const addPurchaseOrder = async (data,onSuccess,onFailure) => {
//     const res = await webApi.post(`/purchase-order-details-store`,data);
//     if (res.status === 200) {


//         const r = res.data;
//         console.log(r, "rerere")

//         return onSuccess(r);

//     } else {
//         onFailure("Something Wrong! Please Try again later" + res.data);
//     }
// }
export const addPurchaseOrder = async (data, onSuccess, onFailure) => {
    console.log(data, "data")
    try {
        const res = await webApi.post(

            `/purchase-order-details-store`,
            data,
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const listPurchaseOrder = async (searchKeyword='',fromDate='',toDate='') => {
    return await webApi.get(`/purchase-order-details-search?searchText=${searchKeyword}&fromDate=${fromDate}&toDate=${toDate}`);
}

export const getEditPurchaseOrder = async (id,data, onSuccess, onFailure) => {
    console.log(data, "data")
    try {
        const res = await webApi.post(

            `/purchase-order-details-edit/${id}`,
            data,
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

// export const sendEmail = async (id,data,onSuccess, onFailure) => {

//     // console.log(id, email, quoteNo, address, "d,email,quoteNo,address")
//     try {
//         const res = await webApi.post(`/purchase-order-details-mail-sending/${id}`,data);
//         if (res.status === 200) {


//             const r = res.data;
//             console.log(r, "rerere")

//             return onSuccess(r);

//         } else {
//             onFailure("Something Wrong! Please Try again later" + res.data);
//         }
//     } catch (error) {
//         console.log("fdgdf")
//     }
// };

export const sendEmail = async (id, email, quoteNo, address,checks, onSuccess, onFailure) => {

    console.log(id, email, quoteNo, address, "d,email,quoteNo,address")
    try {
        const res = await webApi.post(

            `/purchase-order-details-mail-sending/${id}`,
            {
                email: email,
                body: address,
                subject: quoteNo,
                selfCopy:checks
            }

        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const getExcelOrder = async () => {
    return await webApi.get( `/purchase-order-list-export`, 
{

    });
}

export const getPurchaseOrderDelete = async (id, onSuccess, onFailure) => {
    try {
        const res = await webApi.get(

            `/purchase-order-delete/${id}`
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const getPurchaseOrderResend = async (id, data, onSuccess, onFailure) => {
    try {
        const res = await webApi.post(

            `purchase-order-action`,{
                id:id,
                text:data
            }
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};