import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css'; // Import Owl Carousel CSS
import 'owl.carousel/dist/assets/owl.theme.default.css';
import bs1 from '../../images/bs1.jpg';
import bs2 from '../../images/bs2.jpg';
import bs3 from '../../images/bs3.jpg';
import { Link } from 'react-router-dom';

const Owlcarousel2 = () => {
    const options = {
        items: 3,
        loop: false,
        margin: 20,
        autoplay: true,
        nav: true,
        navText : ["<i class='fa-light fa-arrow-left-long'></i>","<i class='fa-light fa-arrow-right-long'></i>"],
        dots: false,
        responsive: {
            0: {
                items: 1, // Display one item on small screens
            },
            600: {
                items: 2, // Display two items on screens larger than 600px
            },
            1000: {
                items: 3, // Display three items on screens larger than 1000px
            },
        },
    };

    return (
        <OwlCarousel className="owl-theme" {...options}> 
            <div className="item">
                <Link to="/managebusinessdetails2">
                <div className='slideBlock'>
                    <h6>CFO Service</h6>
                    <img src={bs1} alt='' />
                </div>
                </Link>
            </div>           
            <div className="item">
                <Link to="/managebusinessdetails">
                    <div className='slideBlock'>
                        <h6>Professional Book Keeping</h6>
                        <img src={bs2} alt='' />
                    </div>
                </Link>
            </div>
            <div className="item">
                <Link to="/managebusinessdetails1">
                    <div className='slideBlock'>
                        <h6>Strategic and Business Planning</h6>
                        <img src={bs3} alt='' />
                    </div>
                </Link>
            </div>
            <div className="item">
                <Link to="/">
                    <div className='slideBlock'>
                        <h6>Professional Training</h6>
                        <img src={bs3} alt='' />
                    </div>
                </Link>
            </div>
            <div className="item">
                <Link to="/">
                    <div className='slideBlock'>
                        <h6>Process Improvements and Controls</h6>
                        <img src={bs3} alt='' />
                    </div>
                </Link>
            </div>            
            <div className="item">
                <Link to="/">
                    <div className='slideBlock'>
                        <h6>Audit Support</h6>
                        <img src={bs3} alt='' />
                    </div>
                </Link>
            </div>
            
        </OwlCarousel>
    )
}

export default Owlcarousel2