import React from 'react'
import Dbheader from '../layout/Dbheader'
import Sidebar from '../layout/Sidebar'


const Demo = () => {
  return (
    <div className="accounting pdt">
      <Dbheader/>
      <Sidebar>
        <h2>Piechart</h2>
      </Sidebar>
    </div>
  )
}

export default Demo