const sampleProductServiceCsv=[

    {
        "Product_code":"123", 
        "Name": "Monitor",
        "Description": "I9 with DDRM",
        "Unit": "Piece",
        "Sales_price": "59000",
        "Purchase_price": "55000",
        "Purchase_tax": "2000",
        "Sales_Tax":"200",
        "Quantity": "1000",
        "Track_Inventory": "YES or NO",
       
    }
]

export default sampleProductServiceCsv;