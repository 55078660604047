import React, { useState, useEffect } from 'react';
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import Form from 'react-bootstrap/Form';
import Headtxt from '../Headtxt';
import Col from 'react-bootstrap/Col';
import { useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { getCustomerListById } from "../../../../Service/Customer/CustomerService";
import CurrencySelect from 'react-select-currency';
const Customerdetails = () => {
  const location = useLocation();
  const customerId = new URLSearchParams(location.search);
  console.log(customerId.get(`id`), "customerId")

  const [show, setShow] = useState(false);
  const [getCustomerId, setCustomerId] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // For toggle
  const [isToggled, setToggled] = useState(false);

  const handleToggleOn = () => {
    setToggled(true);
  };
  const handleToggleOff = () => {
    setToggled(false);
  };


  // For drag-drop
  const [file, setFile] = useState(null);

  const handleDrop = (event) => {
    event.preventDefault();

    const droppedFile = event.dataTransfer.files[0];
    handleFile(droppedFile);
  };

  const handleFile = (selectedFile) => {
    setFile(selectedFile);

    // You can perform additional actions with the file, such as uploading it to a server.
    // For simplicity, we're just logging the file information here.
    console.log('File selected:', selectedFile);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };


  useEffect(() => {
    getallCustomerList()
  }, [])

  async function getallCustomerList() {
    getCustomerListById(customerId.get(`id`)).then(function (result) {
      const response = result.data;
      console.log(response, "response")
      setCustomerId(response?.data);
      // setDepartmentId(response?.data?.businessUnit?.Departments);
    });
  }

  return (
    <div className="customerListing pdt">
      <Dbheader />
      <Sidebar>
        <div className='customerMain'>
          <div className='custBtm'>
            <div className='dbTopsec pb-3'>
              <Headtxt link="/customerlisting" title="Customer Details" />
              {/* <div className='custButton'>
                <button className='commonButton expBtn' onClick={handleShow}><i class="fa-light fa-upload"></i> Import</button>
              </div> */}
            </div>

            <div className='commonWhite addCustomerForm p-3 mb-3'>

              <Form>
                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                  <Col md="4">

                    <Form.Control type="text" value={getCustomerId?.type} />

                  </Col>
                  {getCustomerId?.type === "Individual" || getCustomerId?.type === "" ? <><Col md="4">
                    <Form.Control type="text" placeholder="First Name" value={getCustomerId?.fname} />
                  </Col>
                    <Col md="4">
                      <Form.Control type="text" placeholder="Last Name" value={getCustomerId?.lname} />
                    </Col></>
                    : <Col md="8">
                      <Form.Control type="text" placeholder="Company Name" value={getCustomerId?.company_name} />
                    </Col>
                  }
                </Form.Group>

                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                  <Col md="6">
                    <Form.Control type="email" placeholder="Email address" value={getCustomerId?.email} />
                  </Col>
                  <Col md="6">
                    <Form.Control type="text" placeholder="Phone Number" value={getCustomerId?.phone} />
                  </Col>
                </Form.Group>

                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                  <Col md="6">
                    <Form.Control type="text" placeholder="Website URL (Optional)" value={getCustomerId?.website_url} />
                  </Col>
                  <Col md="6">
                    <Form.Control type="text" placeholder="VAT Number(Optional)" value={getCustomerId?.vat_number} />
                  </Col>
                </Form.Group>

                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                  <Col md="6">
                    <label>Billing address</label>
                    <Form.Control type="text" placeholder="Billing Address(Optional)" value={getCustomerId?.billing_address} />
                  </Col>
                  <Col md="6">
                    <label></label>
                    <Form.Control type="text" value={getCustomerId?.country} />
                  </Col>
                </Form.Group>

                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                  <Col md="4">
                    <Form.Control type="text" value={getCustomerId?.state} />
                  </Col>
                  <Col md="4">
                    <Form.Control type="text" value={getCustomerId?.city} />
                  </Col>
                  <Col md="4">
                    <Form.Control type="text" placeholder="Zip Code(Optional)" value={getCustomerId?.zipcode} />
                  </Col>
                </Form.Group>
                {/* <Col md="4">
                <CurrencySelect
      value={getCustomerId?.currency}
     // onChange={handleCurrencyChange}
      placeholder="Select a currency"      
      className="slctCurrency"
      style={{width:'200%'}}
      disabled
    />
       </Col> */}
                {/* 
                <div className='radioline'><span>Use same address for delivery</span>
                  <div>
                    <input type="radio" id="test1" name="radio-group" onClick={handleToggleOff} />
                    <label for="test1">Yes</label>
                  </div>
                  <div>
                    <input type="radio" id="test2" name="radio-group" onClick={handleToggleOn} />
                    <label for="test2">No</label>
                  </div>
                  <div className='radioRel'><Form.Check type="radio" label="Yes" name="group1" aria-label="radio1" /></div>
                  <div className='radioRel'><Form.Check type="radio" label="No" name="group1" aria-label="radio2" /></div>
                </div> */}

                <div className='pt-3'>
                  <label>Delivery address</label>
                  <div>
                    <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                      <Col md="6">
                        <Form.Control type="text" placeholder="Delivery Address" value={getCustomerId?.delivery_address} />
                      </Col>
                      <Col md="6">
                        <Form.Control type="text" value={getCustomerId?.delivery_country} />
                      </Col>
                    </Form.Group>

                    <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                      <Col md="4">
                      <Form.Control type="text" value={getCustomerId?.delivery_state} />

                      </Col>
                      <Col md="4">
                      <Form.Control type="text" value={getCustomerId?.delivery_city} />

                      </Col>
                      <Col md="4">
                        <Form.Control type="text" placeholder="Zip Code(Optional)" value={getCustomerId?.delivery_zipcode} />
                      </Col>
                    </Form.Group>
                  </div>

                </div>


              </Form>
            </div>
            {/* <button className='commonButton expBtn'>Save</button> */}


          </div>
        </div>
      </Sidebar>

      <Modal show={show} onHide={handleClose} centered className='customerModal'>
        <Modal.Header closeButton>
          <Modal.Title><h5>Import Products</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='modalContent'>
            <div
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              className='drgdrop'
            >
              <i class="fa-light fa-cloud-arrow-up"></i>
              <div style={{ display: "flex" }}>
                <p>Drag &amp; drop files or </p>
                <input type="file" onChange={(e) => handleFile(e.target.files[0])} style={{ display: 'none' }} />
                <button onClick={() => document.querySelector('input[type="file"]').click()}>Browse</button>
              </div>

              {file && <p>Selected File: {file.name}</p>}
            </div>
          </div>
          <div>
            <p><button className='commonButton modalsubmit'>Submit</button></p>
            <p><button className='commonButton downloadformat'>Download format</button></p>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>

    </div>
  )
}

export default Customerdetails