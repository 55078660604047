import { React, useState,useEffect } from 'react'
import Sidebar from '../../../layout/Sidebar'
import Dbheader from '../../../layout/Dbheader'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
// import Headtxt from '../Headtxt';
import { ToastContainer, toast } from 'react-toastify';
import Listview from '../../sales/Listview';
import Gridview from '../../sales/Gridview';
import Card from 'react-bootstrap/Card'
import Headtxt from '../../Headtxt';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import bclogo from '../../../../images/bclogo.png';
import moment from "moment"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getAccounttId, getAddBillPayment, getBillPaymentId } from '../../../../../Service/Quotation/QuotationService';
import { useNavigate,useLocation, } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map'
import axios from 'axios';
import Swal from 'sweetalert2';
const Billpaymentadd = ({setIsSpinner}) => {
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState(null);
    const [billId, setBillId] = useState([]);
    const [Account, setAccount] = useState([]);
    const [method, setMethod] = useState('');
    const [billtableId, setBilltableId] = useState('');
    const [formateDate, setformatedDate] = useState(null);
    const [currency,setcurrency] = useState();
const [currencyData, setCurrencyData] = useState(null);
const [selectedCurrency, setSelectedCurrency] = useState('CAD');
const [selectedCurrencyValue, setSelectedCurrencyValue] = useState(null);
const [error, setError] = useState(null);
    const handleDateChange = (date) => {
        setSelectedDate(date);
        setformatedDate(moment(date).format('DD-MM-YYYY'))
    };
    
    const [selectedBillAmount, setSelectedBillAmount] = useState('');
    const [selectedBillId, setSelectedBillId] = useState('');
    const [selectedaccountId, setSelectedAccountId] = useState('');
    // Function to handle bill ID selection
    const handleBillIdChange = (e) => {
        const selectedId = e.target.value;
        setSelectedBillId(selectedId);
        console.log(e.target,'billlamont===');
        // Find the bill with the selected ID and set its amount
        const selectedBill = billId.find((bill) => bill.bill_id === selectedId);
       // console.log(selectedBill,'billlamont==');
        if (selectedBill) {
            setSelectedBillAmount(selectedBill.supplierCurrency_dueAmount);
            setBilltableId(selectedBill.id);
            setSelectedCurrency(selectedBill.currency)
        } else {
            setSelectedBillAmount(''); // Reset the amount if the selected bill ID is not found
        }
    };
    const handleAccountChange = (e) => {
        const selectedId = e.target.value;
        setSelectedAccountId(selectedId);
        console.log(e.target,'billlamont===');
        // Find the bill with the selected ID and set its amount
    //     const selectedBill = billId.find((bill) => bill.bill_id === selectedId);
    //    // console.log(selectedBill,'billlamont==');
    //     if (selectedBill) {
    //         setSelectedBillAmount(selectedBill.due_amount);
    //         setBilltableId(selectedBill.id)
    //     } else {
    //         setSelectedBillAmount(''); // Reset the amount if the selected bill ID is not found
    //     }
    };
    const handleAmountChange = (e) => {
        setSelectedBillAmount(e.target.value);
    };
    const fetchCurrencyData = async () => {
        const UserCurrency=  localStorage.getItem("1Simpleclick_Currency");
        setCurrencyData(UserCurrency)
          try {
            const apiId = 'onesimpleclick809194384'; // Replace with your actual API ID
            const apiKey = 'omumk49ld03teh0h681go0jmqp'; // Replace with your actual API Key
            const encodedCredentials = btoa(`${apiId}:${apiKey}`);
    
            const response = await axios.get('https://xecdapi.xe.com/v1/convert_from.json/', {
              params: {
                from:selectedCurrency?selectedCurrency:"CAD" , // base currency
            to: UserCurrency, // target currency
                amount: 1,
              },
              headers: {
                'Authorization': `Basic ${encodedCredentials}`
              }
            });
            setSelectedCurrencyValue(response.data.to[0].mid);
            console.log('UserCurrency',UserCurrency)
          } catch (error) {
            setError(error.message);
            console.error(error);
          }
        };
        useEffect(() => {
            if (selectedCurrency) {
              fetchCurrencyData();
              getSymbolFromCurrency('CAD')
            }
          }, [selectedCurrency]);
    console.log(selectedBillAmount,'billlamont==');
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        setViews(false)
    };


    const [showrecord, setShowRecord] = useState(false);
    const handleShowRecord  = () => setShowRecord(true);
    const handleCloseRecord = () => {
        setShowRecord(false);
    };


    const [supply, setSupply] = useState(false)
    const toggleCompany = (e) => {
        e.preventDefault();
        setSupply(false)
    }
    const toggleIndividual = (e) => {
        e.preventDefault();
        setSupply(true)
    }


const [view, setView] = useState(false)
  const toggleHandler = (e) => {
    e.preventDefault();
    setView(true)
  }
  const toggleHandler1 = (e) => {
    e.preventDefault();
    setView(false)
  }

  const [views, setViews] = useState(false)
  const toggleHandlerb = (e) => {
    e.preventDefault();
    setViews(true)
  }


  // custom-select-drupdown
const [isOpen, setIsOpen] = useState(false);
const [selectedOption, setSelectedOption] = useState('More');

const toggleDropdown = () => {
  setIsOpen(!isOpen);
};

const handleOptionClick = (option) => {
  setSelectedOption(option);
  setIsOpen(false);
};

const [shows, setShows] = useState(false);
const handleShowinv = () => {
    setShows(true);
    setShowsvat(false);
}
const handleCloseinv = () => {
    setShows(false);
    setShowsvat(true);
}

const [showsvat, setShowsvat] = useState(false);
const handleClosevat = () => setShowsvat(false);
async function getBill() {
    getBillPaymentId("").then(function (result) {
        const response = result?.data;
   ///console.log(response, "rrrr")
   setBillId(response?.data);
    });
}
async function getAccount() {
    getAccounttId("").then(function (result) {
        const response = result?.data;
   console.log(response, "rrrr")
   setAccount(response?.data?.accounts);
    });
}
useEffect(() => {
    getBill();
    getAccount();
}, []);
const onStatus=(e)=>{
    console.log(e.target.value,"babababababa")
    setMethod(e.target.value)
   }
const onDone = () => {
    if(billtableId===''||null)
    {toast.error('Please select bill Id')}
   else if(method===''||null)
    {toast.error('Please select payment method')}
    else if(selectedBillAmount===''||null)
    {toast.error('Please add payment amount')}
    else if(formateDate=== null)
    {toast.error('Please add payment date')}
    else if (parseFloat(selectedBillAmount) < parseFloat(selectedBillAmount)) {
        toast.error('Payment amount cannot be greater than the bill amount');
    }
    else if(selectedaccountId===''||null)
        {toast.error('Please add paymentaccount')}
    else
    {
        console.log(billtableId,method,selectedBillAmount,formateDate)
        setIsSpinner(true)
        const currencydata= (selectedCurrencyValue*selectedBillAmount).toFixed(2)
        getAddBillPayment(billtableId,method,selectedBillAmount,formateDate,selectedaccountId,currencydata,
      (r) => {
        console.log(r, "response")
        // if (r.success === true) {
        //   toast.success(r.message)
        //   navigate('/billpayment')
        // } 
        if (r.success === true) {
            setIsSpinner(false)
            Swal.fire({
             title: r.message,
           //  text: 'The product has been added successfully!',
             icon: 'success',
             confirmButtonText: 'OK',
             customClass: {
                 confirmButton: 'my-confirm-button'
             }
         }).then(() => {
            navigate('/billpayment')
            setIsSpinner(false)
         })
       }
        else {
          console.log("nononononono")
          toast.error(r.message)
          setIsSpinner(false)
        }
      })}
  }
    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>                
                    <div className='productServList'>
                        <div className='dbTopsec pb-4 pt-2'>
                            <Headtxt link="/billpayment" title="Add Bill Payment" />
                          
                        </div>

                       
                        <div className='commonWhite addCustomerForm p-3 mb-3'>
                            <Form>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="6" className='mb-3'>
                                        <Form.Select aria-label="Default select example" onChange={handleBillIdChange}>
                                            <option selected hidden>Bill Id</option>
                                            {billId.map((item,i)=> 
                                            <option key={i} value={item.bill_id}>{item.bill_id} </option>
                                            ) }
                                            

                                           
                                        </Form.Select>
                                    </Col>
                                    <Col md="6" className='mb-3'>
                                    <Form.Select aria-label="Default select example" onChange={onStatus}>
                                            <option selected hidden>Choose Mode of payment</option>
                                            <option value="Credit Cards">Credit Cards</option>
                                            <option value="Debit Cards">Debit Cards</option>
                                            <option value="NetBanking">NetBanking</option>
                                            <option value="Mobile Payments">Mobile Payments</option>
                                            <option value="Cash">Cash</option>
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="3" className='mb-3'>
                                        <Form.Control type="text" placeholder="Amount to be paid" value={selectedBillAmount} onChange={handleAmountChange}/>
                                    </Col>
                                    <Col md="4" className='datepickr mb-3'>
                                        <DatePicker
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            dateFormat="dd/MM/yyyy" // You can customize the date format
                                            isClearable
                                            placeholderText="Payment date"
                                        />
                                    </Col>
                                    <Col md="4" className='mb-3' style={{marginLeft:'20px'}}>
                                        <Form.Select aria-label="Default select example" onChange={(e)=>{Account.length<=0? navigate(`/accountchart`):handleAccountChange(e)}}>
                                            <option selected hidden>Payment Account</option>
                                            {Account.length<=0 ?
                                            <option onChange={()=>console.log('oooo')}><i class="fa-solid fa-plus"></i>+ Add an Account</option>
                                           : Account.map((item,i)=> 
                                            <option key={i} value={item.account_id}>{item.account_name} </option>
                                            ) }
                                       

                                           
                                        </Form.Select>
                                    </Col>
                                    <Col md="2" >
                            <p >Exchange Rate</p>
                            </Col>
                            <Col md="2" >
                                  <input id="c474_exchange_rate" style={{marginBottom:'15px',}} name="exchange_rate" readOnly class="input-medium" type="text" tabindex="7" 
                                  value= {`${getSymbolFromCurrency(selectedCurrency)}  ${selectedCurrencyValue}`}>
                                    </input>
                                  
                                    </Col>
                                    <Col md="2" >
                            <p style={{marginLeft:'50px'}}>Amount</p>
                            </Col>
                            <Col md="2" >
                                  <input id="c474_exchange_rate" style={{marginBottom:'15px'}} name="exchange_rate" readOnly class="input-medium" type="text" tabindex="7" 
                                  value= {`${getSymbolFromCurrency(currencyData)}  ${selectedCurrencyValue*selectedBillAmount}`}>
                                    </input>
                                   
                                    </Col>
                                   
                                    <p>{getSymbolFromCurrency(selectedCurrency)} 1 {selectedCurrency} = {getSymbolFromCurrency(currencyData)}  {selectedCurrencyValue}  {currencyData}</p>
                                </Form.Group>

                              

                                <button type='button' className='commonButton expBtn' onClick={onDone}>Done</button>&nbsp;&nbsp;
                                <button className='commonButton addBtn' onClick={()=>navigate('/billpayment')}>Cancel</button>



                            </Form>

                        </div>
                    </div>
                </div>
            </Sidebar>




        </div>
    )
}

export default Billpaymentadd