import { React, useState } from 'react'
import Dbheader from '../../layout/Dbheader'
import Sidebar from '../../layout/Sidebar'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Headtxt from '../Headtxt'
import Nav from 'react-bootstrap/Nav';
import { Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import {getAddProductService} from "../../../../Service/ProductService/ProductService";
import { exitingProduct, getAddProductService,getImportProductService } from "../../../../Service/ProductService/ProductService";
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import { CSVLink } from "react-csv";
import sampleProductServiceCsv from './SampleProductServiceCsv'
import sampleServiceCsv from './SampleServiceCsv'
import Swal from 'sweetalert2';
import instance from '../../../../WebApi/WebApi'
const Addproductservice2 = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    const [type, setType] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [unit, setUnit] = useState("");
    const [salePrice, setSalePrice] = useState("");
    const [purchasePrice, setPurchasePrice] = useState("");
    const [salesTax, setSalesTax] = useState("");
    const [purchaseTax, setPurchaseTax] = useState("");
    const [orderQty, setOrderQty] = useState("");
    const [inventory, setInventory] = useState("");
    const [jsondata, setJsonData] = useState([]);
    const [supplierId, setSupplierId] = useState("");
    const [code,setCode]=useState()
    const [selection, setSelection] = useState('Products');
    const [showProductexisting, setShowProductExisting] = useState(false);
    const handleCloseProductDelete = () => setShowProductExisting(false);
    const onYesProduct = () => {
       exitingProduct (type,orderQty,code, (res) => {
            console.log(res, "response")
            if (res.success === true) {
                toast.success(res.message)
                setShowProductExisting(false);
                navigate("/productservicelisting2");
            } else {
                toast.error(res.message)
               /// setShowProductExisting(false);
            }
        })
    }
    const handleDrop = (event) => {
        event.preventDefault();

        const droppedFile = event.dataTransfer.files[0];
        handleFile(droppedFile);
    };
    const handleSelectionChange = (e) => {
        setSelection(e.target.value);
    };
    const handleFile = (selectedFile) => {
        console.log(selectedFile, "selectedFile")
        if (selectedFile) {
            // Check if the selected file is a CSV file
            if (selectedFile.type === 'text/csv' || selectedFile.name.endsWith('.csv')) {
                // File is valid, set it in the state

                setFile(selectedFile);
                Papa.parse(selectedFile, {
                    header: true, // Assuming the first row contains headers
                    complete: (result) => {
                        console.log(result.data, "dadadadada")
                        setJsonData(result.data);
                    },
                    error: (error) => {
                        console.error('CSV parsing error:', error.message);
                    },
                });
            } else {
                // File is not valid, show an error or do something else
                toast.error('Please select a valid CSV file');
                // Optionally, you can clear the file input

            }
        }



        // You can perform additional actions with the file, such as uploading it to a server.
        // For simplicity, we're just logging the file information here.
        console.log('File selected:', selectedFile);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };


    // For toggle
    // const [isToggled, setToggled] = useState(false);

    const handleToggleOn = () => {
        // setToggled(true);
        setInventory("No")
    };
    const handleToggleOff = () => {
        // setToggled(false);
        setInventory("Yes")
    };


    const onType = (e) => {
        setType(e.target.value)
    }

    const onName = (e) => {
        setName(e.target.value)
    }
    const onID = (e) => {
        setCode(e.target.value)
      }
    const onDescription = (e) => {
        setDescription(e.target.value)
    }

    const onUnit = (e) => {
        setUnit(e.target.value)
    }

    const onSalesPrice = (e) => {
        setSalePrice(e.target.value)
    }

    const onPurchasePrice = (e) => {
        setPurchasePrice(e.target.value)
    }
    const onSalesTax = (e) => {
        setSalesTax(e.target.value)
    }
    const onPurchaseTax = (e) => {
        setPurchaseTax(e.target.value)
    }

    const onOrderQty = (e) => {
        setOrderQty(e.target.value)
    }




    const onSave = async () => {
        if (type === "") {
            toast.error("Please select a type");
        } else if (name === "") {
            toast.error("Please type a name");
        } else if (unit === "") {
            toast.error("Please select a unit");
        } else if (type === "Product" && inventory === "") {
            toast.error("Please select track inventory");
        } else {
            const data = {
                "type": type,
                "name": name,
                "description": description,
                "unit": unit,
                "sales_price": salePrice,
                "tax": purchaseTax,
                "purchase_price": purchasePrice,
                "quantity": orderQty,
                "track_inventory": inventory,
                //"supplierId": supplierId,
                "code": code,
                 "sales_tax": salesTax,
              }
              const response = await instance.post(`/product-service-details-store`, data)
              const r =response?.data
                    if (r.success === true) {
                        Swal.fire({
                            title: r.message,
                          //  text: 'The product has been added successfully!',
                            icon: 'success',
                            confirmButtonText: 'OK',
                            customClass: {
                                confirmButton: 'my-confirm-button'
                            }
                        }).then(() => {
                            navigate("/productservicelisting2");
                        });
                    }
                   else if (r.message ==="Product details already exists!") {
                       setShowProductExisting(true)
                    }
                    else {
                        console.log("nononononono");
                        toast.error(r.message);
                    }
                }
               
    };

    const onExcelImport = () => {
        getImportProductService(selection,jsondata,
            (r) => {
                console.log(r, "response")
                if (r.success === true) {
                    Swal.fire({
                        title: r.message,
                      //  text: 'The product has been added successfully!',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'my-confirm-button'
                        }
                    }).then(() => {
                        navigate("/productservicelisting2");
                    });
                } else {
                    console.log("nononononono")
                    toast.error(r.message)

                }
            })


    }

    return (
        <div className="customerListing pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                    <div className='custBtm addSupply'>
                        <div className='dbTopsec pb-4'>
                            <Headtxt link="/productservicelisting2" title="Add Product or Service" />
                            <button className='commonButton expBtn' onClick={handleShow}><i class="fa-light fa-download"></i> Import Excel</button>
                        </div>
                        <div className='commonWhite addCustomerForm p-3 mb-3'>
                            <Form>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="6" className='mb-3'>
                                        <Form.Select aria-label="Default select example" onChange={onType}>
                                            <option selected hidden>Select Type</option>
                                            <option value="Product">Product</option>
                                            <option value="Service">Service</option>
                                        </Form.Select>
                                    </Col>
                                    <Col md="3" className='mb-3'>
                                        <Form.Control type="text" placeholder="Name" onChange={onName} />
                                    </Col>
                                    <Col md="3" className='mb-3'>
                  <Form.Control type="text" placeholder="Code" onChange={onID} />
                </Col>
                                </Form.Group>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="12" className='mb-3'>
                                        <Form.Control as="textarea" placeholder="Description (Optional)" rows={3} onChange={onDescription} />
                                    </Col>
                                </Form.Group>

                               

                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                {type === "Service" ?
<Col md="4" className='mb-3'>
<Form.Select aria-label="Default select example" onChange={onUnit}>
    <option selected hidden>Select Unit</option>
    <option value="Hour">Hour</option>
    <option value="Day">Day</option>
    <option value="Contract">Contract</option>
    <option value="Percentage of completion">Percentage of completion</option>
</Form.Select>
</Col>
:

                             <Col md="4" className='mb-3'>
                                        <Form.Select aria-label="Default select example" onChange={onUnit}>
                                            <option selected hidden>Select Unit</option>
                                            <option value="Piece">Piece</option>
                                            <option value="Centiliter">Centiliter</option>
                                            <option value="Ltr">Ltr</option>
                                            <option value="Kg">Kg</option>
                                            <option value="Gms">Gms</option>
                                            <option value="Cubic meter">Cubic meter</option>
                                            <option value="Contract">Contract</option>
                                            <option value="Percentage of completion">Percentage of completion</option>
                                        </Form.Select>
                                    </Col>
                                    
}
                                    <Col md="4" className='mb-3'>
                                        <Form.Control type="number" placeholder={type === "Service" ?"Price(Optional)":"Sales Price(Optional)"} onChange={onSalesPrice} />
                                    </Col>
                                    {/* <Col md="4" className='mb-3'>
                                        <Form.Control type="text" placeholder="Purchase tax" onChange={onPurchaseTax}/>
                                    </Col> */}
                                    <Col md="4" className='mb-3'>
                                        <Form.Control type="number" placeholder="Purchase Tax(Optional)" onChange={onPurchaseTax} />
                                    </Col>

                                </Form.Group>

                                {type === "Service" ? "" :
                                    <Form.Group className="row" controlId="exampleForm.ControlInput1">

                                        <Col md="4" className='mb-3'>
                                            <Form.Control type="number" placeholder="Purchase price(Optional)" onChange={onPurchasePrice} />
                                        </Col>

                                        <Col md="4" className='mb-3'>
                                            <Form.Control type="number" placeholder="Quantity(Optional)" onChange={onOrderQty} />

                                        </Col>
                                        <Col md="4" className='mb-3'>
                                        <Form.Control type="text" placeholder="Sales tax(Optional)" onChange={onSalesTax}/>
                                    </Col>
                                    </Form.Group>}
                                {type === "Service" ? "" :
                                    <div className='radioline mt-3 mb-3'><span>Track Inventory</span>
                                        <div>
                                            <input type="radio" id="test1" name="radio-group" onClick={handleToggleOff} />
                                            <label for="test1">Yes</label>
                                        </div>
                                        <div>
                                            <input type="radio" id="test2" name="radio-group" onClick={handleToggleOn} />
                                            <label for="test2">No</label>
                                        </div>

                                    </div>}



                                <button type='button' className='commonButton expBtn' onClick={onSave}>Save</button>&nbsp;&nbsp;
                                {/* <button className='commonButton addBtn'>Cancel</button> */}



                            </Form>

                        </div>
                    </div>


                </div>
            </Sidebar>




            <Modal show={show} onHide={handleClose} centered className='customerModal'>
            <Modal.Header closeButton>
                <Modal.Title style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <div className='impDrop'>
                        <label>Import</label>
                        <Form.Control
                        as="select"
                        value={selection}
                        onChange={handleSelectionChange}
                        style={{ width: '50%' }}
                    >
                        <option value="Products">Products</option>
                        <option value="Service">Service</option>
                    </Form.Control>
                    </div>
                </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <div className='modalContent'>
                        <div
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            className='drgdrop'
                        >
                            <i class="fa-light fa-cloud-arrow-up"></i>
                            <div style={{ display: "flex" }}>
                                <p>Drag &amp; drop files or </p>
                                <input type="file" onChange={(e) => handleFile(e.target.files[0])} style={{ display: 'none' }} />
                                <button onClick={() => document.querySelector('input[type="file"]').click()}>Browse</button>
                            </div>

                            {file && <p>Selected File: {file.name}</p>}
                        </div>
                    </div>
                    <div>
                        <p><button className='commonButton modalsubmit' onClick={onExcelImport}>Submit</button></p>

                        <CSVLink filename="sample_product.csv" data={sampleProductServiceCsv}>
                            <p><button className='commonButton downloadformat'>Download Product format </button></p>
                        </CSVLink>
                        <CSVLink filename="sample_service.csv" data={sampleServiceCsv}>
                            <p><button className='commonButton downloadformat'>Download Service format </button></p>
                        </CSVLink>
                        <span className='commonButton downloadformat' style={{ fontSize: "13px" }}>(Please follow Demo data format mentioned in csv file)</span>

                    </div>
                </Modal.Body>
            </Modal>


            <Modal size="sm" show={showProductexisting} onHide={handleCloseProductDelete} className='delModal'>
                                <Modal.Header closeButton>
                                    <Modal.Title><h6>do you want to add to the current quantity of the product that exist ?</h6></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <button variant="primary" className='commonButton expBtn' onClick={onYesProduct}  >
                                        Yes
                                    </button>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    <button variant="primary" className='commonButton expBtn' onClick={handleCloseProductDelete}>
                                        No
                                    </button>
                                </Modal.Body>
                            </Modal>
        </div>
    )
}

export default Addproductservice2