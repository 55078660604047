import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const Linechart = () => {

    const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    const chartData = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      values1: [65, 59, 80, 81, 56, 55, 40, 20, 30, 40, 45, 40],
      values2: [45, 50, 60, 70, 65, 70, 75,80, 90, 95, 100, 90]
    };

    if (chartRef && chartRef.current && chartData) {
      // Destroy any existing chart instance
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext('2d');
      chartInstance.current = new Chart(ctx, {
        type: 'line',
        data: {
          labels: chartData.labels,
          datasets: [{
            label: 'Last 12 months',
            data: chartData.values1,
            borderColor: '#FF5B5B',
            tension: 0.1
          }, {
            label: 'Last 24 months',
            data: chartData.values2,
            borderColor: '#2D9CDB',
            tension: 0.1
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });
    }

    // Cleanup function to destroy the chart instance
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  return (
    <div className='lineChartbg'><canvas ref={chartRef} /></div>
  )
}

export default Linechart