import React from 'react'
import { Link } from 'react-router-dom';

const Headtxt = (props) => {
  const {title, link, breadcm, description} = props;
  return (
    <h5>
      <Link className='backpage' to={link}><i class="fa-solid fa-angle-left"></i></Link>
        <span className='brdcm'>{breadcm}</span>
        {title}
    </h5>
  )
}

export default Headtxt