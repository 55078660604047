import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import Usr from '../../images/usr.png';
import Lock from '../../images/lock.png';
import { getLogin } from "../../../Service/Login/LoginService";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactSession } from 'react-client-session';
const Login = (props) => {
  const { logo } = props;
  const navigate = useNavigate();
  const [showpassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: yup.object({
      email: yup.string().email().required("Email is a required field"),
      password: yup.string().min(6, "Password must have at least 6 characters").required("Password is a required field"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const res = await getLogin(values.email, values.password,(res) => {
        console.log(res, "Login Response");

        if (res.success === true) {
          const userdata = {
            fname: res.user.fname,
            lname: res.user.lname,
            id: res.user.id,
            email: res.user.email,
            phone: res.user.phone,
            country: res.user.country,
            company: res.user.company,
            logo: res.user.logo
          };

          localStorage.setItem("1Simpleclick_AuthToken", res.auth_token);
          localStorage.setItem("1Simpleclick_User", JSON.stringify(userdata));
          localStorage.setItem("1Simpleclick_Currency", res.user.currency);

          ReactSession.setStoreType("localStorage");
          ReactSession.set("username", res.auth_token);

          toast.success(res.message);
          if (res.companyInfo) {
            if (res.taxInfo) {
              if (res.subscriptionInfo) {
                navigate("/dashboard");
                // window.location.reload();
               
                
              } else {
                navigate("/subscriptionplan");
              }
            } else {
              navigate("/tax");
            }
          } else {
            navigate("/profilecreation");
          }

          formik.resetForm();
        } else {
          toast.error(res.message);
        }
      } )}catch (error) {
        console.error("Login error:", error);
        toast.error("An error occurred during login.");
      }
    }
  
  });



  console.error(formik.errors);

  // const renderNameError = formik.touched.name && formik.errors.name && (<span style={{color: "red"}}>{formik.errors.name}</span>);

  const renderEmailError = formik.touched.email && formik.errors.email && (<span className='errorTxt'>{formik.errors.email}</span>);

  const renderPasswordError = formik.touched.password && formik.errors.password && (<span className='errorTxt'>{formik.errors.password}</span>);



  // FOR-GET-LOGIN-RESPONSE
  const url = "https://reqres.in/api/users";

  const login = async (data) => {
    const response = await axios.post(url, data)
    // const getresponse =await axios.get(url)
    // console.log(getresponse, "getResponse")
    if ([200, 201, 202, 203, 204].includes(response.status)) {
      toast.success("Successfully");
    }
    else {
      toast.success("Error");
      console.log(response, "somnath")
    }

  }



  return (
    <div className='authBg'>
      <div className='authLogo'><Link to="/"><img src={logo} /></Link></div>
      <div className='signForm'>
        <div className='signFormbase'>
          <div className='signHdn'>Sign In</div>
          <div className='signMainform'>
            <form onSubmit={formik.handleSubmit}>
              {/* <div>
                  <label htmlFor="name">Name: </label>
                  <input type="text" id="name" name="name" onChange={formik.handleChange} value={formik.values.name} />
                  {renderNameError}
              </div> */}

              <div className='mb-4'>
                <label><img src={Usr} />&nbsp; Email</label>
                <input type="email" id="email" name="email" className='form-control' placeholder='Enter your email' onChange={formik.handleChange} value={formik.values.email} />
                {renderEmailError}
              </div>

              <div className='mb-4'>
                <label><img src={Lock} />&nbsp; Password</label>
                <div className="showHide">
                  <input className="form-control" name='password' type={showpassword ? "text" : "password"} id="password" placeholder="Password" onChange={formik.handleChange} value={formik.values.password} />
                  <div className="input-group-addon eyeAbs loginEye" onClick={() => { setShowPassword(!showpassword) }}>
                  {showpassword? <span><i className="fa fa-eye" aria-hidden="true"></i></span>: <span><i className="fa fa-eye-slash" aria-hidden="true"></i></span> }
                  </div>
                  {renderPasswordError}
                </div>
              </div>



              <div className='forgotPass'><Link to='/forgotpassword'>Forgot Password?</Link></div>

              <button type="submit" className='commonButton buttonSubmit'>Sign In</button>
            </form>
          </div>
        </div>
        <div className='accountTxt'>Don't have an account? &nbsp; <Link to="/signup">Sign up Now</Link></div>
      </div>
    </div>
  )
}

export default Login