import React from 'react';
import bsbnr2 from '../../images/bsbnr2.jpg';
import bs2 from '../../images/bs2.jpg';
import Navigation from '../layout/Navigation';
import cfobanner from '../../images/cfobnr.png';
import cfo1 from '../../images/cfo1.jpg';
import cfo2 from '../../images/cfo2.jpg';
import Footer2 from '../layout/Footer2';

const Managebusinessdetails1 = (props) => {


  return (
    <>
    <div className='manageBusinessBlock'>
      <header>
        <Navigation />
      </header>

      <div className='authLogo cfoLogo'>&nbsp;</div>
      <section className='bannerBg cfoBnr pt-0'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <h1>Strategic Business Planning:</h1>
                    <p>Elevate your company's trajectory with our comprehensive business strategy and risk management services.
We specialize in setting the stage for your success by offering a 360-degree approach that goes beyond conventional thinking.</p>
                    <button className='commonButton getStartbtn'><i class="fa-regular fa-paper-plane"></i> Get In Touch</button>
                </div>
                <div className='col-lg-6'>
                    <img src={cfobanner} alt='' />
                </div>
            </div>
        </div>
    </section>
    <section className='cfoBtm'>
        <div className='container'>
          <div className='row mb-5'>
            <div className='col-lg-6 mb-3'>
              <img src={cfo1} alt='' />
            </div>
            <div className='col-lg-6'>
              <h5>Here's how we can empower your business:</h5>
              <p>Leverage big-picture thinking grounded in robust financial modeling.
Gain a holistic perspective on your business landscape to foster growth and stability.</p>

              <h5>Risk Management Expertise:</h5>
              <p>Identify and assess potential business risks, providing a customized plan to avoid, transfer, or reduce them.</p>
              <p>Anticipate both visible and unforeseen risks, ensuring you are well-prepared for any challenges.</p>

              <h5>Insightful Analysis:</h5>
              <p>Uncover fresh opportunities by bringing a new perspective to your business.
Our team provides valuable insights that may have been previously overlooked.</p>
              
            </div>
          </div>


          <div className='row'>
            <div className='col-lg-6 order-lg-2 mb-3'>
              <img src={cfo2} alt='' />
            </div>
            <div className='col-lg-6 order-lg-1'>
              <h5>Decision-Making Support:</h5>
              <p>Enhance decision-making with a focus on clarity and transparency.
Utilize smart forecasting to pre-plan resource allocation and mitigate potential damage.</p>
              <h5>Future-Proofing Your Business:</h5>
              <p>Devote time to understanding and researching current and future risks relevant to your industry.</p>
              <p>We guide you in understanding how these risks may impact your organization's ability to achieve goals and desired outcomes.</p>
                                         
            </div>
          </div>




        </div>
    </section>

    </div>
    <Footer2/>
    </>
  )
}

export default Managebusinessdetails1

