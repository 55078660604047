import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navigation from '../layout/Navigation';
import Login from '../authentication/Login';
import Signup from '../authentication/Signup';
import Logo from '../../images/logo.png';
import Home from '../home/Index'
import Nopage from '../nopage/Nopage';
import Forgotpassword from '../authentication/Forgotpassword';
import Resetpassword from '../authentication/Resetpassword';
import Profilecreation from '../profilecreation/Profilecreation';
import Verifyotp from '../authentication/Verifyotp';
import Tax from '../tax/Tax';
import Subscribe from '../subscribe/Subscribe';
import Subscriptionplan from '../subscriptionplan/Subscriptionplan';
import Pricing from '../pricing/Pricing';
import Dashboard from '../dbstructure/Dashboard';
import Accounting from '../dbstructure/accounting/Accounting';
// import Sales from '../dbstructure/sales/Sales';
import Customerlisting from '../dbstructure/sales/Customerlisting';
import Addcustomer from '../dbstructure/addcustomer/Addcustomer';
import Demo from '../dbstructure/Demo';
import Customerdetails from '../dbstructure/customerdetails/Customerdetails';
import EditCustomer from '../dbstructure/addcustomer/EditCustomer';



import Supplierlisting from '../dbstructure/supplier/Supplierlisting';
import Listviewsupplier from '../dbstructure/supplier/Listviewsupplier';
import Gridviewsupplier from '../dbstructure/supplier/Gridviewsupplier';
import Addsuppliers from '../dbstructure/supplier/Addsuppliers';
import Suppliersdetails from '../dbstructure/supplier/Suppliersdetails';
import EditSupplier from '../dbstructure/supplier/EditSupplier';
import Productservicelisting from '../dbstructure/productservice/Productservicelisting';
import Addproductservice from '../dbstructure/productservice/Addproductservice';
import ProductEdit from '../dbstructure/productservice/ProductEdit';
import ServiceEdit from '../dbstructure/productservice/ServiceEdit';
import Productdetails from '../dbstructure/productservice/Productdetails';
import Servicedetails from '../dbstructure/productservice/ServiceDetails';
import Purchaseorderlisting from '../dbstructure/supplier/purchaseorder/Purchaseorderlisting';
import Addpurchaseorder from '../dbstructure/supplier/purchaseorder/Addpurchaseorder';
import Salesorderlisting from '../dbstructure/sales/Salesorderlisting';
import Salesordergeneration from '../dbstructure/sales/Salesordergeneration';
import Salesordergenerationdetails from '../dbstructure/sales/Salesordergenerationdetails';
import Salesreceiptlist from '../dbstructure/sales/Salesreceiptlist';
import Salesreceiptadd from '../dbstructure/sales/Salesreceiptadd';
import Refundlist from '../dbstructure/sales/Refundlist';
import Addrefund from '../dbstructure/sales/Addrefund';
import Refunddetails from '../dbstructure/sales/Refunddetails';
import Invoice from '../dbstructure/sales/Invoice';
import Newinvoicecreation from '../dbstructure/sales/Newinvoicecreation';
import Quote from '../dbstructure/sales/Quote';
import Managebusinessdetails from '../home/Managebusinessdetails';
import Managebusinessdetails1 from '../home/Managebusinessdetails1';
import Managebusinessdetails2 from '../home/Managebusinessdetails2';
import Quotelisting from '../dbstructure/supplier/Quotelisting';
import Piechart from '../dbstructure/dbcontent/Piechart';
import Addsupplierquote from '../dbstructure/supplier/Addsupplierquote';
import Privacy from '../privacy/Privacy';
import Termscondition from '../termscondition/Termscondition';
import Professionalservice from '../professionalservice/Professionalservice';
import Contactus from '../contactus/Contactus';
import Salesmodule from '../salesmodule/Salesmodule';
import Purchasemodule from '../purchasemodule/Purchasemodule';
import Paymentmodule from '../paymentmodule/Paymentmodule';
import Accountingmodule from '../accountingmodule/Accountingmodule';
import Bill from '../dbstructure/supplier/bill/Bill';
import Billdetails from '../dbstructure/supplier/bill/Billdetails';
import Billadd from '../dbstructure/supplier/bill/Billadd';
import BillPayment from '../dbstructure/supplier/bill/BillPayment';
import Billpaymentadd from '../dbstructure/supplier/bill/Billpaymentadd';
import Quotedetails from '../dbstructure/supplier/Quotedetails';
import Transactions from '../dbstructure/accounting/Transactions';
import Accountchart from '../dbstructure/accounting/Accountchart';
import Balancesheet from '../dbstructure/reports/Balancesheet';
import Profitloss from '../dbstructure/reports/Profitloss';
import Cashflow from '../dbstructure/reports/Cashflow';
import Cfoservice from '../dbstructure/professionalservice/Cfoservice';
import Myprofile from '../dbstructure/Myprofile';
import Mysubscription from '../subscriptionplan/Mysubscription';
import Aboutus from '../aboutus/Aboutus';
import Pricinghome from '../pricing/Pricinghome';
import Featureinvoice from '../features/Featureinvoice';
import Bloglist from '../bloglist/Bloglist';
import Blogdetails from '../bloglist/Blogdetails';
import Changepassword from '../authentication/Changepassword';
import EditPurchaseOrder from '../dbstructure/supplier/purchaseorder/EditPurchaseOrder';
import BillAddSubmit from '../dbstructure/supplier/bill/BillAddSubmit';
import Productservicelisting2 from '../dbstructure/productservice/Productservicelisting2';
import Returnrefund from '../dbstructure/supplier/bill/Returnrefund';
import RefundList from '../dbstructure/supplier/bill/RefundList';
import ViewBillDetails from '../dbstructure/supplier/bill/ViewBillDetails';
import Addproductservice2 from '../dbstructure/productservice/Addproductservice2';
import Addcustomerquote from '../dbstructure/sales/Addcustomerquote';
import QuoteEdit from '../dbstructure/sales/editcustomerquote';
import QuoteSalesordergeneration from '../dbstructure/sales/quotesalesordergeneration';
import SalesOrderEdit from '../dbstructure/sales/editsalesorder';
import InvoiceSalesOrder from '../dbstructure/sales/invoicefromsalesorder';
import InvoiceEdit from '../dbstructure/sales/EditInvoice';
import SalesReturnrefund from '../dbstructure/sales/Refunddetails';
import ViewInvoiceDetails from '../dbstructure/sales/ViewInvoiceDetails';
import Productdetails2 from '../dbstructure/productservice/Productdetails2';
import ViewQuotedetails from '../dbstructure/supplier/Viewquotedetails.jsx';
import ViewCustomerQuote from '../dbstructure/sales/Viewcustomerquote.jsx';
import ViewPurchaseOrder from '../dbstructure/supplier/purchaseorder/Viewpurchaseorder.jsx';
import SalesOrderView from '../dbstructure/sales/Viewsalesorder.jsx';




const Index = ({setIsSpinner}) => {
  return (
    // <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Navigation />}> */}
          <Route path="/" exact element={<Home />} />
          <Route path="/login" element={<Login logo={Logo} />} />
          <Route path="/signup" element={<Signup logo={Logo} />} />
          <Route path="/forgotpassword" element={<Forgotpassword logo={Logo} />} />
          <Route path="/resetpassword" element={<Resetpassword logo={Logo} />} />
          <Route path="/changepassword" element={<Changepassword logo={Logo} />} />
          <Route path="/profilecreation" element={<Profilecreation logo={Logo} />} /> 
          <Route path="/verifyotp" element={<Verifyotp logo={Logo} />} />
          <Route path="/profilecreation" element={<Profilecreation logo={Logo} />} />
          <Route path="/tax" element={<Tax logo={Logo} />} />
          <Route path="/subscribe" element={<Subscribe logo={Logo} />} />
          <Route path="/subscriptionplan" element={<Subscriptionplan logo={Logo} />} />
          <Route path="/pricing" element={<Pricing logo={Logo} />} />
          <Route path="/dashboard" element={<Dashboard logo={Logo} />} />
          <Route path="/accounting" element={<Accounting logo={Logo} />} />
          <Route path="/customerlisting" element={<Customerlisting logo={Logo} />} />
          <Route path="/addcustomer" element={<Addcustomer logo={Logo} setIsSpinner={setIsSpinner}/>} />
          <Route path="/customerdetails" element={<Customerdetails logo={Logo} setIsSpinner={setIsSpinner}/>} />
          <Route path="/editcustomer" element={<EditCustomer logo={Logo} setIsSpinner={setIsSpinner}/>} />
          <Route path="/customereditquote" element={<QuoteEdit logo={Logo} setIsSpinner={setIsSpinner}/>} />
          <Route path="/demo" element={<Demo logo={Logo} />} />
          <Route path="/supplierlisting" element={<Supplierlisting logo={Logo} />} />
          <Route path="/listviewsupplier" element={<Listviewsupplier logo={Logo} />} />
          <Route path="/gridviewsupplier" element={<Gridviewsupplier logo={Logo} />} />
          <Route path="/addsuppliers" element={<Addsuppliers logo={Logo} />} />
          <Route path="/suppliersdetails" element={<Suppliersdetails logo={Logo} />} />
          <Route path="/editsupplier" element={<EditSupplier logo={Logo} />} />
          <Route path="/productservicelisting" element={<Productservicelisting logo={Logo} />} />
          <Route path="/productservicelistings" element={<Productservicelisting2 logo={Logo} />} />
          <Route path="/addproductservice" element={<Addproductservice logo={Logo} />} />
          <Route path="/editproductservice" element={<EditPurchaseOrder logo={Logo} />} />
          <Route path="/editproduct" element={<ProductEdit logo={Logo} />} />
          <Route path="/editservice" element={<ServiceEdit logo={Logo} />} />
          <Route path="/productdetails" element={<Productdetails logo={Logo} />} />
          <Route path="/servicedetails" element={<Servicedetails logo={Logo} />} />
          <Route path="/purchaseorderlisting" element={<Purchaseorderlisting logo={Logo}  setIsSpinner={setIsSpinner}/>} />
          <Route path="/addpurchaseorder" element={<Addpurchaseorder logo={Logo} />} />
          <Route path="/salesorderlisting" element={<Salesorderlisting logo={Logo} setIsSpinner={setIsSpinner}/>} />
          <Route path="/viewsalesorder" element={<SalesOrderView logo={Logo} setIsSpinner={setIsSpinner}/>} />
          <Route path="/salesordergeneration" element={<Salesordergeneration logo={Logo}setIsSpinner={setIsSpinner}/>} />
          <Route path="/quotesalesordergeneration" element={<QuoteSalesordergeneration logo={Logo} setIsSpinner={setIsSpinner}/>}/>
          <Route path="/salesordergenerationdetails" element={<Salesordergenerationdetails logo={Logo} />} />
          <Route path="/salesorderedit" element={<SalesOrderEdit logo={Logo} setIsSpinner={setIsSpinner}/>}/>
          <Route path="/salesreceiptlist" element={<Salesreceiptlist logo={Logo} />} />
          <Route path="/salesreceiptadd" element={<Salesreceiptadd logo={Logo} setIsSpinner={setIsSpinner}/>} />
          <Route path="/addcustomerquote" element={<Addcustomerquote logo={Logo} setIsSpinner={setIsSpinner}/>} />
          <Route path="/refundlist" element={<Refundlist logo={Logo} />} />
          <Route path="/addrefund" element={<Addrefund logo={Logo} />} />
          <Route path="/refunddetails" element={<SalesReturnrefund logo={Logo} setIsSpinner={setIsSpinner}/>} />
          <Route path="/invoice" element={<Invoice logo={Logo} setIsSpinner={setIsSpinner}/>} />
          <Route path="/editinvoice" element={<InvoiceEdit logo={Logo} setIsSpinner={setIsSpinner}/>} />
          <Route path="/newinvoicecreation" element={<Newinvoicecreation logo={Logo} setIsSpinner={setIsSpinner}/>} />
          <Route path="/newinvoicecreationfronsalesorder" element={<InvoiceSalesOrder logo={Logo} setIsSpinner={setIsSpinner}/>} />
          <Route path="/viewinvoicedetails" element={<ViewInvoiceDetails logo={Logo} />} />
          <Route path="/viewCustomerquotedetails" element={<ViewCustomerQuote logo={Logo} />} />
          <Route path="/quote" element={<Quote logo={Logo} setIsSpinner={setIsSpinner}/>} />
          <Route path="/managebusinessdetails" element={<Managebusinessdetails logo={Logo} />} />
          <Route path="/managebusinessdetails1" element={<Managebusinessdetails1 logo={Logo} />} />
          <Route path="/managebusinessdetails2" element={<Managebusinessdetails2 logo={Logo} />} />
          <Route path="/quotelisting" element={<Quotelisting logo={Logo} setIsSpinner={setIsSpinner} />} />
          <Route path="/piechart" element={<Piechart logo={Logo} />} />
          <Route path="/addsupplierquote" element={<Addsupplierquote logo={Logo} />} />
          <Route path="/viewquotedetails" element={<ViewQuotedetails logo={Logo} />} />
          <Route path="/privacy" element={<Privacy logo={Logo} />} />
          <Route path="/termscondition" element={<Termscondition logo={Logo} />} />
          <Route path="/salesmodule" element={<Salesmodule logo={Logo} />} />
          <Route path="/professionalservice" element={<Professionalservice logo={Logo} />} />
          <Route path="/contactus" element={<Contactus logo={Logo} />} />
          <Route path="/purchasemodule" element={<Purchasemodule logo={Logo} />} />
          <Route path="/viewpurchaseorder" element={<ViewPurchaseOrder logo={Logo} setIsSpinner={setIsSpinner}/>} />
          <Route path="/paymentmodule" element={<Paymentmodule logo={Logo} setIsSpinner={setIsSpinner}/>} />
          <Route path="/accountingmodule" element={<Accountingmodule logo={Logo} />} />
          <Route path="/bill" element={<Bill logo={Logo} setIsSpinner={setIsSpinner} />} />
          <Route path="/billdetails" element={<Billdetails logo={Logo} setIsSpinner={setIsSpinner} />} />
          <Route path="/billadd" element={<Billadd logo={Logo}setIsSpinner={setIsSpinner} />} />
          <Route path="/billaddsubmit" element={<BillAddSubmit logo={Logo} setIsSpinner={setIsSpinner}/>} />
          <Route path="/billPayment" element={<BillPayment logo={Logo} />} />
          <Route path="/billpaymentadd" element={<Billpaymentadd logo={Logo} setIsSpinner={setIsSpinner}/>} />
          <Route path="/quotedetails" element={<Quotedetails logo={Logo} />} />
          <Route path="/transactions" element={<Transactions logo={Logo} />} />
          <Route path="/accountchart" element={<Accountchart logo={Logo} />} />
          <Route path="/balancesheet" element={<Balancesheet logo={Logo} />} />
          <Route path="/profitloss" element={<Profitloss logo={Logo} />} />
          <Route path="/cashflow" element={<Cashflow logo={Logo} />} />
          <Route path="/cfoservice" element={<Cfoservice logo={Logo} />} />
          <Route path="/myprofile" element={<Myprofile logo={Logo} />} />
          <Route path="/mysubscription" element={<Mysubscription logo={Logo} />} />
          <Route path="/aboutus" element={<Aboutus logo={Logo} />} />
          <Route path="/pricinghome" element={<Pricinghome logo={Logo} />} />
          <Route path="/featureinvoice" element={<Featureinvoice logo={Logo} />} />
          <Route path="/bloglist" element={<Bloglist logo={Logo} />} />
          <Route path="/blogdetails" element={<Blogdetails logo={Logo} />} />
          <Route path="/returnrefunds" element={<Returnrefund logo={Logo} setIsSpinner={setIsSpinner} />} />
          <Route path="/purchaserefundlist" element={<RefundList logo={Logo} />} />
          <Route path="/purchaserefundlist" element={<RefundList logo={Logo} />} />
          <Route path="/viewbilldetails" element={<ViewBillDetails logo={Logo} />} />
          <Route path="/addproductservice2" element={<Addproductservice2 logo={Logo} />} />
          <Route path="/productservicelisting2" element={<Productservicelisting2 logo={Logo} />} />
          <Route path="/productdetails2" element={<Productdetails2 logo={Logo} />} />
          <Route path="*" element={<Nopage />} />
          
        {/* </Route> */}
      </Routes>
    // </BrowserRouter>
  )
}

export default Index