import { React, useState, useEffect } from 'react'
import Sidebar from '../../../layout/Sidebar'
import Dbheader from '../../../layout/Dbheader'
import Form from 'react-bootstrap/Form';
import Headtxt from '../../Headtxt';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { PhoneInput } from 'react-international-phone';
import { City, Country, State } from "country-state-city";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useLocation, } from 'react-router-dom';
import { getProductServiceList, getProductServiceListBYSupplierId } from "../../../../../Service/Customer/CustomerService";
import { getSearchSupplier, getAddSuppliers, getImportCustomer, getSupplierList } from "../../../../../Service/Suppliers/SuppliersService";
import { getAddbill, getBillData, getEditbill, getSendBill } from '../../../../../Service/Quotation/QuotationService';
import { getAddProductService, getImportProductService, getInventoryAccounts } from "../../../../../Service/ProductService/ProductService";
import { getEditSupplier, getSuppliereListById } from "../../../../../Service/Suppliers/SuppliersService";
import currencies from "../../../../components/Common.currency.json";
import axios from 'axios';
import getSymbolFromCurrency from 'currency-symbol-map'
import Swal from 'sweetalert2';
import instance from '../../../../../WebApi/WebApi';
const Billdetails = ({setIsSpinner}) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  }
  const handleClose = () => setShow(false);
  const [suppliersList, setAllSuppliersList] = useState([]);
  const [companyFinal, setCompanyFinal] = useState("");
  const [file, setFile] = useState([]);
  const [productServiceList, setAllProductServiceList] = useState([]);
  const [productServiceList2, setAllProductServiceList2] = useState([]);
  const [allProductServiceData, setAllProductServiceData] = useState([]);
  const [supplier1, setSupplier1] = useState(false);
  const supplierShow1 = () => setSupplier1(true);
  const supplier1Close = () => setSupplier1(false);
  const [supplier2, setSupplier2] = useState(false);
  const supplierShow2 = () => setSupplier2(true);
  const handleClosed = () => setSupplier1(false);
  const [paymentTerms, setPaymentTerms] = useState("");
  const [address, setQuoteAddress] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [productService2, setProductService2] = useState(false);
  const productServiceShow2 = () => setProductService2(true);
  const [finalStartDate, setFinalStartDate] = useState("");
  const [finalEndDate, setFinalEndDate] = useState("");
  const [deleteId, setDeleteId] = useState([]);
  const [discountQuantity, setDiscountQuantity] = useState(0);
  const [note, setNote] = useState("");
  const [showPdf, setShowPdf] = useState(false);
  const [dataPdf, setDataPdf] = useState("");
  const [views, setViews] = useState(false)
  const [addressQuote, setAddressQuote] = useState("")
  const [checks, setChecks] = useState("false");
  const [inventory, setInventory] = useState("");
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [unit, setUnit] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [purchaseTax, setPurchaseTax] = useState("");
  const [orderQty, setOrderQty] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [webUrl, setWebUrl] = useState('');
  const [vatNumber, setVatNumber] = useState('');
  const [billAddress, setBillAddress] = useState('');
  const [country, setCountry] = useState("");
  const [DeliveryCountry, setDeliveryCountry] = useState("");
  const [state, setState] = useState("");
  const [deliveryState, setDeliveryState] = useState("");
  const [city, setCity] = useState("");
  const [deliveryCity, setDeliveryCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [deliveryZipCode, setDeliveryZipCode] = useState();
  const [cityData, setCityData] = useState([]);
  const [deliveryCityData, setDeliveryCityData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [deliveryStateData, setDeliveryStateData] = useState([]);
  const supplier2Close = () => setSupplier2(false);
  const [supplier, setSupplier] = useState("");
  const [addres, setAddress] = useState("");
  const [productList, setProductList] = useState([]);
  const [subtotal, setSubTotal] = useState("");
  const [discountamount, setDiscountAmount] = useState("");
  const [image, setImage] = useState();
  // const [supplierid, setSupplierId] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [selectedDateb, setSelectedDateb] = useState(null);
  const [imageStateSet, setImageStateSet] = useState();
  let countryData = Country.getAllCountries();
  const [olddoc, setOldDoc] = useState([]);
  const [supplierId, setSupplierId] = useState()
  const [inventoryOptions, setInventoryOptions] = useState([]);
  const [subTotal1, setSubTotal1] = useState(0);
  const [subSubDiscount1, setSubDiscount1] = useState(0);
  const [subSubVat1, setSubVat1] = useState(0);
  const [subSubTotalValue1, setSubTotalValue1] = useState(0);
  const [currencyData, setCurrencyData] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState('KES');
  const [selectedCurrencyValue, setSelectedCurrencyValue] = useState(null);
  const [error, setError] = useState(null);
  const [code, setCode] = useState("")
  const handleCurrencyChange = (event) => {
    const { value } = event.target;
    setSelectedCurrency(value);
    console.log('Selected currency:', selectedCurrency);
    fetchCurrencyData();
    // You can send this URL to the API endpoint
  };
  //  const handleShow = () => setShow(true);
  const handleClosePdf = () => setShowPdf(false);
  const vclose = () => {
    setShowPdf(false);
    navigate('/bill')
  }
  const toDate = moment(endDate);
  const formattedToDate = toDate.format('YYYY-MM-DD');
  const productServiceClose = () => setProductService2(false);

  const onFromDate = (e) => {
    setStartDate(e)
    console.log(e, "eeeeeeeeeeeeeee")
    const fromDate = moment(e).format('DD-MM-YYYY');
    console.log(fromDate, "fromDate");
    setFinalStartDate(fromDate)

  }

  const onToDate = (e) => {
    setEndDate(e)
    console.log(e, "eeeeeeeeeeeeeee")
    const toDate = moment(e).format('DD-MM-YYYY');
    console.log(toDate, "toDate")
    setFinalEndDate(toDate)
  }
  const [qtyIndex, setQtyIndex] = useState([]);
  const onCheckDelete = (id, indexValue) => {
    // console.log(indexValue,"indexValue")
    setDeleteId((prevDeleteId) => {
      if (prevDeleteId.includes(id)) {
        let tempQtyIndex = qtyIndex.filter(item => item !== indexValue)
        setQtyIndex(tempQtyIndex)
        // Remove the id from the array
        return prevDeleteId.filter((d) => d !== id);
      } else {
        setQtyIndex([...qtyIndex, indexValue]);
        // Add the id to the array
        return [...prevDeleteId, id];
      }
    }, () => {
      console.log("hihihihihihihi", deleteId);
    });
  };
  // const onCheckDelete = (id) => {
  //   setDeleteId((prevDeleteId) => {
  //     if (prevDeleteId.includes(id)) {
  //       // Remove the id from the array
  //       return prevDeleteId.filter((d) => d !== id);
  //     } else {
  //       // Add the id to the array
  //       return [...prevDeleteId, id];
  //     }
  //   }, () => {
  //     console.log("hihihihihihihi", deleteId);
  //   });
  // };
  console.log(deleteId, "deleteId")
  async function getallCustomerList(d) {

    getSuppliereListById(d).then(function (result) {
      const response = result.data;
      console.log(response, "response888888886")
      //setAllData(response?.data?.supplier_details)
      const combinedData = [...response?.data?.products, ...response?.data?.services];
      setAllProductServiceList(combinedData);
      // setCustomerId(response?.data);
      // setSupplierId(d)
      // setDepartmentId(response?.data?.businessUnit?.Departments);
    });
  }
  const getTotalPrice = (price, index, tax) => {
    let totalprice = 0
    if (discountQuantity && discountQuantity[index]?.quantity) {

      const totalPrice = Number(discountQuantity[index]?.quantity) * price;
      const totalDiscount = (totalPrice * Number(discountQuantity[index].discount)) / 100;
      const finalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
      const totalFinalprice = (totalPrice - finalDiscount);
      const totalTax = (totalFinalprice * Number(tax)) / 100;
      const totalFinalTaxPrice = (totalFinalprice + totalTax);

      // setDiscountQuantity((prevFormData) => ({
      //   ...prevFormData,
      //   [index]: {
      //     // ...prevFormData[index],
      //     ["totalDiscount"]: totalDiscount,
      //     ["totalTax"]: totalTax,
      //     ["price"]: price,
      //     ["totalFinalTaxPrice"]: totalFinalTaxPrice,
      //   },
      // }));

      return totalFinalTaxPrice;
    }
    return 0;

  }
  const deleteImage = () => {
    // Add your logic here to delete the image
    // For example, you can reset the image URL to an empty string
    setImageUrls([]);
    setFile([])
    setOldDoc([])
  };
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  useEffect(() => {
    getSupplierDataList();
    getInventoryDataList();
    // getProductServiceDataList();
    getData();
    // onQuantityDiscount();
    // getTotalPrice()
  }, []);

  const onAddressQuote = (e) => {
    setAddressQuote(e.target.value)
  }
  const getData = () => {
    getBillData(id).then(function (result) {
      const response = result?.data?.data;
      console.log(result?.data?.data?.Bill?.total, "rrrr")
      setSupplier(result?.data?.data?.supplier?.company_name)
      setCompanyFinal(result?.data?.data?.supplier)
      setAddress(result?.data?.data?.supplier?.billing_address)
      setStartDate(new Date(result?.data?.data?.Bill?.bill_date))
      setSelectedDate(result?.data?.data?.Bill?.bill_date)
      setSelectedDateb(result?.data?.data?.Bill?.expiration_date)
      setEndDate(new Date(result?.data?.data?.Bill?.expiration_date))
      setPaymentTerms(result?.data?.data?.Bill?.payment_terms)
      setOldDoc(result?.data?.data?.Bill?.old_docs)
      setNote(result?.data?.data?.Bill?.notes)
      setSelectedCurrency(result?.data?.data?.supplier?.currency);
      setSupplierId(result?.data?.data?.supplier?.id)
      setImageUrls(result?.data?.data?.Bill?.bill_docs)
      JSON.parse(result?.data?.data?.Bill?.products_details).map((d, i) => (setDiscountQuantity((prevFormData) => ({
        ...prevFormData,
        [i + 1]: {
          ['quantity']: d?.qty,
          ['discount']: d?.disc,
          ['price']: d?.price,
          ['id']: d?.id
        },
      }))))
      setAllProductServiceData(JSON.parse(result?.data?.data?.Bill?.products_details).map(data => ({
        "id": data.id,
        "type": data?.type,
        "name": data.product_name,
        "description": data.description,
        "unit": data?.unit,
        "sales_price": data.price,
        "purchase_price": data?.price,
        'price': data?.price,
        "quantity": data.qty,
        "tax": data.vat,
        "discount": data.disc,
        "disc": data.disc,
        'qty': data.qty,
        "total": data.total,
        "inventory": data.inventory,
        'userCurrency_productTotal': data?.userCurrency_productTotal,
        "showing_price": data?.userCurrency_showingPrice,
        'userCurrency_showingPrice': data?.userCurrency_showingPrice
      })))
      getallCustomerList(result?.data?.data?.supplier?.id)
    });
  }

  const url = imageUrls[0];
  if (typeof url === 'string') {
    let parts = url.split('.');
    let file_extension = parts[parts.length - 1];
    // Check if the state is already set to avoid unnecessary re-renders
    if (!imageStateSet) { // Assuming imageStateSet is a state variable indicating whether the image state has been set
      setImage(file_extension);
      setImageStateSet(true); // Update the state indicating that the image state has been set
    }
  } else {
    console.log('The provided URL is not a string.');
  }
  console.log('companyFinal', companyFinal)
  const handelDelete = async (e) => {
    e.preventDefault();
    let idSet = new Set(deleteId);
    idSet = Array.from(idSet)
    const filteredArray = allProductServiceData.filter(item => !idSet.includes(item.id));

    setAllProductServiceData([...filteredArray]);
    setDeleteId([]);
    qtyIndex.forEach(key => {
      if (discountQuantity.hasOwnProperty(key)) {
        delete discountQuantity[key];

      }
      const arr = Object.keys(discountQuantity).sort((a, b) => a - b).map(key => discountQuantity[key]);

      const obj = {};
      arr.forEach((value, index) => {
        obj[index + 1] = value;
      });
      setDiscountQuantity(obj)
    });

    setQtyIndex([]);
    subTotal();
    subDiscount();
    subVat();
    subTotalValue();

  }

  const handleSingleDelete = (id,type) => {
    
    if(allProductServiceData?.length===1){
      setAllProductServiceData([])
    }
    else{
      console.log('filteredArray',id,type)
    const filteredArray = allProductServiceData.filter(item => !(item.id === id && item.type === type));
    console.log('filteredArray',allProductServiceData,filteredArray)
      if(filteredArray?.length)
        {
          const updatedQtyIndex = qtyIndex.filter(index => index !== id);
         let updatedDiscountQuantity = { ...discountQuantity };
         const isIdPresent = allProductServiceData.some(item => !(item.id === id && item.type === type));
         if (isIdPresent) {
           // delete updatedDiscountQuantity[id];
           for (const key in updatedDiscountQuantity) {
             if (updatedDiscountQuantity[key].id === id) {
               delete updatedDiscountQuantity[key];
               break;  // Exit loop after finding and deleting the element
             }
           }
           const arr = Object.keys(updatedDiscountQuantity).sort((a, b) => a - b).map(key => updatedDiscountQuantity[key]);
           const obj = {};
           arr.forEach((value, index) => {
             obj[index + 1] = value;
           });
           updatedDiscountQuantity = obj;
         
         }
     
         setAllProductServiceData([...filteredArray]);
         setQtyIndex(updatedQtyIndex);
     
         setDiscountQuantity(updatedDiscountQuantity);
       }

    }
   
  };

  // const handelDelete = (e) => {
  //   e.preventDefault();
  //     // const filteredArray = allProductServiceData.filter(item => item.id != deleteId.includes(item));
  //     const idSet = new Set(deleteId);
  //     const filteredArray = allProductServiceData.filter(item => !idSet.has(item.id));

  //     console.log(filteredArray, "filteredArray")
  //     setAllProductServiceData(filteredArray);
  //     setDeleteId([]);
  //   }
  const onSendPdf = () => {
    getSendBill(dataPdf?.data?.details?.id, dataPdf?.data?.supplierEmail, dataPdf?.data?.details?.quote_id,
      addressQuote, checks,
      (r) => {
        console.log(r, "response")
        //         if (r.success === true) {
        //           toast.success(r.message)
        //           navigate('/bill')
        // }
        if (r.success === true) {
          Swal.fire({
            title: r.message,
            //  text: 'The product has been added successfully!',
            icon: 'success',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'my-confirm-button'
            }
          }).then(() => {
            navigate('/bill')
          })
        }
        else {
          console.log("nononononono")
          toast.error(r.message)

        }
      })
  }
  async function getInventoryDataList() {
    getInventoryAccounts("").then(function (result) {
      const response = result?.data;
      console.log(response, "rrrr")
      setInventoryOptions(response?.data?.inventoryAccounts);
      // setAllProductServiceList2(response?.data);
      // setAllProductServiceList(response?.data);
    });
  }
  const checkMe = (e) => {
    setChecks(e.target.checked);
    // console.log(e.target.checked, "Yesssssssssss");
    console.log(e.target.checked ? "Yesssssssssss" : "Noooooo");
  }


  const toggleHandlerb = (e) => {
    e.preventDefault();
    setViews(true)
  }
  const toggleHandlerc = (e) => {
    e.preventDefault();
    setViews(false)
  }
  const handleDrop = (event) => {
    event.preventDefault();

    const droppedFile = event.dataTransfer.files[0];
    handleFile(droppedFile);
  };
  const onAddress = (e) => {
    setQuoteAddress(e.target.value);
  }
  const onPaymentTerms = (e) => {
    setPaymentTerms(e.target.value)
  }
  const handleFile = (e, selectedFile) => {
    e.preventDefault();
    if (selectedFile) {
      const allowedTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "image/jpeg", "image/png"];
      if (allowedTypes.includes(selectedFile.type)) {
        setFile(selectedFile);

        // You can perform additional actions with the file, such as uploading it to a server.
        // For simplicity, we're just logging the file information here.
        console.log('File selected:', selectedFile);
      } else {
        alert("Only .doc, .pdf, and image files are allowed.");
      }
    }
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const onType = (e) => {
    setType(e.target.value)
  }

  const onName = (e) => {
    setName(e.target.value)
  }
  const onID = (e) => {
    setCode(e.target.value)
  }
  const onDescription = (e) => {
    setDescription(e.target.value)
  }

  const onUnit = (e) => {
    setUnit(e.target.value)
  }

  const onSalesPrice = (e) => {
    setSalePrice(e.target.value)
  }

  const onPurchasePrice = (e) => {
    setPurchasePrice(e.target.value)
  }

  const onPurchaseTax = (e) => {
    setPurchaseTax(e.target.value)
  }

  const onOrderQty = (e) => {
    setOrderQty(e.target.value)
  }

  const handleTogglesOn = () => {
    // setToggled(true);
    setInventory("No")
  };
  const handleTogglesOff = () => {
    // setToggled(false);
    setInventory("Yes")
  };
  const [supply, setSupply] = useState(false)
  const onCompanyName = (e) => {
    setCompanyName(e.target.value)
  }

  const onFirstName = (e) => {
    setFirstName(e.target.value)
  }

  const onLastName = (e) => {
    setLastName(e.target.value)
  }

  const onEmail = (e) => {
    setEmail(e.target.value)
  }
  const onWebUrl = (e) => {
    setWebUrl(e.target.value)
  }

  const onVatNumber = (e) => {
    setVatNumber(e.target.value)
  }

  const onBillingAddress = (e) => {
    setBillAddress(e.target.value)
  }

  const onCountry = (e) => {
    setCountry(e.target.value)
    setStateData(State.getStatesOfCountry(e.target.value));
  }

  const onDeliveryCountry = (e) => {
    console.log(e.target.value, "cdscds");
    setDeliveryCountry(e.target.value)
    setDeliveryStateData(State.getStatesOfCountry(e.target.value));
  }
  console.log(deliveryStateData, "dsddsd")

  const onState = (e) => {
    console.log(e.target.value, "stateiso")
    setState(e.target.value);
    setCityData(City.getCitiesOfState(country, e.target.value));
  }

  const onDeliveryState = (e) => {
    console.log(e.target.value, "stateiso")
    setDeliveryState(e.target.value);
    setDeliveryCityData(City.getCitiesOfState(DeliveryCountry, e.target.value));
  }

  const onCity = (e) => {
    setCity(e.target.value)
  }

  const onDeliveryCity = (e) => {
    setDeliveryCity(e.target.value)
  }

  const onZipCode = (e) => {
    setZipCode(e.target.value)
  }

  // const onDeliveryAddress = (e) => {
  //   setDeliveryAddress(e.target.value)
  // }

  const onDeliveryZipCode = (e) => {
    setDeliveryZipCode(e.target.value)
  }

  const onSave = () => {

    if (companyType === "Company" && companyName === "") {
      toast.error("Please type company name")

    }
    else if (companyType === "Individual" && firstName === "") {
      toast.error("Please type first name")

    } else if (companyType === "Individual" && lastName === "") {
      toast.error("Please type last name")

    } else if (email === "") {
      toast.error("Please type valid email")
    } else if (phone === "" || phone.length < 7) {
      toast.error("Please type phone number")
    }
    //  else if (companyType === "Company" && vatNumber <= 0) {
    //   toast.error("Please type a valid VAT Number(Optional)")
    // }
    // else if (billAddress === "") {
    //   toast.error("Please type billing address")
    // }
    else if (country === "") {
      toast.error("Please select country")

    } else if (state === "") {
      toast.error("Please select state")
    }
    //  else if (city === "") {
    //   toast.error("Please select city")
    // }
    // else if (zipCode === "") {
    //   toast.error("Please type zipcode")
    // }
    else {

      getAddSuppliers(companyType, companyName, firstName, lastName, email, phone, webUrl
        , vatNumber, billAddress, countryData.find(c => c.isoCode === country)?.name, stateData.find(c => c.isoCode === state)?.name
        , city, zipCode,
        (r) => {
          console.log(r, "response")
          //           if (r.success === true) {
          //             toast.success(r.message)

          //             supplier2Close(false)
          //             getSupplierDataList()
          // }
          if (r.success === true) {
            Swal.fire({
              title: r.message,
              //  text: 'The product has been added successfully!',
              icon: 'success',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'my-confirm-button'
              }
            }).then(() => {
              supplier2Close(false)
              getSupplierDataList()
            })
          }
          else {
            console.log("nononononono")
            toast.error(r.message)

          }
        })
    }
  }
  const toggleCompany = (e) => {
    setCompanyType("Company")
    e.preventDefault();
    setSupply(false)
  }
  const toggleIndividual = (e) => {
    setCompanyType("Individual")

    e.preventDefault();
    setSupply(true)
  }
  useEffect(() => {
    subTotal();
    subDiscount();
    subVat();
    subTotalValue();
  }, [discountQuantity]);

  console.log("discountQuantity", discountQuantity)
  // const [loading, setLoading] = useState(true); // Add loading state

  // useEffect(() => {
  //     // Fetch data from APIs
  //     Promise.all([getProductServiceDataList(), getSupplierDataList()])
  //         .then(() => setLoading(false))
  //         .catch(error => console.error('Error fetching data:', error));
  // }, []);
  console.log(discountQuantity, 'quan')
  const subTotal = () => {
    let totalPrice = 0;

    if (discountQuantity) {
      allProductServiceData?.forEach((d, i) => {
        const quantity = Number(discountQuantity[`${i + 1}`]?.quantity || 0);
        const price = Number(d?.type === "service" ? d?.price : d?.purchase_price);
        totalPrice += quantity * price;
      });
    }

    setSubTotal1(totalPrice);
    return totalPrice;
  };

  const subDiscount = () => {
    let totalDiscount = 0;

    if (discountQuantity) {
      allProductServiceData?.forEach((d, i) => {
        const quantity = Number(discountQuantity[`${i + 1}`]?.quantity || 0);
        const discount = Number(discountQuantity[`${i + 1}`]?.discount || 0);
        const price = Number(d?.type === "service" ? d?.price : d?.purchase_price);
        const totalPrice = quantity * price;
        totalDiscount += (totalPrice * discount) / 100;
      });
    }

    setSubDiscount1(totalDiscount);
    return totalDiscount;
  };
console.log(subSubDiscount1,'subSubDiscount1')
  const subVat = () => {
    let totalTax = 0;
    let totalFinalTaxPrice = 0;
    if (discountQuantity) {
      allProductServiceData?.map((d, i) => {
        if (discountQuantity[`${i + 1}`]) {

          if (discountQuantity[`${i + 1}`].quantity) {
            let totalPrice = 0;
            totalPrice += (Number(discountQuantity[`${i + 1}`].quantity) * Number(d?.type === "service" ? d?.price : d?.purchase_price));
            const discountAmount = (totalPrice * Number(discountQuantity[`${i + 1}`].discount)) / 100;
            const discountedPrice = totalPrice - discountAmount;
            totalTax += (discountedPrice * Number(d?.tax)) / 100;
          // totalFinalTaxPrice += (discountedPrice + totalTax);
          }
        }
      })
    }
    console.log('subSubVat1',subSubVat1)
    setSubVat1(totalTax)
    return totalTax;
  }
console.log('subSubVat1',subSubVat1,allProductServiceData)
  const subTotalValue = () => {
    const totalPrice = subTotal();
    const totalDiscount = subDiscount();
    const totalTax = subVat();
    let totalFinalTaxPrice = subTotal() - subDiscount() + subVat();
    console.log(totalFinalTaxPrice,'totalFinalTaxPrice')
    setSubTotalValue1(String(totalFinalTaxPrice));
    return totalFinalTaxPrice;
   
  };

  const onCheck = (data) => {
    console.log(data,allProductServiceData,'ssss')
    if (allProductServiceData.includes(data)) {
      const filterData = allProductServiceData.filter(d => d !== data);
      setAllProductServiceData(filterData, () => {
        console.log(allProductServiceData, "allProductServiceData");
      });
    } else {
      // const updatedData = [{...allProductServiceData, data}];
      const updatedData = [...allProductServiceData, { ...data, showing_price:data.type==='service'?data?.price: data.purchase_price || 0 }];
      console.log(updatedData, "updatedData");
      setAllProductServiceData(updatedData);

      setDiscountQuantity((prevFormData) => ({
        ...prevFormData,
        [allProductServiceData.length + 1]: {
          'id': data.id,
          'quantity': 0,
          'discount': 0,
          'tax': data.tax || 0, // Dynamically set the vat from the data object
          'price':data.type==='service'?data?.price: data.purchase_price || 0
        },
      }));
    }
  };

  // const onCheck = (data) => {
  //     console.log('data====',data)
  //     if (allProductServiceData.includes(data)) {

  //       const filterData = allProductServiceData.filter(d => d !== data);
  //       setAllProductServiceData(filterData, () => {
  //         console.log(allProductServiceData, "allProductServiceData");

  //       });
  //       toast.success('Product added')
  //     } else {
  //       const updatedData = [...allProductServiceData, data];
  //       setAllProductServiceData(updatedData, () => {
  //         console.log(allProductServiceData, "allProductServiceData");
  //       });
  //       toast.success('Item added successfully')
  //     }
  //   };
  const ischecking = (idToCheck) => {
    //  allProductServiceData.includes(d)
    //  console.log('idcheck===',idToCheck,allProductServiceData[0].id)
    const result = allProductServiceData.some(item => item.id === idToCheck)
    if (result) {
      return true
    }
    else {
      return false
    }
  };

  //   const onQuantityDiscount = (event, keyValue) => {
  //     const { name, value } = event.target;
  //     // Update state with new quantity/discount
  //     setDiscountQuantity((prevFormData) => ({
  //         ...prevFormData,
  //         [keyValue]: {
  //             ...prevFormData[keyValue],
  //             [name]: value,
  //         },
  //     }));
  //     // Recalculate subtotal, discount, VAT, and total
  //     // You can call these functions here or useEffect depending on your preference
  //     // For example:
  //     // const newSubTotal = subTotal();
  //     // const newDiscount = subDiscount();
  //     // const newVat = subVat();
  //     // const newTotal = subTotalValue();
  //     // Update state with new values if needed
  // }

  const getTotalPrice1 = (price, tax, quantity, discount) => {
    if (quantity) {
      const totalPrice = Number(quantity) * price;
      const totalDiscount = (totalPrice * Number(discount)) / 100;
      const finalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
      const totalFinalPrice = (totalPrice - finalDiscount);
      const totalTax = (totalFinalPrice * Number(tax)) / 100;
      const totalFinalTaxPrice = (totalFinalPrice + totalTax);
      console.log('totalFinalTaxPrice', totalFinalTaxPrice, totalTax, totalFinalPrice, tax)
      return totalFinalTaxPrice;
    }
    return 0;
  };
  const onInventoryChange = (e, index, rowData) => {
    const selectedInventoryId = e.target.value; // Get the selected inventory ID from the event

    // Update the inventory for the object at the given index
    const updatedData = [...allProductServiceData]; // Create a copy of the array
    updatedData[index] = { ...updatedData[index], inventory: selectedInventoryId }; // Update the inventory property for the object at the given index

    // Call your function to update the state with the updated data
    setAllProductServiceData(updatedData);
  };
  const onQuantityDiscount = (event, keyValue, data) => {
    const { name, value } = event.target;

    // Update discountQuantity state
    setDiscountQuantity((prevFormData) => ({
      ...prevFormData,
      [keyValue]: {
        ...prevFormData[keyValue],
        [name]: value,
      },
    }));

    // Update allProductServiceData state
    const updatedProductServiceData = [...allProductServiceData];
    updatedProductServiceData[keyValue - 1] = {
      ...data,
      [name]: value,
      [name === 'quantity' ? 'qty' : name === 'discount' ? 'disc' : name === 'price' ? 'purchase_price' : 'tax']: value,
    };

    // Calculate updated total price
    const quantity = name === 'quantity' ? value : discountQuantity[keyValue]?.quantity || data.qty || 0;
    const discount = name === 'discount' ? value : discountQuantity[keyValue]?.discount || data.disc || 0;
    const price = name === 'price' ? value : discountQuantity[keyValue]?.price || allProductServiceData?.total;
    const tax = name === 'tax' ? value : discountQuantity[keyValue]?.tax || data.tax || 0;

    const totalPrice = getTotalPrice1(
      price,
      tax,
      quantity,
      discount
    );

    updatedProductServiceData[keyValue - 1].total = totalPrice;
    setAllProductServiceData(updatedProductServiceData);
console.log('totalllllll==', price,
  tax,
  quantity,
  discount,discountQuantity)
    console.log(totalPrice, "Updated Total Price");
  };


  async function getProductServiceDataList() {
    getProductServiceList("").then(function (result) {
      const response = result?.data;
      console.log(response, "rrrr")
      setAllProductServiceList2(response?.data);
      setAllProductServiceList(response?.data);
    });
  }
  async function getSupplierDataList() {
    getSearchSupplier("").then(function (result) {
      const response = result?.data;
      // console.log(response, "rrrr")
      setAllSuppliersList(response?.data);
    });
  }

  const onSearchByProSer = (e) => {
    if (e.target.value != "") {
      const filterCriteria = {
        supplierId: supplierId,
        searchText: e.target.value
      };

      const queryString = new URLSearchParams(filterCriteria).toString();

      getProductServiceListBYSupplierId(queryString).then(function (result) {
        const response = result?.data;
        // console.log(response, "rrrr")

        setAllProductServiceList(response?.data);

      }
      )
    } else {
      setAllProductServiceList(productServiceList2)
    }
  }
  const onSearch = (e) => {
    console.log(e.target.value, "dsdsdsdsd")
    getSearchSupplier(e.target.value).then(function (result) {
      const response = result?.data;
      console.log(response, "rrrr")
      setAllSuppliersList(response?.data);
    });
  }
  const getTotalPrice2 = (price, index,) => {
    if (discountQuantity && discountQuantity[index]?.quantity) {
      const totalPrice = Number(discountQuantity[index]?.quantity) * price;
      const totalDiscount = (totalPrice * Number(discountQuantity[index].discount)) / 100;
      const finalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
      const totalFinalprice = (totalPrice - finalDiscount);
      //  const totalTax = (totalFinalprice * Number(tax)) / 100;
      // const totalFinalTaxPrice = selectedCurrency !== undefined ? totalFinalprice + totalTax :selectedCurrency*(totalFinalprice + totalTax);

      const totalFinalTaxPrice = totalFinalprice
      // setDiscountQuantity((prevFormData) => ({
      //   ...prevFormData,
      //   [index]: {
      //     // ...prevFormData[index],
      //     ["totalDiscount"]: totalDiscount,
      //     ["totalTax"]: totalTax,
      //     ["price"]: price,
      //     ["totalFinalTaxPrice"]: totalFinalTaxPrice,
      //   },
      // }));
      console.log('totalFinalTaxPrice', totalFinalTaxPrice)
      return totalFinalTaxPrice;
    }
    return 0;

  }
  const fetchCurrencyData = async () => {
    const UserCurrency = localStorage.getItem("1Simpleclick_Currency");
    setCurrencyData(UserCurrency)
    try {
      const apiId = 'onesimpleclick809194384'; // Replace with your actual API ID
      const apiKey = 'omumk49ld03teh0h681go0jmqp'; // Replace with your actual API Key
      const encodedCredentials = btoa(`${apiId}:${apiKey}`);

      const response = await axios.get('https://xecdapi.xe.com/v1/convert_from.json/', {
        params: {
          from: selectedCurrency ? selectedCurrency : "CAD", // base currency
          to: UserCurrency, // target currency
          amount: 1,
        },
        headers: {
          'Authorization': `Basic ${encodedCredentials}`
        }
      });
      setSelectedCurrencyValue(response.data.to[0].mid);
      console.log('UserCurrency', UserCurrency)
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  };
  const onFetchSupplier = (d) => {
    console.log(suppliersList.find(c => c.id == d))
    const companyName = suppliersList.find(c => c.id == d);
    if (companyName.type === "Company") {
      setCompanyFinal(companyName)
    } else {
      // setCompanyFinal(companyName)

      setCompanyFinal(`${companyName.fname} ${companyName.lname}`)
    }
    setSupplier1(false)
  }
  useEffect(() => {
    if (selectedCurrency) {
      fetchCurrencyData();
      getSymbolFromCurrency('CAD')
    }
  }, [selectedCurrency]);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };


  const handleDateChangeb = (date) => {
    setSelectedDateb(date);
  };
  const onNote = (e) => {
    setNote(e.target.value);
  }
  const onSaveservice = async () => {
    if (type === "") {
      toast.error("Please select a type")

    }
    else if (name === "") {
      toast.error("Please type name")
    } 
    else if (code === "") {
      toast.error("Please type code")
    } 
    else if (unit === "") {
        toast.error("Please select a unit")
      }
    // else if (salePrice === "") {
    //   toast.error("Please type Sales Price(Optional)")
    // } else if (salePrice < 0) {
    //   toast.error("Please type valid Sales Price(Optional)")
    // } else if (purchaseTax < 0) {
    //   toast.error("Please type valid purchase tax")
    // }
    // else if (type === "Product" && purchasePrice === "") {
    //   toast.error("Please type purchase price")
    // } else if (type === "Product" && purchasePrice < 0) {
    //   toast.error("Please type valid purchase price")
    // }
    // else if (type === "Product" && orderQty === "") {
    //   toast.error("Please type order quantity")
    // } else if (type === "Product" && orderQty < 0) {
    //   toast.error("Please type valid order quantity")
    // }
    else if (type === "Product" && inventory === "") {
      toast.error("Please select track inventory")
    } else {
      const data = {
        "type": type,
        "name": name,
        "description": description,
        "unit": unit,
        "sales_price": salePrice,
        "tax": purchaseTax,
        "purchase_price": purchasePrice,
        "quantity": orderQty,
        "track_inventory": inventory,
        "supplierId": supplierId,
        "code": code,
      // "sales_tax": salesTax,
      }
      setIsSpinner(true)
      const response = await instance.post(`/product-service-details-store`, data)
      const r =response?.data
          if (r.success === true) {
            setIsSpinner(false)
            Swal.fire({
              title: r.message,
              //  text: 'The product has been added successfully!',
              icon: 'success',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'my-confirm-button'
              }
            }).then(() => {
              setProductService2(false);
              getallCustomerList(supplierId);
            })
            setIsSpinner(false)
          }
          else {
            console.log("nononononono")
            toast.error(r.message)
            setIsSpinner(false)
          }
        
    }

  }
  const [disableButton, setDisableButton] = useState(false);

  const onDone = () => {

    const hasUnselectedInventory = allProductServiceData.some(d => !d.inventory);
    if (hasUnselectedInventory) {
      toast.error("Please select GL Codes for all products.");
      setDisableButton(false);
      return; // Exit the function if validation fails
    }
    const hasInvalidPrice = allProductServiceData.some(d => {
      const price = d?.type === "service" ? d?.price : d?.purchase_price;
      return price === 0 || price === "0" || price === "" || price === undefined;
    });

    if (hasInvalidPrice) {
      toast.error("Product price cannot be zero,or empty.");
      return; // Exit the function if validation fails
    }
    let products_details = [];
    allProductServiceData.map((d, i) => (
      products_details.push({
        "product_name": d?.name,
        "qty": Number(discountQuantity[`${i + 1}`]?.quantity),
        "price": d?.type === "service" ? d?.price : d?.purchase_price,
        "disc": Number(discountQuantity[`${i + 1}`]?.discount ? discountQuantity[`${i + 1}`]?.discount : 0),
        "total": d?.total,
        // "total": getTotalPrice((d?.type === "service" ? d?.price : d?.purchase_price), i + 1, d?.tax),
        "vat": d?.tax,
        "description": d?.description,
        "id": d?.id,
        "unit": d.unit,
        "type": d?.type,
        "inventory": d.inventory,
        "refund": "false",
        'userCurrency_productTotal': d?.userCurrency_productTotal?d?.userCurrency_productTotal :(getTotalPrice2((d?.type === "service" ? d?.price : d?.purchase_price), i + 1,) * selectedCurrencyValue).toFixed(2),
        'userCurrency_purchasePrice': ((d?.type === "service" ? d?.price : d?.purchase_price) * selectedCurrencyValue).toFixed(2),
        'userCurrency_showingPrice': ((d?.type === "service" ? d?.price : d?.purchase_price) * selectedCurrencyValue).toFixed(2)
      })
    ))

    console.log('products_details',JSON.stringify(products_details))
    let formData = new FormData();

    formData.append('quote_docs', file);
    formData.append('supplier_id', supplierId);
    formData.append('bill_date', finalStartDate === '' ? selectedDate : finalStartDate);
    formData.append('expiration_date', finalEndDate === '' ? selectedDateb : finalEndDate);
    formData.append('address', addres);
    formData.append('payment_terms', paymentTerms);
    formData.append('products_details', JSON.stringify(products_details));
    formData.append('sub_total', (selectedCurrencyValue * subTotal()).toFixed(2));
    formData.append('discount_amount', (selectedCurrencyValue * subDiscount()).toFixed(2));
    formData.append('vat', (selectedCurrencyValue * subVat()).toFixed(2));
    formData.append('total', (selectedCurrencyValue * subTotalValue()).toFixed(2));
    formData.append('supplierCurrency_subTotal', (subTotal()).toFixed(2));
    formData.append('supplierCurrency_discountAmount', (subDiscount()).toFixed(2));
    formData.append('supplierCurrency_vat', (subVat()).toFixed(2));
    formData.append('supplierCurrency_totalAmount', (subTotalValue()).toFixed(2));
    formData.append('supplier_currency', getSymbolFromCurrency(selectedCurrency));
    formData.append('user_currency', getSymbolFromCurrency(currencyData));
    formData.append('currency_exchangeRate', selectedCurrencyValue);
    formData.append('notes', note ? note : '');
    formData.append('bill_docs[]', file);
    formData.append('old_docs', imageUrls === '' ? JSON.stringify(imageUrls) : JSON.stringify(olddoc))
    console.log(formData, "formData")
    if (finalStartDate && selectedDate === "") {
      toast.error("Please select start date");
      setDisableButton(false);
    } else if (finalEndDate && selectedDateb === "") {
      toast.error("Please select end date")
      setDisableButton(false);
    } else if (paymentTerms === "" || paymentTerms === "null" || paymentTerms === undefined) {
      toast.error("Please select a payment terms")
      setDisableButton(false);
    } else if (products_details.length <= 0) {
      toast.error("Please add  Products")
      setDisableButton(false);
    } else if (Object.values(discountQuantity).filter(item => !Number(item.quantity)).length) {
      toast.error("Please add  quantity")
      setDisableButton(false);
    } else {
      getEditbill(id, formData,
        (r) => {
          console.log(r, "response")
          // if (r.success === true) {
          //   toast.success(r.message)
          //   // setSupplier2(false)
          //   // getSupplierDataList();
          //   setDataPdf(r)
          //   setShowPdf(true);
          //   setDisableButton(false);

          // } 
          if (r.success === true) {
            Swal.fire({
              title: r.message,
              //  text: 'The product has been added successfully!',
              icon: 'success',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'my-confirm-button'
              }
            }).then(() => {
              setDataPdf(r)
              setShowPdf(true);
              setDisableButton(false);
            })
          }
          else {
            console.log("nononononono")
            toast.error(r.message)
            setDisableButton(false);
          }
        })
    }
  }
  const onButtonClick = () => {
    const pdfUrl = dataPdf?.data?.pdf;
    if (pdfUrl) {
      window.open(pdfUrl, '_blank'); // Open PDF in a new tab
    } else {
      // Handle case when PDF URL is not available
      console.error('PDF URL is not available');
    }
  };

  const _HANDLE_FILE_CHANGE = (e) => {
    e.preventDefault();
    document.querySelector('input[type="file"]').click()
  }

  return (
    <div className="producservlisting pdt">
      <Dbheader />
      <Sidebar>
        <div className='customerMain'>
          <div className='productServList'>
            <div className='dbTopsec pb-4 pt-2'>
              <Headtxt link="/bill" title="Edit Bill Details" />

              {/* <div className='custButton'>
                                <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp;
                                <button className='commonButton expBtn'><i class="fa-light fa-download"></i> Export Excel</button>
                            </div> */}
            </div>

            <div className='commonWhite addCustomerForm p-3 mb-3'>
              <Form>
                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                  {/* <Col md="4" className='mb-2'>
                                    <Dropdown className='dropselect'>
                      <Dropdown.Toggle variant="success" id="dropdown-basic" >
                      {supplier!=''?supplier:companyFinal ? companyFinal?.type === "Company" ? companyFinal?.company_name : companyFinal.fname + " " + companyFinal.lname : "Select Supplier"}
                      </Dropdown.Toggle>
      
                    </Dropdown>
                                    </Col> */}
                  <Col md="4" className='mb-2'>
                    <Form.Control type="text" disabled value={companyFinal?.type === "Company" ? companyFinal?.company_name : companyFinal.fname + " " + companyFinal.lname} />
                  </Col>
                  <Col md="4" className='datepickr mb-3'>
                    <DatePicker
                      selected={startDate}
                      onChange={onFromDate}
                      dateFormat="dd/MM/yyyy" // You can customize the date format
                      // isClearable
                      placeholderText="Bill date"
                    />
                  </Col>
                  <Col md="4" className='datepickr mb-3'>
                    <DatePicker
                      selectsEnd
                      selected={endDate}
                      onChange={onToDate}
                      endDate={endDate}
                      startDate={startDate}
                      minDate={startDate}
                      dateFormat="dd/MM/yyyy" // You can customize the date format
                      // isClearable
                      placeholderText="Due date"
                    />
                  </Col>
                </Form.Group>
                <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                  <Col md="4">
                    <Form.Control type="text" placeholder="Address" onChange={() => { onAddress() }} value={companyFinal?.billing_address
                      ? companyFinal.billing_address
                      : [companyFinal?.country, companyFinal?.state, companyFinal?.city]
                        .filter(Boolean) // This removes empty values
                        .join(', ')} />
                  </Col>
                  <Col md="4" className='mb-3'>
                    <Form.Select aria-label="Default select example" onChange={onPaymentTerms} value={paymentTerms}>
                      <option selected hidden>Select Payment Terms </option>
                      <option value="30 days">30 days</option>
                      <option value="60 days">60 days</option>
                      <option value="90 days">90 days</option>
                      <option value="Due on receipt">Due on receipt</option>
                      <option value="3 installation">3 installation</option>
                      <option value="2 installation">2 installation</option>

                    </Form.Select>
                  </Col>
                  <Col md="4" className='mb-3'>
                    <div id="selectors">
                      <select value={selectedCurrency} disabled>
                        {Object.keys(currencies).map((currency, index) => (
                          <option value={currency} key={index}>
                            {currency} - {currencies[currency].name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Form.Group>
                {selectedCurrencyValue != undefined ?
                  <div class="">
                    <p>Exchange Rate</p>
                    <input id="c474_exchange_rate" style={{ marginBottom: '15px' }} name="exchange_rate" readOnly class="input-medium" type="text" tabindex="7" value={`${getSymbolFromCurrency(selectedCurrency)}  ${selectedCurrencyValue}`}>
                    </input>
                    <p>{getSymbolFromCurrency(selectedCurrency)} 1 {selectedCurrency} = {getSymbolFromCurrency(currencyData)}  {selectedCurrencyValue}  {currencyData}</p>
                  </div>
                  : null}

                {/* <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                  
                                   
                                </Form.Group> */}

                {/* <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="6" className='mb-3'>
                                        <Form.Select aria-label="Default select example">
                                            <option selected hidden>Payment Terms</option>
                                            <option value="Company">Company</option>
                                            <option value="Individual">Individual</option>

                                        </Form.Select>
                                    </Col>
                                    <Col md="6" className='mb-3'>
                                        <Form.Select aria-label="Default select example">
                                            <option selected hidden>Currency</option>
                                            <option value="Company">Company</option>
                                            <option value="Individual">Individual</option>

                                        </Form.Select>
                                    </Col>
                                </Form.Group> */}


                <div className='salesOrdTable'>
                  <div className='listViews'>
                    <table class="table">
                      <thead>
                        <tr>

                          <th scope="col">Description</th>
                          <th scope="col">GL Codes</th>
                          <th scope="col">Qty</th>
                          {/* <th scope="col">Unit</th> */}
                          <th scope="col">Price</th>
                          <th scope="col">Disc.%</th>
                          <th scope="col">Total</th>
                          <th scope="col">VAT%</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {productList.length != 0 && productList?.map((d, i) => (

<tr>
  <td><input type='checkbox' checked={deleteId.includes(d?.id)} onChange={() => onCheckDelete(d?.id)} /></td>
  <td><span className='lightBlock'>{d?.product_name}</span></td>
  <td><input className='lightBlock' onChange={(e) => onQuantityDiscount(e, i + 1)} name='quantity' defaultValue={d.qty}/></td>
  <td><input className='lightBlock' value={d?.type === "service" ? d?.price : d?.purchase_price} readOnly defaultValue={d.price}/></td>
  <td><input className='lightBlock' onChange={(e) => onQuantityDiscount(e, i + 1)} name='discount' defaultValue={d?.disc}/></td>
  <td><input className='lightBlock' readOnly value={getTotalPrice((d?.type === "service" ? d?.price : d?.purchase_price), i + 1, d?.tax)}/></td>
  <td><span className='lightBlock'>{d?.tax}</span></td>
</tr>
))} */}

                        {allProductServiceData.length != 0 && allProductServiceData?.map((d, i) => (

                          <tr>
                            {/* <td><input type='checkbox' checked={deleteId.includes(d?.id)} onChange={() => onCheckDelete(d?.id,i+1)}/></td>
   */}
                            <td><span className='lightBlock'>{d?.name}</span></td>
                            <td>
                              <select className='lightBlock' onChange={(e) => onInventoryChange(e, i, d)} value={d.inventory}>
                                <option value="">Select GL Codes</option>
                                {/* Populate options dynamically based on your inventory data */}
                                {inventoryOptions.map((inventoryOption, index) => (
                                  <option key={index} value={inventoryOption.id}>{inventoryOption.account_name}</option>
                                ))}
                              </select>
                            </td>
                            <td>
                              {/* <input className='lightBlock' onChange={(e) => onQuantityDiscount(e, i + 1)} name='quantity' defaultValue={d.qty}/> */}
                              {d?.qty ? <input className='lightBlock' onChange={(e) => onQuantityDiscount(e, i + 1, d)} name='quantity' value={d?.qty} type='number'/> : <input className='lightBlock' type='number' onChange={(e) => onQuantityDiscount(e, i + 1, d)} name='quantity' value={d?.qty ? d?.qty : ''} />}
                            </td>
                            {/* <td><input className='lightBlock' value={ d?.unit}defaultValue={d.unit} readOnly/></td> */}
                            <td><input className='lightBlock' value={d?.type==='service'?d?.price:d?.purchase_price } onChange={(e) => onQuantityDiscount(e, i + 1, d)} name='price' type='number'/>
                            <p style={{ fontSize: '12px', color: '#3f63af' }}>* {getSymbolFromCurrency(selectedCurrency)} {((d?.showing_price) / selectedCurrencyValue).toFixed(2)}</p>
                            </td>
                            <td>
                              {d?.disc ? <input className='lightBlock' onChange={(e) => onQuantityDiscount(e, i + 1, d)} name='discount' value={d?.disc} type='number'/> : <input className='lightBlock' onChange={(e) => onQuantityDiscount(e, i + 1, d)} name='discount' value={d?.disc ? d?.disc : ''} type='number'/>}
                            </td>
                            <td>
                              {/* <input className='lightBlock' readOnly value={Number(getTotalPrice((d.type==='service'?d?.price:d?.sales_price), i + 1, d?.tax)).toFixed(2)} name='total' defaultValue={d?.total} /> */}
                              <input className='lightBlock' readOnly value={d?.total ? Number(d?.total).toFixed(2) : ""} id={`all-${d?.id}`} />
                            </td>
                            <td>
                              {d?.tax ? <input className='lightBlock' onChange={(e) => onQuantityDiscount(e, i + 1, d)} name='tax' value={d?.tax} type='number'/> : <input className='lightBlock' onChange={(e) => onQuantityDiscount(e, i + 1, d)} name='tax' value={d?.tax ? d?.tax : ''} type='number'/>}
                            </td>
                            <td>
                              <i className="fa-regular fa-trash-can" onClick={() => handleSingleDelete(d.id,d.type)}></i>
                            </td>
                          </tr>
                        ))}


                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='mb-4'>
                  <button className='commonButton expBtn' onClick={e => handleShow(e)}><i class="fa-regular fa-magnifying-glass"></i> Add Product or Service</button>&nbsp;&nbsp;
                  
                </div>
                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                  <Col md="6" className='mb-3'>
                    <Form.Control as="textarea" placeholder="Notes" rows={3} onChange={onNote} />
                  </Col>
                  <Col md="6" className='mb-3'>
                    <div className='subTotal'>
                      <div className='saflex'>
                        <span>Sub Total</span>
                        <span>{getSymbolFromCurrency(selectedCurrency)}{Number(subTotal1).toFixed(2)}</span>
                      </div>
                      {/* <div className='saflex'>
                                                <span>Discount Rate</span>
                                                <span>20:00</span>
                                            </div> */}
                      <div className='saflex'>
                        <span>Discount Amount</span>
                        <span>{getSymbolFromCurrency(selectedCurrency)}{Number(subSubDiscount1).toFixed(2)}</span>
                      </div>
                      <div className='saflex'>
                        <span>VAT</span>
                        <span>{getSymbolFromCurrency(selectedCurrency)}{Number(subSubVat1).toFixed(2)}</span>
                      </div>
                      <div className='saflex totlbg'>
                        <span>Total</span>
                        <span>{getSymbolFromCurrency(selectedCurrency)}{Number(subSubTotalValue1).toFixed(2)}</span>
                      </div>
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                  <p>User Total</p>
                  <Col md="6" className='mb-3'>
                    <div className='subTotal'>
                      <div className='saflex'>
                        <span>Sub Total</span>
                        <span>{getSymbolFromCurrency(currencyData)}{(selectedCurrencyValue * Number(subTotal1)).toFixed(2)}</span>
                      </div>
                      {/* <div className='saflex'>
                                                <span>Discount Rate</span>
                                                <span>20:00</span>
                                            </div> */}
                      <div className='saflex'>
                        <span>Discount Amount</span>
                        <span>{getSymbolFromCurrency(currencyData)}{(selectedCurrencyValue * Number(subSubDiscount1)).toFixed(2)}</span>
                      </div>
                      <div className='saflex'>
                        <span>VAT</span>
                        <span>{getSymbolFromCurrency(currencyData)}{(selectedCurrencyValue * Number(subSubVat1)).toFixed(2)}</span>
                      </div>
                      <div className='saflex totlbg'>
                        <span>Total</span>
                        <span>{getSymbolFromCurrency(currencyData)}{(selectedCurrencyValue * Number(subSubTotalValue1)).toFixed(2)}</span>
                      </div>
                    </div>
                  </Col>
                </Form.Group>

                <label>Attachments</label>
                <div className='adsDrag'>
                  {

                    //                                 imageUrls!='' && image ==='png' ?  
                    //                                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    //   {/* Render the cross icon */}
                    //   <span style={{ position: 'absolute', top: '5px', left: '5px', cursor: 'pointer' }} onClick={deleteImage}>
                    //     <i className="fas fa-times-circle" style={{ fontSize: '1.5em', color: 'red' }}></i>
                    //   </span>
                    //   {/* Render the current image */}
                    //   <img src={imageUrls} alt="Current" width="100px" height="100px" style={{ paddingLeft: '20px' }} />
                    // </div>: 
                    imageUrls != '' ?

                      <div style={{ position: 'relative', display: 'inline-block', }}>
                        {/* Render the cross icon */}
                        <span style={{ position: 'absolute', cursor: 'pointer', width: '200px' }} onClick={deleteImage}>
                          <i className="fas fa-times-circle" style={{ fontSize: '1.5em', color: 'red' }}></i>
                        </span>
                        {/* Render the current image */}
                        <i className="fa fa-file" style={{ fontSize: '4.5em', color: '#f39170', marginRight: '10px' }}></i>
                      </div>
                      : null
                  }
                  {imageUrls.length <= 0 ?
                    <div
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      className='drgdrop'
                    >
                      <i class="fa-light fa-cloud-arrow-up"></i>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p>Drag &amp; drop files or </p>
                        <input type="file" onChange={(e) => handleFile(e, e.target.files[0])} style={{ display: 'none' }} />
                        <button onClick={(e) => _HANDLE_FILE_CHANGE(e)}>Browse</button>
                      </div>

                      {file && <p>Selected File: {file.name}</p>}
                    </div>
                    : null
                  }



                </div>
                <p style={{ fontSize: '15px', color: '#3f63af' }}>* Calculated latest purchase price as per Supplier currency.</p>

              </Form>
            </div>
            <button className='commonButton expBtn' onClick={onDone} disabled={disableButton}>
              {disableButton ? 'Processing...' : 'Done'}
            </button>&nbsp;&nbsp;
            <button className='commonButton addBtn' onClick={() => navigate('/bill')}>Cancel</button>


          </div>
        </div>
      </Sidebar>




      <Modal size="xl" show={show} onHide={handleClose} centered className='customerModal sloModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>View Leads and Customers</h5>
            <div className='slSearch'>
              <i class="fa-regular fa-magnifying-glass"></i>
              <Form.Control
                type="search"
                placeholder="Search This List"
                className=" mr-sm-2"
              />
            </div>
          </Modal.Title>

        </Modal.Header>
        <Modal.Body>
          <div className='listViews salesPopTable'>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Customer Name</th>
                  <th scope="col">Notes</th>
                  <th scope="col">City</th>
                  <th scope="col">Zip Code(Optional)</th>
                  <th scope="col">Country</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type='checkbox' />&nbsp; Alex Brown</td>
                  <td>CUS - 003</td>
                  <td></td>
                  <td>lorem</td>
                  <td>258965</td>
                  <td>India</td>
                </tr>
                <tr>
                  <td><input type='checkbox' />&nbsp; Alex Brown</td>
                  <td>CUS - 003</td>
                  <td></td>
                  <td>lorem</td>
                  <td>258965</td>
                  <td>India</td>
                </tr>


              </tbody>
            </table>
          </div>
          {/* 
                    <Pagination className='pgns'>
                        <Pagination.Prev />
                        <Pagination.Item>{1}</Pagination.Item>                        
                        <Pagination.Item>{2}</Pagination.Item>
                        <Pagination.Next />
                    </Pagination> */}


        </Modal.Body>
        <Modal.Footer>
          <button className='commonButton expBtn'><i class="fa-regular fa-plus"></i> Add</button>
          <button className='commonButton expBtn' onClick={handleClose}>Close</button>
        </Modal.Footer>
      </Modal>


      <Modal size="xl" show={supplier1} onHide={supplier1Close} centered className='customerModal sloModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>View Suppliers</h5>
            <div className='slSearch'>
              <i class="fa-regular fa-magnifying-glass"></i>
              <Form.Control
                type="search"
                placeholder="Search Suppliers"
                className=" mr-sm-2"
                onChange={onSearch}
              />

            </div>

          </Modal.Title>
          <button className='commonButton expBtn modAddbtn' onClick={supplierShow2}><i class="fa-regular fa-plus"></i> Add</button>

        </Modal.Header>
        <Modal.Body>
          <div className='listViews salesPopTable'>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Supplier Type</th>
                  <th scope="col">Supplier Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Country</th>
                </tr>
              </thead>
              <tbody>
                {suppliersList?.map((d) => (
                  <tr>
                    {/* <td><input type='checkbox'/>&nbsp; Alex Brown</td> */}
                    <td ><Link onClick={onFetchSupplier.bind(d?.id, `${d?.id}`)}>{d?.type}</Link></td>
                    <td><Link onClick={onFetchSupplier.bind(d?.id, `${d?.id}`)}>{d?.type === "Company" ? d?.company_name : d?.fname + " " + d?.lname}</Link></td>
                    <td><Link onClick={onFetchSupplier.bind(d?.id, `${d?.id}`)}>{d?.email}</Link></td>
                    <td><Link onClick={onFetchSupplier.bind(d?.id, `${d?.id}`)}>{d?.phone}</Link></td>
                    <td><Link onClick={onFetchSupplier.bind(d?.id, `${d?.id}`)}>{d?.country}</Link></td>
                  </tr>
                ))}




              </tbody>
            </table>
          </div>

          {/* <Pagination className='pgns'>
            <Pagination.Prev />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Item>{2}</Pagination.Item>
            <Pagination.Next />
          </Pagination> */}


        </Modal.Body>
        <Modal.Footer>
          <button className='commonButton expBtn' onClick={handleClosed}>Close</button>
        </Modal.Footer>
      </Modal>

      <Modal size="xl" show={show} onHide={handleClose} centered className='customerModal sloModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>List of Products and Services</h5>
            <div className='slSearch'>
              <i class="fa-regular fa-magnifying-glass"></i>
              <Form.Control
                type="search"
                placeholder="Search Product and Service"
                className=" mr-sm-2"
                onChange={onSearchByProSer}
              />
            </div>
          </Modal.Title>
          <button className='commonButton expBtn modAddbtn' onClick={productServiceShow2}><i class="fa-regular fa-plus"></i> Add</button>

        </Modal.Header>


        <Modal.Body>
          <div className='listViews salesPopTable listScroll'>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Type</th>
                  <th scope="col">Name</th>
                  <th scope="col">description</th>
                  <th scope="col">Price</th>
                  <th scope="col">Unit</th>
                  <th scope="col">Tax</th>
                </tr>
              </thead>
              <tbody>
                {productServiceList?.map((d) => (
                  allProductServiceData.some(item => item.id === d.id) ? null :
                    <tr>
                      <td>
                        {
                          d?.type === 'product' ?
                            <>
                              <span>&nbsp;</span>
                              <span>&nbsp;</span>
                              <input
                                type='checkbox'
                                onChange={() => onCheck(d)}
                                //disabled={allProductServiceData.length !== 0 ? d?.type !== allProductServiceData[0].type : false} 
                                checked={allProductServiceData.includes(d)}
                                style={{ marginTop: '6px' }}
                              />
                            </>
                            :
                            <input
                              type='checkbox'
                              onChange={() => onCheck(d)}
                              //disabled={allProductServiceData.length !== 0 ? d?.type !== allProductServiceData[0].type : false} 
                              checked={allProductServiceData.includes(d)}
                              style={{ marginTop: '6px' }}
                            />
                        }
                        &nbsp;&nbsp;&nbsp;{d?.type}
                      </td>
                      <td>{d?.name}</td>
                      <td>{d?.description}</td>
                      <td>{d?.type === "service" ? d?.price : d?.purchase_price}</td>
                      <td>{d?.unit}</td>
                      <td>{d?.tax}</td>
                    </tr>

                ))}




              </tbody>
            </table>
          </div>

          {/* <Pagination className='pgns'>
            <Pagination.Prev />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Item>{2}</Pagination.Item>
            <Pagination.Next />
          </Pagination> */}


        </Modal.Body>
        <Modal.Footer>
          {/* <button className='commonButton expBtn' onClick={handleClose}>Submit</button> */}
          <button className='commonButton expBtn' onClick={handleClose}>Close</button>
        </Modal.Footer>
      </Modal>

      <Modal size="xl" show={supplier2} onHide={supplier2Close} centered className='customerModal sloModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Add Supplier</h5>
            {/* <div className='slSearch'>
                            <i class="fa-regular fa-magnifying-glass"></i>
                            <Form.Control
                                type="search"
                                placeholder="Search This List"
                                className=" mr-sm-2"
                            />
                        </div> */}
          </Modal.Title>
          {/* <button className='commonButton expBtn modAddbtn2' onClick={onSave}><i class="fa-regular fa-plus"></i> Add</button> */}


        </Modal.Header>
        <Modal.Body>
          <div className='customerMain'>
            <div className='custBtm addSupply'>
              {/* <div className='dbTopsec pb-2'>
                <Headtxt link="/supplierlisting" title="New Supplier" />
                <button className='commonButton expBtn' onClick={handleShow}><i class="fa-light fa-download"></i> Import Excel</button>
              </div> */}
              <div className='addsuppBtn'>
                <button className={`${!supply ? "commonButton active" : "commonButton "} `} onClick={toggleCompany}>Company</button>
                <button className={`${supply ? "commonButton active" : "commonButton "} `} onClick={toggleIndividual}>Individual</button>
              </div>
              <div className='commonWhite addCustomerForm p-3 mb-3'>

                <Form>
                  {!supply ?
                    <Form.Group className="row" controlId="exampleForm.ControlInput1">
                      <Col md="12" className='mb-3'>
                        <Form.Control type="text" placeholder="Company Name" onChange={onCompanyName} />
                      </Col>
                    </Form.Group> : <Form.Group className="row" controlId="exampleForm.ControlInput1">
                      <Col md="6" className='mb-3'>
                        <Form.Control type="text" placeholder="First Name" onChange={onFirstName} />
                      </Col>
                      <Col md="6" className='mb-3'>
                        <Form.Control type="text" placeholder="Last Name" onChange={onLastName} />
                      </Col>
                    </Form.Group>}

                  <Form.Group className="row" controlId="exampleForm.ControlInput1">
                    <Col md="6" className='mb-3'>
                      <Form.Control type="email" placeholder="Email Address" onChange={onEmail} />
                    </Col>
                    <Col md="6" className='mb-3'>
                      <PhoneInput
                        defaultCountry="ua"
                        value={phone}
                        className='phInp'
                        onChange={(phone) => setPhone(phone)}
                      />

                    </Col>
                  </Form.Group>
                  <Form.Group className="row" controlId="exampleForm.ControlInput1">
                    <Col md="6" className='mb-3'>
                      <Form.Control type="text" placeholder="Website URL (Optional)" onChange={onWebUrl} />
                    </Col>
                    <Col md="6" className='mb-3'>
                      <Form.Control type="text" placeholder="VAT Number(Optional)" onChange={onVatNumber} />
                    </Col>
                  </Form.Group>

                  <Form.Group className="row" controlId="exampleForm.ControlInput1">
                    <Col md="6" className='mb-3'>
                      <Form.Control as="textarea" placeholder="Billing Address (Optional)" rows={3} onChange={onBillingAddress} />
                    </Col>
                    <Col md="6" className='mb-3'>
                      <Form.Select aria-label="Default select example" onChange={onCountry} className='mb-3'>
                        <option selected hidden>Select country</option>

                        {countryData.map((con) => (
                          <option value={con.isoCode}>{con.name}</option>

                        ))}
                      </Form.Select>

                      <Form.Select aria-label="Default select example" onChange={onState} className='mb-3'>
                        <option selected hidden>Select state</option>

                        {stateData.map((sta) => (
                          <option value={sta.isoCode}>{sta.name}</option>

                        ))}
                      </Form.Select>

                      <Form.Select aria-label="Default select example" onChange={onCity} className='mb-3'>
                        <option selected hidden>Select city</option>

                        {cityData.map((cit) => (
                          <option value={cit.name}>{cit.name}</option>

                        ))}
                      </Form.Select>

                      <Form.Control type="text" placeholder="Zip Code(Optional)" onChange={onZipCode} />

                    </Col>
                  </Form.Group>



                  {/* <div className='addsBtn'>
                    <button type='submit' className='commonButton expBtn' onClick={onSave}>Save</button>&nbsp;&nbsp;
                    <button className='commonButton addBtn'>Cancel</button>
                  </div> */}


                </Form>


              </div>
            </div>


          </div>
          {/* <button className='commonButton expBtn'>Save</button> */}

        </Modal.Body>
        <Modal.Footer>
          <button className='commonButton expBtn' onClick={onSave}><i class="fa-regular fa-plus"></i> Add Supplier</button>
          <button className='commonButton expBtn' onClick={supplier2Close}>Close</button>
        </Modal.Footer>
      </Modal>
      <Modal size="lg" show={productService2} onHide={productServiceClose} centered className='customerModal sloModal asdModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Add Product or Service</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='commonWhite addCustomerForm p-3 mb-3'>
            <Form>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="6" className='mb-3'>
                  <Form.Select aria-label="Default select example" onChange={onType}>
                    <option selected hidden>Select Type</option>
                    <option value="Product">Product</option>
                    <option value="Service">Service</option>
                  </Form.Select>
                </Col>
                <Col md="3" className='mb-3'>
                  <Form.Control type="text" placeholder="Name" onChange={onName} />
                </Col>
                <Col md="3" className='mb-3'>
                  <Form.Control type="text" placeholder="Code" onChange={onID} />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-3'>
                  <Form.Control as="textarea" placeholder="Description(Optional)" rows={3} onChange={onDescription} />
                </Col>
              </Form.Group>



              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                {type === "Service" ?
                  <Col md="4" className='mb-3'>
                    <Form.Select aria-label="Default select example" onChange={onUnit}>
                      <option selected hidden>Select Unit</option>
                      <option value="Hour">Hour</option>
                      <option value="Day">Day</option>
                      <option value="Contract">Contract</option>
                      <option value="Percentage of completion">Percentage of completion</option>
                    </Form.Select>
                  </Col>
                  :

                  <Col md="4" className='mb-3'>
                    <Form.Select aria-label="Default select example" onChange={onUnit}>
                      <option selected hidden>Select Unit</option>
                      <option value="Piece">Piece</option>
                      <option value="Centiliter">Centiliter</option>
                      <option value="Ltr">Ltr</option>
                      <option value="Kg">Kg</option>
                      <option value="Gms">Gms</option>
                      <option value="Cubic meter">Cubic meter</option>
                      <option value="Contract">Contract</option>
                      <option value="Percentage of completion">Percentage of completion</option>
                    </Form.Select>
                  </Col>

                }
                <Col md="4" className='mb-3'>
                  <Form.Control type="number" placeholder={type === "Service" ? "Price(Optional)" : "Sales Price(Optional)"} onChange={onSalesPrice} />
                </Col>
                {/* <Col md="4" className='mb-3'>
                                        <Form.Control type="text" placeholder="Purchase tax" onChange={onPurchaseTax}/>
                                    </Col> */}
                <Col md="4" className='mb-3'>
                  <Form.Control type="number" placeholder="Purchase tax(Optional)" onChange={onPurchaseTax} />
                </Col>

              </Form.Group>

              {type === "Service" ? "" :
                <Form.Group className="row" controlId="exampleForm.ControlInput1">

                  <Col md="4" className='mb-3'>
                    <Form.Control type="number" placeholder="Purchase price(Optional)" onChange={onPurchasePrice} />
                  </Col>

                  <Col md="4" className='mb-3'>
                    <Form.Control type="number" placeholder="Quantity(Optional)" onChange={onOrderQty} />

                  </Col>
                </Form.Group>}
              {type === "Service" ? "" :
                <div className='radioline mt-3 mb-3'><span>Track Inventory</span>
                  <div>
                    <input type="radio" id="test1" name="radio-group" onClick={handleTogglesOff} />
                    <label for="test1">Yes</label>
                  </div>
                  <div>
                    <input type="radio" id="test2" name="radio-group" onClick={handleTogglesOn} />
                    <label for="test2">No</label>
                  </div>

                </div>}

              <div className='rightBtn'>
                <button type='button' className='commonButton expBtn' onClick={onSaveservice}>Save</button>
              </div>




            </Form>

          </div>
        </Modal.Body>


        {/* <Modal.Footer>
          <button className='commonButton expBtn' onClick={onSave}><i class="fa-regular fa-plus"></i> Add Supplier</button>
          <button className='commonButton expBtn' onClick={productServiceClose}>Close</button>
        </Modal.Footer> */}
      </Modal>
      <Modal size="lg" show={showPdf} backdrop="static" onHide={handleClosePdf} className="salesExport">
        <div className='salesExpCol fullHgt'>
          <Modal.Header>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>



          <div className='modIframe'><iframe src={dataPdf?.data?.pdf} /></div>
          <div className='expRgt'>
            {/* <button className='commonButton expBtn btnshadow'><i class="fa-regular fa-envelope"></i> Email</button>
                        <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-print"></i> Print</button>
                        <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-down-to-bracket"></i> Download</button> */}

            {!views ?
              <div>
                <button className='commonButton expBtn btnshadow' onClick={toggleHandlerb}><i class="fa-regular fa-envelope"></i> Email</button>
                <button className='commonButton wtBtn btnshadow' onClick={onButtonClick}><i class="fa-light fa-print"></i> Print</button>
                <button className='commonButton wtBtn btnshadow' onClick={onButtonClick}><i class="fa-light fa-down-to-bracket"></i> Download</button><br /><br /><br /><br />
                <button className='commonButton expBtn btnshadow' onClick={vclose}><i class="fa-solid fa-xmark"></i> Close</button>

              </div> :
              <Form>
                <button className='commonButton backArrow' onClick={toggleHandlerc}><i class="fa-solid fa-arrow-left"></i> Back</button>
                <Form.Group className="mb-3 salesRform" controlId="exampleForm.ControlInput1">
                  <Col md="12" className='mb-2'>
                    <label>To <span className='redColor'>*</span></label>
                    <Form.Control type="text" placeholder="Johndoe@gmail.com" value={dataPdf?.data?.supplierEmail} />
                  </Col>
                  <Col md="12" className='mb-2'>
                    <label>Subject :</label>
                    <Form.Control type="text" placeholder="ABC Company" value={dataPdf?.data?.details?.quote_id} />
                  </Col>
                  <Col md="12" className='mb-3'>
                    <label>Message :</label>
                    <Form.Control as="textarea" placeholder="Enter your message" rows={3} className='mb-3' onChange={onAddressQuote} />
                  </Col>
                  <div className='salesCheck mb-3'><Form.Check type="checkbox" onClick={checkMe} /> Send me a copy</div>
                  <button type="button" className='commonButton expBtn btnshadow' onClick={onSendPdf}>Send</button>

                </Form.Group>
              </Form>
            }


          </div>
        </div>
      </Modal>
    </div>
  )
}



export default Billdetails