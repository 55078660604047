import { React, useState } from 'react'
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import Form from 'react-bootstrap/Form';
import Headtxt from '../Headtxt';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';
import { Link } from 'react-router-dom';
import Customdropdown from '../../Customdropdown';

const Balancesheet = () => {

    const options = ['All accounts', 'Company', 'Individuals'];  
    const options2 = ['All contacts', 'Company', 'Individuals'];  


    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const [selectedDateb, setSelectedDateb] = useState(null);

    const handleDateChangeb = (date) => {
        setSelectedDateb(date);
    };



    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                <div className='custTop'>
                    <Link to='' className='active'>Balance Sheet</Link>
                    <Link to='/profitloss' >Income (Profit & Loss)</Link>
                    <Link to='/cashflow'>Cashflow Statement</Link>
                    
                </div>



                    <div className='productServList'>
                        <div className='dbTopsec pb-4 pt-2 noBackbtn'>
                            <Headtxt link="" title="Balance Sheet" />

                            <div className='custButton'>
                                {/* <button className='commonButton addBtn'><Link to="/addproductservice"><i class="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp; */}
                                <button className='commonButton expBtn'><i class="fa-light fa-upload"></i> Export</button>
                            </div>
                        </div>


                        <div className='commonWhite addCustomerForm mb-3 p-3'>
                            <h6>Financial Year</h6>
                        <Form>
                                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                    <Col md="5" className='mb-3'>
                                    <Customdropdown options={options} placeholdr="All Accounts"/>
                                    </Col>
                                    <Col md="5" className='datepickr mb-3'>
                                        <DatePicker
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            dateFormat="dd/MM/yyyy" // You can customize the date format
                                            isClearable
                                            placeholderText="Date"
                                        />
                                    </Col>
                                    <Col md="2" className='datepickr'>
                                        <button className='commonButton expBtn'>Apply</button>
                                    </Col>
                                    
                                </Form.Group>                                

                                </Form>
                        </div>
                        <div className='cash'>
                            <div className='cashblock'>
                                <div className='cashtag'>Cash and Bank</div>
                                <div className='cashTxt'>$2,094.82 &nbsp;<i class="fa-duotone fa-square-plus"></i></div>
                            </div>
                            <div className='cashblock'>
                                <div className='cashtag'>To be received</div>
                                <div className='cashTxt'>$27,300.00 &nbsp;<i class="fa-duotone fa-square-plus"></i></div>
                            </div>
                            <div className='cashblock'>
                                <div className='cashtag'>To be paid out</div>
                                <div className='cashTxt'>$1,399.75 &nbsp;</div>
                            </div>
                            <div className='cashblock'>
                                <div className='cashtp'>
                                    <i class="fa-solid fa-equals"></i>
                                    <h2>$27,995.07</h2>
                                </div>
                            </div>
                        </div>
                        <div>
                              <div className='salesOrdTable'>
                                    <div className='listViews'>
                                        <table class="table balanceSheet"> 
                                            {/* <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Debit</th>
                                                    <th scope="col">Credit</th>
                                                    <th scope="col">Balance</th>
                                                </tr>
                                            </thead> */}
                                            <tbody>
                                                <tr>
                                                  <td className='actTop'>Accounts</td>
                                                  <td className='actTop sheetdate'>Dec 07, 2023</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Cash and Bank</td>
                                                    <td>$2,094.82</td>                                                   
                                                </tr>
                                                <tr>
                                                    <td>Total Other Current Assets</td>
                                                    <td>$27,300.00</td>                                                   
                                                </tr>                                                
                                                <tr>
                                                    <td>
                                                      <h6>Total Assets</h6>                                                      
                                                    </td>
                                                    <td><strong>$29,394.82</strong></td>                                                    
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                              </div>
                        </div>



                    </div>
                </div>
            </Sidebar>




            
        </div>
    )
}


export default Balancesheet