import webApi, { baseURL } from "../../WebApi/WebApi";

export const getSubscriptionPlanList = async (data) => {
    return await webApi.get(`planview`, {

    });
}


export const getSubscriptionPayment = async (planId, onSuccess, onFailure) => {
    console.log(planId,"planId")
    try {
        const res = await webApi.post(
            `/subscription_submit`, 
            {
                "default_price" : planId,
            }
        );
        if (res.status === 200) {
            const r = res.data;
            return onSuccess(r);
        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};