import React from 'react';
import bnr from '../../images/bnr.png';
import { useNavigate } from 'react-router-dom';

const Banner = () => {
    const navigate = useNavigate();

    const onStart=()=>{
        navigate("/signup")
        console.log("start")
    }
  return (
    <section className='bannerBg'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <h1>Simplified and seamless accounting, just a click away!</h1>
                    {/* <p>Rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quo tinctio. </p>
                    <p><strong>Rerum facilis est et expedita distinctio. Nam libero tempore...</strong></p> */}
                    <p>With 1SimpleClick, streamline your financial management with our user-friendly accounting system suitable for all levels of expertise. Access quick and timely reports to gain real-time insights into your financial health.</p>
                    <button className='commonButton getStartbtn' onClick={onStart}><i class="fa-regular fa-paper-plane"></i> Get Started Now</button>
                </div>
                <div className='col-lg-6'>
                    <img src={bnr} alt='' />
                </div>
            </div>
        </div>
    </section>
  )
}

export default Banner