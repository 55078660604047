import { React, useState, useEffect } from 'react'
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import bclogo from '../../../images/bclogo.png';
import cals from '../../../images/cals.png';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { ToastContainer, toast } from 'react-toastify';
import '../../../../assets/css/style.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Pagination from 'react-bootstrap/Pagination';
import { getQuotationList, getQuotationCancelResend,getExcelQuote,getDateSearch, getdeleteQuotation } from "../../../../Service/Quotation/QuotationService";
import { CSVLink } from 'react-csv';
// import Headtxt from '../Headtxt';
import moment from "moment"
// import Listview from '../../sales/Listview';
// import Gridview from '../../sales/Gridview';
import Card from 'react-bootstrap/Card'
import Headtxt from '../../dbstructure/Headtxt';
import Swal from 'sweetalert2';
import getSymbolFromCurrency from 'currency-symbol-map'
const Quotelisting = ({setIsSpinner}) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [shows, setShows] = useState(false);
    const handleShowinv = () => {
        setShows(true);
        setShowsvat(false);
    }
    const handleCloseinv = () => {
        setShows(false);
        setShowsvat(true);
    }

    const [showsvat, setShowsvat] = useState(false);
    const handleClosevat = () => setShowsvat(false);


    const [querySearch, setQuerySearch] = useState('');
    const [selectedPage, setSelectedPage] = useState('/');
    const navigate = useNavigate();

    const handlePageChange = (page) => {
        setSelectedPage(page);
        navigate(page);
    };


    const [supply, setSupply] = useState(false)
    const toggleCompany = (e) => {
        e.preventDefault();
        setSupply(false)
    }
    const toggleIndividual = (e) => {
        e.preventDefault();
        setSupply(true)
    }


    const [view, setView] = useState(false)
    const toggleHandler = (e) => {
        e.preventDefault();
        setView(true)
    }
    const toggleHandler1 = (e) => {
        e.preventDefault();
        setView(false)
    }


    // custom-select-drupdown
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('More');

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    const handleGeneratePurchase = (quotation) => {
        // Navigate to another page and transfer the quotation data
        // For example, navigate to "/generatepurchase" and pass the quotation data as a query parameter
        navigate(`/addpurchaseorder?id=${quotation.id}`, { state: { quotations } });
    };
   const handleEditQuote = (quotation) => {
    console.log('Quotation:', quotation); // Check if the quotation data is available
    navigate('/quotedetails');
    if (quotation) {
        console.log('Navigating to quotedetails page...'); // Check if navigation is triggered
        
    } else {
        console.log('Quotation data is undefined or null.');
    }
};
    // FOR-LOAD-MORE
    const [quotations, setQuotation] = useState([]);
    const [visibleItems, setVisibleItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const [quoteExport,setQuoteExport]=useState([]);
    console.log(quotations, "Companyyyyyyyyyyyyyyyyyyyyyy")
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [formatedfromDate, setformatedFromDate] = useState(new Date());
    const [formatedtoDate, setformatedToDate] = useState(new Date());
      const handleFromDateChange = (e) => {
        const value  = e;
        setFromDate(value);
        triggerSearch(querySearch, e, toDate);
      };
    
      const handleToDateChange = (e) => {
        const value  = e;
        setToDate(value);
        triggerSearch(querySearch, e, fromDate);
      };
      useEffect(() => {
        getSearchList();
    }, [fromDate, toDate]);
    
    const onExcelExport = () => {
        getExcelQuote().then(function (result) {
            const response = result?.data?.data
            console.log(response,"response")
            setQuoteExport(response);
        });
    
    }
    
    const [hasMore, setHasMore] = useState(true);
    useEffect(() => {
        // Initial load of items
        loadMoreItems();
    }, []);
    
    const loadMoreItems = () => {
        if (!hasMore || loading) return;
        setLoading(true);
        setTimeout(() => {
            const newItems = fetchMoreItems(visibleItems);
            if (newItems.length > 0) {
                //setItems((prevItems) => [...prevItems, ...newItems]);
                setVisibleItems((prev) => prev + newItems.length);
            } else {
                setHasMore(false);
            }
            setLoading(false);
        }, 1000); // Adjust the delay as needed
    };
    
    const handleScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop >=
            document.documentElement.offsetHeight - 90
        ) {
            // Auto-load more items when scrolling close to the bottom
            loadMoreItems();
        }
    };
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [loading, hasMore]);
    
    const fetchMoreItems = (start) => {
        // Simulate fetching more items from an API
        const itemsPerPage = 5;
        const totalItems = 10; // Adjust as needed for your use case
        const newItems = [];
    
        for (let i = start; i < start + itemsPerPage; i++) {
            if (i < totalItems) {
                newItems.push(`Item ${i + 1}`);
            }
        }
    
        return newItems;
    };

    // DATE-PICKER
    const [selectedDate, setSelectedDate] = useState(null);
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    // RANGE-DATEPICKER
    const [dateRange, setDateRange] = useState([null, null]);

    const handleDateChangeb = (dates) => {
        // Set the time to midnight (00:00:00) to avoid time-related issues
      const midnightDates = dates.map(date => (date ? new Date(date.setHours(0, 0, 0, 0)) : null));
     //  const midnightDates= moment(new Date(dates)).format("DD/MM/YYYY")
        setDateRange(midnightDates);
    };


    // For toggle
    const [isToggled, setToggled] = useState(false);

    const handleToggleOn = () => {
        setToggled(true);
    };
    const handleToggleOff = () => {
        setToggled(false);
    };

    useEffect(() => {
        getQuotationDataList();
        onExcelExport();
       // getSearchList()
    }, []);

    async function getQuotationDataList() {
        getQuotationList("").then(function (result) {
            const response = result?.data;
       console.log(response, "rrrr")
            setQuotation(response?.data);
        });
    }
    
    const getSearchList = () => {
        // Construct the filter criteria based on fromDate and toDate
        const filterCriteria = {
            fromDate: fromDate ? moment(fromDate).format('DD-MM-YYYY') : '',
            toDate: toDate ? moment(toDate).format('DD-MM-YYYY') : ''
        };
        console.log('filter---', filterCriteria);
        const queryString = new URLSearchParams(filterCriteria).toString();
        console.log(queryString, 'cre');
        getDateSearch(queryString).then(function (result) {
            const response = result?.data;
            console.log('resultttt---', response);
            setQuotation(response?.data);
        });
    };
    // const ondateSearch = (e) => {
    //     getQuotationList(e.target.value).then(function (result) {
    //         const response = result?.data;
    //         // console.log(response, "rrrr")
    //         setQuotation(response?.data);
    //     });
    // }
    const onSearch = (e) => {
        const query = e.target.value;
        setQuerySearch(query);
        triggerSearch(query, fromDate, toDate);
    };

    const triggerSearch = (query, from, to) => {
        const filterCriteria = {
            query,
            fromDate: from ? moment(from).format('DD-MM-YYYY') : '',
            toDate: to ? moment(to).format('DD-MM-YYYY') : '',
           
        };
        const queryString = new URLSearchParams(filterCriteria).toString();
        const finalQueryString = query ? `searchText=${query}&${queryString}` : `searchText=&${queryString}`;
        console.log(queryString,'queryString')
        getDateSearch(finalQueryString).then(function (result) {
            const response = result?.data;
            console.log('resultttt---', response);
            setQuotation(response?.data);
        });
    };


    const onCancel = (id) => {
        setIsSpinner(true)
        getQuotationCancelResend(id, "cancel",
            (r) => {
                console.log(r, "response")
                // if (r.success === true) {
                //     toast.success(r.message)
                //     getQuotationDataList();

                // }
                if (r.success === true) {
                    setIsSpinner(false)
                    Swal.fire({
                     title: r.message,
                   //  text: 'The product has been added successfully!',
                     icon: 'success',
                     confirmButtonText: 'OK',
                     customClass: {
                         confirmButton: 'my-confirm-button'
                     }
                 }).then(() => {
                   
                    getQuotationDataList();
                 })
               }   
                else {
                    console.log("nononononono")
                    toast.error(r.message)
                    setIsSpinner(false)
                }
            })
    }

    const onResend = (id) => {
        setIsSpinner(true)
        getQuotationCancelResend(id, "resend",
            (r) => {
                console.log(r, "rererererere")
                // if (r.success === true) {
                //     toast.success(r.message)
                //     getQuotationDataList();

                // } 
                if (r.success === true) {
                    setIsSpinner(false)
                    Swal.fire({
                     title: r.message,
                   //  text: 'The product has been added successfully!',
                     icon: 'success',
                     confirmButtonText: 'OK',
                     customClass: {
                         confirmButton: 'my-confirm-button'
                     }
                 }).then(() => {
                    getQuotationDataList();
                 })
               } 
                else {
                    console.log("nononononono")
                    toast.error(r.message)
                    setIsSpinner(false)
                }
            })
    }
    const onDelete = (id) => {
        setIsSpinner(true)
        getdeleteQuotation(id,
            (r) => {
                console.log(r, "rererererere")
                // if (r.success === true) {
                //     toast.success(r.message)
                //     getQuotationDataList();
    
                // }
                if (r.success === true) {
                    setIsSpinner(false)
                    Swal.fire({
                     title: r.message,
                   //  text: 'The product has been added successfully!',
                     icon: 'success',
                     confirmButtonText: 'OK',
                     customClass: {
                         confirmButton: 'my-confirm-button'
                     }
                 }).then(() => {
                    getQuotationDataList();
                 })
               }   
                else {
                    console.log("nononononono")
                    toast.error(r.message)
                    setIsSpinner(false)
                }
            })
    }
const onStatus=(e)=>{
    console.log(e.target.value,"babababababa")
    getQuotationList(e.target.value).then(function (result) {
        const response = result?.data;
        console.log(response, "newrrrr")
        setQuotation(response?.data);
    });
   

}

    return (
        <div className="producservlisting pdt">
            <Dbheader />
            <Sidebar>
                <div className='customerMain'>
                    <div className='custTop'>
                        <Link to='/supplierlisting'>Supplier</Link>
                        <Link to='/productservicelistings'>Product &amp; Service</Link>
                        <Link to='/quotelisting' className='active'>Quote</Link>
                        <Link to='/purchaseorderlisting'>Purchase Order</Link>
                        <Link to='/bill'>Bill</Link>
                        <div className="custom-dropdown-container customDropdown ms-4">
                            <div className="custom-dropdown-header" onClick={toggleDropdown}>
                                <span>{selectedOption}</span>
                                <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}> <i className="fa-regular fa-angle-down"></i></span>
                            </div>

                            {isOpen && (
                                <ul className="custom-dropdown-options">
                                    <li
                                        className={`option ${selectedOption === 'Option 1' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 1')} hidden
                                    >
                                        Option 1
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 2' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 2')}
                                    >
                                        <Link to="/billpayment"><i className="fa-light fa-calendar-lines-pen"></i> Bill Payment</Link>
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 4' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 4')}
                                    >
                                         <Link to="/purchaserefundlist"><i className="fa-light fa-calendar-lines-pen"></i> Refunds</Link>
                                    </li>
                                   
                                </ul>
                            )}

                            {/* <p>You selected: {selectedOption}</p> */}
                        </div>
                    </div>
                    <div className='productServList'>
                        <div className='dbTopsec pb-2 pt-2 noBackbtn'>
                            <Headtxt link="" title="Quote" />

                            {/* <div className='custButton'>
                                <button className='commonButton addBtn'><Link to="/addproductservice"><i className="fa-regular fa-circle-plus"></i> Add Product</Link></button>&nbsp;&nbsp;
                                <button className='commonButton expBtn'><i className="fa-light fa-download"></i> Export Excel</button>
                            </div> */}
                        </div>

                        <div className='customerSrchTop pb-3 poResponsive'>
                            <div className='customSrchfield'>
                                <div>
                                    <span><i className="fa-solid fa-magnifying-glass"></i></span><input type='search' placeholder='Search by keywords' onChange={onSearch} value={querySearch}/>
                                </div>&nbsp;&nbsp;
                              
                                <div className='invDatepicker'>
                                     <DatePicker
                    selected={fromDate}
                    onChange={handleFromDateChange}
                    selectsStart
                    startDate={fromDate}
                    endDate={toDate}
                    placeholderText="From Date"
                    isClearable={fromDate?true:false}
                />
                 </div>
                   <div className='invDatepicker' style={{ marginLeft: '10px' }}>
                <DatePicker
                    selected={toDate}
                    onChange={handleToDateChange}
                    selectsEnd
                    startDate={fromDate}
                    endDate={toDate}
                    placeholderText="To Date"
                    isClearable={toDate?true:false}
                    minDate={fromDate}
                    
                />
                             {/* <label htmlFor="fromDate">From Date:</label> */}
                            
       
            {/* <input
              type="date" 
              id="fromDate" 
              value={fromDate} 
              onChange={handleFromDateChange} 
              placeholder={!fromDate ? 'Select From Date' : undefined}
            />
       

      {/* <label htmlFor="toDate">To Date:</label> */}
                    
                {/*  <input type="date" id="toDate" value={toDate} onChange={handleToDateChange} style={{marginLeft:10}} placeholder="Select From Date" /> */}
                                </div>
                            </div>
                            <div className='custButton flexfield'>
                                <Link to='/addsupplierquote' className='commonButton addBtn'><i className="fa-regular fa-circle-plus"></i> Create Quote</Link>&nbsp;&nbsp;
                                {/* <button className='commonButton expBtn' onClick={getSearchList}>Export</button> */}
                                <CSVLink data={quoteExport} filename={"quotations.csv"}>
                                     <button className='commonButton expBtn'><i class="fa-light fa-upload"></i> Export</button>
                                     </CSVLink>
                           </div>
                        </div>


                        {/* <div className='addsuppBtn'>
                            <button className={`${!supply ? "commonButton active" : "commonButton "} `} onClick={toggleCompany}>Product</button>
                            <button className={`${supply ? "commonButton active" : "commonButton "} `} onClick={toggleIndividual}>Service</button>
                        </div> */}
                        <div className='mt-2'>
                            <div className='gridList mb-3 flexfield gridListResp' style={{ justifyContent: "flex-end" }}>
                                <div className='srtBy'>
                                    <label><i className="fa-regular fa-filter"></i> Filter: </label>&nbsp;
                                    <Form.Select aria-label="Default select example" className='sortby' onChange={onStatus}>
                                    <option value="">Select Status</option>                                        
                                        <option value="Not Ordered">Not Ordered</option>
                                        {/* <option value="Ordered">Ordered</option> */}
                                        <option value="Billed">Billed</option>
                                        <option value="Not Billed">Not Billed</option>
                                        <option value="Cancelled">Cancelled</option>
                                        <option value="Paid">Paid</option>
                                        <option value="Partially Paid">Partially Paid</option>
                                    </Form.Select>&nbsp;
                                </div>
                                <div>
                                    <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler1} className={!view ? "listactive" : ""}><i className="fa-solid fa-list"></i></button>
                                    <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler} className={view ? "listactive" : ""}><i className="fa-solid fa-grid"></i></button>
                                </div>
                            </div>
                            {!view ?
                                <div className='listViews'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Quote Date</th>
                                                <th scope="col">Quote ID</th>
                                                <th scope="col">Supplier</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Due Date</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {quotations?.length != 0 && quotations?.slice(0, visibleItems)?.map((item, index) => (
                                                <tr key={index} text={item}>
                                                    <td>{item?.date}</td>
                                                    <td><Link to={`/viewquotedetails?id=${item.id}`}> {item?.quote_id}</Link></td>
                                                    <td>{item?.type == "Company" ? item?.company_name : item?.fname + " " + item?.lname} </td>
                                                    <td>{item?.supplier_currency}{Number(item?.total).toFixed(2)}</td>
                                                    <td>{item?.expiration_date}</td>
                                                    <td>
                                                    <span className='pending' style={{
    backgroundColor: item?.status === 'Cancelled' ? '#f2b8be' :
                     item?.status === 'Not Ordered' ? '#f8d5c9' :
                     item?.status === 'Paid'||item?.status === 'Billed' ? '#d7f4b2' :
                     item?.status === 'Partially Paid' ? '#cfdcf8' :
                   //  item?.status === 'Billed' ? '#ce7ac' :
                     item?.status === 'Unpaid' || item?.status === 'Not Billed' ? '#f0caee' : '',
    color: item?.status === 'Cancelled' ? '#e51c0e' :
           item?.status === 'Partially Paid' ? '#1650ce' :
           item?.status === 'Not Ordered' ? '#FF6C3C' :
           item?.status === 'Paid' ||item?.status === 'Billed'? '#638D2C' :
          // item?.status === 'Billed' ? '#ce7ac' :
           item?.status === 'Unpaid' || item?.status === 'Not Billed' ? '#e61cdf' : ''
}}>
    {item?.status}
</span>
</td>
                                                    <td>
                                                        <div className='tdFlex'>
                                                            {/* <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span> */}
                                                            {item?.status =='Not Ordered'?  <span className='viewIcon orange'>
                                                             
                                                                <Link to={`/quotedetails?id=${item.id}`} ><i className="fa-light fa-pen"></i></Link></span>:" "}
                                                            {item?.status === 'Cancelled' || item?.status === 'Paid' || item?.status === 'Partially Paid' || item?.status==='Billed' || item?.status==='Not Billed' ? <span className='viewIcon' style={{borderRight: "0"}} onClick={() => onDelete(item?.id)}><i className="fa-regular fa-trash-can"></i></span>:<span className='viewIcon' style={{marginRight: "10px"}} onClick={() => onDelete(item?.id)}><i className="fa-regular fa-trash-can"></i></span>}
                                                            {!(item?.status === 'Cancelled' || item?.status === 'Paid' || item?.status === 'Partially Paid' || item?.status==='Billed' || item?.status==='Not Billed') &&
                                                            <Dropdown className='dropRow'>
                                                                <Dropdown.Toggle id="dropdown-basic">
                                                                    {
                                                                        item?.status =='Not Ordered' ?
                                                                            <i className="fa-light fa-ellipsis-vertical"></i> : ""}
                                                                </Dropdown.Toggle>


                                                                <Dropdown.Menu>
                                                                    {item?.status =='Not Ordered'?
                                                                    <Dropdown.Item onClick={() => onCancel(item?.id)}><i className="fa-regular fa-circle-xmark"></i> &nbsp; Cancel</Dropdown.Item>:''}
                                                                    <Dropdown.Item onClick={() => onResend(item?.id)}><i className="fa-light fa-share"></i> &nbsp; Resend</Dropdown.Item>
                                                                    {item?.status =='Not Ordered'? <Dropdown.Item onClick={() => handleGeneratePurchase(item)}><i className="fa-light fa-share"></i> &nbsp; Generate Order</Dropdown.Item>:''}
                                                                    <Dropdown.Item onClick={e=>{ navigate(`/viewquotedetails?id=${item.id}`)}}><Link to={`/viewquotedetails?id=${item.id}`}><i className="fa-light fa-eye"></i></Link>&nbsp; View Quote Details</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                          }
                                                        </div>
                                                    </td>
                                                </tr>

                                            ))}
                                        </tbody>
                                    </table>

                                    {loading ?
                                    (
                                        <div className='loadItems'>                
                    <button className="commonButton addBtn" disabled>
                        Loading...
                    </button>
                    </div>
                ) : hasMore ? (
                    <div className='loadItems'>
                    <button onClick={loadMoreItems} className="commonButton addBtn">
                    No More Quote
                    </button>
                    </div>
                ) : (
                    <div className='loadItems'>
                    <button className="commonButton addBtn" disabled>
                        No More Quote
                    </button>
                    </div>
                )
                }

                                </div>
                                :
                                <div className='gridView'>
                                    {quotations?.map((item) => (

                                        <Card>
                                            <Card.Body>
                                                <div className='commonFlex'>
                                                    <h6>{item?.quote_id}</h6>
                                                    <span><img src={cals} alt='' className='mb-2' /> {item?.date}</span>
                                                </div>
                                                <div className='commonFlex'>
                                                    <span className='customr'>{item?.type === "Company" ? item?.company_name : item?.fname + " " + item?.lname}</span>
                                                    <span>&nbsp;</span>
                                                </div>
                                                <div className='commonFlex'>
                                                    <span className='pending'style= {{
    backgroundColor: item?.status === 'Cancelled' ? '#f2b8be' :
                     item?.status === 'Not Ordered' ? '#f8d5c9' :
                     item?.status === 'Paid'||item?.status === 'Billed' ? '#d7f4b2' :
                     item?.status === 'Partially Paid' ? '#cfdcf8' :
                   //  item?.status === 'Billed' ? '#ce7ac' :
                     item?.status === 'Unpaid' || item?.status === 'Not Billed' ? '#f0caee' : '',
    color: item?.status === 'Cancelled' ? '#e51c0e' :
           item?.status === 'Partially Paid' ? '#1650ce' :
           item?.status === 'Not Ordered' ? '#FF6C3C' :
           item?.status === 'Paid' ||item?.status === 'Billed'? '#638D2C' :
          // item?.status === 'Billed' ? '#ce7ac' :
           item?.status === 'Unpaid' || item?.status === 'Not Billed' ? '#e61cdf' : ''
}}>{item?.status}</span>
                                                    <h6>{item?.total}</h6>
                                                </div>
                                                <div className='commonFlex gridFooter'>
                                                    <div>
                                                        <span className='viewIcon orange'><Link to={`/quotedetails?id=${item.id}`}><i className="fa-light fa-pen"></i></Link></span>
                                                        <span className='viewIcon' onClick={() => onDelete(item?.id)}><i className="fa-regular fa-trash-can"></i></span>
                                                    </div>
                                                    <div className='invgridicn'>
                                                        {/* <Link to=""><i className="far fa-envelope"></i></Link>&nbsp; */}
                                                        <div className='tdFlex'>
                                                            {/* <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span> */}
                                                            {/* <span className='viewIcon orange'><Link to={`/`}><i className="fa-light fa-pen"></i></Link></span>
                                                            <span className='viewIcon'><i className="fa-regular fa-trash-can"></i></span>&nbsp;&nbsp; */}
                                                            <Dropdown className='dropRow'>
                                                                <Dropdown.Toggle id="dropdown-basic">
                                                                    <i className="fa-light fa-ellipsis-vertical"></i>
                                                                </Dropdown.Toggle>

                                                              
                                                                <Dropdown.Menu>
                                                                    {item?.status =='Not Ordered'?
                                                                    <Dropdown.Item onClick={() => onCancel(item?.id)}><i className="fa-regular fa-circle-xmark"></i> &nbsp; Cancel</Dropdown.Item>:''}
                                                                    <Dropdown.Item onClick={() => onResend(item?.id)}><i className="fa-light fa-share"></i> &nbsp; Resend</Dropdown.Item>
                                                                    {item?.status =='Not Ordered'? <Dropdown.Item onClick={() => handleGeneratePurchase(item)}><i className="fa-light fa-share"></i> &nbsp; Generate Order</Dropdown.Item>:''}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className='cardBlock actn'>
                                                <div>
                                                    <span className='viewIcon'><Link to={`/customerdetails`}><i className="fa-light fa-eye"></i></Link></span>
                                                    <span className='viewIcon orange'><Link to={`/editcustomer`}><i className="fa-light fa-pen"></i></Link></span>
                                                    <span className='viewIcon'><i className="fa-regular fa-trash-can"></i></span>
                                                </div>
                                            </div> */}
                                            </Card.Body>
                                        </Card>
                                    ))}

                                </div>
                            }

                        </div>
                    </div>
                </div>
            </Sidebar>

            <Modal size="lg" show={shows} onHide={handleCloseinv} centered className='customerModal sloModal civModal'>
                <Modal.Header closeButton>
                    <Modal.Title className='px-5'>
                        <h5 className='mb-2'>What is the information to put on the invoices?</h5>
                        <p>Invoice numbers occur in a continuing sequence. What is the last Invoice number that
                            you have sent a customer?</p>
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                            <Col md="6">
                                <span className='unAtr'>*</span>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Invoice Prefix"
                                    className="mb-3"
                                >
                                    <Form.Control type="text" placeholder="Invoice Prefix" />
                                </FloatingLabel>
                            </Col>
                            <Col md="6">
                                <Form.Control type="text" placeholder="001" />
                            </Col>
                        </Form.Group>
                    </Form>


                </Modal.Body>
                <Modal.Footer>
                    <button className='commonButton expBtn' onClick={handleCloseinv}>Next</button>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showsvat} onHide={handleClosevat} centered className='customerModal sloModal civModal vatModal'>
                <Modal.Header>
                    <Modal.Title className='px-5'>
                        <h5 className='mb-2'>Does your company collect VAT?</h5>&nbsp;
                        <div className='radioline yesNo'>
                            <input type="radio" checked id="yes" name="radio-group" onClick={handleToggleOff} />
                            <label for="yes">Yes</label>
                            <input type="radio" id="no" name="radio-group" onClick={handleToggleOn} />
                            <label for="no">No</label>
                        </div>
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                            <Col md="12">
                                <h6>Accounting basis</h6>
                                <div className='radioline invBasis'>
                                    {/* <span>Use same address for delivery</span> */}
                                    <div className='invLft'>
                                        <input type="radio" id="test1" name="radio-group" onClick={handleToggleOff} />
                                        <label for="test1">Cash</label>
                                    </div>
                                    <div className='invrgt'>
                                        <input type="radio" id="test2" name="radio-group" onClick={handleToggleOn} />
                                        <label for="test2">Accural</label>
                                    </div>
                                </div>
                            </Col>

                        </Form.Group>
                        <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                            <Col md="6">
                                <label>VAT registration number</label>
                                <Form.Control type="text" placeholder="Enter VAT registration number" />
                            </Col>
                        </Form.Group>
                    </Form>


                </Modal.Body>
                <Modal.Footer>
                    <button className='backBtn' onClick={handleShowinv}><i className="fa-light fa-circle-arrow-left"></i> Back</button>
                    <button className='commonButton expBtn' onClick={handleClosevat}><Link to="/newinvoicecreation">Next</Link></button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Quotelisting