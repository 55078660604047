import React from 'react';
import homevideo from '../../images/videohome.png';

const Videosection = () => {
  return (
    <div className='homeVideo'>
        <h2>Everything you need in<br/> 1SimpleClick</h2>
        <h6>You are supported by professionals who speak your language to give you competitive advantage.</h6>
        <div className='videoBase'>
            <img src={homevideo} alt='' />
        </div>
    </div>
  )
}

export default Videosection