import React, { useState, useMemo } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import Usr from '../../images/usr.png';
// import Lock from '../../images/lock.png';
import Lock from '../../images/lock.png';
import Env from '../../images/envelop.png';
import Case from '../../images/suitcase.png';
import Call from '../../images/call.png';
import Pin from '../../images/pin.png';
import Coin from '../../images/coin.png'
import Watermark from '../../images/watermark.png';
import regdtemplate from '../../images/regdtemplate.png';
import Form from 'react-bootstrap/Form';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { toast } from 'react-toastify';
import { getRegister } from "../../../Service/Signup/SignupService";
import { useNavigate } from 'react-router-dom';
import CurrencySelect from 'react-select-currency';
const Signup = (props) => {

  
  const [value, setValue] = useState('')
  const [buttonChange, setButtonChange] = useState(false)
  const [selectedCurrency, setSelectedCurrency] = useState('KES')
  const options = useMemo(() => countryList().getData(), [])
  const currencyoptions = [
    { value: 'CAD', label: 'CAD' },
    { value: 'KES', label: 'KES' },
    { value: 'SOS', label: 'SOS' }
  ];
  const changeHandler = value => {
    setValue(value)
  }

  const { logo } = props;
  const [phone, setPhone] = useState('');
  const [showpassword, setShowPassword] = useState(false);
  const [showconfirmpassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const handleCurrencyChange = (selectedOption) => {
    const currency = selectedOption.target.value;
    setSelectedCurrency(currency);
  }

  console.log('selectedcurrency',selectedCurrency)
  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      companyname: '',
      email: '',
      phone: '',
      country: 'Kenya',
    //  currency: '',
      password: '',
      confirmpassword: ''
    },
    validationSchema: yup.object({
      firstname: yup.string().min(2, "Name must have atleast 2 chracter").required("First Name is a required field"),
      lastname: yup.string().min(2, "Name must have atleast 2 chracter").required("Last Name is a required field"),
      companyname: yup.string().min(5, "Name must have atleast 5 chracter").required("Company Name is a required field"),
      email: yup.string().email().required("Email is a required field"),
      country: yup.string().required("Select a Country"),
     // currency: yup.string().required("Select a Currency"),
      phone: yup.string().min(11, "Phone Number is a required field").required("Phone Number is a required field"),
      password: yup.string().min(6, "password must have atleast 6 chracter").required("Password is a required field"),
      confirmpassword: yup.string().required("Confirm Password is a required field").oneOf([yup.ref('password'), null], 'Passwords do not match'),
      // confirmpassword: yup.string().oneOf([yup.ref('password'), null], 'Must match "password" field value'),

    }),



    onSubmit: async (values, { resetForm }) => {
      // login(values);
      setButtonChange(true)
      console.log('kkkk==')
      await getRegister(values.firstname, values.lastname, values.companyname,
        values.email, values.phone, values.country,selectedCurrency, values.password, values.confirmpassword, (res) => {
       console.log(res,'kkkk')
          if (res.success === true) {
            setButtonChange(false)

            // setAuthed({isAuth: true, token: res.data.token, user: userdata})
            toast.success(res.message)
            setValue("")
            setPhone("")

            navigate("/login");

            formik.resetForm();
          } else {
            setButtonChange(false)
            toast.error(res.message)
            
          }
        })
      // resetForm({values: ""})
    }

  })

  console.log(formik.values, "phoneeeeeeeeeeee")


  const renderFirstNameError = formik.touched.firstname && formik.errors.firstname && (<span className='errorTxt' style={{ color: "red" }}>{formik.errors.firstname}</span>);

  const renderLastNameError = formik.touched.lastname && formik.errors.lastname && (<span className='errorTxt' style={{ color: "red" }}>{formik.errors.lastname}</span>);

  const renderCompanyNameError = formik.touched.companyname && formik.errors.companyname && (<span className='errorTxt' style={{ color: "red" }}>{formik.errors.companyname}</span>);

  const renderEmailError = formik.touched.email && formik.errors.email && (<span className='errorTxt'>{formik.errors.email}</span>);

  const renderCountryError = formik.touched.country && formik.errors.country && (<span className='errorTxt'>{formik.errors.country}</span>);

  const renderCurrencyError = formik.touched.currency && formik.errors.currency && (<span className='errorTxt'>{formik.errors.currency}</span>);
 
  const renderPhoneError = formik.touched.phone && formik.errors.phone && formik.values.phone > 5 && (<span className='errorTxt'>{formik.errors.phone}</span>);

  const renderPasswordError = formik.touched.password && formik.errors.password && (<span className='errorTxt'>{formik.errors.password}</span>);

  const renderConfirmPasswordError = formik.touched.confirmpassword && formik.errors.confirmpassword && (<span className='errorTxt'>{formik.errors.confirmpassword}</span>);



  // FOR-GET-LOGIN-RESPONSE
  const url = "https://reqres.in/api/users";

  const login = async (data) => {
    const response = await axios.post(url, data)
    if ([200, 201, 202, 203, 204].includes(response.status)) {
      toast.success("Successfully");
    }
    else {
      toast.success("Error");
      console.log(response, "somnath")
    }

  }


  return (
    <div className='authBg pt-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-7 regdLeft'>
            <div className='regdLogn mb-3'><Link to="/"><img src={logo} /></Link></div>
            {/* <p>Join <span>700,000+</span> business owners in 50 countries who use OneUp to automate accounting, inventory, invoicing and more, on any device at any time.</p>
            <p><strong>So you can focus on what you love.</strong></p> */}
            <p>Transform the way you run your business with 1SimpleClick – where new ideas and trust come together. Improve your business using our advanced and safe online accounting system that uses the latest technology and smart innovation. Count on us to help your business grow by managing your finances in a secure and efficient way.</p>
            {/* <div className='watermrk'><img src={Watermark} /></div> */}
            <div className='regdTemplate mb-4'><img src={regdtemplate} /></div>
          </div>
          <div className='col-lg-5'>
            <div className='signForm m-0'>
              <div className='signFormbase'>
                <div className='signHdn noBordr pb-0 pt-2'>Sign Up</div>
                <div className='signMainform register'>
                  <form onSubmit={buttonChange ? formik.handleSubmit : formik.handleSubmit}>
                    <div className='fieldBlock mb-2'>
                      <label htmlFor="name"><img src={Usr} />&nbsp;</label>
                      <input type="text" id="firstname" className='form-control' name="firstname" onChange={formik.handleChange} value={formik.values.firstname} placeholder='First Name' />
                      {renderFirstNameError}
                    </div>

                    <div className='fieldBlock mb-2'>
                      <label htmlFor="name"><img src={Usr} />&nbsp;</label>
                      <input type="text" id="lastname" className='form-control' name="lastname" onChange={formik.handleChange} value={formik.values.lastname} placeholder='Last Name' />
                      {renderLastNameError}
                    </div>

                    <div className='fieldBlock mb-2'>
                      <label htmlFor="name"><img src={Case} />&nbsp;</label>
                      <input type="text" id="companyname" className='form-control' name="companyname" onChange={formik.handleChange} value={formik.values.companyname} placeholder='Company Name' />
                      {renderCompanyNameError}
                    </div>

                    <div className='fieldBlock mb-2'>
                      <label><img src={Env} />&nbsp; </label>
                      <input type="email" id="email" name="email" className='form-control' placeholder='Email address' onChange={formik.handleChange} value={formik.values.email} />
                      {renderEmailError}
                    </div>

                    <div className='fieldBlock mb-2 phoneInputDropdown' style={{zIndex: "109"}}>
                      <label><img src={Call} />&nbsp; </label>
                      <PhoneInput
                        defaultCountry="ke"
                        value={formik.values.phone}
                        onChange={(value) => formik.handleChange({ target: { name: 'phone', value } })}
                        onBlur={formik.handleBlur}
                        className='form-control'
                      />
                      {renderPhoneError}

                    </div>

                    <div className='fieldBlock mb-2 countryField' style={{zIndex: "99"}}>
                      <label><img src={Pin} />&nbsp; </label>

                      <Select options={options} value={{ value: formik?.values?.country ? formik?.values?.country : "Select Country", label: formik?.values?.country ? formik?.values?.country : "Select Country" }} onChange={(value) => {formik.handleChange({ target: { name: 'country', value: value?.label } })}} defaultValue={"KE"} />
                      {renderCountryError}
                    </div>
                    <div className='fieldBlock mb-2 countryField' >
      {/* <img src={Coin} style={{ width: '40px', height: '40px', marginRight: '10px',position:'absolute'}} alt="Coin Icon" /> */}
      <CurrencySelect
      value={selectedCurrency}
      onChange={handleCurrencyChange}
      placeholder="Select a currency"      
      className="slctCurrency"
    />
      </div>
                    <div className='fieldBlock mb-2 zindex'>
                      <label><img src={Lock} />&nbsp;</label>
                      <div className="showHide">
                        <input className="form-control" name='password' type={showpassword ? "text" : "password"} id="password" placeholder="Password" onChange={formik.handleChange} value={formik.values.password} />
                        <div className="input-group-addon eyeAbs" onClick={() => { setShowPassword(!showpassword) }}>
                        {showpassword? <span><i className="fa fa-eye" aria-hidden="true"></i></span>: <span><i className="fa fa-eye-slash" aria-hidden="true"></i></span> }
                        </div>
                        {renderPasswordError}
                      </div>
                    </div>

                    <div className='fieldBlock mb-2 zindex'>
                      <label><img src={Lock} />&nbsp;</label>
                      <div className="showHide">
                        <input className="form-control" name="confirmpassword" type={showconfirmpassword ? "text" : "password"} id="confirmpassword" placeholder="Confirm Password" onChange={formik.handleChange} value={formik.values.confirmpassword} />
                        {/* <label className="floatLft" htmlFor="confirmpassword">Conform Password</label> */}
                        <div className="input-group-addon eyeAbs" onClick={() => { setShowConfirmPassword(!showconfirmpassword) }}>
                          {showconfirmpassword? <span><i className="fa fa-eye" aria-hidden="true"></i></span>: <span><i className="fa fa-eye-slash" aria-hidden="true"></i></span> }
                        </div>
                        {renderConfirmPasswordError}
                        {/* {!renderConfirmPasswordError && cpassworderror && (<span style={{color: "red"}}>{cpassworderror}</span>)} */}
                      </div>
                    </div>



                    {/* <div className='forgotPass'><Link href='/authentication/Forgotpassword'>Forgot Password?</Link></div> */}
                    {buttonChange ?
                      <button type="button" className='commonButton buttonSubmit'>Processing...</button>
                      :
                      <button type="submit" className='commonButton buttonSubmit' >Create an account</button>
                    }
                  </form>
                </div>
              </div>
              <div className='termsTxt pb-2'>By clicking Create account you agree to the &nbsp; <Link to="/termscondition">Terms of Service</Link> and &nbsp; <Link to="/privacy">Privacy policies.</Link></div>

              <div className='accountTxt pt-0'>Already have an account? &nbsp; <Link to="/login">Log In</Link></div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Signup