const sampleCsv=[

    {
        "Type": "Company or Individual",
        "First_Name": "Jhon",
        "Last_Name": "Doe",
        "Company_Name": "Test Company",
        "Email": "test@hotmail.com",
        "Phone": "+12654125896",
        "Website_URL": "www.test.com",
        "VAT_Number": "test12345",
        "Billing_Address": "63 C,fremont road,fremont",
        "Country": "United States",
        "State": "Arizona",
        "City": "Big park",
        "Zipcode": "23CG45",
        "Delivery_Address": "63 C,fremont road,fremont",
        "Delivery_Country": "United States",
        "Delivery_State": "Arizona",
        "Delivery_City": "Big park",
        "Delivery_Zipcode": "23CG45"
    }
]

export default sampleCsv;