import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import Usr from '../../images/usr.png';
import Suitcase from '../../images/suitcase.png';
import Pin from '../../images/pin.png';
import { getLogin } from "../../../Service/Login/LoginService";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import Autocomplete from "react-google-autocomplete";
import { getProfileCreation } from "../../../Service/ProfileCreation/ProfileCreationService";
import instance from '../../../WebApi/WebApi';

const Profilecreation = (props) => {
  const { logo } = props;
  const navigate = useNavigate();
  const [showpassword, setShowPassword] = useState(false);
  const [getImage, setGetImage] = useState(null);
  const [getImageresult, setGetImageresult] = useState("")
  const [formattedAddress, setFormattedAddress] = useState("")
  const [globalAddress, setGlobalAddress] = useState("")
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [allData, setAllData] = useState(
    {
      pin: '',
      city: '',
    }
  )
  const [allDataFormated, setAllDataFormated] = useState(
    {
      address: '',

    }
  )
  const [city, setCity] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [companyUrl, setCompanyUrl] = useState("")



  const onFile = (event) => {

    if (event["target"].files.length > 0) {
      const file = event["target"].files[0];
      formik.setFieldValue("browse", file.name)

      // setUserData({...userData, profileImage : file});
      setImage({
        preview: URL.createObjectURL(event.target.files[0]),
        raw: event.target.files[0]
      });

      setGetImage(file)
      setGetImageresult(file.name)

      //     const reader = new FileReader();
      //     reader.readAsDataURL(file);
      //     reader.onload = (event) => {
      //         setGetImageresult(reader.result);
      //   };
    }

  }
  console.log(getImage, "browseeeeeeee")


  const formik = useFormik({
    initialValues: {
      browse: '',
      industry: '',
      name: '',
      address: '',
      country: '',
      state: '',
      city: '',
      zipcode: ''

    },
    validationSchema: yup.object({
      // browse: yup.string().required("Image require"),
      industry: yup.string().required("Select industry"),
      name: yup.string().min(2, "name must have atleast 2 chracter").required("Company Name is require field"),
      address: yup.string().min(2, "address must have atleast 2 chracter").required("Address is require field"),
      // country: yup.string().required("Select Country"),
      // state: yup.string().required("Select State"),
      // city: yup.string().required("Select City"),
      // zipcode: yup.string().required("Enter zipcode"),
    }),



    onSubmit: async (values, { resetForm }) => {
      console.log("hgfdsawarayaskldnklsnd.................;;;;;;;;;;;;;;;;;;kkkkkk['")
      let formData = new FormData();

      formData.append('logo', getImage);
      formData.append('industry_type', formik.values.industry);
      formData.append('company_name', formik.values.name);
      formData.append('address', formik.values.address);

      formData.append('country', globalAddress.country);
      formData.append('state', globalAddress.state);
      formData.append('city', allData?.city ? allData?.city : city);
      formData.append('zipcode', allData?.pin ? allData?.pin : zipCode);
      formData.append('website_url', companyUrl ? companyUrl : "");
     if(localStorage.getItem('1Simpleclick_AuthToken'))
   {
       try{
        const res= await instance.post(`/user-company-info-post`, formData)
        console.log("res-->",res)
        
          if (res?.data?.success === true) {
  
            // setAuthed({isAuth: true, token: res.data.token, user: userdata})
            toast.success(res?.data?.message)
  
            navigate("/tax");
            // window.location.reload();
            // toast.success(res.message)
  
            formik.resetForm();
          } else {
  
            toast.error(res.message)
  
          }

      }catch(err){
        toast.error(err?.response?.data?.message)
        console.log("error--->",err)
      }
    }
      // resetForm({ values: "" })
    }

  })
  console.log(formik.values, "valuesssssssssssssssss");


  // const browseError = !getImage && (<span style={{ color: "red" }}>{formik.errors.browse}</span>);

  const industryError = formik.touched.industry && formik.errors.industry && (<span className='errorTxt'>{formik.errors.industry}</span>);

  const renderNameError = formik.touched.name && formik.errors.name && (<span style={{ color: "red" }}>{formik.errors.name}</span>);

  const renderAddressError = formik.touched.address && formik.errors.address && (<span style={{ color: "red" }}>{formik.errors.address}</span>);

  const countryError = !globalAddress.country && formik.touched.country && formik.errors.country && (<span className='errorTxt'>{formik.errors.country}</span>);

  const stateError = !globalAddress.state && formik.touched.state && formik.errors.state && (<span className='errorTxt'>{formik.errors.state}</span>);

  const cityError = !allData.city && formik.touched.city && formik.errors.city && (<span className='errorTxt'>{formik.errors.city}</span>);

  const zipError = !allData.pin && formik.touched.zipcode && formik.errors.zipcode && (<span style={{ color: "red" }}>{formik.errors.zipcode}</span>);






  // FOR-GET-LOGIN-RESPONSE
  const url = "https://reqres.in/api/users";

  const login = async (data) => {
    const response = await axios.post(url, data)
    // const getresponse =await axios.get(url)
    // console.log(getresponse, "getResponse")
    if ([200, 201, 202, 203, 204].includes(response.status)) {
      toast.success("Successfullyholo");
    }
    else {
      toast.success("Error");
    }

  }

  const onAddress = (result) => {
    // Extracting components from the selected place details
    const addressComponents = result?.address_components || [];
    formik.setFieldValue("address", result?.formatted_address)
    setFormattedAddress(result?.formatted_address)
    // Create an object to store the extracted information
    const locationInfo = {
      country: '',
      state: '',
      city: '',
      pin: '',
    };

    // Loop through the address components to extract relevant information
    addressComponents.forEach((component) => {
      const types = component.types;

      if (types.includes('country')) {
        locationInfo.country = component.long_name;
      } else if (types.includes('administrative_area_level_1')) {
        locationInfo.state = component.long_name;
      } else if ((types?.includes('administrative_area_level_3') && types?.includes('political')) || (types?.includes('locality') && types?.includes('political'))) {
        locationInfo.city = component.long_name;
      } else if (types.includes('postal_code')) {
        locationInfo.pin = component.long_name;
      }
    });
    setGlobalAddress(locationInfo)
    setAllData(locationInfo)
    setAllDataFormated(formattedAddress)

  };

  const onZipCode = (e) => {
    setZipCode(e.target.value)
    setAllData({ ...allData, pin: e.target.value })

  }
  const onCity = (e) => {
    setCity(e.target.value)
    setAllData({ ...allData, city: e.target.value })

  }
  console.log(getImage, "getImage")

  const onCompanyUrl = (e) => {
    setCompanyUrl(e.target.value)
  }
  return (
    <div className='authBg'>
      <div className='proflogo'><Link to="/"><img src={logo} /></Link></div>
      {/* {/ <div className='authLogo'><Link to="/"><img src={logo} /></Link></div>  */}
      {/* {/ <div className='subhead'><h5>What is your company's contact<br/> information?</h5></div> /} */}
      <div className='authLogo pt-5'><h5>What is your company's contact<br /> information?</h5></div>

      <div className='signForm'>
        <form onSubmit={formik.handleSubmit}>
          <div className='signFormbase py-4'>
            {/* {/ <div className='signHdn'>Sign In</div> /} */}
            <div className='signMainform'>
 
              <div className='brwseFile'>
                <div className='upld'>
                  <label htmlFor="upload-button" className='uploadBtn'>
                    {image.preview ? (
                      <img src={image.preview} alt="dummy" width="100" height="100" />
                    ) : (
                      <>
                        <span className="fa-stack fa-2x">
                          <i class="fa-thin fa-image"></i>
                        </span>
                      </>
                    )}
                    <span className=" m-0">Upload Logo here</span>
                  </label>

                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    id="upload-button"
                    style={{ display: "none" }}
                    // onChange={handleChange}
                    onChange={onFile}
                  // onChange={formik.handleChange}
                  // onChange={formik.handleChange} value={formik.values.browse}
                  />
                  {/* {browseError} */}
                  <br />
                </div>
                <div className='brwseInst'>
                  <p>Your company logo will be used on quotes, invoices, and other printable documents.</p>
                  <p><span>Recommended file types .PNG. or .JPG less than 1MB</span></p>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='fieldBlock mb-4'>
                    <label><img src={Suitcase} />&nbsp;</label>
                    <Form.Select aria-label="Default select example" id="industry" name="industry" className='form-control' onChange={formik.handleChange} value={formik.values.industry}>
                      <option hidden selected>Select Business Industry</option>
                      <option value="Technology">Technology</option>
                      <option value="Finance">Finance</option>
                      <option value="Healthcare">Healthcare</option>
                      <option value="Retail">Retail</option>
                      <option value="Hospitality and Tourism">Hospitality and Tourism</option>
                      <option value="Manufacturing">Manufacturing</option>
                      <option value="Real Estate">Real Estate</option>
                      <option value="Entertainment and Media">Entertainment and Media</option>
                    </Form.Select>
                    {industryError}
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='fieldBlock mb-4'>
                    <label><img src={Suitcase} />&nbsp;</label>
                    <input type="text" id="name" name="name" className='form-control' onChange={formik.handleChange} value={formik.values.name} placeholder='Company name'  autoComplete="new-off"/>
                    {renderNameError}
                  </div>
                </div>
              </div>

              <div className='fieldBlock mb-4'>
                <label><img src={Pin} />&nbsp;</label>
                {/* {/ <input type="text" id="address" name="address" className='form-control' onChange={formik.handleChange} value={formik.values.address} placeholder='Address' /> /} */}

                <Autocomplete
                  apiKey="AIzaSyASsOMW6tK9Qo981eUEHdcPi3WjNcJwFQ0"
                  onPlaceSelected={(result) => onAddress(result)}
                  options={{ types: ["geocode"] }}
                  onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}
                  id="address" name="address" className='form-control' onChange={formik.handleChange} value={formik.values.address} placeholder='Address' autoComplete="new-off"
                />
                {renderAddressError}
              </div>

              <div className='row'>
                <div className='col-md-6'>
                  <div className='fieldBlock mb-4'>
                    <label><img src={Pin} />&nbsp;</label>
                    <input type="text" id="country" name="country" className='form-control' value={globalAddress.country} placeholder='Country'autoComplete="new-off" />

                    {/* {countryError} */}
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='fieldBlock mb-4'>
                    <label><img src={Pin} />&nbsp;</label>
                    <input type="text" id="state" name="state" className='form-control' value={globalAddress.state} placeholder='State' autoComplete="new-off"/>

                    {/* {stateError} */}
                  </div>
                </div>
              </div>


              <div className='row'>
                <div className='col-md-6'>
                  <div className='fieldBlock mb-4'>
                    <label><img src={Pin} />&nbsp;</label>
                    <input type="text" id="city" name="city" className='form-control' onChange={onCity} value={allData?.city} placeholder='City' autoComplete="new-off"/>

                    {/* {cityError} */}
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='fieldBlock mb-4'>
                    <label><img src={Pin} />&nbsp;</label>
                    <input type="text" id="zipcode" className='form-control' onChange={onZipCode} value={allData?.pin} name='pin' placeholder='Zipcode' autoComplete="new-off"/>
                    {/* {zipError} */}
                  </div>
                </div>

              </div>

              <div className='row'>
                <div className='col-md-6'>
                  <div className='fieldBlock mb-4'>
                    <label><img src={Pin} />&nbsp;</label>
                    <input type="text" className='form-control' placeholder='Company Website URL' onChange={onCompanyUrl} />
                  </div>
                </div>
              </div>


              {/* {/ <div className='prfBtn'><button type="submit" className='commonButton buttonSubmit'>Next</button></div> /} */}

            </div>
          </div>
          {/* {/ <div className='accountTxt'>Don't have an account? &nbsp; <Link to="/signup">Sign up Now .</Link></div> /} */}
          <div className='pflex'>
            {/* {/ <Link>Back</Link> /} */}
            &nbsp;
            <button type="submit" className='commonButton buttonSubmit w-auto' >Next</button>
          </div>

        </form>



      </div>
    </div>
  )
}

export default Profilecreation