import React from 'react';
import bsbnr from '../../images/bsbnr.jpg';
import bs1 from '../../images/bs1.jpg';
import Navigation from '../layout/Navigation';
import cfobanner from '../../images/cfobnr.png';
import cfo1 from '../../images/cfo1.jpg';
import cfo2 from '../../images/cfo2.jpg';
import Footer2 from '../layout/Footer2';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css'; // Import Owl Carousel CSS
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import bs1 from '../../images/bs1.jpg';
// import bs2 from '../../images/bs2.jpg';
// import bs3 from '../../images/bs3.jpg';
import { Link } from 'react-router-dom';







const Paymentmodule = (props) => {  

    
    const options = {
        items: 3,
        loop: false,
        margin: 20,
        autoplay: true,
        nav: true,
        navText : ["<i class='fa-light fa-arrow-left-long'></i>","<i class='fa-light fa-arrow-right-long'></i>"],
        dots: false,
        responsive: {
            0: {
                items: 1, // Display one item on small screens
            },
            600: {
                items: 2, // Display two items on screens larger than 600px
            },
            1000: {
                items: 3, // Display three items on screens larger than 1000px
            },
        },
    };

  return (
    <>
    <div className='authBg manageBusinessBlock pb-0'>
      <header>
        <Navigation/>
        </header>


        <div className='cfoLogo'>&nbsp;</div>
      <section className='bannerBg cfoBnr pt-0'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <h1 className='mb-0'>Payment Modules</h1>
                    <h6>Affordable services customized to your need</h6>
                    <p>Our affordable bookkeeping services will ensure you stay on top of your business. Budget friendly options to choose that will guarantee satisfaction.</p>
                    <Link to='/login' className='commonButton getStartbtn'><i class="fa-regular fa-paper-plane"></i> Access Now</Link>
                </div>
                <div className='col-lg-6'>
                    <img src={cfobanner} alt='' />
                </div>
            </div>
        </div>
    </section>



    <div className='manageBusiness salesblock'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h2>1SimpleClick to ManageYour<br /> Business</h2>
                        <p>Rely on our CPAs and seasoned <br />
                            professionals that speak your language to give you a competitive advantage</p><br />
                        
                        <OwlCarousel className="owl-theme" {...options}>            
                            <div className="item">
                                <Link to="">
                                    <div className='slideBlock'>
                                        <i class="fa-solid fa-table-columns"></i>
                                        <h5>Dashboard</h5>
                                        <p>Displays an overview of outstanding invoices, pending payments, and recent transactions.</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="item">
                                <Link to="">
                                    <div className='slideBlock'>
                                    <i class="fa-light fa-file-invoice"></i>
                                        <h5>Payment Form</h5>
                                        <p>Allows users to record customer payments or vendor payments, specify invoice details, payment amount, and method.</p>
                                        
                                    </div>
                                </Link>
                            </div>
                            <div className="item">
                                <Link to="">
                                <div className='slideBlock'>
                                <i class="fa-regular fa-rectangle-list"></i>
                                    <h5>Payment List</h5>
                                    <p>Shows a list of recorded payments with details like customer/vendor name, date, amount, and payment method.</p>
                                    
                                </div>
                                </Link>
                            </div>
                            <div className="item">
                                <Link to="">
                                <div className='slideBlock'>
                                <i class="fa-light fa-clipboard-list-check"></i>
                                    <h5>Reports</h5>
                                    <p>Provides reports on receivables and payables, payment history, and aging analysis.</p>
                                    
                                </div>
                                </Link>
                            </div>
                            {/* <div className="item">
                                <Link to="">
                                <div className='slideBlock'>
                                <i class="fa-solid fa-people-roof"></i>
                                    <h5>Vendor Management</h5>
                                    <p>Provides a screen to manage vendor information, including contact details, payment terms, and shipping addresses.</p>
                                    
                                </div>
                                </Link>
                            </div> */}
                           
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </div>




    </div>
    <Footer2/>
    </>
  )
}

export default Paymentmodule