import React from 'react'
import { useNavigate } from 'react-router-dom';



const Resttous = () => {
    const navigate = useNavigate();

    const onStart=()=>{
        navigate("/login")
        console.log("start")
    }
    return (
        <div className='restUs'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h2>Don't Delay!<br/> Join us today.</h2>
                        <button className='commonButton' onClick={onStart}>Let's Start</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Resttous