import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import dash from '../../images/dash.png';
import cp from '../../images/callpay.png';
import mm from '../../images/mobmoney.png';
import cc from '../../images/credit.png';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getSubscriptionPlanList, getSubscriptionPayment } from "../../../Service/Subscription/SubscriptionStripe";

const Mysubscription = (props) => {
    const { logo } = props;
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [subscriptionPlan, setAllSubscriptionPlanList] = useState([]);



  
    return (

        <div className='authBg'>
            <div className='proflogo'><Link to="/"><img src={logo} /></Link></div>
            <div className='authLogo pt-5'>&nbsp;</div>

            <div className='signForm subsMain'>
                <div className='choosePlanTop'>
                    <h3>My Subscription Plan</h3>
                    <img src={dash} />
                    {/* <p>Pay for the entire year and get two months absolutely free</p> */}
                </div>
                <div className='choosePlanBtm'>
                    <div className='row'>

                    <div className='col-md-3'></div>
                        
                        <div className='col-md-6'>
                            <div className='planBg'>
                                <div className="plantop orangebg">
                                    <h5 className='m-0'>Monthly Plan</h5>
                                    <span className='mytick'><i class="fa-solid fa-check"></i></span>

                                    {/* <button>Popular</button> */}
                                </div>

                                <div className='plancontent'>
                                    <h2>$29.00/<small>month</small></h2> 

                                    <h6 className='highlight pb-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet</li>
                                        <li>Lorem ipsum dolor sit amet</li>
                                        <li>Lorem ipsum dolor sit amet</li>
                                        <li>Lorem ipsum dolor sit amet</li>
                                        <li>Lorem ipsum dolor sit amet</li>
                                        <li>Lorem ipsum dolor sit amet</li>
                                        <li>Lorem ipsum dolor sit amet</li>
                                    </ul>
                                    <button className='commonButton buttonSubmit'>Update</button>
                                </div>

                            </div>

                        </div>

                        <div className='col-md-3'></div>

                        
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Mysubscription