import { React, useState, useEffect } from 'react'
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import Form from 'react-bootstrap/Form';
import Headtxt from '../Headtxt';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';
import { PhoneInput } from 'react-international-phone';
import { City, Country, State } from "country-state-city";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { getSearchSupplier, getAddSuppliers, getImportCustomer, getSupplierList } from "../../../../Service/Suppliers/SuppliersService";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { addInvoice, addSalesOrder, getAddCustomer, getAddSalesquotation, getCustomerList, getProductServiceList, getProductServiceListBYSupplierId, getSearchCustomer, getSendInvoice, getSendSalesOrder, getSendSalesQuote } from "../../../../Service/Customer/CustomerService";
import { Link } from 'react-router-dom'
import { getEditSupplier, getSuppliereListById } from "../../../../Service/Suppliers/SuppliersService";
import { exitingProduct, getAddProductService, getImportProductService, getInventoryAccounts, getUserAccountList, getUserccountList } from "../../../../Service/ProductService/ProductService";
import { getAddquotation, getSendQuote } from "../../../../Service/Quotation/QuotationService";
import moment from 'moment';
import { CSVLink } from "react-csv";
import axios from 'axios';
import getSymbolFromCurrency from 'currency-symbol-map'
import Swal from 'sweetalert2';
import CurrencySelect from 'react-select-currency';
import currencies from "../../../components/Common.currency.json";
import { getAddInventoryaccount } from '../../../../Service/Account/AccountService';
import instance from '../../../../WebApi/WebApi';
const Newinvoicecreation = ({ setIsSpinner }) => {
  const [inventory, setInventory] = useState("");

  const options = [
    { value: '30 days' },
    { value: '60 days' },
    { value: '90 days' },
    { value: 'due on receipt' },
    { value: '3 installation' },
    { value: '2 installation' }
  ];
  const navigate = useNavigate();

  let countryData = Country.getAllCountries();
  let deliveryCountryData = Country.getAllCountries();
  const [imageUrls, setImageUrls] = useState('');
  const [companyType, setCompanyType] = useState("Company");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [webUrl, setWebUrl] = useState('');
  const [vatNumber, setVatNumber] = useState('');
  const [billAddress, setBillAddress] = useState('');
  const [country, setCountry] = useState("");
  const [DeliveryCountry, setDeliveryCountry] = useState("");
  const [state, setState] = useState("");
  const [deliveryState, setDeliveryState] = useState("");
  const [city, setCity] = useState("");
  const [deliveryCity, setDeliveryCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [deliveryZipCode, setDeliveryZipCode] = useState('');
  const [cityData, setCityData] = useState([]);
  const [deliveryCityData, setDeliveryCityData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [deliveryStateData, setDeliveryStateData] = useState([]);
  const [jsondata, setJsonData] = useState([]);
  const [supplierId, setSupplierId] = useState()
  const [subTotal1, setSubTotal1] = useState(0);
  const [subSubDiscount1, setSubDiscount1] = useState(0);
  const [subSubVat1, setSubVat1] = useState(0);
  const [subSubTotalValue1, setSubTotalValue1] = useState(0);
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [unit, setUnit] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [purchaseTax, setPurchaseTax] = useState("");
  const [salesTax, setSalesTax] = useState("");
  const [orderQty, setOrderQty] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [error, setError] = useState(null);
  const [inventoryOptions, setInventoryOptions] = useState([]);
  const [costOfGoodsSoldList, setCostOfGoodsSoldList] = useState([]);
  const [incomeAccList, setIncomeAccList] = useState([]);
  // For toggle
  const [isToggled, setToggled] = useState('');
  const [currency, setCurrency] = useState("KES");
  const handleToggleOn = () => {
    setToggled(true);
  };
  const handleToggleOff = () => {
    setToggled(false);
  };

  const [supplier1, setSupplier1] = useState(false);
  const supplierShow1 = () => setSupplier1(true);
  const supplier1Close = () => setSupplier1(false);
  const [dataPdf, setDataPdf] = useState("");

  const [supplier2, setSupplier2] = useState(false);
  const [productService2, setProductService2] = useState(false);
  const supplierShow2 = () => setSupplier2(true);
  const productServiceShow2 = () => setProductService2(true);
  const supplier2Close = () => setSupplier2(false);
  const productServiceClose = () => setProductService2(false);

  const [companyFinal, setCompanyFinal] = useState("");

  const [discountQuantity, setDiscountQuantity] = useState(0);
  const [finalTotal, setFinalTotal] = useState();

  const [allProductServiceData, setAllProductServiceData] = useState([]);
  const [allProductServiceData2, setAllProductServiceData2] = useState([]);
  const [customerList, setAllCustomerList] = useState([]);
  const [productServiceList, setAllProductServiceList] = useState([]);
  const [productServiceList2, setAllProductServiceList2] = useState([]);
  const [productServiceList3, setAllProductServiceList3] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleShow2 = (e) => { e.preventDefault(); setShow2(true); getProductServiceDataList() }
  const handleClose2 = () => setShow2(false);
  const [showPdf, setShowPdf] = useState(false);
  const handleShow = () => setShow(true);
  const handleClosePdf = () => setShowPdf(false);
  const vclose = () => {
    setShowPdf(false);
    navigate('/invoice')
  }
  const [showProductexisting, setShowProductExisting] = useState(false);
  const handleCloseProductDelete = () => setShowProductExisting(false);
  const onYesProduct = () => {
    exitingProduct(type, orderQty, code, (res) => {
      console.log(res, "response")
      if (res.success === true) {
        toast.success(res.message)
        setShowProductExisting(false);
        getProductServiceDataList();
       // navigate("/productservicelisting2");
      } else {
        toast.error(res.message)
        /// setShowProductExisting(false);
      }
    })
  }
  const deleteImage = () => {
    // Add your logic here to delete the image
    // For example, you can reset the image URL to an empty string
    setImageUrls([]);
    setFile(null)
    //setOldDoc([])
  };
  const handleClose = () => setShow(false);
  const handleClosed = () => setSupplier1(false);
  const [address, setQuoteAddress] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [note, setNote] = useState("");
  const [attachment, setAttachment] = useState("");
  const [file, setFile] = useState(null);
  const [deleteId, setDeleteId] = useState([]);
  const [deleteId2, setDeleteId2] = useState([]);
  const [views, setViews] = useState(false)
  const [emailQuote, setEmailQuote] = useState("")
  const [addressQuote, setAddressQuote] = useState("")
  const [id, setId] = useState()
  const [checks, setChecks] = useState("false");
  const [code, setCode] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('KES');
  const [currencyData, setCurrencyData] = useState(null);
  const [selectedCurrencyValue, setSelectedCurrencyValue] = useState(null);
  const [addaccount, setAddaccount] = useState(false);
  const [addincomeaccount, setAddIncomeaccount] = useState(false);
  const [addexpenseaccount, setAddExpenseaccount] = useState(false);
  const accountpop = () => setAddaccount(true);
  const addaccountClose = () => setAddaccount(false);
  const accountpop2 = () => setAddIncomeaccount(true);
  const addincomeaccountClose = () => setAddIncomeaccount(false);
  const accountpop3= () =>  setAddExpenseaccount(true);
  const addexpenaccountClose = () =>  setAddExpenseaccount(false);
  const [accountId, setAccountId] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountDate, setAccountDate] = useState("");
  const [accountdescription, setAccountDescription] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  //email send details 
  const onAccountName = (e) => {
    setAccountName(e.target.value)
  }
  const onAccountId = (e) => {
    setAccountId(e.target.value)
  }
  const onAccountDescription = (e) => {
    setAccountDescription(e.target.value)
  }
  const CustomDropdown = ({ placeholder, value, }) => {
    console.log('options==', value)
    return (
      <select value={value} className="form-control">

        <option key='1234' value={value}>{value}</option>



      </select>
    );
  };
  const onEmailQuote = (e) => {
    setEmailQuote(e.target.value)
  }

  const onAddressQuote = (e) => {
    setAddressQuote(e.target.value)
  }
  const handleCurrencyChange = (selectedOption) => {
    const currency = selectedOption.target.value;
    setSelectedCurrency(currency);
    //  const url = `${Base_URL}`;
    // console.log('Selected currency:', currency);
    // console.log('Selected currency:', selectedCurrency);

    // You can send this URL to the API endpoint
  };
  const handleRemoveFile = (fileToRemove) => {
    setSelectedFiles(prevFiles => prevFiles.filter(file => file !== fileToRemove));
  };
  const handelFileChange = (e) => {
    e.preventDefault();
    document.querySelector('input[type="file"]').click()

    let a = document.getElementById('fileData');

    console.log(a, 'oo');

    //  add browsed files one by one
    const files = Array.from(e.target.files);
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);
  }

  const onSendPdf = () => {
    setIsSpinner(true)
    getSendInvoice(dataPdf?.data?.details?.id, dataPdf?.data?.customerEmail, `Customer order - ${dataPdf?.data?.details?.invoice_id}`,
      addressQuote, checks,
      (r) => {
        console.log(r, "response")
        // if (r.success === true) {
        //   toast.success(r.message)
        //   navigate('/quotelisting')


        // } 
        if (r.success === true) {
          setIsSpinner(false)
          Swal.fire({
            title: r.message,
            //  text: 'The product has been added successfully!',
            icon: 'success',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'my-confirm-button'
            }
          }).then(() => {
            navigate('/invoice')
          })
        }
        else {
          console.log("nononononono")
          toast.error(r.message)
          setIsSpinner(false)
        }
      })
  }

  const checkMe = (e) => {
    setChecks('true');
    // console.log(e.target.checked, "Yesssssssssss");
    console.log(e.target.checked ? "Yesssssssssss" : "Noooooo");
  }


  const toggleHandlerb = (e) => {
    e.preventDefault();
    setViews(true)
  }
  const toggleHandlerc = (e) => {
    e.preventDefault();
    setViews(false)
  }

  const handleDrop = (event) => {
    event.preventDefault();

    const droppedFile = event.dataTransfer.files[0];
    handleFile(droppedFile);
  };
  const handleFile = (selectedFile) => {
    setFile(selectedFile);

    // You can perform additional actions with the file, such as uploading it to a server.
    // For simplicity, we're just logging the file information here.
    console.log('File selected:', selectedFile);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const [supply, setSupply] = useState(false)
  const toggleCompany = (e) => {
    setCompanyType("Company")
    e.preventDefault();
    setSupply(false)
  }
  const toggleIndividual = (e) => {
    setCompanyType("Individual")

    e.preventDefault();
    setSupply(true)
  }


  //Suppliers listing or customer listing
  useEffect(() => {
    getCustomerDataList()


  }, []);
  useEffect(() => {
    //getallCustomerList();
    getProductServiceDataList()
  }, [])

  async function getallCustomerList(d) {

    getSuppliereListById(d).then(function (result) {
      const response = result.data;
      console.log(response, "response888888886")
      //setAllData(response?.data?.supplier_details)
      const combinedData = [...response?.data?.products, ...response?.data?.services];
      setAllProductServiceList(combinedData);
      // setCustomerId(response?.data);

      // setDepartmentId(response?.data?.businessUnit?.Departments);
    });
  }
  async function getCustomerDataList() {
    getCustomerList().then(function (result) {
      const response = result?.data;
      // console.log(response, "rrrr")
      setAllCustomerList(response?.data);
    });
  }
  async function getProductServiceDataList() {
    getProductServiceList("").then(function (result) {
      const response = result?.data;
      // console.log(response, "rrrr")
      const filteredData = response?.data.map(item => ({ ...item, qty: 0 }));
      setAllProductServiceList(filteredData);
      setAllProductServiceList2(response?.data);

    });
  }

  const onSearchByProSer = (e) => {
    if (e.target.value != "") {
      const filterCriteria = {
        supplierId: supplierId,
        searchText: e.target.value
      };

      const queryString = new URLSearchParams(filterCriteria).toString();

      getProductServiceList(queryString).then(function (result) {
        const response = result?.data;
        // console.log(response, "rrrr")

        setAllProductServiceList(response?.data);

      }
      )
    } else {
      setAllProductServiceList(productServiceList2)
    }
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [selectedDateb, setSelectedDateb] = useState(null);

  const handleDateChangeb = (date) => {
    setSelectedDateb(date);
  };

  const onCompanyType = (e) => {
    setCompanyType(e.target.value)
  }

  const onCompanyName = (e) => {
    setCompanyName(e.target.value)
  }

  const onFirstName = (e) => {
    setFirstName(e.target.value)
  }

  const onLastName = (e) => {
    setLastName(e.target.value)
  }

  const onEmail = (e) => {
    setEmail(e.target.value)
  }
  const onWebUrl = (e) => {
    setWebUrl(e.target.value)
  }

  const onVatNumber = (e) => {
    setVatNumber(e.target.value)
  }

  const onBillingAddress = (e) => {
    setBillAddress(e.target.value)
  }

  const onCountry = (e) => {
    setCountry(e.target.value)
    setStateData(State.getStatesOfCountry(e.target.value));
  }

  const onDeliveryCountry = (e) => {
    console.log(e.target.value, "cdscds");
    setDeliveryCountry(e.target.value)
    setDeliveryStateData(State.getStatesOfCountry(e.target.value));
  }
  console.log(deliveryStateData, "dsddsd")

  const onState = (e) => {
    console.log(e.target.value, "stateiso")
    setState(e.target.value);
    setCityData(City.getCitiesOfState(country, e.target.value));
  }

  const onDeliveryState = (e) => {
    console.log(e.target.value, "stateiso")
    setDeliveryState(e.target.value);
    setDeliveryCityData(City.getCitiesOfState(DeliveryCountry, e.target.value));
  }

  const onCity = (e) => {
    setCity(e.target.value)
  }

  const onDeliveryCity = (e) => {
    setDeliveryCity(e.target.value)
  }

  const onZipCode = (e) => {
    const input = e.target.value;
    // Regular expression to allow only alphanumeric characters
    const regex = /^[a-zA-Z0-9]*$/;
    if (regex.test(input) || input === '') {
      // If input passes validation or is empty, update state
      setZipCode(input);
    }
    // You can add an else statement to show an error message or handle invalid input
  };
  const onDeliveryAddress = (e) => {
    setDeliveryAddress(e.target.value)
  }

  const onDeliveryZipCode = (e) => {
    setDeliveryZipCode(e.target.value)
  }

  const onSave = async () => {
   
    if (companyType === "Company" && companyName === "") {
      toast.error("Please type company name");
    } else if (companyType === "Individual" && firstName === "") {
      toast.error("Please type first name");
    } else if (companyType === "Individual" && lastName === "") {
      toast.error("Please type last name");
    } else if (email === "") {
      toast.error("Please type valid email");
    } else if (phone === "" || phone.length < 7) {
      toast.error("Please type phone number");
    }
    // else if (companyType === "Company" && vatNumber <= 0) {
    //   toast.error("Please type a valid VAT Number(Optional)");
    // }
    else if (billAddress === "") {
      toast.error("Please type billing address");
    }
    else if (country === "") {
      toast.error("Please select country");
    }
//else if (state === "") {
  //    toast.error("Please //select state");
 //   } 
    // else if (city === "") {
    //   toast.error("Please select city");
    // }
    else if (selectedCurrency === "" || selectedCurrency === null || selectedCurrency === undefined) {
      toast.error("Please select currency");
    }
    // else if (zipCode === "") {
    //   toast.error("Please type zipcode")
    // }
    else if(isToggled==='')
      {
        toast.error("Please choose address for delivery")
      }
    else if (isToggled && deliveryAddress === '') {
      toast.error("Please type delivery address")
    }
    else if (isToggled && DeliveryCountry === '') {
      toast.error("Please type delivery country")
    }
    else if (isToggled && deliveryState === '') {
      toast.error("Please type delivery state")
    }
    else if (isToggled && deliveryCity === '') {
      toast.error("Please type delivery city")
    }
    else if (isToggled && deliveryZipCode === '') {
      toast.error("Please type delivery zipcode")
    }
    else {
      try {
        setIsSpinner(true)
        getAddCustomer(
          companyType, companyName, firstName, lastName, email, phone, webUrl
          , vatNumber, billAddress, countryData.find(c => c.isoCode === country)?.name, stateData.find(c => c.isoCode === state)?.name
          , city, selectedCurrency, zipCode, deliveryAddress, deliveryCountryData.find(c => c.isoCode === DeliveryCountry)?.name, deliveryStateData.find(c => c.isoCode === deliveryState)?.name,
          deliveryCity, deliveryZipCode,country,isToggled===''?DeliveryCountry:country, (response => {
            console.log(response, "response");

            if (response?.success === true) {
              setIsSpinner(false)
              Swal.fire({
                title: response?.message,
                //  text: 'The product has been added successfully!',
                icon: 'success',
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'my-confirm-button'
                }
              }).then(() => {
                supplier2Close(false);
                getCustomerDataList();
              })
            }
            else {
              console.log("nononononono");
              toast.error(response.message);
              setIsSpinner(false)
            }
          }));

      } catch (error) {
        console.error("Error calling", error);
        toast.error(error);
      }
    }
  };

  const onSaveservice = async () => {
    if (type === "") {
      toast.error("Please select a type")

    }
    else if (name === "") {
      toast.error("Please type name")
    } 
    else if (code === "") {
      toast.error("Please type code")
    } 
    else if (unit === "") {
        toast.error("Please select a unit")
      }
    // else if (name === "") {
    //   toast.error("Please type  name")
    // } else if (unit === "") {
    //   toast.error("Please select a unit")
    // } else if (salePrice === "") {
    //   toast.error("Please type Sales Price(Optional)")
    // } else if (salePrice < 0) {
    //   toast.error("Please type valid Sales Price(Optional)")
    // } else if (purchaseTax < 0) {
    //   toast.error("Please type valid purchase tax")
    // }
    // else if (type === "Product" && purchasePrice === "") {
    //   toast.error("Please type purchase price")
    // } else if (type === "Product" && purchasePrice < 0) {
    //   toast.error("Please type valid purchase price")
    // }
    // else if (type === "Product" && orderQty === "") {
    //   toast.error("Please type order quantity")
    // } else if (type === "Product" && orderQty < 0) {
    //   toast.error("Please type valid order quantity")
    // }
    else if (type === "Product" && inventory === "") {
      toast.error("Please select track inventory")
    }
    
    else {
      const data = {
        "type": type,
        "name": name,
        "description": description,
        "unit": unit,
        "sales_price": salePrice,
        "tax": purchaseTax,
        "purchase_price": purchasePrice,
        "quantity": orderQty,
        "track_inventory": inventory,
        "supplierId": '',
        "code": code,
       "sales_tax": salesTax,
      }
      const response = await instance.post(`/product-service-details-store`, data)
      const r =response?.data


      console.log("response666", response)
      //   if (r.success === true) {
      //     toast.success(r.message)
      //     // navigate("/productservicelisting")
      //     setProductService2(false);
      //     getallCustomerList(supplierId);
      //  //   getProductServiceDataList();
      //   } 
      if (r.success === true) {
        Swal.fire({
          title: r.message,
          //  text: 'The product has been added successfully!',
          icon: 'success',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'my-confirm-button'
          }
        }).then(() => {
          setProductService2(false);
          getallCustomerList(supplierId);
        })
      }
      else if (r.message === "Product details already exists!") {
        setProductService2(false);
        setShowProductExisting(true);
      
      }
      else {
        console.log("nononononono")
        toast.error(r.message)

      }

    }

  }

  const onSearch = (e) => {
    console.log(e.target.value, "dsdsdsdsd")
    getSearchCustomer(e.target.value).then(function (result) {
      const response = result?.data;
      console.log(response, "rrrr")
      setAllCustomerList(response?.data);
    });
  }

  const onFetchSupplier = (d) => {
    console.log(customerList.find(c => c.id == d))
    const companyName = customerList.find(c => c.id == d);
    if (companyName.type === "Company") {
      setCompanyFinal(companyName)
      setCurrency(companyName?.currency)
      setSelectedCurrency(companyName?.currency);
    } else {
      setCompanyFinal(companyName)
      setCurrency(companyName?.currency)
      setSelectedCurrency(companyName?.currency);
      // setCompanyFinal(`${companyName.fname} ${companyName.lname}`)
    }
    console.log('iddd=====2', d)
    // getallCustomerList(d);
    setSupplierId(d)
    setSupplier1(false)
    setAllProductServiceData([])
  }
  console.log(companyFinal, "cfcfcfcfcfcfc")

  // const onCheck = (data) => {
  //   if (allProductServiceData.includes(data)) {
  //     const filterData = allProductServiceData.filter(d => d != data)
  //     setAllProductServiceData(filterData)
  //   } else {
  //     allProductServiceData.push(data);
  //     setAllProductServiceData(allProductServiceData)
  //   }
  // console.log(allProductServiceData, "allProductServiceData")

  // }

  const onCheck = (data) => {
    if (allProductServiceData.includes(data)) {
      const filterData = allProductServiceData.filter(d => d !== data);
      setAllProductServiceData(filterData, () => {
        console.log(allProductServiceData, "allProductServiceData");
      });
    } else {
      // const updatedData = [{...allProductServiceData, data}];
      const updatedData = [...allProductServiceData, { ...data, showing_price: data?.type === "service" ? data?.price : data.sales_price || 0 }];
      console.log(updatedData, "updatedData");
      setAllProductServiceData(updatedData);

      setDiscountQuantity((prevFormData) => ({
        ...prevFormData,
        [allProductServiceData.length + 1]: {
     'id': data.id,
          'qty': 0,
          'discount': 0,
          'price': 0,
          'tax': data.sales_tax || 0,
          'showing_price': data?.type === "service" ? data?.price : data.sales_price || 0
        },
      }));
    }
  };
  // const onCheck2 = (data) => {
  //   if (allProductServiceData2.includes(data)) {
  //     const filterData = allProductServiceData2.filter(d => d !== data);
  //     setAllProductServiceData2(filterData, () => {
  //       console.log(allProductServiceData2, "allProductServiceData2");
  //     });
  //   } else {
  //     const updatedData = [...allProductServiceData2, data];
  //     console.log(updatedData,"updatedData")
  //     setAllProductServiceData2(updatedData);

  //     setDiscountQuantity((prevFormData) => ({
  //       ...prevFormData,
  //       [allProductServiceData2.length+1]: {
  //         ['quantity']: 0,
  //         ['discount']:0,
  //         ['price']:0
  //       },
  //     }))


  //   }
  // };

  const onCheck2 = (product) => {
    setAllProductServiceData2((prevSelected) => {
      if (prevSelected.includes(product)) {
        return prevSelected.filter((p) => p !== product);
      } else {
        return [...prevSelected, product];
      }
    });
  };
  console.log(allProductServiceData, "allProductServiceDataoutside");

  const getTotalPrice = (price, index, tax) => {
    if (discountQuantity && discountQuantity[index]?.qty) {
      const totalPrice = Number(discountQuantity[index]?.qty) * price;
      const totalDiscount = (totalPrice * Number(discountQuantity[index].discount)) / 100;
      const finalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
      const totalFinalprice = (totalPrice - finalDiscount);
      const totalTax = (totalFinalprice * Number(tax)) / 100;
      const totalFinalTaxPrice = (totalFinalprice + totalTax);

      // setDiscountQuantity((prevFormData) => ({
      //   ...prevFormData,
      //   [index]: {
      //     // ...prevFormData[index],
      //     ["totalDiscount"]: totalDiscount,
      //     ["totalTax"]: totalTax,
      //     ["price"]: price,
      //     ["totalFinalTaxPrice"]: totalFinalTaxPrice,
      //   },
      // }));
      console.log('totalFinalTaxPrice', totalFinalTaxPrice)
      return totalFinalTaxPrice;
    }
    return 0;

  }

  // const onQuantityDiscount = (event, keyValue) => {
  //   const { name, value } = event.target;
  //   console.log("name", name);
  //   if (discountQuantity) {
  //     setDiscountQuantity((prevFormData) => ({
  //       ...prevFormData,
  //       [keyValue]: {
  //         ...prevFormData[keyValue],
  //         [name]: value,
  //       },
  //     }));
  //   } else {
  //     setDiscountQuantity((prevFormData) => ({
  //       ...prevFormData,
  //       [keyValue]: {
  //         [name]: value,
  //       },
  //     }));
  //   }
  // };

  const getTotalPrice1 = (price, tax, quantity, discount) => {
    if (quantity) {
      const totalPrice = Number(quantity) * price;
      const totalDiscount = (totalPrice * Number(discount)) / 100;
      const finalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
      const totalFinalPrice = (totalPrice - finalDiscount);
      const totalTax = (totalFinalPrice * Number(tax)) / 100;
      const totalFinalTaxPrice = (totalFinalPrice + totalTax);
      console.log('totalFinalTaxPrice', totalFinalTaxPrice, totalTax, totalFinalPrice, tax)
      return totalFinalTaxPrice;
    }
    return 0;
  };
  const onQuantityDiscount = (event, keyValue, data) => {
    const { name, value } = event.target;

    // Update discountQuantity state
    setDiscountQuantity((prevFormData) => ({
      ...prevFormData,
      [keyValue]: {
        ...prevFormData[keyValue],
        [name]: value,
      },
    }));

    // Update allProductServiceData state
    const updatedProductServiceData = [...allProductServiceData];
    updatedProductServiceData[keyValue - 1] = {
      ...data,
      [name]: value,
      [name === 'qty' ? 'qty' : name === 'discount' ? 'disc' : name === 'price' ? 'sales_price' : 'sales_tax']: value,
    };

    // Calculate updated total price
    const qty = name === 'qty' ? value : discountQuantity[keyValue]?.qty || data.qty || 0;
    const discount = name === 'discount' ? value : discountQuantity[keyValue]?.discount || data.discount || 0;
    const price = name === 'price' ? value : discountQuantity[keyValue]?.price || 0;
    const tax = name === 'sales_tax' ? value : discountQuantity[keyValue]?.sales_tax || data.sales_tax || 0;

    const totalPrice = getTotalPrice1(
      price,
      tax,
      qty,
      discount
    );

    updatedProductServiceData[keyValue - 1].total = totalPrice;
    setAllProductServiceData(updatedProductServiceData);

    console.log(totalPrice, "Updated Total Price");
  };

  const onButtonClick = () => {
    const pdfUrl = dataPdf?.data?.pdf;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.target = '_black';
    // link.download = "document.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const _handle_file_change = (e) => {
    e.preventDefault()
    document.querySelector('input[type="file"]').click()
  }
  const onType = (e) => {
    setType(e.target.value)
  }

  const onName = (e) => {
    setName(e.target.value)
  }
  const onID = (e) => {
    setCode(e.target.value)
  }
  const onDescription = (e) => {
    setDescription(e.target.value)
  }

  const onUnit = (e) => {
    setUnit(e.target.value)
  }

  const onSalesPrice = (e) => {
    setSalePrice(e.target.value)
  }

  const onPurchasePrice = (e) => {
    setPurchasePrice(e.target.value)
  }

  const onPurchaseTax = (e) => {
    setPurchaseTax(e.target.value)
  }
  const onSalesTax = (e) => {
    setSalesTax(e.target.value)
  }
  const onOrderQty = (e) => {
    setOrderQty(e.target.value)
  }

  const handleTogglesOn = () => {
    // setToggled(true);
    setInventory("No")
  };
  const handleTogglesOff = () => {
    // setToggled(false);
    setInventory("Yes")
  };
  const fetchCurrencyData = async () => {
    const UserCurrency = localStorage.getItem("1Simpleclick_Currency");
    setCurrencyData(UserCurrency)
    try {
      const apiId = 'onesimpleclick809194384'; // Replace with your actual API ID
      const apiKey = 'omumk49ld03teh0h681go0jmqp'; // Replace with your actual API Key
      const encodedCredentials = btoa(`${apiId}:${apiKey}`);

      const response = await axios.get('https://xecdapi.xe.com/v1/convert_from.json/', {
        params: {
          from: selectedCurrency ? selectedCurrency : "CAD", // base currency
          to: UserCurrency, // target currency
          amount: 1,
        },
        headers: {
          'Authorization': `Basic ${encodedCredentials}`
        }
      });
      setSelectedCurrencyValue(response.data.to[0].mid);
      console.log('UserCurrency', UserCurrency)
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  };
  useEffect(() => {
    if (selectedCurrency) {
      fetchCurrencyData();
      getSymbolFromCurrency('CAD')
      console.log('getSymbolFromCurrency', getSymbolFromCurrency('INR'))
    }
  }, [selectedCurrency]);
  useEffect(() => {
    subTotal();
    subDiscount();
    subVat();
    subTotalValue();
  }, [discountQuantity]);

  console.log("discountQuantity", discountQuantity)

  const subTotal = () => {
    let totalPrice = 0;

    if (discountQuantity) {
      allProductServiceData?.map((d, i) => {
        if (discountQuantity[`${i + 1}`]) {

          if (discountQuantity[`${i + 1}`].qty) {


            totalPrice += (Number(discountQuantity[`${i + 1}`].qty) * Number(d?.type === "service" ? d?.price : d?.sales_price));
            // const totalDiscount = (totalPrice * Number(discountQuantity[i].discount)) / 100;
            // const finalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
            // const totalFinalprice = (totalPrice - finalDiscount);
            // const totalTax=(totalPrice * Number(tax)) / 100;
            // const totalFinalTaxPrice=(totalPrice + totalTax)-finalDiscount;
            // console.log(discountQuantity[i]?.quantity, "totalPrice")

          }
        }
      })
    }
    setSubTotal1(totalPrice)
    return totalPrice;
  }


  const subDiscount = () => {
    let totalDiscount = 0;

    if (discountQuantity) {
      allProductServiceData?.map((d, i) => {
        if (discountQuantity[`${i + 1}`]) {

          if (discountQuantity[`${i + 1}`].discount) {
            let totalPrice = 0;

            totalPrice += (Number(discountQuantity[`${i + 1}`].qty) * Number(d?.type === "service" ? d?.price : d?.sales_price));
            totalDiscount += (totalPrice * Number(discountQuantity[`${i + 1}`].discount)) / 100;
            // const finalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
            // const totalFinalprice = (totalPrice - finalDiscount);
            // const totalTax=(totalPrice * Number(tax)) / 100;
            // const totalFinalTaxPrice=(totalPrice + totalTax)-finalDiscount;

          }
        }
      })
    }
    setSubDiscount1(totalDiscount)
    return totalDiscount;
  }

  const subVat = () => {
    let totalTax = 0;

    if (discountQuantity) {
      allProductServiceData?.map((d, i) => {
        if (discountQuantity[`${i + 1}`]) {

          if (discountQuantity[`${i + 1}`].qty) {
            let totalPrice = 0;
            totalPrice += (Number(discountQuantity[`${i + 1}`].qty) * Number(d?.type === "service" ? d?.price : d?.sales_price));
            const discountAmount = (totalPrice * Number(discountQuantity[`${i + 1}`].discount)) / 100;
            const discountedPrice = totalPrice - discountAmount;
            totalTax += (discountedPrice * Number(d?.sales_tax)) / 100;
          }
        }
      })
    }
    setSubVat1(totalTax)
    return totalTax;
  }

  const subTotalValue = () => {
    let totalTax = 0;
    let totalPrice = 0;
    let totalDiscount = 0;
    let totalFinalTaxPrice = subTotal() - subDiscount() + subVat();
    let finalDiscount = 0;

    // if (discountQuantity) {
    //   allProductServiceData?.map((d, i) => {
    //     if (discountQuantity[`${i + 1}`]) {


    //       totalPrice += (Number(discountQuantity[`${i + 1}`].quantity) * Number(d?.type === "service" ? d?.price : d?.purchase_price));
    //       totalDiscount += (totalPrice * Number(discountQuantity[`${i + 1}`].discount)) / 100;
    //       finalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
    //       // const totalFinalprice = (totalPrice - finalDiscount);
    //       totalTax += (totalPrice * Number(d?.tax)) / 100;
    //       totalFinalTaxPrice += (totalPrice + totalTax) - finalDiscount;

    //     }
    //   })
    // }
    setSubTotalValue1(String(totalFinalTaxPrice))
    return totalFinalTaxPrice;
  }

  const [qtyIndex, setQtyIndex] = useState([]);
  const onCheckDelete = (id, indexValue) => {
    // console.log(indexValue,"indexValue")
    setDeleteId((prevDeleteId) => {
      if (prevDeleteId.includes(id)) {
        let tempQtyIndex = qtyIndex.filter(item => item !== indexValue)
        setQtyIndex(tempQtyIndex)
        // Remove the id from the array
        return prevDeleteId.filter((d) => d !== id);
      } else {
        setQtyIndex([...qtyIndex, indexValue]);
        // Add the id to the array
        return [...prevDeleteId, id];
      }
    }, () => {
      console.log("hihihihihihihi", deleteId);
    });
  };
  const onCheckDelete2 = (id, indexValue) => {
    // console.log(indexValue,"indexValue")
    setDeleteId2((prevDeleteId) => {
      if (prevDeleteId.includes(id)) {
        let tempQtyIndex = qtyIndex.filter(item => item !== indexValue)
        setQtyIndex(tempQtyIndex)
        // Remove the id from the array
        return prevDeleteId.filter((d) => d !== id);
      } else {
        setQtyIndex([...qtyIndex, indexValue]);
        // Add the id to the array
        return [...prevDeleteId, id];
      }
    }, () => {
      console.log("hihihihihihihi", deleteId);
    });
  };
  const handelDelete = () => {

    let idSet = new Set(deleteId);
    idSet = Array.from(idSet)
    const filteredArray = allProductServiceData.filter(item => !idSet.includes(item.id));

    setAllProductServiceData([...filteredArray]);
    setDeleteId([]);
    qtyIndex.forEach(key => {
      if (discountQuantity.hasOwnProperty(key)) {
        delete discountQuantity[key];

      }
      const arr = Object.keys(discountQuantity).sort((a, b) => a - b).map(key => discountQuantity[key]);

      const obj = {};
      arr.forEach((value, index) => {
        obj[index + 1] = value;
      });
      setDiscountQuantity(obj)
    });
    setQtyIndex([]);
    subTotal();
    subDiscount();
    subVat();
    subTotalValue();

  }
  const handleSingleDelete = (data) => {
    const filteredArray = allProductServiceData.filter(item => item != data);
    const updatedQtyIndex = qtyIndex.filter(index => index !== data?.id);
    let updatedDiscountQuantity = { ...discountQuantity };
    const isIdPresent = allProductServiceData.some(item => item.id === data?.id);
    if (isIdPresent) {
      // delete updatedDiscountQuantity[id];
      for (const key in updatedDiscountQuantity) {
        if (updatedDiscountQuantity[key].id === data?.id) {
          delete updatedDiscountQuantity[key];
          break;  // Exit loop after finding and deleting the element
        }
      }
      const arr = Object.keys(updatedDiscountQuantity).sort((a, b) => a - b).map(key => updatedDiscountQuantity[key]);
      const obj = {};
      arr.forEach((value, index) => {
        obj[index + 1] = value;
      });
      updatedDiscountQuantity = obj;
    }

    setAllProductServiceData([...filteredArray]);
    setQtyIndex(updatedQtyIndex);

    setDiscountQuantity(updatedDiscountQuantity);
  };

  const onAddress = (e) => {
    setQuoteAddress(e.target.value);
  }


  const onPaymentTerms = (e) => {
    setPaymentTerms(e.target.value)
  }

  const onNote = (e) => {
    setNote(e.target.value);
  }
  const getTotalPrice2 = (price, index,) => {
    if (discountQuantity && discountQuantity[index]?.qty) {
      const totalPrice = Number(discountQuantity[index]?.qty) * price;
      const totalDiscount = (totalPrice * Number(discountQuantity[index].discount)) / 100;
      const finalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
      const totalFinalprice = (totalPrice - finalDiscount);
      //  const totalTax = (totalFinalprice * Number(tax)) / 100;
      // const totalFinalTaxPrice = selectedCurrency !== undefined ? totalFinalprice + totalTax :selectedCurrency*(totalFinalprice + totalTax);

      const totalFinalTaxPrice = totalFinalprice
      // setDiscountQuantity((prevFormData) => ({
      //   ...prevFormData,
      //   [index]: {
      //     // ...prevFormData[index],
      //     ["totalDiscount"]: totalDiscount,
      //     ["totalTax"]: totalTax,
      //     ["price"]: price,
      //     ["totalFinalTaxPrice"]: totalFinalTaxPrice,
      //   },
      // }));
      console.log('totalFinalTaxPrice', totalFinalTaxPrice)
      return totalFinalTaxPrice;
    }
    return 0;

  }

  const onDone = () => {
    const hasUnselectedInventory = allProductServiceData.some(d => d.type !== "service" && !d.inventory);
    if (hasUnselectedInventory) {
      toast.error("Please select GL Codes for all products.");
      return; // Exit the function if validation fails
    }
    const hasUnselectedIncome = allProductServiceData.some(d => !d.income);
    if (hasUnselectedIncome) {
      toast.error(" Please select Income Account for all products/service.");
      return; // Exit the function if validation fails
    }
    const hasUnselectedGoodAccount = allProductServiceData.some(d => d.type !== "service" && !d.goodsaccount);
    if (hasUnselectedGoodAccount) {
      toast.error("Please select Cost of Goods Account for all products.");
      return; // Exit the function if validation fails
    }
    const hasInvalidPrice = allProductServiceData.some(d => {
      const price = d?.type === "service" ? d?.price : d?.sales_price;
      return price === 0 || price === "0" || price === "" || price === undefined;
    });
    
    if (hasInvalidPrice) {
      toast.error("Product price cannot be zero,or empty.");
      return; // Exit the function if validation fails
    }
    let products_details = [];
    allProductServiceData.forEach((d, i) => {
      products_details.push({
        "product_name": d?.name,
        "qty": Number(discountQuantity[`${i + 1}`]?.qty),
        "price": d?.type === "service" ? d?.price : d?.sales_price,
        "disc": Number(discountQuantity[`${i + 1}`]?.discount ? discountQuantity[`${i + 1}`]?.discount : 0),
        "total": getTotalPrice((d?.type === "service" ? d?.price : d?.sales_price), i + 1, d?.sales_tax),
        "vat": d?.sales_tax,
        "description": d?.description,
        "id": d?.id,
        "type": d?.type,
        "unit": d.unit,
        "inventory": parseInt(d.inventory),
        //"refund": d?.type === "service" ? "true" : "false",
        "refund": "false",
        'userCurrency_productTotal': (getTotalPrice2((d?.type === "service" ? d?.price : d?.sales_price), i + 1,) * selectedCurrencyValue).toFixed(2),
        "track_inventory": d?.track_inventory,
        "original_quantity": d?.quantity,
        "income_acc": parseInt(d.income),
        "costofGoodsSold_acc":  parseInt(d.goodsaccount),
        "userCurrency_salePrice":d?.showing_price
      });
    });

    let formData = new FormData();

    //formData.append('quote_docs', file);
    formData.append('customer_id', companyFinal?.type === "Company" ? companyFinal?.id : companyFinal.id);
    formData.append('invoice_date', finalStartDate);
    formData.append('expiration_date', finalEndDate);
    formData.append('address', companyFinal?.billing_address);
    formData.append('payment_terms', paymentTerms);
    formData.append('products_details', JSON.stringify(products_details));
    formData.append('sub_total', (selectedCurrencyValue * subTotal()).toFixed(2));
    formData.append('discount_amount', (selectedCurrencyValue * subDiscount()).toFixed(2));
    formData.append('vat', (selectedCurrencyValue * subVat()).toFixed(2));
    formData.append('total', (selectedCurrencyValue * subTotalValue()).toFixed(2));
    formData.append('customerCurrency_subTotal', (subTotal()).toFixed(2));
    formData.append('customerCurrency_discountAmount', (subDiscount()).toFixed(2));
    formData.append('customerCurrency_vat', (subVat()).toFixed(2));
    formData.append('customerCurrency_totalAmount', (subTotalValue()).toFixed(2));
    formData.append('customer_currency', getSymbolFromCurrency(selectedCurrency));
    formData.append('user_currency', getSymbolFromCurrency(currencyData));
    formData.append('currency_exchangeRate', selectedCurrencyValue);
    formData.append('notes', note ? note : "");
    // formData.append('invoice_docs[]', file);
    selectedFiles.filter((f, index) => {
      formData.append(`invoice_docs[${index}]`, f);
    })
    if (companyFinal === "") {
      toast.error("Please select a supplier");
      // Re-enable the button if validation fails
    } else if (finalStartDate === "") {
      toast.error("Please select start date");
      // Re-enable the button if validation fails
    } else if (finalEndDate === "") {
      toast.error("Please select end date");
      // Re-enable the button if validation fails
    } else if (paymentTerms === "") {
      toast.error("Please select a payment terms");
      // Re-enable the button if validation fails
    } else if (products_details.length <= 0) {
      toast.error("Please add Products");
      // Re-enable the button if validation fails
    } else if (Object.values(discountQuantity).some(item => !Number(item.qty) || Number(item.qty) < 0)) {
      toast.error("Please add quantity");
      // Re-enable the button if validation fails
    }
    else if (products_details.some(product => product.track_inventory === "Yes" && product.qty > product.original_quantity)) {
      toast.error("Product quantity cannot be greater than available stock");
    }
    else {
      addInvoice(formData, (r) => {
        // if (r.success === true) {
        //     toast.success(r.message);
        //     setDataPdf(r);
        //     setShowPdf(true);
        // }
        if (r.success === true) {
          Swal.fire({
            title: r.message,
            //  text: 'The product has been added successfully!',
            icon: 'success',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'my-confirm-button'
            }
          }).then(() => {
            setDataPdf(r);
            setShowPdf(true);
          })
        }

        else {
          toast.error(r.message);

        }
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");

      });
    }
  };


  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [finalStartDate, setFinalStartDate] = useState("");
  const [finalEndDate, setFinalEndDate] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedInventory ,setSelectedInventory] = useState("");
  const [selectedExpense, setSelectedExpense] = useState("");
  const toDate = moment(endDate);
  const formattedToDate = toDate.format('YYYY-MM-DD');


  const onFromDate = (e) => {
    setStartDate(e)
    console.log(e, "eeeeeeeeeeeeeee")
    const fromDate = moment(e).format('MM/DD/YYYY');
    console.log(fromDate, "fromDate");
    setFinalStartDate(fromDate)

  }

  const onToDate = (e) => {
    setEndDate(e)
    console.log(e, "eeeeeeeeeeeeeee")
    const toDate = moment(e).format('MM/DD/YYYY');
    console.log(toDate, "toDate")
    setFinalEndDate(toDate)
  }
  async function getInventoryDataList() {
    getInventoryAccounts("").then(function (result) {
      const response = result?.data;
      console.log(response, "rrrr")
      if (response?.message === 'No account found') { setInventoryOptions([]) }
      else {

        setInventoryOptions(response?.data?.inventoryAccounts);
      }
      // setAllProductServiceList2(response?.data);
      // setAllProductServiceList(response?.data);
    });
  }
  async function getUseraccountList() {
    getUserAccountList("").then(function (result) {
      const response = result?.data;
      console.log(response, "rrrr")
      if (response?.message === 'No account found') { setCostOfGoodsSoldList([]);setIncomeAccList([]); }
      else {

        setCostOfGoodsSoldList(response?.data?.costOfGoodsSoldList);
        setIncomeAccList(response?.data?.incomeAccList);
      }
      // setAllProductServiceList2(response?.data);
      // setAllProductServiceList(response?.data);
    });
  }
  useEffect(() => {
    getInventoryDataList();
    getUseraccountList()
  }, []);
  const onInventoryChange = (e, index, rowData) => {

    const selectedInventoryId = e.target.value; // Get the selected inventory ID from the event

    // Update the inventory for the object at the given index
    const updatedData = [...allProductServiceData]; // Create a copy of the array
    updatedData[index] = { ...updatedData[index], inventory: selectedInventoryId }; // Update the inventory property for the object at the given index
    console.log('e.target.value', updatedData)
    // Call your function to update the state with the updated data
    setAllProductServiceData(updatedData);
  };
  const onIncome = (e, index, rowData) => {

    const selectedInventoryId = e.target.value; // Get the selected inventory ID from the event

    // Update the inventory for the object at the given index
    const updatedData = [...allProductServiceData]; // Create a copy of the array
    updatedData[index] = { ...updatedData[index], income: selectedInventoryId }; // Update the inventory property for the object at the given index
    console.log('e.target.value', updatedData)
    // Call your function to update the state with the updated data
    setAllProductServiceData(updatedData);
  };
  const onGoodsAccount = (e, index, rowData) => {

    const selectedInventoryId = e.target.value; // Get the selected inventory ID from the event

    // Update the inventory for the object at the given index
    const updatedData = [...allProductServiceData]; // Create a copy of the array
    updatedData[index] = { ...updatedData[index], goodsaccount: selectedInventoryId }; // Update the inventory property for the object at the given index
    console.log('e.target.value', updatedData)
    // Call your function to update the state with the updated data
    setAllProductServiceData(updatedData);
  };

  const onSave2 = (id) => {
 
    setSelectedExpense(accountName)
    setSelectedInventory(accountName)
    getAddInventoryaccount( id,accountName, accountId, accountdescription,
      (r) => {
        console.log(r, "response")
        // if (r.success === true) {
        //   toast.success(r.message)
        //   // setAddaccount(false)
        //   // getUserAccounts();
        //   // setSelectedSubtype('')
        //   addaccountClose()
        //   getInventoryDataList();
        // }
        if (r.success === true) {
          Swal.fire({
            title: r.message,
            //  text: 'The product has been added successfully!',
            icon: 'success',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'my-confirm-button'
            }
          }).then(() => {
            addaccountClose();
            addincomeaccountClose();
            addexpenaccountClose();
            getInventoryDataList();
            getUseraccountList()
          })
        }
        else {
          console.log("nononononono")
          toast.error(r.message)
          // setSelectedSubtype('')
        }
      })

  }
  return (
    <div className="producservlisting pdt">
      <Dbheader />
      <Sidebar>
        <div className='customerMain'>
          <div className='productServList'>
            <div className='dbTopsec pb-4 pt-2'>
              <Headtxt link="/invoice" title="New Invoice Creation" />

            </div>

            <div className='commonWhite addCustomerForm p-3 mb-3'>
              <Form>
                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                  <Col md="4" className='mb-2'>
                    <Dropdown className='dropselect'>
                      <Dropdown.Toggle variant="success" id="dropdown-basic" >
                        {companyFinal ? companyFinal?.type === "Company" ? companyFinal?.company_name : companyFinal.fname + " " + companyFinal.lname : "Select Customer"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={supplierShow1}>View all Customer</Dropdown.Item>
                        <Dropdown.Item onClick={supplierShow2}><i class="fa-solid fa-plus"></i> Add a Customer</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* <Form.Select aria-label="Default select example">
                                            <option selected hidden>Select Supplier</option>
                                            <option value="Company"><button>+</button> Company</option>
                                            <option value="Individual">Individual</option>

                                        </Form.Select> */}
                  </Col>
                  <Col md="4" className='datepickr mb-2'>
                    <DatePicker
                      selectsStart
                      selected={startDate}
                      onChange={onFromDate}
                      placeholderText="From"
                    // dateFormat="yyyy-MM-dd"
                    />
                    {/* <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="dd/MM/yyyy" 
                      isClearable
                      placeholderText="Select a date"
                    /> */}
                  </Col>
                  <Col md="4" className='datepickr mb-2'>
                    <DatePicker
                      selectsEnd
                      selected={endDate}
                      onChange={onToDate}
                      endDate={endDate}
                      startDate={startDate}
                      minDate={startDate}
                      placeholderText="To"
                    />
                    {/* <DatePicker
                      selected={selectedDateb}
                      onChange={handleDateChangeb}
                      dateFormat="dd/MM/yyyy"
                      isClearable
                      placeholderText="Expiration a date"
                    /> */}
                  </Col>
                </Form.Group>

                <Form.Group className="row mb-2" controlId="exampleForm.ControlInput1">
                  <Col md="4" className='mb-2'>
                    <Form.Control type="text" placeholder="Address" readOnly onChange={onAddress} value={companyFinal?.billing_address
                      ? companyFinal.billing_address
                      : [companyFinal?.country, companyFinal?.state, companyFinal?.city]
                        .filter(Boolean) // This removes empty values
                        .join(', ')} />
                  </Col>
                  <Col md="4" className='mb-2'>

                    {/* <Customdropdown options={options} placeholdr="Select" /> */}

                    <Form.Select aria-label="Default select example" onChange={onPaymentTerms}>
                      <option selected hidden>Select Payment Terms </option>
                      <option value="30 days">30 days</option>
                      <option value="60 days">60 days</option>
                      <option value="90 days">90 days</option>
                      <option value="Due on receipt">Due on receipt</option>
                      <option value="3 installation">3 installation</option>
                      <option value="2 installation">2 installation</option>

                    </Form.Select>
                  </Col>
                  <Col md="4" className='mb-3'>
                    <div id="selectors">
                      <select value={selectedCurrency} disabled>
                        {Object.keys(currencies).map((currency, index) => (
                          <option value={currency} key={index}>
                            {currency} - {currencies[currency].name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Form.Group>
                {selectedCurrencyValue != undefined ?
                  <div class="">
                    <p>Exchange Rate</p>
                    <input id="c474_exchange_rate" style={{ marginBottom: '15px' }} name="exchange_rate" readOnly class="input-medium" type="text" tabindex="7" value={`${selectedCurrencyValue}`}>
                    </input>
                    <p>{getSymbolFromCurrency(selectedCurrency)} 1 {selectedCurrency} = {getSymbolFromCurrency(currencyData)}  {selectedCurrencyValue}  {currencyData}</p>
                  </div>

                  : null}



                <div className='salesOrdTable'  >
                  <div className='listViews'>
                    <table class="table"  >
                      <thead>
                        <tr>
                          <th scope="col">Product/Service name</th>
                          <th scope="col">GL Codes</th>
                          <th scope="col">Income Account</th>
                          <th scope="col">Cost of Goods Account</th>
                          <th scope="col">Available Qty</th>
                          <th scope="col">Qty</th>
                          {/* <th scope="col">Unit</th> */}
                          <th scope="col">Price</th>
                          <th scope="col">Disc.%</th>
                          <th scope="col">Total</th>
                          <th scope="col">VAT%</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allProductServiceData.length !== 0 && allProductServiceData.map((d, i) => (
                          <tr key={d.id}>
                            {/* <td>
        <input type='checkbox' checked={deleteId.includes(d?.id)} onChange={() => onCheckDelete(d?.id, i + 1)} />
      </td> */}
                            <td>
                              <span className='lightBlock'>{d?.name}</span>
                            </td>
                            <td>
                              <select className='lightBlock4' onChange={(e) => { e.target.value==='Add Inventory' ? accountpop() : onInventoryChange(e, i, d) }} disabled={d?.type==='service'}>
                                <option value="">Select GL Codes</option>
                                {/* Populate options dynamically based on your inventory data */}
                               
                                  <option value={"Add Inventory"} onChange={() => console.log('oooo')}><i class="fa-solid fa-plus"></i>+ Add an Inventory Account</option>
                                 {
                                  inventoryOptions.map((inventoryOption, index) => (
                                    <option key={index}  value={inventoryOption.id}>{inventoryOption.account_name}</option>
                                  ))}
                              </select>
                            </td>
                            <td>
                              <select className='lightBlock4' onChange={(e) => { e.target.value==='Add Income'  ? accountpop2() : onIncome(e, i, d) }}>
                                <option value="">Select Income Account</option>
                                {/* Populate options dynamically based on your inventory data */}
                              
                                  <option value={"Add Income"}><i class="fa-solid fa-plus"></i>+ Add an Income Account</option>
                                 
                                  {incomeAccList?.map((inventoryOption, index) => (
                                    <option key={index}  value={inventoryOption.account_id}>{inventoryOption.account_name}</option>
                                  ))}
                              </select>
                            </td>
                            <td>
                              <select className='lightBlock4' disabled={d?.type==='service'} onChange={(e) => { e.target.value==='Add Cost' ? accountpop3() : onGoodsAccount(e, i, d) }}>
                                <option value="">Select Cost of Goods Account</option>
                                {/* Populate options dynamically based on your inventory data */}
                              
                                  <option value={"Add Cost"} onClick={() => accountpop3()}><i class="fa-solid fa-plus"></i>+ Add an Cost of Goods Account Account</option>
                                 

                                  { costOfGoodsSoldList?.map((inventoryOption, index) => (
                                    <option key={index}  value={inventoryOption.account_id}>{inventoryOption.account_name}</option>
                                  ))}
                              </select>
                            </td>
                            <td>
                            <input className='lightBlock3' value={d?.quantity} readOnly />
                            </td>
                            <td>
                              {d?.qty ? (
                                <input className='lightBlock3' onChange={(e) => onQuantityDiscount(e, i + 1, d)} name='qty' value={d?.qty} type='number' />
                              ) : (
                                <input className='lightBlock3' onChange={(e) => onQuantityDiscount(e, i + 1, d)} name='qty' value={d?.qty ? d?.qty : ''} type='number' />
                              )}
                            </td>
                            {/* <td>
                              <input className='lightBlock2' value={d?.unit} readOnly />
                            </td> */}
                            <td>
                              <input className='lightBlock4'  onChange={(e) => onQuantityDiscount(e, i + 1, d)} name='price' type='number' />
                              <p style={{ fontSize: '12px', color: '#3f63af' }}>* {selectedCurrency?getSymbolFromCurrency(selectedCurrency):'choose customer'} {(d?.showing_price) && selectedCurrencyValue 
  ? ((d?.showing_price) / selectedCurrencyValue).toFixed(2)
  : ''}</p>
                            </td>
                            <td>
                              {d?.disc ? (
                                <input className='lightBlock3' onChange={(e) => onQuantityDiscount(e, i + 1, d)} name='discount' value={d?.disc} type='number'/>
                              ) : (
                                <input className='lightBlock3' onChange={(e) => onQuantityDiscount(e, i + 1, d)} name='discount' value={d?.disc ? d?.disc : ''} type='number' />
                              )}
                            </td>
                            <td>
                              <input className='lightBlock2' readOnly value={d?.total ? Number(d?.total).toFixed(2) : ""} id={`all-${d?.id}`} />
                            </td>
                            <td>
                              {d?.sales_tax ? (
                                <input className='lightBlock3' onChange={(e) => onQuantityDiscount(e, i + 1, d)} name='sales_tax' value={d?.sales_tax}type='number' />
                              ) : (
                                <input className='lightBlock3' onChange={(e) => onQuantityDiscount(e, i + 1, d)} name='sales_tax' type='number' value={d?.sales_tax ? d?.sales_tax : ''} />
                              )}
                            </td>
                            <td>
                              <i className="fa-regular fa-trash-can" onClick={() => handleSingleDelete(d)}></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className='mb-4'>
                  <button type='button' className='commonButton expBtn' onClick={handleShow}><i class="fa-regular fa-magnifying-glass"></i> Add Product or Service</button>&nbsp;&nbsp;
                  {/* <button type='button' className='commonButton addBtn' onClick={handelDelete}>Delete</button> */}
                </div>


                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                  <Col md="6" className='mb-3'>
                    <Form.Control as="textarea" placeholder="Notes" rows={3} onChange={onNote} />
                  </Col>
                  <Col md="6" className='mb-3'>
                    <div className='subTotal'>
                      <div className='saflex'>
                        <span>Sub Total</span>
                        <span>{`${getSymbolFromCurrency(currency)} ${Number(subTotal1).toFixed(2)}`}</span>
                      </div>
                      {/* <div className='saflex'>
                        <span>Discount Rate</span>
                        <span>20:00</span>
                      </div> */}
                      <div className='saflex'>
                        <span>Discount Amount</span>
                        <span>{`${getSymbolFromCurrency(currency)} ${Number(subSubDiscount1).toFixed(2)}`}</span>

                      </div>
                      <div className='saflex'>
                        <span>VAT</span>
                        <span>{`${getSymbolFromCurrency(currency)} ${Number(subSubVat1).toFixed(2)}`}</span>

                      </div>
                      <div className='saflex totlbg'>
                        <span>Total</span>
                        <span>{`${getSymbolFromCurrency(currency)} ${Number(subSubTotalValue1).toFixed(2)}`}</span>

                      </div>
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                  <p>User Total</p>
                  <Col md="6" className='mb-3'>
                    <div className='subTotal'>
                      <div className='saflex'>
                        <span>Sub Total</span>
                        <span>{getSymbolFromCurrency(currencyData)}{(selectedCurrencyValue * Number(subTotal1)).toFixed(2)}</span>
                      </div>
                      {/* <div className='saflex'>
                                                <span>Discount Rate</span>
                                                <span>20:00</span>
                                            </div> */}
                      <div className='saflex'>
                        <span>Discount Amount</span>
                        <span>{getSymbolFromCurrency(currencyData)}{(selectedCurrencyValue * Number(subSubDiscount1)).toFixed(2)}</span>
                      </div>
                      <div className='saflex'>
                        <span>VAT</span>
                        <span>{getSymbolFromCurrency(currencyData)}{(selectedCurrencyValue * Number(subSubVat1)).toFixed(2)}</span>
                      </div>
                      <div className='saflex totlbg'>
                        <span>Total</span>
                        <span>
                          {getSymbolFromCurrency(currencyData)}{(selectedCurrencyValue * subSubTotalValue1).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Form.Group>
                <label>Attachments</label>
                <div className='mulFiles mt-2'>
                  <div className='mulFileselect'>
                    <i class="fa-light fa-cloud-arrow-up"></i> Choose Files
                    <input id='fileData' onChange={(e) => handelFileChange(e)}
                      type="file"
                      multiple
                    // onChange={handelFileChange}
                    /></div>
                  {/* Display the selected file names */}
                  {selectedFiles.length > 0 && (
                    <div className=''>
                      {/* <h3>Selected Files:</h3> */}
                      <ul>
                        {selectedFiles.map((file, index) => (
                          // </li>
                          <div style={{ position: 'relative', display: 'inline-block', }}>
                            {/* Render the cross icon */}
                            <span style={{ position: 'absolute', cursor: 'pointer', width: '200px' }} onClick={() => handleRemoveFile(file)}>
                              <i className="fas fa-times-circle" style={{ fontSize: '1.5em', color: 'red' }}></i>
                            </span>
                            {/* Render the current image */}
                            <i className="fa fa-file" style={{ fontSize: '4.5em', color: '#f39170', marginRight: '10px' }}></i>
                          </div>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                <p style={{ fontSize: '15px', color: '#3f63af' }}>* Calculated latest Sales Price(Optional) as per Customer currency.</p>



              </Form>
            </div>
            <button className='commonButton expBtn' onClick={onDone}>Done</button>&nbsp;&nbsp;
            <button className='commonButton addBtn'>Cancel</button>


          </div>
        </div>
      </Sidebar>



      {/* ===================For supplier product ============*/}
      <Modal size="xl" show={show} onHide={handleClose} centered className='customerModal sloModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>List of Products and Services</h5>
            <div className='slSearch'>
              <i class="fa-regular fa-magnifying-glass"></i>
              <Form.Control
                type="search"
                placeholder="Search Product and Service"
                className=" mr-sm-2"
                onChange={onSearchByProSer}
              />
            </div>
          </Modal.Title>
          <button className='commonButton expBtn modAddbtn' onClick={productServiceShow2}><i class="fa-regular fa-plus"></i> Add</button>

        </Modal.Header>


        <Modal.Body>
          <div className='listViews salesPopTable listScroll'>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Type</th>
                  <th scope="col">Name</th>
                  <th scope="col">description</th>
                  <th scope="col">Price</th>
                  <th scope="col">Unit</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Tax</th>
                </tr>
              </thead>
              <tbody>
                {productServiceList?.length <= 0  ?
                  //                 <div className='productdata'>

                  // <h6 >No Product Found</h6> 
                  //                 </div>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>No Data found</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    {/* <td>&nbsp;</td> */}
                  </tr>

                  :

                  productServiceList?.map((d) => (
                    allProductServiceData.some(item => item.id === d.id) ? null :
                      <tr>
                        <td>
                          {
                            d?.type === 'product' ?
                              <>
                                <span>&nbsp;</span>
                                <span>&nbsp;</span>
                                <input
                                  type='checkbox'
                                  onChange={() => onCheck(d)}
                                  //disabled={allProductServiceData.length !== 0 ? d?.type !== allProductServiceData[0].type : false} 
                                  disabled={d.track_inventory === "Yes" && d.quantity === 0}
                                  checked={allProductServiceData.includes(d)}
                                  style={{ marginTop: '6px' }}
                                />
                              </>
                              :
                              <input
                                type='checkbox'
                                onChange={() => onCheck(d)}
                                //disabled={allProductServiceData.length !== 0 ? d?.type !== allProductServiceData[0].type : false} 
                                disabled={d.track_inventory === "Yes" && d.quantity === 0}
                                checked={allProductServiceData.includes(d)}
                                style={{ marginTop: '6px' }}
                              />
                          }
                          &nbsp;&nbsp;&nbsp;{d?.type}
                        </td>
                        <td>{d?.name}</td>
                        <td>{d?.description}</td>
                        <td>{getSymbolFromCurrency(currencyData)} {d?.type === "service" ? d?.price : d?.sales_price}</td>
                        <td>{d?.unit}</td>
                        <td>{d?.quantity}</td>
                        <td>{d?.sales_tax}</td>
                      </tr>
                  ))}




              </tbody>
            </table>
          </div>

          {/* */}


        </Modal.Body>
        <Modal.Footer>
          {/* <button className='commonButton expBtn' onClick={handleClose}>Submit</button> */}
          <button className='commonButton expBtn' onClick={handleClose}>Close</button>
        </Modal.Footer>
      </Modal>




      <Modal size="xl" show={supplier1} onHide={supplier1Close} centered className='customerModal sloModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>View Customers</h5>
            <div className='slSearch'>
              <i class="fa-regular fa-magnifying-glass"></i>
              <Form.Control
                type="search"
                placeholder="Search Customers"
                className=" mr-sm-2"
                onChange={onSearch}
              />

            </div>

          </Modal.Title>
          <button className='commonButton expBtn modAddbtn' onClick={supplierShow2}><i class="fa-regular fa-plus"></i> Add</button>

        </Modal.Header>
        <Modal.Body>
          <div className='listViews salesPopTable'>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Customer Type</th>
                  <th scope="col">Customer Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Country</th>
                </tr>
              </thead>
              <tbody>
                {customerList?.map((d) => (
                  <tr>
                    {/* <td><input type='checkbox'/>&nbsp; Alex Brown</td> */}
                    <td ><Link onClick={onFetchSupplier.bind(d?.id, `${d?.id}`)}>{d?.type}</Link></td>
                    <td><Link onClick={onFetchSupplier.bind(d?.id, `${d?.id}`)}>{d?.type === "Company" ? d?.company_name : d?.fname + " " + d?.lname}</Link></td>
                    <td><Link onClick={onFetchSupplier.bind(d?.id, `${d?.id}`)}>{d?.email}</Link></td>
                    <td><Link onClick={onFetchSupplier.bind(d?.id, `${d?.id}`)}>{d?.phone}</Link></td>
                    <td><Link onClick={onFetchSupplier.bind(d?.id, `${d?.id}`)}>{d?.country}</Link></td>
                  </tr>
                ))}




              </tbody>
            </table>
          </div>

          {/* */}


        </Modal.Body>
        <Modal.Footer>
          <button className='commonButton expBtn' onClick={handleClosed}>Close</button>
        </Modal.Footer>
      </Modal>

      <Modal size="xl" show={supplier2} onHide={supplier2Close} centered className='customerModal sloModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Add customer</h5>
            {/* <div className='slSearch'>
                            <i class="fa-regular fa-magnifying-glass"></i>
                            <Form.Control
                                type="search"
                                placeholder="Search This List"
                                className=" mr-sm-2"
                            />
                        </div> */}
          </Modal.Title>
          {/* <button className='commonButton expBtn modAddbtn2' onClick={onSave}><i class="fa-regular fa-plus"></i> Add</button> */}


        </Modal.Header>
        <Modal.Body>
          <div className='customerMain'>
            <div className='custBtm addSupply'>
              {/* <div className='dbTopsec pb-2'>
                <Headtxt link="/supplierlisting" title="New Supplier" />
                <button className='commonButton expBtn' onClick={handleShow}><i class="fa-light fa-download"></i> Import Excel</button>
              </div> */}
              <div className='addsuppBtn'>
                <button className={`${!supply ? "commonButton active" : "commonButton "} `} onClick={toggleCompany}>Company</button>
                <button className={`${supply ? "commonButton active" : "commonButton "} `} onClick={toggleIndividual}>Individual</button>
              </div>
              <div className='commonWhite addCustomerForm p-3 mb-3'>

                <Form>
                  {!supply ?
                    <Form.Group className="row" controlId="exampleForm.ControlInput1">
                      <Col md="12" className='mb-3'>
                        <Form.Control type="text" placeholder="Company Name" onChange={onCompanyName} />
                      </Col>
                    </Form.Group> : <Form.Group className="row" controlId="exampleForm.ControlInput1">
                      <Col md="6" className='mb-3'>
                        <Form.Control type="text" placeholder="First Name" onChange={onFirstName} />
                      </Col>
                      <Col md="6" className='mb-3'>
                        <Form.Control type="text" placeholder="Last Name" onChange={onLastName} />
                      </Col>
                    </Form.Group>}

                  <Form.Group className="row" controlId="exampleForm.ControlInput1">
                    <Col md="6" className='mb-3'>
                      <Form.Control type="email" placeholder="Email Address" onChange={onEmail} />
                    </Col>
                    <Col md="6" className='mb-3'>
                      <PhoneInput
                        defaultCountry="ke"
                        value={phone}
                        className='phInp'
                        onChange={(phone) => setPhone(phone)}
                      />

                    </Col>
                  </Form.Group>
                  <Form.Group className="row" controlId="exampleForm.ControlInput1">
                    <Col md="6" className='mb-3'>
                      <Form.Control type="text" placeholder="Website URL (Optional)" onChange={onWebUrl} />
                    </Col>
                    <Col md="6" className='mb-3'>
                      <Form.Control type="text" placeholder="VAT Number(Optional)" onChange={onVatNumber} />
                    </Col>
                  </Form.Group>

                  <Form.Group className="row" controlId="exampleForm.ControlInput1">
                    <Col md="6" className='mb-3'>
                      <Form.Control as="textarea" placeholder="Billing Address" rows={3} onChange={onBillingAddress} />
                    </Col>
                    <Col md="6" className='mb-3'>
                      <Form.Select aria-label="Default select example" onChange={onCountry} className='mb-3'>
                        <option selected hidden>Select country</option>

                        {countryData.map((con) => (
                          <option value={con.isoCode}>{con.name}</option>

                        ))}
                      </Form.Select>

                      <Form.Select aria-label="Default select example" onChange={onState} className='mb-3'>
                        <option selected hidden>Select state</option>

                        {stateData.map((sta) => (
                          <option value={sta.isoCode}>{sta.name}</option>

                        ))}
                      </Form.Select>

                      <Form.Select aria-label="Default select example" onChange={onCity} className='mb-3'>
                        <option selected hidden>Select city</option>

                        {cityData.map((cit) => (
                          <option value={cit.name}>{cit.name}</option>

                        ))}
                      </Form.Select>
                      <Col md="6" className='mb-3'>
                        <CurrencySelect
                          value={selectedCurrency}
                          onChange={handleCurrencyChange}
                          placeholder="Select a currency"
                          className="slctCurrency"
                          style={{ width: '200%' }}
                        />
                        {/* </div> */}
                      </Col>
                      <Form.Control type="text" placeholder="Zip Code(Optional)" onChange={onZipCode}
                        value={zipCode} />

                    </Col>
                  </Form.Group>
                  <div className='radioline'><span>Use same address for delivery</span>
                    <div>
                      <input type="radio" id="test1" name="radio-group" onClick={handleToggleOff} />
                      <label for="test1">Yes</label>
                    </div>
                    <div>
                      <input type="radio" id="test2" name="radio-group" onClick={handleToggleOn} />
                      <label for="test2">No</label>
                    </div>
                    {/* <div className='radioRel'><Form.Check type="radio" label="Yes" name="group1" aria-label="radio1" /></div>
                  <div className='radioRel'><Form.Check type="radio" label="No" name="group1" aria-label="radio2" /></div> */}
                  </div>

                  <div className='pt-3'>
                    {isToggled &&
                      <div>
                        <Form.Group className="row mb-3" controlId="exampleForm.ControlInput1">
                          <Col md="6">
                            {/* <Form.Control type="text" placeholder="Billing Address" /> */}
                            <Form.Control as="textarea" placeholder="Delivery Address" rows={3} onChange={onDeliveryAddress} />
                          </Col>
                          <Col md="6">
                            <Form.Select aria-label="Default select example" className='mb-3' onChange={onDeliveryCountry}>
                              <option selected hidden>Select country</option>
                              {deliveryCountryData.map((con) => (
                                <option value={con.isoCode}>{con.name}</option>

                              ))}
                            </Form.Select>

                            <Form.Select aria-label="Default select example" className='mb-3' onChange={onDeliveryState}>
                              <option selected hidden>Select state</option>

                              {deliveryStateData.map((sta) => (
                                <option value={sta.isoCode}>{sta.name}</option>

                              ))}
                            </Form.Select>

                            <Form.Select aria-label="Default select example" className='mb-3' onChange={onDeliveryCity}>
                              <option selected hidden>Select city</option>

                              {deliveryCityData.map((cit) => (
                                <option value={cit.name}>{cit.name}</option>

                              ))}
                            </Form.Select>

                            <Form.Control type="text" placeholder="Zip Code(Optional)" onChange={onDeliveryZipCode} />
                          </Col>
                        </Form.Group>
                      </div>
                    }
                  </div>


                </Form>


              </div>
            </div>

          </div>
          {/* <button className='commonButton expBtn'>Save</button> */}

        </Modal.Body>
        <Modal.Footer>
          <button className='commonButton expBtn' onClick={onSave}><i class="fa-regular fa-plus"></i> Add Customer</button>
          <button className='commonButton expBtn' onClick={supplier2Close}>Close</button>
        </Modal.Footer>
      </Modal>
      {/* =====================for add suplier modalllll========= */}
      <Modal size="xl" show={show2} onHide={handleClose2} centered className='customerModal sloModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>List of Products and Services</h5>
            <div className='slSearch'>
              <i class="fa-regular fa-magnifying-glass"></i>
              <Form.Control
                type="search"
                placeholder="Search Product and Service"
                className=" mr-sm-2"
                onChange={onSearchByProSer}
              />
            </div>
          </Modal.Title>
          {/* <button className='commonButton expBtn modAddbtn' onClick={productServiceShow2}><i class="fa-regular fa-plus"></i> Add</button> */}

        </Modal.Header>


        <Modal.Body>
          <div className='listViews salesPopTable listScroll'>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Type</th>
                  <th scope="col">Name</th>
                  <th scope="col">description</th>
                  <th scope="col">Price</th>
                  <th scope="col">Unit</th>
                  <th scope="col">Tax</th>
                </tr>
              </thead>
              <tbody>
                {productServiceList3?.map((d) => (
                  allProductServiceData2.some(item => item.id === d.id) ? null :
                    <tr>
                      <td>
                        {
                          d?.type === 'product' ?
                            <>
                              <span>&nbsp;</span>
                              <span>&nbsp;</span>
                              <input
                                type='checkbox'
                                onChange={() => onCheck2(d)}
                                disabled={allProductServiceData2.length !== 0 ? d?.type !== allProductServiceData2[0].type : false}
                                checked={allProductServiceData2.includes(d)}
                                style={{ marginTop: '6px' }}
                              />
                            </>
                            :
                            <input
                              type='checkbox'
                              onChange={() => onCheck2(d)}
                              disabled={allProductServiceData2.length !== 0 ? d?.type !== allProductServiceData2[0].type : false}
                              checked={allProductServiceData2.includes(d)}
                              style={{ marginTop: '6px' }}
                            />
                        }
                        &nbsp;&nbsp;&nbsp;{d?.type}
                      </td>
                      <td>{d?.name}</td>
                      <td>{d?.description}</td>
                      <td>{d?.type === "service" ? d?.price : d?.sales_price}</td>
                      <td>{d?.unit}</td>
                      <td>{d?.tax}</td>
                    </tr>
                ))}




              </tbody>
            </table>
          </div>

          {/* */}


        </Modal.Body>
        <Modal.Footer>
          {/* <button className='commonButton expBtn' onClick={handleClose}>Submit</button> */}
          <button className='commonButton expBtn' onClick={handleClose2}>Close</button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={productService2} onHide={productServiceClose} centered className='customerModal sloModal asdModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Add Product or Service</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='commonWhite addCustomerForm p-3 mb-3'>
            <Form>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="6" className='mb-3'>
                  <Form.Select aria-label="Default select example" onChange={onType}>
                    <option selected hidden>Select Type</option>
                    <option value="Product">Product</option>
                    <option value="Service">Service</option>
                  </Form.Select>
                </Col>
                <Col md="3" className='mb-3'>
                  <Form.Control type="text" placeholder="Name" onChange={onName} />
                </Col>
                <Col md="3" className='mb-3'>
                  <Form.Control type="text" placeholder="Code" onChange={onID} />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-3'>
                  <Form.Control as="textarea" placeholder="Description(Optional)" rows={3} onChange={onDescription} />
                </Col>
              </Form.Group>



              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                {type === "Service" ?
                  <Col md="4" className='mb-3'>
                    <Form.Select aria-label="Default select example" onChange={onUnit}>
                      <option selected hidden>Select Unit</option>
                      <option value="Hour">Hour</option>
                      <option value="Day">Day</option>
                      <option value="Contract">Contract</option>
                      <option value="Percentage of completion">Percentage of completion</option>
                    </Form.Select>
                  </Col>
                  :

                  <Col md="4" className='mb-3'>
                    <Form.Select aria-label="Default select example" onChange={onUnit}>
                      <option selected hidden>Select Unit</option>
                      <option value="Piece">Piece</option>
                      <option value="Centiliter">Centiliter</option>
                      <option value="Ltr">Ltr</option>
                      <option value="Kg">Kg</option>
                      <option value="Gms">Gms</option>
                      <option value="Cubic meter">Cubic meter</option>
                      <option value="Contract">Contract</option>
                      <option value="Percentage of completion">Percentage of completion</option>
                    </Form.Select>
                  </Col>

                }
                <Col md="4" className='mb-3'>
                  <Form.Control type="number" placeholder={type === "Service" ? "Price(Optional)" : "Sales Price(Optional)"} onChange={onSalesPrice} />
                </Col>
                {/* <Col md="4" className='mb-3'>
                                        <Form.Control type="text" placeholder="Purchase tax" onChange={onPurchaseTax}/>
                                    </Col> */}
                <Col md="4" className='mb-3'>
                  <Form.Control type="number" placeholder={type === "Service" ? "Sales tax(Optional)" : "Purchase tax(Optional)"} onChange={type === "Service" ? onSalesTax : onPurchaseTax} />
                </Col>

              </Form.Group>

              {type === "Service" ? "" :
                <Form.Group className="row" controlId="exampleForm.ControlInput1">

                  <Col md="4" className='mb-3'>
                    <Form.Control type="number" placeholder="Purchase price(Optional)" onChange={onPurchasePrice} />
                  </Col>

                  <Col md="4" className='mb-3'>
                    <Form.Control type="number" placeholder="Quantity(Optional)" onChange={onOrderQty} />

                  </Col>
                  <Col md="4" className='mb-3'>
                    <Form.Control type="text" placeholder="Sales tax(Optional)" onChange={onSalesTax} />
                  </Col>
                  {/* <Col md="4" className='mb-2'>
                    <Dropdown className='dropselect'>
                      <Dropdown.Toggle variant="success" id="dropdown-basic" >
                        {companyFinal ? companyFinal?.type === "Company" ? companyFinal?.company_name : companyFinal.fname + " " + companyFinal.lname : "Select Supplier"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={supplierShow1}>View all Customers</Dropdown.Item>
                        {/* <Dropdown.Item onClick={supplierShow2}><i class="fa-solid fa-plus"></i> Add a Supplier</Dropdown.Item> 
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col> */}
                </Form.Group>}
              {type === "Service" ? "" :
                <div className='radioline mt-3 mb-3'><span>Track Inventory</span>
                  <div>
                    <input type="radio" id="test1" name="radio-group" onClick={handleTogglesOff} />
                    <label for="test1">Yes</label>
                  </div>
                  <div>
                    <input type="radio" id="test2" name="radio-group" onClick={handleTogglesOn} />
                    <label for="test2">No</label>
                  </div>

                </div>}

              <div className='rightBtn'>
                <button type='button' className='commonButton expBtn' onClick={onSaveservice}>Save</button>
              </div>


            

            </Form>

          </div>
        </Modal.Body>


        {/* <Modal.Footer>
          <button className='commonButton expBtn' onClick={onSave}><i class="fa-regular fa-plus"></i> Add Supplier</button>
          <button className='commonButton expBtn' onClick={productServiceClose}>Close</button>
        </Modal.Footer> */}
      </Modal>


      <Modal size="lg" show={showPdf} backdrop="static" onHide={handleClosePdf} className="salesExport">
        <div className='salesExpCol fullHgt'>
          <Modal.Header>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          {/* <div className='explft'>
                        <div className='salesModtop'>
                            <div className='salesModLft'>
                                <img src={bclogo} alt='' className='mb-2' />
                                <h5>ABC Company</h5>
                                <small>15 / 12 Lorem ipsum lfjfghyti <br />Ney ypork. Hilton </small>
                            </div>
                            <div className='salesModRgt'>
                                <h5>PURCHASE ORDER</h5>
                                <small>no. ClNV-NZ</small><br />
                                <small>24/11/2023</small><br />
                                <strong>Due on receipt</strong>
                            </div>
                        </div>
                        <div className='bsOrder'>
                            <div className='bsOrderlft'>
                                <h5>Billing address</h5>
                                <h6>Test Company</h6>
                                <small>15 / 12 Lorem ipsum lfjfghyti<br />
                                    Ney ypork. Hilton </small>
                            </div>
                            <div className='bsOrderRgt'>
                                <h5>Shipping address</h5>
                                <h6>Test Company</h6>
                                <small>15 / 12 Lorem ipsum lfjfghyti<br />
                                    Ney ypork. Hilton </small>
                            </div>
                        </div>
                        <div className='bsTables'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Item name & description</th>
                                        <th>VAT</th>
                                        <th>Qty</th>
                                        <th>Unit Price</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <h6>ABC Product</h6>
                                            em ipsum lfjfghyti
                                        </td>
                                        <td>20%</td>
                                        <td>1</td>
                                        <td>$20.00/price</td>
                                        <td>$20.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td colSpan="3">
                                            <h6>Subtotal</h6>
                                        </td>
                                        <td>$20.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td colSpan="3">
                                            standard rate($20.00)
                                        </td>
                                        <td>$4.00</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colSpan="3">Total</td>
                                        <td>$24</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div> */}
          {/* file:///C:/Users/BRAINIUM/Downloads/Example-PDF-document%20(4).htm */}
          {/* https://dev8.mydevfactory.com//1SimpleClick//public//quote_pdf//SQO20240226242.pdf */}


          <div className='modIframe'><iframe src={dataPdf?.data?.pdf} /></div>
          <div className='expRgt'>
            {/* <button className='commonButton expBtn btnshadow'><i class="fa-regular fa-envelope"></i> Email</button>
                        <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-print"></i> Print</button>
                        <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-down-to-bracket"></i> Download</button> */}

            {!views ?
              <div>
                <button className='commonButton expBtn btnshadow' onClick={toggleHandlerb}><i class="fa-regular fa-envelope"></i> Email</button>
                <button className='commonButton wtBtn btnshadow' onClick={onButtonClick}><i class="fa-light fa-print"></i> Print</button>
                <button className='commonButton wtBtn btnshadow' onClick={onButtonClick}><i class="fa-light fa-down-to-bracket"></i> Download</button><br /><br /><br /><br />
                <button className='commonButton expBtn btnshadow' onClick={vclose}><i class="fa-solid fa-xmark"></i> Close</button>

              </div> :
              <Form>
                <button className='commonButton backArrow' onClick={toggleHandlerc}><i class="fa-solid fa-arrow-left"></i> Back</button>
                <Form.Group className="mb-3 salesRform" controlId="exampleForm.ControlInput1">
                  <Col md="12" className='mb-2'>
                    <label>To <span className='redColor'>*</span></label>
                    <Form.Control type="text" placeholder="Johndoe@gmail.com" value={dataPdf?.data?.customerEmail} />
                  </Col>
                  <Col md="12" className='mb-2'>
                    <label>Subject :</label>
                    <Form.Control type="text" placeholder="ABC Company" value={`Customer Invoice - ${dataPdf?.data?.details?.invoice_id}`} />
                  </Col>
                  <Col md="12" className='mb-3'>
                    <label>Message :</label>
                    <Form.Control as="textarea" placeholder="Enter your message" rows={3} className='mb-3' onChange={onAddressQuote} />
                  </Col>
                  <div className='salesCheck mb-3'><Form.Check type="checkbox" onClick={checkMe} /> Send me a copy</div>
                  <button type="button" className='commonButton expBtn btnshadow' onClick={onSendPdf}>Send</button>

                </Form.Group>
              </Form>
            }


          </div>
        </div>
      </Modal>
      {/* ===============================Inventory Account Modal=========================== */}
      <Modal size="lg" show={addaccount} onHide={addaccountClose} centered className='customerModal sloModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Add an Account</h5>
          </Modal.Title>


        </Modal.Header>
        <Modal.Body>
          <div className='commonWhite addCustomerForm mb-3'>
            <Form>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-3'>
                  <CustomDropdown placeholder="Account Type" value="Inventory" />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="6" className='mb-3'>
                  <Form.Control type="text" placeholder="Account name" onChange={onAccountName} />
                </Col>
                <Col md="6" className='mb-3'>
                  <Form.Control type="text" placeholder="Account ID" onChange={onAccountId} />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-0'>
                  <Form.Control as="textarea" placeholder="Description" rows={3} onChange={onAccountDescription} />
                </Col>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='commonButton addBtn' onClick={addaccountClose}>Cancel</button>
          <button className='commonButton expBtn' onClick={()=>onSave2('4')} >Save</button>
        </Modal.Footer>
      </Modal>
 {/* ===============================Income Account Modal=========================== */}
      <Modal size="lg" show={addincomeaccount} onHide={addincomeaccountClose} centered className='customerModal sloModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Add an Account</h5>
          </Modal.Title>


        </Modal.Header>
        <Modal.Body>
          <div className='commonWhite addCustomerForm mb-3'>
            <Form>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-3'>
                  <CustomDropdown placeholder="Account Type" value="Revenue" />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="6" className='mb-3'>
                  <Form.Control type="text" placeholder="Account name" onChange={onAccountName} />
                </Col>
                <Col md="6" className='mb-3'>
                  <Form.Control type="text" placeholder="Account ID" onChange={onAccountId} />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-0'>
                  <Form.Control as="textarea" placeholder="Description" rows={3} onChange={onAccountDescription} />
                </Col>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='commonButton addBtn' onClick={addincomeaccountClose}>Cancel</button>
          <button className='commonButton expBtn' onClick={()=>onSave2('20')} >Save</button>
        </Modal.Footer>
      </Modal>
      {/* ===============================Expense Account Modal=========================== */}
      <Modal size="lg" show={addexpenseaccount} onHide={addexpenaccountClose} centered className='customerModal sloModal'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Add an Account</h5>
          </Modal.Title>


        </Modal.Header>
        <Modal.Body>
          <div className='commonWhite addCustomerForm mb-3'>
            <Form>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-3'>
                  <CustomDropdown placeholder="Account Type" value="Expenses" />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="6" className='mb-3'>
                  <Form.Control type="text" placeholder="Account name" onChange={onAccountName} />
                </Col>
                <Col md="6" className='mb-3'>
                  <Form.Control type="text" placeholder="Account ID" onChange={onAccountId} />
                </Col>
              </Form.Group>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-0'>
                  <Form.Control as="textarea" placeholder="Description" rows={3} onChange={onAccountDescription} />
                </Col>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='commonButton addBtn' onClick={addexpenaccountClose}>Cancel</button>
          <button className='commonButton expBtn' onClick={()=>onSave2('25')} >Save</button>
        </Modal.Footer>
      </Modal>
      <Modal size="sm" show={showProductexisting} onHide={handleCloseProductDelete} className='delModal'>
        <Modal.Header closeButton>
          <Modal.Title><h6>do you want to add to the current quantity of the product that exist ?</h6></Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <button variant="primary" className='commonButton expBtn' onClick={onYesProduct}  >
            Yes
          </button>
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          <button variant="primary" className='commonButton expBtn' onClick={handleCloseProductDelete}>
            No
          </button>
        </Modal.Body>
      </Modal>
    </div>
  )
}


export default Newinvoicecreation