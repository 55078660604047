import webApi, { baseURL } from "../../WebApi/WebApi";


export const getQuotationList = async (data) => {
    return await webApi.get(`quote-details-search?searchText=${data}`,

    );
}
export const getDateSearch = async (queryString) => {
    
    return await webApi.get(`quote-details-search?${queryString}`,
    );
}
// export const getQuotationCancelResend = async (id, data) => {
//     return await webApi.post(`quote-action/${id}/${data}`,

//     );
// }
export const getQuoteData = async (data) => {
    
    return await webApi.get(`/quote-details/${data}`,
    );
}
export const getExcelQuote = async () => {
    return await webApi.get( `/quote-list-export`, 
{

    });
}
export const getQuotationCancelResend = async (id, data, onSuccess, onFailure) => {
    console.log(data, "data")
    try {
        const res = await webApi.post(

            `quote-action`,{
                id:id,
                text:data
            }
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
//add quotation
export const getAddquotation = async (data, onSuccess, onFailure) => {
    console.log(data, "data")
    try {
        const res = await webApi.post(

            `/quote-details-store`,
            data,
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const getEditQuotation = async (id,data, onSuccess, onFailure) => {
    console.log(data, "data")
    try {
        const res = await webApi.post(

            `/quote-details-edit/${id}`,
            data,
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const getdeleteQuotation = async (id,onSuccess, onFailure) => {
  
    try {
        const res = await webApi.get(

            `/quote-delete/${id}`
           
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const getSendQuote = async (id, email, quoteNo, address,checks, onSuccess, onFailure) => {

    console.log(id, email, quoteNo, address, "d,email,quoteNo,address")
    try {
        const res = await webApi.post(

            `/quote-details-mail-sending/${id}`,
            {
                email: email,
                body: address,
                subject: quoteNo,
                selfCopy:checks
            }

        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const getAddbill = async (data, onSuccess, onFailure) => {
    console.log(data, "data")
    try {
        const res = await webApi.post(

            `/bill-details-store`,
            data,
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const getEditbill = async (id,data, onSuccess, onFailure) => {
    console.log(data, "data")
    try {
        const res = await webApi.post(

            `/bill-details-edit/${id}`,
            data,
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const getRefundbill = async (id,data, onSuccess, onFailure) => {
    console.log(data, "data")
    try {
        const res = await webApi.post(
`/bill-products-return/${id}`,
            data,
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const getBillList = async (data) => {
    return await webApi.get(`/bill-details-search?searchText=&${data}`,

    );
}
export const getBillAccount = async (data) => {
    return await webApi.get(`bill-refund-account-list/${data}`,

    );
}
export const getSearchBillList = async (queryString,data) => {
    return await webApi.get(`/bill-details-search?searchText=${data}&${queryString}`,

    );
}
export const getBillPaymentList = async (data) => {
    return await webApi.get(`bill-payment-details-search?searchText=&${data}`,

    );
}
export const getSearchBillPaymentList = async (data) => {
    return await webApi.get(`bill-payment-details-search?searchText=${data}`,

    );
}
export const getRefundPaymentList = async (data) => {
    return await webApi.get(`bill-return-search?searchText=&${data}`,

    );
}
export const getSearchRefundPaymentList = async (data) => {
    return await webApi.get(`bill-return-search?searchText=${data}`,

    );
}
export const getBillCancelResend = async (id, data, onSuccess, onFailure) => {
    console.log(data, "data")
    try {
        const res = await webApi.post(

            `/bill-action`,{
                id:id,
                text:data
            }
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const getBillDelete = async (id, onSuccess, onFailure) => {
    console.log(id, "id")
    try {
        const res = await webApi.get(

            `/bill-delete/${id}`
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const getExcelBill = async () => {
    return await webApi.get( `/bill-list-export`, 
{

    });
}
export const getBillData = async (data) => {
    
    return await webApi.get(`bill-details/${data}`,
    console.log('sss',data)

    );
}
export const getSendBill = async (id, email, quoteNo, address,checks, onSuccess, onFailure) => {

    console.log(id, email, quoteNo, address, "d,email,quoteNo,address")
    try {
        const res = await webApi.post(

            `/bill-details-mail-sending/${id}`,
            {
                email: email,
                body: address,
                subject: quoteNo,
                selfCopy:checks
            }

        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};

export const fetchBillAddPage = async (data) => {
    
    return await webApi.get(`/purchase-order-details/${data}`,
    // console.log('sss',data)

    );
} ;

export const getBillPaymentId = async (data) => {
    return await webApi.get(`/bill-notPaid`,

    );
};
export const getAccounttId = async (data) => {
    return await webApi.get(`/payable-accounts`,

    );
};
export const getAddBillPayment = async (id,mode,amount,date,selectedaccountId,currencydata, onSuccess, onFailure) => {

    console.log(id,mode,amount,date, "d,email,quoteNo,address")
    try {
        const res = await webApi.post(

            `/bill-manual-payment-details_store/${id}`,
            {
                manual_payment_method: mode,
                amount:currencydata ,
                manual_payment_date: date,
                manual_payment_account :selectedaccountId,
                manual_payment_type : "debit",
                supplierCurrencyaAmount:amount
            }

        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
export const getBillPaymentDownload = async (id, onSuccess, onFailure) => {
 
    try {
        const res = await webApi.post(

            `/bill-details-download`,{
                id:id,
            }
        );
        if (res.status === 200) {
            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};