import React, { useEffect, useState } from 'react'
import dbchart from '../../../images/dbchart.jpg';
import ch from '../../../images/ch.png';
import pie from '../../../images/pie.png';
import st1 from '../../../images/st1.png';
import st2 from '../../../images/st2.png';
import st3 from '../../../images/st3.png';
import st4 from '../../../images/st4.png';
import st5 from '../../../images/st5.png';
import { Link, useNavigation } from 'react-router-dom';
import Piechart from './Piechart';
import Barchart from './Barchart';
import Linechart from './Linechart';
import { getDashboardData } from '../../../../Service/Dashboard/DashboardService';
import getSymbolFromCurrency from 'currency-symbol-map'
const Dbcontent = () => {
 const [purchaseorder, setPurchaseOrder] = useState();
 const [salesorder, setSalesOrder] = useState();
 const [currency, setCurrency] = useState();
 const [expense, setExpense] = useState();
 const [currentyearexpense, setCurrentYearExpense] = useState();
 const [preyearexpense, setPreYearExpense] = useState();
 const [netincome, setNetIncome] = useState();
 const [preyearnetincome, setPreYearNetIncome] = useState();
 const [currentyearincome, setCurrentYearIncome] = useState();
 const [preyearincome, setPreYearIncome] = useState();
 const [accountPayable, setAccountPayable] = useState([]);
 const [accountReceivable, setAccountReceivable] = useState([]);
 const [fname, setFname] = useState();
 const [lname, setLname] = useState();
 const [companyname, setCompanyName] = useState();
    useEffect(() => {
        getUserData();
    setCurrency(localStorage.getItem('1Simpleclick_Currency'))
      }, []);
    
      async function getUserData() {
        getDashboardData().then(function (result) {
          const response = result?.data;
          console.log(response, "rrrr")
          setPurchaseOrder(response?.data?.billPayment);
          setExpense(response?.data?.expenses);
          setAccountPayable(response?.data?.accountsPayable);
          setPreYearExpense(response?.data?.preYearExpenses);
          setCurrentYearExpense(response?.data?.currYearExpenses);
          setAccountReceivable(response?.data?.accountReceivable);
          setSalesOrder(response?.data?.totalSalesOrder);
          setPreYearNetIncome(response?.data?.previousYearNetIncome);
          setNetIncome(response?.data?.currentYearNetIncome);
          setPreYearIncome(response?.data?.previousYearTotalIncome);
          setCurrentYearIncome(response?.data?.currentYearTotalIncome);
          setCompanyName(response?.data?.company_name);
          setLname(response?.data?.lname);
          setFname(response?.data?.fname);
          //setAllUserList(response?.data);
        });
      }

    return (
        <div className='dbTxtbase'>
            <div className='dbTopsec'>
                <h5>Dashboard</h5>
            </div>
            <div className='dbSection1 mb-4'>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='orderSlab slab1 mb-3'>
                            <h3>{getSymbolFromCurrency(currency)}{salesorder}</h3>
                            <h6>Total Invoiced Amount</h6>
                            {/* <small>20% Increase from Last Week</small> */}
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='orderSlab slab2 mb-3'>
                            <h3>{getSymbolFromCurrency(currency)}{purchaseorder}</h3>
                            <h6>Total Billed Amount</h6>
                            {/* <small>15% Increase from Last Month</small> */}
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='orderSlab slab3 mb-3'>
                            <h3>{getSymbolFromCurrency(currency)} {expense}</h3>
                            <h6>Other Expenses</h6>
                            {/* <small>5% Increase from Last Week</small> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className='dbSection1 mb-4'>
                <div className='row'>
                    <div className='col-md-8'>
                        <div className='commonWhite p-2'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='payble'>
                                        <h5>A/C Payable</h5>
                                        {accountPayable?.map((d, i) => (
  <div key={i} className='payflex'>
    <div className="payheader">
    <small className='payprice' >{d?.expiration_date}</small>
      <span className='payprice' style={{marginLeft:'70px'}} >{d?.supplier_currency} {d?.supplierCurrency_dueAmount}</span>
    </div>
    <Link to={`/viewbilldetails?id=${d?.id}`}>  <span className='payftxt' >{d?.type==='Company'?d?.company_name:d?.fname + d?.lname}</span></Link>
  </div>
))}
                                      
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='payble'>
                                        <h5>A/C Receivable</h5>
                                        {accountReceivable?.map((d, i) => (
  <div key={i} className='payflex'>
    <div className="payheader">
    <small className='payprice' >{d?.expiration_date}</small>
    
      <span className='payprice' style={{marginLeft:'30px'}} >{d?.customer_currency} {d?.customerCurrency_dueAmount}</span>
    </div>
    <Link to={`/viewbilldetails?id=${d?.id}`}>  <span className='payftxt' >{d?.type==='Company'?d?.company_name:d?.fname + " "+ d?.lname}</span></Link>
  </div>
))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                         <div className='commonWhite' style={{height: "100%"}}>
                                <div className='ovViewtop'>
                                    <h5>Overdue Invoices & Bills</h5>
                                </div>
                                <div className='ovViewbtm pie'>
                                    {/* <img src={pie} alt='' /> */}
                                    <Piechart/>
                                </div>
                            </div>
                    </div>
                </div>
            </div>


            <div className='dbSection1 mb-4'>
                <div className=''>
                    <div className='row'>                        
                        <div className='col-md-6'>
                            <div className='commonWhite'>
                                <div className='ovViewtop'>
                                    <h5>Cash flow</h5>
                                    <select>
                                        <option>Weekly</option>
                                    </select>
                                </div>
                                {/* <div><img src={ch} alt='' style={{width: "100%", borderRadius: "15px", marginBottom: '15px'}} /></div> */}
                                
                                    <Barchart/>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='commonWhite'>
                                <div className='ovViewtop'>
                                    <h5>Profit &amp; Loss</h5>
                                    <div className='incexp'>
                                        <span><span className='inc'></span>Income</span>
                                        <span><span className='exp'></span>Expense</span>
                                    </div>
                                </div>
                                {/* <div><img src={dbchart} alt='' style={{width: "100%", borderRadius: "15px", marginBottom: '15px'}} /></div> */}
                                <Linechart/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='dbSection2 mb-4'>
                <div className='serviceTool'>
                
                    <div className='commonSt st1'>
                    <Link to="/addcustomer">
                        <img src={st1} alt='' />
                        <h6>Add Customer</h6>
                        </Link>
                    </div>
                   
                    <div className='commonSt st2'>
                    <Link to="/addsuppliers">
                        <img src={st2} alt='' />
                        <h6>Add Supplier</h6>
                        </Link>
                    </div>
                    <div className='commonSt st3'>
                    <Link to="/newinvoicecreation">
                        <img src={st3} alt='' />
                        <h6>Generate Invoice</h6>
                        </Link>
                    </div>
                    <div className='commonSt st4'>
                        <Link to='/addsupplierquote'>
                        <img src={st4} alt='' />
                        <h6>Generate Quote</h6>
                        </Link>
                    </div>
                    <div className='commonSt st5'>
                    <Link to='/salesreceiptlist'>
                        <img src={st5} alt='' />
                        <h6>Manage Payment</h6>
                        </Link>
                    </div>
                </div>
            </div>

            <div className='dbSection1 mb-4'>
                <div className='commonWhite p-2'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='income'>
                                <h5>Income</h5>
                                <div className='baseFlex'>
                                    <div className='dbprice'>
                                        <small>Previous Year</small>
                                        <h6>{getSymbolFromCurrency(currency)}{preyearincome}</h6>
                                    </div>
                                    <div className='dbprice'>
                                        <small>Current Year</small>
                                        <h6>{getSymbolFromCurrency(currency)}{currentyearincome}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='expense'>
                                <h5>Expense</h5>
                                <div className='baseFlex'>
                                <div className='dbprice'>
                                        <small>Previous Year</small>
                                        <h6>{getSymbolFromCurrency(currency)}{preyearexpense}</h6>
                                    </div>
                                    <div className='dbprice'>
                                        <small>Current Year</small>
                                        <h6>{getSymbolFromCurrency(currency)}{currentyearexpense}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='netincome'>
                                <h5>Net Income</h5>
                                <div className='baseFlex'>
                                    <div className='dbprice'>
                                        <small>Previous Year</small>
                                        <h6>{getSymbolFromCurrency(currency)}{preyearnetincome}</h6>
                                    </div>
                                    <div className='dbprice'>
                                        <small>Current Year</small>
                                        <h6>{getSymbolFromCurrency(currency)}{netincome}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
           
            {/* <div className='footerCopy'>Copyright &copy; 2023 1SimpleClick.com. All Rights Reserved</div> */}
        </div>
    )
}

export default Dbcontent