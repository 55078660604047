import React from 'react';
import bsbnr3 from '../../images/bsbnr3.jpg';
import bs3 from '../../images/bs3.jpg';
import Navigation from '../layout/Navigation';
import { Link } from 'react-router-dom';
import cfobanner from '../../images/cfobnr.png';
import cfo1 from '../../images/cfo1.jpg';
import cfo2 from '../../images/cfo2.jpg';
import Footer2 from '../layout/Footer2';

const Managebusinessdetails2 = (props) => {



  return (
    <>
    <div className='manageBusinessBlock'>
      <header>
        <Navigation />
      </header>
      {/* <div className='proflogo'><Link to="/"><img src={logo} /></Link></div>       */}
      <div className='authLogo cfoLogo'>&nbsp;</div>


      <section className='bannerBg cfoBnr pt-0'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <h1>CFO Service</h1>
                    <p>Transform your financial future with expert advice tailored to your needs. As your trusted partners, we bring a wealth of diverse experience and a vast network of resources to your team.</p>
                    <button className='commonButton getStartbtn'><i class="fa-regular fa-paper-plane"></i> Get In Touch</button>
                </div>
                <div className='col-lg-6'>
                    <img src={cfobanner} alt='' />
                </div>
            </div>
        </div>
    </section>
    <section className='cfoBtm'>
        <div className='container'>
          <div className='row mb-5'>
            <div className='col-lg-6 mb-3'>
              <img src={cfo1} alt='' />
            </div>
            <div className='col-lg-6'>
              <h5>Strategic Financial Leadership:</h5>
              <p>Elevate your business with a seasoned CFO providing strategic financial guidance.</p>
              <h5>Financial Optimization Expertise:</h5>
              <p>Unlock the full potential of your finances with our CFO's optimization strategies.</p>
              <h5>CFO on Demand:</h5>

              <p>Access top-tier CFO services as needed, providing flexibility and cost-effectiveness.</p>
              <h5>Executive Financial Management:</h5>
              <p>Engage a seasoned financial executive to drive success and shape your financial future.</p>
              <h5>Performance-driven CFO Solutions:</h5>
              <p>Harness the power of a results-oriented CFO to enhance your business performance.</p>
            </div>
          </div>


          <div className='row'>
            <div className='col-lg-6 order-lg-2 mb-3'>
              <img src={cfo2} alt='' />
            </div>
            <div className='col-lg-6 order-lg-1'>
              <h5>Tailored Financial Strategies:</h5>
              <p>Benefit from customized financial solutions designed by our experienced CFO.</p>
              <h5>Strategic Budgeting and Forecasting:</h5>

              <p>Empower your business with precise budgeting and forecasting under CFO expertise.</p>
              <h5>Cash Flow Optimization:</h5>

              <p>Optimize cash flow and financial liquidity with our CFO's targeted strategies.</p>
              <h5>Risk Management Leadership:</h5>

              <p>Mitigate risks and safeguard your financial stability with our expert CFO.</p>
              <h5>Virtual CFO Services:</h5>
              <p>Leverage the expertise of a CFO remotely for efficient and adaptable financial management</p>
            </div>
          </div>




        </div>
    </section>


    </div>
    <Footer2/>
    </>
  )
}

export default Managebusinessdetails2

