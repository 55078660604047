import { React, useState, useEffect } from 'react'
import Sidebar from '../../../layout/Sidebar'
import Dbheader from '../../../layout/Dbheader'
import Form from 'react-bootstrap/Form';
import Headtxt from '../../Headtxt';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';
import { PhoneInput } from 'react-international-phone';
import { City, Country, State } from "country-state-city";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Customdropdown from '../../../Customdropdown';
import { getSearchSupplier, getAddSuppliers, getImportCustomer, getSupplierList } from "../../../../../Service/Suppliers/SuppliersService";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate,useSearchParams } from 'react-router-dom';
import { getProductServiceList, getProductServiceListBYSupplierId } from "../../../../../Service/Customer/CustomerService";
import { Link } from 'react-router-dom'
import { getAddProductService, getImportProductService } from "../../../../../Service/ProductService/ProductService";
import { getAddquotation, getSendQuote } from "../../../../../Service/Quotation/QuotationService";
import { getPurchaseOrder,addPurchaseOrder,listPurchaseOrder,sendEmail,editPurchaseOrderget,getEditPurchaseOrder } from "../../../../../Service/PurchaseOrder/PurchaseOrder";
import moment from 'moment';
import { CSVLink } from "react-csv";
import {getEditSupplier,getSuppliereListById} from "../../../../../Service/Suppliers/SuppliersService";
import getSymbolFromCurrency from 'currency-symbol-map'
import Swal from 'sweetalert2';
import instance from '../../../../../WebApi/WebApi';
const ViewPurchaseOrder = () => {
    const [inventory, setInventory] = useState("");
    const [searchParams] = useSearchParams();
    const options = [
      { value: '30 days' },
      { value: '60 days' },
      { value: '90 days' },
      { value: 'Due on receipt' },
      { value: '3 installation' },
      { value: '2 installation' }
    ];
    const navigate = useNavigate();
  
    let countryData = Country.getAllCountries();
    let deliveryCountryData = Country.getAllCountries();
  
    const [companyType, setCompanyType] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [webUrl, setWebUrl] = useState('');
    const [vatNumber, setVatNumber] = useState('');
    const [billAddress, setBillAddress] = useState('');
    const [country, setCountry] = useState("");
    const [DeliveryCountry, setDeliveryCountry] = useState("");
    const [state, setState] = useState("");
    const [deliveryState, setDeliveryState] = useState("");
    const [city, setCity] = useState("");
    const [deliveryCity, setDeliveryCity] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [deliveryZipCode, setDeliveryZipCode] = useState();
    const [cityData, setCityData] = useState([]);
    const [deliveryCityData, setDeliveryCityData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [deliveryStateData, setDeliveryStateData] = useState([]);
    const [jsondata, setJsonData] = useState([]);
    const [type, setType] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [unit, setUnit] = useState("");
    const [salePrice, setSalePrice] = useState("");
    const [purchasePrice, setPurchasePrice] = useState("");
    const [purchaseTax, setPurchaseTax] = useState("");
    const [orderQty, setOrderQty] = useState("");
  
    const [deliveryAddress, setDeliveryAddress] = useState("");
    // For toggle
    const [isToggled, setToggled] = useState(false);
    const [currency, setCurrency] = useState("CAD");
    const handleToggleOn = () => {
      setToggled(true);
    };
    const handleToggleOff = () => {
      setToggled(false);
    };
  
    const [supplier1, setSupplier1] = useState(false);
    const supplierShow1 = () => setSupplier1(true);
    const supplier1Close = () => setSupplier1(false);
    const [dataPdf, setDataPdf] = useState("");
  
    const [supplier2, setSupplier2] = useState(false);
    const [productService2, setProductService2] = useState(false);
    const supplierShow2 = () => setSupplier2(true);
    const productServiceShow2 = () => setProductService2(true);
    const supplier2Close = () => setSupplier2(false);
    const productServiceClose = () => setProductService2(false);
  
    const [companyFinal, setCompanyFinal] = useState("");
  
    const [discountQuantity, setDiscountQuantity] = useState(0);
    const [finalTotal, setFinalTotal] = useState();
    const [quoteId,setQuoteId]=useState("");
    const [allProductServiceData, setAllProductServiceData] = useState([]);
    const [supplierId,setSupplierId] = useState("");
    const [suppliersList, setAllSuppliersList] = useState([]);
    const [productServiceList, setAllProductServiceList] = useState([]);
    const [productServiceList2, setAllProductServiceList2] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [show, setShow] = useState(false);
    const [showPdf, setShowPdf] = useState(false);
    const handleShow = () => setShow(true);
    const handleClosePdf = () => setShowPdf(false);
    const vclose = () => {
      setShowPdf(false);
      navigate('/purchaseorderlisting')
    }
    const handleClose = () => setShow(false);
    const handleClosed = () => setSupplier1(false);
    const [address, setQuoteAddress] = useState("");
    const [paymentTerms, setPaymentTerms] = useState("");
    const [note, setNote] = useState("");
    const [attachment, setAttachment] = useState("");
    const [file, setFile] = useState(null);
    const [deleteId, setDeleteId] = useState([]);
    const [views, setViews] = useState(false)
    const [emailQuote, setEmailQuote] = useState("")
    const [addressQuote, setAddressQuote] = useState("")
    const [imageUrls, setImageUrls] = useState('');
   const [checks, setChecks] = useState("false");
    const [code,setCode]=useState('')
    //email send details 
  
    const onEmailQuote = (e) => {
      setEmailQuote(e.target.value)
    }
  
    const onAddressQuote = (e) => {
      setAddressQuote(e.target.value)
    }
  
    const onSendPdf = () => {
      // sendEmail()
      sendEmail(dataPdf?.data?.details?.id, dataPdf?.data?.supplierEmail, `Vendor purchase order - ${companyFinal?.order_id}`,
        addressQuote,checks,
        (r) => {
          console.log(r, "response")
  //         if (r.success === true) {
  //           toast.success(r.message)
  //           navigate('/purchaseorderlisting')
  //  } 
   if (r.success === true) {
    Swal.fire({
     title: r.message,
   //  text: 'The product has been added successfully!',
     icon: 'success',
     confirmButtonText: 'OK',
     customClass: {
         confirmButton: 'my-confirm-button'
     }
 }).then(() => {
  navigate('/purchaseorderlisting')
 })
}
   else {
            console.log("nononononono")
            toast.error(r.message)
  
          }
        })
    }
  
    const checkMe = (e) => {
      setChecks(e.target.checked);
      // console.log(e.target.checked, "Yesssssssssss");
      console.log(e.target.checked? "Yesssssssssss" : "Noooooo");
    }
    
  
    const toggleHandlerb = (e) => {
      e.preventDefault();
      setViews(true)
    }
    const toggleHandlerc = (e) => {
      e.preventDefault();
      setViews(false)
    }
  
    const handleDrop = (event) => {
      event.preventDefault();
  
      const droppedFile = event.dataTransfer.files[0];
      handleFile(droppedFile);
    };
  
    const handleFile = (e,selectedFile) => {
      e.preventDefault();
      console.log(selectedFile)
      setFile(selectedFile);
  
      // You can perform additional actions with the file, such as uploading it to a server.
      // For simplicity, we're just logging the file information here.
      // console.log('File selected:', file);
    };
    console.log('File selected:', file);
  
    const handleDragOver = (event) => {
      event.preventDefault();
    };
    const [supply, setSupply] = useState(false)
    const toggleCompany = (e) => {
      setCompanyType("Company")
      e.preventDefault();
      setSupply(false)
    }
    const toggleIndividual = (e) => {
      setCompanyType("Individual")
  
      e.preventDefault();
      setSupply(true)
    }
  
  
    //Suppliers listing or customer listing
    useEffect(() => {
      getSupplierDataList();
    //  getProductServiceDataList();
      _GET_PURCHASE_ORDER();
    }, []);
  
    async function getSupplierDataList() {
      getSearchSupplier("").then(function (result) {
        const response = result?.data;
        // console.log(response, "rrrr")
        setAllSuppliersList(response?.data);
      });
    }
  
    async function getProductServiceDataList() {
      getProductServiceList("").then(function (result) {
        const response = result?.data;
        // console.log(response, "rrrr")
        setAllProductServiceList2(response?.data);
        setAllProductServiceList(response?.data);
      });
    }
  
    const onSearchByProSer = (e) => {
      if (e.target.value != "") { const filterCriteria = {
        supplierId: supplierId,
        searchText: e.target.value
    };

    const queryString = new URLSearchParams(filterCriteria).toString();

      getProductServiceListBYSupplierId(queryString).then(function (result) {
          const response = result?.data;
          // console.log(response, "rrrr")
  
          setAllProductServiceList(response?.data);
  
        }
        )
      } else {
        setAllProductServiceList(productServiceList2)
      }
    }
  
    const handleDateChange = (date) => {
      setSelectedDate(date);
    };
  
    const [selectedDateb, setSelectedDateb] = useState(null);
  
    const handleDateChangeb = (date) => {
      setSelectedDateb(date);
    };
  
    const onCompanyType = (e) => {
      setCompanyType(e.target.value)
    }
  
    const onCompanyName = (e) => {
      setCompanyName(e.target.value)
    }
  
    const onFirstName = (e) => {
      setFirstName(e.target.value)
    }
  
    const onLastName = (e) => {
      setLastName(e.target.value)
    }
  
    const onEmail = (e) => {
      setEmail(e.target.value)
    }
    const onWebUrl = (e) => {
      setWebUrl(e.target.value)
    }
  
    const onVatNumber = (e) => {
      setVatNumber(e.target.value)
    }
  
    const onBillingAddress = (e) => {
      setBillAddress(e.target.value)
    }
  
    const onCountry = (e) => {
      setCountry(e.target.value)
      setStateData(State.getStatesOfCountry(e.target.value));
    }
  
    const onDeliveryCountry = (e) => {
      console.log(e.target.value, "cdscds");
      setDeliveryCountry(e.target.value)
      setDeliveryStateData(State.getStatesOfCountry(e.target.value));
    }
    console.log(deliveryStateData, "dsddsd")
  
    const onState = (e) => {
      console.log(e.target.value, "stateiso")
      setState(e.target.value);
      setCityData(City.getCitiesOfState(country, e.target.value));
    }
  
    const onDeliveryState = (e) => {
      console.log(e.target.value, "stateiso")
      setDeliveryState(e.target.value);
      setDeliveryCityData(City.getCitiesOfState(DeliveryCountry, e.target.value));
    }
  
    const onCity = (e) => {
      setCity(e.target.value)
    }
  
    const onDeliveryCity = (e) => {
      setDeliveryCity(e.target.value)
    }
  
    const onZipCode = (e) => {
      setZipCode(e.target.value)
    }
  
    const onDeliveryAddress = (e) => {
      setDeliveryAddress(e.target.value)
    }
  
    const onDeliveryZipCode = (e) => {
      setDeliveryZipCode(e.target.value)
    }
  
    const onSave = () => {
  
      if (companyType === "Company" && companyName === "") {
        toast.error("Please type company name")
  
      }
      else if (companyType === "Individual" && firstName === "") {
        toast.error("Please type first name")
  
      } else if (companyType === "Individual" && lastName === "") {
        toast.error("Please type last name")
  
      } else if (email === "") {
        toast.error("Please type valid email")
      } else if (phone === "" || phone.length < 7) {
        toast.error("Please type phone number")
      }
      //  else if (companyType === "Company" && vatNumber <= 0) {
      //   toast.error("Please type a valid VAT Number(Optional)")
      // }
      else if (billAddress === "") {
        toast.error("Please type billing address")
      }
      else if (country === "") {
        toast.error("Please select country")
  
      } else if (state === "") {
        toast.error("Please select state")
      } else if (city === "") {
        toast.error("Please select city")
      }
      else if (zipCode === "") {
        toast.error("Please type zipcode")
      }
      else if (zipCode <=0) {
        toast.error("Please type valid zipcode")
      }
      else {
  
        getAddSuppliers(companyType, companyName, firstName, lastName, email, phone, webUrl
          , vatNumber, billAddress, countryData.find(c => c.isoCode === country)?.name, stateData.find(c => c.isoCode === state)?.name
          , city, zipCode,
          (r) => {
            console.log(r, "response")
  //           if (r.success === true) {
  //             toast.success(r.message)
  
  //             supplier2Close(false)
  //             getSupplierDataList()
  //  } 
   if (r.success === true) {
    Swal.fire({
     title: r.message,
   //  text: 'The product has been added successfully!',
     icon: 'success',
     confirmButtonText: 'OK',
     customClass: {
         confirmButton: 'my-confirm-button'
     }
 }).then(() => {
  supplier2Close(false)
  getSupplierDataList()
 })
}
   else {
              console.log("nononononono")
              toast.error(r.message)
  
            }
          })
      }
    }
  
  
    const onSaveservice = async () => {
      if (type === "") {
        toast.error("Please select a type")
  
      }
      else if (name === "") {
        toast.error("Please type name")
      } 
      else if (code === "") {
        toast.error("Please type code")
      } 
      else if (unit === "") {
          toast.error("Please select a unit")
        }
      // else if (salePrice === "") {
      //   toast.error("Please type Sales Price(Optional)")
      // } else if (salePrice < 0) {
      //   toast.error("Please type valid Sales Price(Optional)")
      // } else if (purchaseTax < 0) {
      //   toast.error("Please type valid purchase tax")
      // }
      // else if (type === "Product" && purchasePrice === "") {
      //   toast.error("Please type purchase price")
      // } else if (type === "Product" && purchasePrice < 0) {
      //   toast.error("Please type valid purchase price")
      // }
      // else if (type === "Product" && orderQty === "") {
      //   toast.error("Please type order quantity")
      // } else if (type === "Product" && orderQty < 0) {
      //   toast.error("Please type valid order quantity")
      // }
      else if (type === "Product" && inventory === "") {
        toast.error("Please select track inventory")
      } else {
  
        const data = {
          "type": type,
          "name": name,
          "description": description,
          "unit": unit,
          "sales_price": salePrice,
          "tax": purchaseTax,
          "purchase_price": purchasePrice,
          "quantity": orderQty,
          "track_inventory": inventory,
          "supplierId": supplierId,
          "code": code,
        // "sales_tax": salesTax,
        }
        const response = await instance.post(`/product-service-details-store`, data)
        const r =response?.data
            if (r.success === true) {
              Swal.fire({
               title: r.message,
             //  text: 'The product has been added successfully!',
               icon: 'success',
               confirmButtonText: 'OK',
               customClass: {
                   confirmButton: 'my-confirm-button'
               }
           }).then(() => {
            setProductService2(false);
              getallCustomerList(supplierId);
           })
          }
            
            else {
              console.log("nononononono")
              toast.error(r.message)
  
            }
          
      }
  
    }
  
    const onSearch = (e) => {
      console.log(e.target.value, "dsdsdsdsd")
      getSearchSupplier(e.target.value).then(function (result) {
        const response = result?.data;
        console.log(response, "rrrr")
        setAllSuppliersList(response?.data);
      });
    }
    async function getallCustomerList(d) {

      getSuppliereListById(d).then(function (result) {
          const response = result.data;
          console.log(response, "response888888886")
          //setAllData(response?.data?.supplier_details)
          const combinedData = [...response?.data?.products, ...response?.data?.services];
        setAllProductServiceList(combinedData);
          // setCustomerId(response?.data);
  
          // setDepartmentId(response?.data?.businessUnit?.Departments);
      });
  }
    const onFetchSupplier = (d) => {
      console.log(suppliersList.find(c => c.id == d))
      const companyName = suppliersList.find(c => c.id == d);
      if (companyName.type === "Company") {
        setCompanyFinal(companyName)
      } else {
        setCompanyFinal(companyName)
  
        // setCompanyFinal(`${companyName.fname} ${companyName.lname}`)
      }
      getallCustomerList(d)
      setSupplier1(false)
    }
    console.log(companyFinal, "cfcfcfcfcfcfc")
  
    // const onCheck = (data) => {
    //   if (allProductServiceData.includes(data)) {
    //     const filterData = allProductServiceData.filter(d => d != data)
    //     setAllProductServiceData(filterData)
    //   } else {
    //     allProductServiceData.push(data);
    //     setAllProductServiceData(allProductServiceData)
    //   }
    // console.log(allProductServiceData, "allProductServiceData")
  
    // }
  
  
    const onCheck = (data) => {
      if (allProductServiceData.includes(data)) {
        const filterData = allProductServiceData.filter(d => d !== data);
        setAllProductServiceData(filterData, () => {
          console.log(allProductServiceData, "allProductServiceData");
        });
      } else {
        const updatedData = [...allProductServiceData, data];
        console.log(updatedData, "updatedData");
        setAllProductServiceData(updatedData);
    
        setDiscountQuantity((prevFormData) => ({
          ...prevFormData,
          [allProductServiceData.length + 1]: {
            'id': data?.id,
            'quantity': 0,
            'discount': 0,
            'tax': data.tax || 0 // Dynamically set the vat from the data object
          },
        }));
      }
    };
  
    console.log(allProductServiceData, "allProductServiceDataoutside");
  
    const getTotalPrice = (price, index, tax) => {
      if (discountQuantity && discountQuantity[index]?.quantity) {
        const totalPrice = Number(discountQuantity[index]?.quantity) * price;
        const totalDiscount = (totalPrice * Number(discountQuantity[index].discount)) / 100;
        const finalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
        const totalFinalprice = (totalPrice - finalDiscount);
        const totalTax = (totalFinalprice * Number(tax)) / 100;
        const totalFinalTaxPrice = (totalFinalprice+totalTax);
  
        // setDiscountQuantity((prevFormData) => ({
        //   ...prevFormData,
        //   [index]: {
        //     // ...prevFormData[index],
        //     ["totalDiscount"]: totalDiscount,
        //     ["totalTax"]: totalTax,
        //     ["price"]: price,
        //     ["totalFinalTaxPrice"]: totalFinalTaxPrice,
        //   },
        // }));
  
        return totalFinalTaxPrice;
      }
      return 0;
  
    }
  
    const getTotalPrice1 = (price, tax, quantity, discount) => {
 
      if (quantity) {
        const totalPrice = Number(quantity) * price;
        const totalDiscount = (totalPrice * Number(discount)) / 100;
        const finalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
        const totalFinalPrice = (totalPrice - finalDiscount);
        const totalTax = (totalFinalPrice * Number(tax)) / 100;
        const totalFinalTaxPrice = (totalFinalPrice + totalTax);
    console.log('totalFinalTaxPrice',totalFinalTaxPrice,totalTax,totalFinalPrice,tax)
        return totalFinalTaxPrice;
      }
      return 0;
    };
    const onQuantityDiscount = (event, keyValue, data) => {
      const { name, value } = event.target;
    
      // Update discountQuantity state
      setDiscountQuantity((prevFormData) => ({
        ...prevFormData,
        [keyValue]: {
          ...prevFormData[keyValue],
          [name]: value,
        },
      }));
    
      // Update allProductServiceData state
      const updatedProductServiceData = [...allProductServiceData];
      updatedProductServiceData[keyValue - 1] = {
        ...data,
        [name]: value,
        [name === 'quantity' ? 'qty' : name === 'discount' ? 'disc' : name === 'price' ? 'purchase_price' : 'tax']: value,
      };
    
      // Calculate updated total price
      const quantity = name === 'quantity' ? value : discountQuantity[keyValue]?.quantity || data.quantity || 0;
      const discount = name === 'discount' ? value : discountQuantity[keyValue]?.discount || data.discount || 0;
      const price = name === 'price' ? value : discountQuantity[keyValue]?.price || data.price || data.purchase_price || 0;
      const tax = name === 'tax' ? value : discountQuantity[keyValue]?.tax || data.tax || 0;
    
      const totalPrice = getTotalPrice1(
        price,
        tax,
        quantity,
        discount
      );
    
      updatedProductServiceData[keyValue - 1].total = totalPrice;
      setAllProductServiceData(updatedProductServiceData);
    
      console.log(totalPrice, "Updated Total Price");
    };
  
    console.log(discountQuantity, "discountQuantity")
  
    const onButtonClick = () => {
      const pdfUrl = dataPdf?.data?.pdf;

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.target='_blank';
      // link.download = "document.pdf"; // specify the filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
  
  
  
  
  
    const onType = (e) => {
      setType(e.target.value)
    }
  
    const onName = (e) => {
      setName(e.target.value)
    }
    const onID = (e) => {
      setCode(e.target.value)
    }
    const onDescription = (e) => {
      setDescription(e.target.value)
    }
  
    const onUnit = (e) => {
      setUnit(e.target.value)
    }
  
    const onSalesPrice = (e) => {
      setSalePrice(e.target.value)
    }
  
    const onPurchasePrice = (e) => {
      setPurchasePrice(e.target.value)
    }
  
    const onPurchaseTax = (e) => {
      setPurchaseTax(e.target.value)
    }
  
    const onOrderQty = (e) => {
      setOrderQty(e.target.value)
    }
  
    const handleTogglesOn = () => {
      // setToggled(true);
      setInventory("No")
    };
    const handleTogglesOff = () => {
      // setToggled(false);
      setInventory("Yes")
    };
  
    useEffect(() => {
      subTotal();
      subDiscount();
      subVat();
      subTotalValue();
    }, [discountQuantity]);
  
    // console.log("discountQuantity", discountQuantity)
  
    const [subTotal1,setSubTotal1]=useState(0);
    const [subSubDiscount1,setSubDiscount1]=useState(0);
    const [subSubVat1,setSubVat1]=useState(0);
    const [subSubTotalValue1,setSubTotalValue1]=useState(0);

    const [selectedFiles, setSelectedFiles] = useState([]);

    // const handelFileChange = (e)=>{
    //   e.preventDefault();
    //   // console.log(e.target.files,'ll');
    //   document.querySelector('input[type="file"]').click()
    //   let a = document.getElementById('fileData');
  
    //   console.log(a,'oo');
  
    //   //  add browsed files one by one
    //   const files = Array.from(e.target.files);
    //   setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    // }
  
    const handleRemoveFile = (fileToRemove) => {
      setSelectedFiles(prevFiles => prevFiles.filter(file => file !== fileToRemove));
    };
  
    const [allData,setAllData]=useState({});
    // getPurchaseOrder
    const _GET_PURCHASE_ORDER = async () => {
      if(searchParams.get("id")){
      await editPurchaseOrderget(searchParams.get("id"))
        .then((res) => {
            const data = res.data.data.purchase_order;
            const dataSupplier = res.data.data.supplier;
        //   let data = res.data.data[0];
        setFile(data?.old_docs)
        setSelectedFiles(data?.order_docs)
          setCompanyFinal({
            type:data.type,
            address:data?.billing_address,
            name:dataSupplier.type==='Individual'?dataSupplier.fname+" "+dataSupplier.lname:dataSupplier.company_name,
            id:data.Supplier_Id,
            billing_address:dataSupplier?.billing_address,
            order_id:data.order_id
          })
          setQuoteId(data.quote_id)
          setStartDate(data.date)
          setPaymentTerms(data.payment_terms)
          setCurrency(dataSupplier?.currency)
          setEndDate(data.expiration_date)
          setNote(data.notes)
          setImageUrls(data?.quote_docs)
          // setFile(null)
          setSupplierId(dataSupplier.id)
      
          JSON.parse(data?.products_details).map((d,i)=>{return(      
            setDiscountQuantity((prevFormData) => ({
            ...prevFormData,
            [i+1]: {
              ['id']: d?.id,
              ['quantity']: d?.qty,
              ['discount']:d?.disc,
              ['tax']:d?.disc
            },
          })))})
          console.log('kkkkk',dataSupplier?.currency)
          setAllProductServiceData(JSON.parse(data.products_details).map(data=>({
            "id":data.id,
            "type": data?.type,
            "name": data.product_name,
            "description": data.description,
            "unit": data?.unit,
            "sales_price": data.price,
            "purchase_price":data?.price?data?.price:data?.purchase_price,
            "quantity":data.qty,
            'qty':data.qty,
            "tax": data.vat,
            "discount":data.disc,
            "disc":data.disc,     
            'price':data.price,
            "total":data.total
          })))
          
          setAllData(data);
          onFromDate(data.date);
          onToDate(data.expiration_date);
          subTotal();
          getallCustomerList(data.supplier_id)
        })
        .catch((err) => {
          console.log(err);
        });
      }else{
  
      }
     
    };
  
    console.log(discountQuantity,"LLLLLLL>>")
  
    useEffect(()=>{
      subTotal();
      subDiscount();
      subVat();
      subTotalValue();
    },[allData])
  
    const subTotal = () => {
  
      let totalPrice = 0;
      
      console.log(allProductServiceData,"uu");
      if (discountQuantity) {
        allProductServiceData?.map((d, i) => {
          if (discountQuantity[`${i + 1}`]) {
  
            if (discountQuantity[`${i + 1}`].quantity) {
             
  
              totalPrice += (Number(discountQuantity[`${i + 1}`].quantity) * (d?.type === "service" ? Number(d?.price) : Number(d?.purchase_price)));
              // const totalDiscount = (totalPrice * Number(discountQuantity[i].discount)) / 100;
              // const finalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
              // const totalFinalprice = (totalPrice - finalDiscount);
              // const totalTax=(totalPrice * Number(tax)) / 100;
              // const totalFinalTaxPrice=(totalPrice + totalTax)-finalDiscount;
              // console.log(discountQuantity[i]?.quantity, "totalPrice")
  
            }
          }
        })
      }
      console.log(totalPrice,"KKKKK")
      setSubTotal1(totalPrice)
      return totalPrice;
    }
  
  
    const subDiscount = () => {
      let totalDiscount = 0;
      if (discountQuantity) {
        allProductServiceData?.map((d, i) => {
          if (discountQuantity[`${i + 1}`]) {
  
            if (discountQuantity[`${i + 1}`].discount) {
              let totalPrice = 0;
  
              totalPrice += (Number(discountQuantity[`${i + 1}`].quantity) * Number(d?.type === "service" ? d?.price : d?.purchase_price));
              totalDiscount += (totalPrice * Number(discountQuantity[`${i + 1}`].discount)) / 100;
              // const finalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
              // const totalFinalprice = (totalPrice - finalDiscount);
              // const totalTax=(totalPrice * Number(tax)) / 100;
              // const totalFinalTaxPrice=(totalPrice + totalTax)-finalDiscount;
  
            }
          }
        })
      }
      setSubDiscount1(totalDiscount)
      return totalDiscount;
    }
  
    const subVat = () => {
      let totalTax = 0;
  
      if (discountQuantity) {
        allProductServiceData?.map((d, i) => {
          if (discountQuantity[`${i + 1}`]) {
  
            if (discountQuantity[`${i + 1}`].quantity) {
              let totalPrice = 0;
              totalPrice += (Number(discountQuantity[`${i + 1}`].quantity) * Number(d?.type === "service" ? d?.price : d?.purchase_price));
              const discountAmount = (totalPrice * Number(discountQuantity[`${i + 1}`].discount)) / 100;
              const discountedPrice = totalPrice - discountAmount;
              totalTax += (discountedPrice * Number(d?.tax)) / 100;
            }
          }
        })
      }
      setSubVat1(totalTax)
      return totalTax;
    }
  
    const subTotalValue = () => {
      let totalTax = 0;
      let totalPrice = 0;
      let totalDiscount = 0;
      let totalFinalTaxPrice = Number(subTotal()) - Number(subDiscount()) + Number(subVat());
      let finalDiscount = 0;
  
      // if (discountQuantity) {
      //   allProductServiceData?.map((d, i) => {
      //     if (discountQuantity[`${i + 1}`]) {
  
  
      //       totalPrice += (Number(discountQuantity[`${i + 1}`].quantity) * Number(d?.type === "service" ? d?.price : d?.purchase_price));
      //       totalDiscount += (totalPrice * Number(discountQuantity[`${i + 1}`].discount)) / 100;
      //       finalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
      //       // const totalFinalprice = (totalPrice - finalDiscount);
      //       totalTax += (totalPrice * Number(d?.tax)) / 100;
      //       totalFinalTaxPrice += (totalPrice + totalTax) - finalDiscount;
  
      //     }
      //   })
      // }
      setSubTotalValue1(String(totalFinalTaxPrice))
      return String(totalFinalTaxPrice);
    }
  
    const [qtyIndex,setQtyIndex]=useState([]);
    const onCheckDelete = (id,indexValue) => {
      // console.log(indexValue,"indexValue")
      setDeleteId((prevDeleteId) => {
        if (prevDeleteId.includes(id)) {
          let tempQtyIndex = qtyIndex.filter(item=>item!==indexValue)
          setQtyIndex(tempQtyIndex)
          // Remove the id from the array
          return prevDeleteId.filter((d) => d !== id);
        } else {
          setQtyIndex([...qtyIndex,indexValue]);
          // Add the id to the array
          return [...prevDeleteId, id];
        }
      }, () => {
        console.log("hihihihihihihi", deleteId);
      });
    };

    const handelDelete = () => {

      let idSet = new Set(deleteId);
      idSet = Array.from(idSet)
      const filteredArray = allProductServiceData.filter(item => !idSet.includes(item.id));
      
      setAllProductServiceData([...filteredArray]);
      setDeleteId([]);
      qtyIndex.forEach(key => {
        if (discountQuantity.hasOwnProperty(key)) {
        delete discountQuantity[key];
        
        }
        const arr = Object.keys(discountQuantity).sort((a, b) => a - b).map(key => discountQuantity[key]);

        const obj = {};
         arr.forEach((value, index) => {
         obj[index + 1] = value;
        });
        setDiscountQuantity(obj)
      });
     
      setQtyIndex([]);

      subTotal();
      subDiscount();
      subVat();
      subTotalValue();

    }
    const handleSingleDelete = (id) => {
      const filteredArray = allProductServiceData.filter(item => item.id !== id);
      const updatedQtyIndex = qtyIndex.filter(index => index !== id);
      let updatedDiscountQuantity = { ...discountQuantity };
      const isIdPresent = allProductServiceData.some(item => item.id === id);
      if (isIdPresent) {
        // delete updatedDiscountQuantity[id];
        for (const key in updatedDiscountQuantity) {
          if (updatedDiscountQuantity[key].id === id) {
            delete updatedDiscountQuantity[key];
            break;  // Exit loop after finding and deleting the element
          }
        }
        const arr = Object.keys(updatedDiscountQuantity).sort((a, b) => a - b).map(key => updatedDiscountQuantity[key]);
        const obj = {};
        arr.forEach((value, index) => {
          obj[index + 1] = value;
        });
        updatedDiscountQuantity = obj;
      }
    
      setAllProductServiceData([...filteredArray]);
      setQtyIndex(updatedQtyIndex);
    
      setDiscountQuantity(updatedDiscountQuantity);
    };
  
    const onAddress = (e) => {
      setQuoteAddress(e.target.value);
    }
  
  
    const onPaymentTerms = (e) => {
      setPaymentTerms(e.target.value)
    }
  
    const onNote = (e) => {
      setNote(e.target.value);
    }
  
  
    const onDone = () => {
      console.log(companyFinal?.billing_address, "addressssss")
  
      let products_details = [];
      console.log(allProductServiceData,"UUUUUUU")
      allProductServiceData.map((d, i) => (
        products_details.push({
          "product_name": d?.name,
          "qty": Number(d?.qty?d?.qty:0),
          "price": d?.type === "service" ? d?.price : d?.purchase_price,
          "disc": Number(d?.disc?d?.disc:0),
          // "disc": Number(discountQuantity[`${i + 1}`]?.discount ? discountQuantity[`${i + 1}`]?.discount : 0),
          "total": d?.total,
          "vat": d?.tax,
          "description": d?.description,
          "id":d?.id,
          "type":d?.type,
          "unit":d?.unit,
          "sales_price":d?.sales_price
        })
      ))
  
  
      let formData = new FormData();

      selectedFiles.filter(item=>!file.includes(item)).forEach((f,index) => {
        formData.append(`order_docs[${index}]`, f);
      })
      formData.append('old_docs', JSON.stringify(file));
      
      // formData.append('old_docs', []);
      // formData.append('order_docs', file);
    //   for (let i = 0 ; i < 2 ; i++) {
    //     formData.append(`order_docs${i + 1}`, file);
    // }
      formData.append('supplier_id', supplierId);
      formData.append('date', finalStartDate);
      formData.append('expiration_date', finalEndDate);
      formData.append('address', companyFinal?.billing_address);
      formData.append('payment_terms', paymentTerms);
      formData.append('products_details', JSON.stringify(products_details));
      formData.append('sub_total', (subTotal()).toFixed(2));
      formData.append('discount_amount', (subDiscount()).toFixed(2));
      formData.append('vat',(subVat()).toFixed(2));
      formData.append('total', subTotalValue());
      formData.append('notes', note?note:"");
      formData.append('supplier_currency', getSymbolFromCurrency(currency));
      const value = Object.fromEntries(formData.entries());
      console.log(value,"::::::::::::")
  
      if (companyFinal === "") {
        toast.error("Please select a supplier")
  
      } else if (finalStartDate === "") {
        toast.error("Please select start date")
      } else if (finalEndDate === "") {
        toast.error("Please select end date")
      }  else if (products_details.length <= 0) {
        toast.error("Please add  Products")
      } 
      else if (Object.values(discountQuantity).filter(item=>!Number(item.quantity)).length) {
        toast.error("Please add  quantity")
      } else {
        console.log(formData)
        getEditPurchaseOrder(searchParams.get('id'),formData,
          (r) => {
            console.log(r, "response")
            // if (r.success === true) {
            //   toast.success(r.message)
            //   // setSupplier2(false)
            //   // getSupplierDataList();
            //   setDataPdf(r)
            //   setShowPdf(true);
  
            // } 
            
            if (r.success === true) {
              Swal.fire({
               title: r.message,
             //  text: 'The product has been added successfully!',
               icon: 'success',
               confirmButtonText: 'OK',
               customClass: {
                   confirmButton: 'my-confirm-button'
               }
           }).then(() => {
            setDataPdf(r)
            setShowPdf(true);
           })
         }
            else {
              console.log("nononononono")
              toast.error(r.message)
  
            }
          })





        // addPurchaseOrder(formData,
        //   (r) => {
        //     console.log(r, "response")
        //     if (r.success === true) {
        //       toast.success(r.message)
        //       // setSupplier2(false)
        //       // getSupplierDataList();
        //       setDataPdf(r)
        //       setShowPdf(true);
  
        //     } else {
        //       console.log("nononononono")
        //       toast.error(r.message)
  
        //     }
        //   })
      }
    }
  
    // console.log(dataPdf?.data?.pdf, "pppppppppppppppp")
  
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
  
    const [finalStartDate, setFinalStartDate] = useState("");
    const [finalEndDate, setFinalEndDate] = useState("");
  
    
  
    const toDate = moment(endDate);
    const formattedToDate = toDate.format('YYYY-MM-DD');
  
  
    const onFromDate = (e) => {
      setStartDate(e)
      console.log(e, "eeeeeeeeeeeeeee")
      const fromDate = moment(e).format('MM/DD/YYYY');
      console.log(fromDate, "fromDate");
      setFinalStartDate(fromDate)
  
    }
  
    const onToDate = (e) => {
      setEndDate(e)
      console.log(e, "eeeeeeeeeeeeeee")
      const toDate = moment(e).format('MM/DD/YYYY');
      console.log(toDate, "toDate")
      setFinalEndDate(toDate)
    }
  
    const deleteImage = () => {
      // Add your logic here to delete the image
      // For example, you can reset the image URL to an empty string
      setImageUrls('');
    };
  
    const handelFileChange = (e)=>{
      e.preventDefault();
      document.querySelector('input[type="file"]').click()

      let a = document.getElementById('fileData');
  
      console.log(a,'oo');
  
      //  add browsed files one by one
      const files = Array.from(e.target.files);
      setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    }

    const _BACK = (e)=>{
      e.preventDefault();
      navigate('/purchaseorderlisting')
   }

   console.log(allProductServiceData,"OOOOOOOOOOOOOOOOOOO")
  return (
    <div className="producservlisting pdt">
    <Dbheader />
    <Sidebar>
      <div className='customerMain'>
        <div className='productServList'>
          <div className='dbTopsec pb-4 pt-2'>
            <Headtxt link="/purchaseorderlisting" title="View Purchase Order" />

          </div>

          <div className='commonWhite addCustomerForm p-3 mb-3'>
            <Form>
              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                {!searchParams.get("id") &&
                <>
                <Col md="4" className='mb-2'>
                  <Dropdown className='dropselect'>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" >
                      {companyFinal.name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={supplierShow1}>View all Suppliers</Dropdown.Item>
                      <Dropdown.Item onClick={supplierShow2}><i class="fa-solid fa-plus"></i> Add a Supplier</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <Form.Select aria-label="Default select example">
                                          <option selected hidden>Select Supplier</option>
                                          <option value="Company"><button>+</button> Company</option>
                                          <option value="Individual">Individual</option>

                                      </Form.Select> */}
                </Col>
                <Col md="4" className='mb-2'>
                  <Form.Control type="text" placeholder="Display Address" onChange={onAddress} value={companyFinal?.billing_address} disabled/>
                </Col>
                <Col md="4" className='mb-2'>
                  <Form.Control type="text" placeholder="Reference No. /Quote Id" onChange={onAddress} value={companyFinal?.billing_address} disabled/>
                </Col>
                </>
                }
                {searchParams.get("id") &&
                <>
                 <Col md="4" className='mb-2'>
                  <Form.Control type="text" disabled value={companyFinal.name} />
                </Col>
                <Col md="4" className='mb-2'>
                  <Form.Control type="text" placeholder="Address" onChange={onAddress} value={companyFinal?.billing_address 
    ? companyFinal.billing_address 
    :[companyFinal?.country, companyFinal?.state, companyFinal?.city]
    .filter(Boolean) // This removes empty values
    .join(', ')} disabled/>
                </Col>
                <Col md="4" className='mb-2'>
                  <Form.Control type="text" placeholder="Reference No. /Quote Id" onChange={onAddress} value={companyFinal?.order_id} disabled />
                </Col>
                </>
                }
              </Form.Group>

              <Form.Group className="row mb-2" controlId="exampleForm.ControlInput1">
              <Col md="6" className='datepickr mb-2'>
                  <DatePicker
                    // selectsStart
                    selected={startDate?moment(startDate).toDate():null}
                    onChange={onFromDate}
                    placeholderText="Date"
                    disabled
                  />
                </Col>
                <Col md="6" className='datepickr mb-2'>
                  <DatePicker
                    // selectsEnd
                    selected={endDate?moment(endDate).toDate():null}
                    onChange={onToDate}
                    endDate={endDate}
                    // endDate={endDate}
                    startDate={startDate}
                    minDate={startDate}
                    placeholderText="Expiration Date"
                    disabled
                  />
                  {/* <DatePicker
                    selected={selectedDateb}
                    onChange={handleDateChangeb}
                    dateFormat="dd/MM/yyyy"
                    isClearable
                    placeholderText="Expiration a date"
                  /> */}
                </Col>
              </Form.Group>

              <Form.Group className="row mb-2" controlId="exampleForm.ControlInput1">
                {/* <Col md="6" className='mb-2'>
                  <Form.Control type="text" placeholder="Address" onChange={onAddress} value={companyFinal?.billing_address} />
                </Col> */}
                <Col md="12" className='mb-2'>

                  {/* <Customdropdown options={options} placeholdr="Select" /> */}

                  <Form.Select aria-label="Default select example" onChange={onPaymentTerms} disabled>
                    {/* <option selected hidden>Select Payment Terms </option>
                    <option value="30 days" >30 days</option>
                    <option value="60 days">60 days</option>
                    <option value="90 days">90 days</option>
                    <option value="3 installation">3 installation</option>
                    <option value="2 installation">2 installation</option> */}
                    <option selected hidden value=''>Select Payment Terms(Optional) </option>
                      {options?.map((item) => (
                      <option
                        selected={item.value === paymentTerms}
                        value={item.value}
                      >
                        {item.value}
                      </option>
                    ))}
                  </Form.Select>
                </Col>

              </Form.Group>




              <div className='salesOrdTable'>
                <div className='listViews'>
                  <table class="table">
                    <thead>
                      <tr>
                       
                        <th scope="col">Product/Service name</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Unit</th>
                        <th scope="col">Price</th>
                        <th scope="col">Disc.%</th>
                        <th scope="col">Total</th>
                        <th scope="col">VAT%</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      {/* {console.log(allProductServiceData, "abc")} */}
                      {allProductServiceData.length != 0 && allProductServiceData?.map((d, i) =>{
                      return (
                        <tr>
                        {/* <td><input type='checkbox' checked={deleteId.includes(d?.id)} onChange={() => onCheckDelete(d?.id,i+1)} /></td> */}
                        <td><span className='lightBlock' disabled>{d?.name}</span></td>
                        {/* {d?.qty ? <td><input className='lightBlock' onChange={(e) => onQuantityDiscount(e, i + 1)} name='quantity' value={d?.qty}/></td> :
                        <td><input className='lightBlock' onChange={(e) => onQuantityDiscount(e, i + 1)} name='quantity' value={d?.qty}/></td>} */}
                        {/* <td>{d?.qty}</td> */}
                        <td>
                          {d?.qty ? <input className='lightBlock' disabled onChange={(e) => onQuantityDiscount(e, i + 1,d)} name='quantity' value={d?.qty}/>:<input className='lightBlock' onChange={(e) => onQuantityDiscount(e, i + 1,d)} name='quantity' value={d?.qty?d?.qty:''}/> }
                        {/* {!d?.qty && <input className='lightBlock' onChange={(e) => onQuantityDiscount(e, i + 1,d)} name='quantity' value={d?.qty?d?.qty:''}/>} */}
                        </td>

                        <td><input className='lightBlock' value={ d?.unit }disabled readOnly/></td>
                        <td><input className='lightBlock'disabled value={ d?.type === "service" ? d?.price : d?.purchase_price } onChange={(e) => onQuantityDiscount(e, i + 1,d)} name='price'/></td>
                        <td>
                          {/* {d?.disc?d?.disc:"sou"} */}
                        {/* {d?.disc && <input className='lightBlock' onChange={(e) => onQuantityDiscount(e, i + 1)} name='discount' value={d?.discount}/> }
                        {!d?.disc && <input className='lightBlock' onChange={(e) => onQuantityDiscount(e, i + 1)} name='discount' value={d?.discount}/>} */}
                          {d?.disc ? <input className='lightBlock' disabled onChange={(e) => onQuantityDiscount(e, i + 1,d)} name='discount' value={d?.disc}/>:<input className='lightBlock' disabled onChange={(e) => onQuantityDiscount(e, i + 1,d)} name='discount' value={d?.disc?d?.disc:''}/>}

                          {/* {!d?.disc && <input className='lightBlock' onChange={(e) => onQuantityDiscount(e, i + 1,d)} name='discount' value={d?.disc?d?.disc:''}/>} */}
                          </td>
                        <td>
                          <input className='lightBlock' readOnly value={d?.total?Number(d?.total).toFixed(2):""} id={`all-${d?.id}`}  />
                          </td>
                          <td>
    {d?.tax ? <input className='lightBlock' disabled onChange={(e) => onQuantityDiscount(e, i + 1, d)} name='tax' value={d?.tax} /> : <input className='lightBlock' disabled onChange={(e) => onQuantityDiscount(e, i + 1, d)} name='tax' value={d?.tax} />}
  </td>
 
                      </tr>

                      )
                        
                        // <tr>
                        //   <td><input type='checkbox' checked={deleteId.includes(d?.id)} onChange={() => onCheckDelete(d?.id)} /></td>
                        //   <td><span className='lightBlock'>{d?.name}</span></td>
                        //   <td><input className='lightBlock' onChange={(e) => onQuantityDiscount(e, i + 1)} name='quantity' defaultValue={d?.qty}  /></td>
                        //   <td><input className='lightBlock' value={d?.type === "service" ? d?.price : d?.purchase_price} readOnly /></td>
                        //   <td><input className='lightBlock' onChange={(e) => onQuantityDiscount(e, i + 1)} name='discount' defaultValue={d?.disc} /></td>
                        //   <td><input className='lightBlock' readOnly value={getTotalPrice((d?.type === "service" ? d?.price : d?.purchase_price), i + 1, d?.tax)} /></td>
                        //   <td><span className='lightBlock'>{d?.tax}</span></td>
                        // </tr>
                      })}



                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div className='mb-4'>
                <button type='button' className='commonButton expBtn' onClick={handleShow}><i class="fa-regular fa-magnifying-glass"></i> Add Product or Service</button>&nbsp;&nbsp;
                <button type='button' className='commonButton addBtn' onClick={handelDelete}>Delete</button>
              </div> */}



              <Form.Group className="row" controlId="exampleForm.ControlInput1">
                <Col md="6" className='mb-3'>
                  <Form.Control as="textarea" placeholder="Notes" value={note} rows={3} onChange={onNote} disabled/>
                </Col>
                <Col md="6" className='mb-3'>
                  <div className='subTotal'>
                    <div className='saflex'>
                      <span>Sub Total</span>
                      <span>{`${getSymbolFromCurrency(currency)} ${Number(subTotal1).toFixed(2)}`}</span>
                    </div>
                    {/* <div className='saflex'>
                      <span>Discount Rate</span>
                      <span>20:00</span>
                    </div> */}
                    <div className='saflex'>
                      <span>Discount Amount</span>
                      <span>{`${getSymbolFromCurrency(currency)} ${Number(subSubDiscount1).toFixed(2)}`}</span>

                    </div>
                    <div className='saflex'>
                      <span>VAT</span>
                      <span>{`${getSymbolFromCurrency(currency)} ${Number(subSubVat1).toFixed(2)}`}</span>

                    </div>
                    <div className='saflex totlbg'>
                      <span>Total</span>
                      <span>{`${getSymbolFromCurrency(currency)} ${Number(subSubTotalValue1).toFixed(2)}`}</span>

                    </div>
                  </div>
                </Col>
              </Form.Group>

              <label>Attachments</label>
              <div className='mulFiles mt-2'>
                <div className='mulFileselect'>
                <i class="fa-light fa-cloud-arrow-up" disabled></i> Choose Files
                <input id='fileData' 
                    type="file"
                    multiple
                    disabled
                    // onChange={handelFileChange}
                /></div>
                {/* Display the selected file names */}
                {selectedFiles.length > 0 && (
                    <div className=''>
                    {/* <h3>Selected Files:</h3> */}
                    <ul>
                    {selectedFiles.map((file, index) => (
                    // </li>
                    <div style={{ position: 'relative', display: 'inline-block', }}>
                    {/* Render the cross icon */}
                    <span style={{ position: 'absolute', cursor: 'pointer',width: '200px' }} >
                      <i className="fas fa-times-circle" style={{ fontSize: '1.5em', color: 'red' }}></i>
                    </span>
                    {/* Render the current image */}
                    <i className="fa fa-file" style={{ fontSize: '4.5em', color: '#f39170',marginRight:'10px' }}></i>
                  </div>
                    ))}
                </ul>
                    </div>
                )}
                </div>
              {/* <div className='adsDrag'>
                          { imageUrls!=''?   <div style={{ position: 'relative', display: 'inline-block' }}>

<span style={{ position: 'absolute', top: '5px', left: '5px', cursor: 'pointer' }} onClick={deleteImage}>
  <i className="fas fa-times-circle" style={{ fontSize: '1.5em', color: 'red' }}></i>
</span>

<img src={imageUrls} alt="Current" width="100px" height="100px" style={{ paddingLeft: '20px' }} />
</div>:null}
   {imageUrls===''?
                                  <div
                                      onDrop={e=>handleDrop(e)}
                                      onDragOver={e=>handleDragOver(e)}
                                      className='drgdrop'
                                  >
                                      <i class="fa-light fa-cloud-arrow-up"></i>
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                          <p>Drag &amp; drop files or </p>
                                          <input type="file" onChange={(e) => handleFile(e,e.target.files[0])} style={{ display: 'none' }} />
                                          <button onClick={(e) => handelFileChange(e)}>Browse</button>
                                      </div>

                                      {file && <p>Selected File: {file.name}</p>}
                                  </div>
                                :null  
                                }
              </div> */}
              {/* <div className='adsDrag'>
                <div
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  className='drgdrop'
                >
                  <i class="fa-light fa-cloud-arrow-up"></i>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p>Drag &amp; drop files or </p>
                    <input type="file" onChange={(e) => handleFile(e.target.files[0])} style={{ display: 'none' }} />
                    <button type='button' onClick={() => document.querySelector('input[type="file"]').click()}>Browse</button>
                  </div>

                  {file && <p>Selected File: {file.name}</p>}
                </div>
              </div> */}

              {/* <Form.Group className="row mb-2" controlId="exampleForm.ControlInput1">
       
                <Col md="12" className='mb-2'>
                  
                
                </Col>

              </Form.Group> */}


            </Form>
          </div>
          

        </div>
      </div>
    </Sidebar>



     


    {/* <Modal size="xl" show={show} onHide={handleClose} centered className='customerModal sloModal'>
      <Modal.Header closeButton>
        <Modal.Title>
          <h5>List of Products and Services</h5>
          <div className='slSearch'>
            <i class="fa-regular fa-magnifying-glass"></i>
            <Form.Control
              type="search"
              placeholder="Search Product and Service"
              className=" mr-sm-2"
              onChange={onSearchByProSer}
            />
          </div>
        </Modal.Title>
        <button className='commonButton expBtn modAddbtn' onClick={productServiceShow2}><i class="fa-regular fa-plus"></i> Add</button>

      </Modal.Header>


      <Modal.Body>
        <div className='listViews salesPopTable listScroll'>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Name</th>
                <th scope="col">description</th>
                <th scope="col">Price</th>
                <th scope="col">Unit</th>
                <th scope="col">Tax</th>
              </tr>
            </thead>
            <tbody>
              {productServiceList?.map((d) => (
                <tr>
                  <td><input type='checkbox' onChange={() => onCheck(d)} disabled={allProductServiceData.length != 0 ? d?.type != allProductServiceData[0].type : false} checked={allProductServiceData.includes(d)} />&nbsp; {d?.type}</td>
                  <td>{d?.name}</td>
                  <td>{d?.description}</td>
                  <td>{d?.type === "service" ? d?.price : d?.purchase_price}</td>
                  <td>{d?.unit}</td>
                  <td>{d?.tax}</td>
                </tr>
              ))}




            </tbody>
          </table>
        </div>

        <Pagination className='pgns'>
          <Pagination.Prev />
          <Pagination.Item>{1}</Pagination.Item>
          <Pagination.Item>{2}</Pagination.Item>
          <Pagination.Next />
        </Pagination>


      </Modal.Body>
      <Modal.Footer>
        <button className='commonButton expBtn' onClick={handleClose}>Submit</button>
        <button className='commonButton expBtn' onClick={handleClose}>Close</button>
      </Modal.Footer>
    </Modal> */}

<Modal size="xl" show={show} onHide={handleClose} centered className='customerModal sloModal'>
      <Modal.Header closeButton>
        <Modal.Title>
          <h5>List of Products and Services</h5>
          <div className='slSearch'>
            <i class="fa-regular fa-magnifying-glass"></i>
            <Form.Control
              type="search"
              placeholder="Search Product and Service"
              className=" mr-sm-2"
              onChange={onSearchByProSer}
            />
          </div>
        </Modal.Title>
        <button className='commonButton expBtn modAddbtn' onClick={productServiceShow2}><i class="fa-regular fa-plus"></i> Add</button>

      </Modal.Header>


      <Modal.Body>
        <div className='listViews salesPopTable listScroll'>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Name</th>
                <th scope="col">description</th>
                <th scope="col">Price</th>
                <th scope="col">Unit</th>
                <th scope="col">Tax</th>
              </tr>
            </thead>
            <tbody>
              {productServiceList?.map((d) => (
                  allProductServiceData.some(item => item.id === d.id)?null:
                <tr>
                        <td>
    {
        d?.type === 'product' ? 
        <>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
            <input 
                type='checkbox' 
                onChange={() => onCheck(d)} 
                //disabled={allProductServiceData.length !== 0 ? d?.type !== allProductServiceData[0].type : false} 
               // checked={allProductServiceData.includes(d)}
                style={{marginTop:'6px'}} 
            />
        </>
        :
        <input 
            type='checkbox' 
            onChange={() => onCheck(d)} 
            //disabled={allProductServiceData.length !== 0 ? d?.type !== allProductServiceData[0].type : false} 
            checked={allProductServiceData.includes(d)} 
            style={{marginTop:'6px'}}
        />
    }
    &nbsp;&nbsp;&nbsp;{d?.type}
</td>
                  <td>{d?.name}</td>
                  <td>{d?.description}</td>
                  <td>{d?.type === "service" ? d?.price : d?.purchase_price}</td>
                  <td>{d?.unit}</td>
                  <td>{d?.tax}</td>
                </tr>
                
              ))}




            </tbody>
          </table>
        </div>

        {/* <Pagination className='pgns'>
          <Pagination.Prev />
          <Pagination.Item>{1}</Pagination.Item>
          <Pagination.Item>{2}</Pagination.Item>
          <Pagination.Next />
        </Pagination> */}


      </Modal.Body>
      <Modal.Footer>
        {/* {/ <button className='commonButton expBtn' onClick={handleClose}>Submit</button> /} */}
        <button className='commonButton expBtn' onClick={handleClose}>Close</button>
      </Modal.Footer>
    </Modal>




    <Modal size="xl" show={supplier1} onHide={supplier1Close} centered className='customerModal sloModal'>
      <Modal.Header closeButton>
        <Modal.Title>
          <h5>View Suppliers</h5>
          <div className='slSearch'>
            <i class="fa-regular fa-magnifying-glass"></i>
            <Form.Control
              type="search"
              placeholder="Search Suppliers"
              className=" mr-sm-2"
              onChange={onSearch}
            />

          </div>

        </Modal.Title>
        <button className='commonButton expBtn modAddbtn' onClick={supplierShow2}><i class="fa-regular fa-plus"></i> Add</button>

      </Modal.Header>
      <Modal.Body>
        <div className='listViews salesPopTable'>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Supplier Type</th>
                <th scope="col">Supplier Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Country</th>
              </tr>
            </thead>
            <tbody>
              {suppliersList?.map((d) => (
                <tr>
                  {/* <td><input type='checkbox'/>&nbsp; Alex Brown</td> */}
                  <td ><Link onClick={onFetchSupplier.bind(d?.id, `${d?.id}`)}>{d?.type}</Link></td>
                  <td><Link onClick={onFetchSupplier.bind(d?.id, `${d?.id}`)}>{d?.type === "Company" ? d?.company_name : d?.fname + " " + d?.lname}</Link></td>
                  <td><Link onClick={onFetchSupplier.bind(d?.id, `${d?.id}`)}>{d?.email}</Link></td>
                  <td><Link onClick={onFetchSupplier.bind(d?.id, `${d?.id}`)}>{d?.phone}</Link></td>
                  <td><Link onClick={onFetchSupplier.bind(d?.id, `${d?.id}`)}>{d?.country}</Link></td>
                </tr>
              ))}




            </tbody>
          </table>
        </div>

        {/* <Pagination className='pgns'>
          <Pagination.Prev />
          <Pagination.Item>{1}</Pagination.Item>
          <Pagination.Item>{2}</Pagination.Item>
          <Pagination.Next />
        </Pagination>
 */}

      </Modal.Body>
      <Modal.Footer>
        <button className='commonButton expBtn' onClick={handleClosed}>Close</button>
      </Modal.Footer>
    </Modal>

    <Modal size="xl" show={supplier2} onHide={supplier2Close} centered className='customerModal sloModal'>
      <Modal.Header closeButton>
        <Modal.Title>
          <h5>Add Supplier</h5>
          {/* <div className='slSearch'>
                          <i class="fa-regular fa-magnifying-glass"></i>
                          <Form.Control
                              type="search"
                              placeholder="Search This List"
                              className=" mr-sm-2"
                          />
                      </div> */}
        </Modal.Title>
        {/* <button className='commonButton expBtn modAddbtn2' onClick={onSave}><i class="fa-regular fa-plus"></i> Add</button> */}


      </Modal.Header>
      <Modal.Body>
        <div className='customerMain'>
          <div className='custBtm addSupply'>
            {/* <div className='dbTopsec pb-2'>
              <Headtxt link="/supplierlisting" title="New Supplier" />
              <button className='commonButton expBtn' onClick={handleShow}><i class="fa-light fa-download"></i> Import Excel</button>
            </div> */}
            <div className='addsuppBtn'>
              <button className={`${!supply ? "commonButton active" : "commonButton "} `} onClick={toggleCompany}>Company</button>
              <button className={`${supply ? "commonButton active" : "commonButton "} `} onClick={toggleIndividual}>Individual</button>
            </div>
            <div className='commonWhite addCustomerForm p-3 mb-3'>

              <Form>
                {!supply ?
                  <Form.Group className="row" controlId="exampleForm.ControlInput1">
                    <Col md="12" className='mb-3'>
                      <Form.Control type="text" placeholder="Company Name" onChange={onCompanyName} />
                    </Col>
                  </Form.Group> : <Form.Group className="row" controlId="exampleForm.ControlInput1">
                    <Col md="6" className='mb-3'>
                      <Form.Control type="text" placeholder="First Name" onChange={onFirstName} />
                    </Col>
                    <Col md="6" className='mb-3'>
                      <Form.Control type="text" placeholder="Last Name" onChange={onLastName} />
                    </Col>
                  </Form.Group>}

                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                  <Col md="6" className='mb-3'>
                    <Form.Control type="email" placeholder="Email Address" onChange={onEmail} />
                  </Col>
                  <Col md="6" className='mb-3'>
                    <PhoneInput
                      defaultCountry="ua"
                      value={phone}
                      className='phInp'
                      onChange={(phone) => setPhone(phone)}
                    />

                  </Col>
                </Form.Group>
                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                  <Col md="6" className='mb-3'>
                    <Form.Control type="text" placeholder="Website URL (Optional)" onChange={onWebUrl} />
                  </Col>
                  <Col md="6" className='mb-3'>
                    <Form.Control type="text" placeholder="VAT Number(Optional)" onChange={onVatNumber} />
                  </Col>
                </Form.Group>

                <Form.Group className="row" controlId="exampleForm.ControlInput1">
                  <Col md="6" className='mb-3'>
                    <Form.Control as="textarea" placeholder="Billing Address" rows={3} onChange={onBillingAddress} />
                  </Col>
                  <Col md="6" className='mb-3'>
                    <Form.Select aria-label="Default select example" onChange={onCountry} className='mb-3'>
                      <option selected hidden>Select country</option>

                      {countryData.map((con) => (
                        <option value={con.isoCode}>{con.name}</option>

                      ))}
                    </Form.Select>

                    <Form.Select aria-label="Default select example" onChange={onState} className='mb-3'>
                      <option selected hidden>Select state</option>

                      {stateData.map((sta) => (
                        <option value={sta.isoCode}>{sta.name}</option>

                      ))}
                    </Form.Select>

                    <Form.Select aria-label="Default select example" onChange={onCity} className='mb-3'>
                      <option selected hidden>Select city</option>

                      {cityData.map((cit) => (
                        <option value={cit.name}>{cit.name}</option>

                      ))}
                    </Form.Select>

                    <Form.Control type="text" placeholder="Zip Code(Optional)" onChange={onZipCode} />

                  </Col>
                </Form.Group>



                {/* <div className='addsBtn'>
                  <button type='submit' className='commonButton expBtn' onClick={onSave}>Save</button>&nbsp;&nbsp;
                  <button className='commonButton addBtn'>Cancel</button>
                </div> */}


              </Form>


            </div>
          </div>


        </div>
        {/* <button className='commonButton expBtn'>Save</button> */}

      </Modal.Body>
      <Modal.Footer>
        <button className='commonButton expBtn' onClick={onSave}><i class="fa-regular fa-plus"></i> Add Supplier</button>
        <button className='commonButton expBtn' onClick={supplier2Close}>Close</button>
      </Modal.Footer>
    </Modal>


    <Modal size="lg" show={productService2} onHide={productServiceClose} centered className='customerModal sloModal asdModal'>
      <Modal.Header closeButton>
        <Modal.Title>
          <h5>Add Product or Service</h5>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='commonWhite addCustomerForm p-3 mb-3'>
          <Form>
            <Form.Group className="row" controlId="exampleForm.ControlInput1">
              <Col md="6" className='mb-3'>
                <Form.Select aria-label="Default select example" onChange={onType}>
                  <option selected hidden>Select Type</option>
                  <option value="Product">Product</option>
                  <option value="Service">Service</option>
                </Form.Select>
              </Col>
              <Col md="3" className='mb-3'>
                <Form.Control type="text" placeholder="Name" onChange={onName} />
              </Col>
              <Col md="3" className='mb-3'>
                  <Form.Control type="text" placeholder="Code" onChange={onID} />
                </Col>
            </Form.Group>
            <Form.Group className="row" controlId="exampleForm.ControlInput1">
              <Col md="12" className='mb-3'>
                <Form.Control as="textarea" placeholder="Description(Optional)" rows={3} onChange={onDescription} />
              </Col>
            </Form.Group>



            <Form.Group className="row" controlId="exampleForm.ControlInput1">
            {type === "Service" ?
<Col md="4" className='mb-3'>
<Form.Select aria-label="Default select example" onChange={onUnit}>
    <option selected hidden>Select Unit</option>
    <option value="Hour">Hour</option>
    <option value="Day">Day</option>
    <option value="Contract">Contract</option>
    <option value="Percentage of completion">Percentage of completion</option>
</Form.Select>
</Col>
:

<Col md="4" className='mb-3'>
                                        <Form.Select aria-label="Default select example" onChange={onUnit}>
                                            <option selected hidden>Select Unit</option>
                                            <option value="Piece">Piece</option>
                                            <option value="Centiliter">Centiliter</option>
                                            <option value="Ltr">Ltr</option>
                                            <option value="Kg">Kg</option>
                                            <option value="Gms">Gms</option>
                                            <option value="Cubic meter">Cubic meter</option>
                                            <option value="Contract">Contract</option>
                                            <option value="Percentage of completion">Percentage of completion</option>
                                        </Form.Select>
                                    </Col>
                                    
}
              <Col md="4" className='mb-3'>
                <Form.Control type="number" placeholder={type === "Service" ?"Price(Optional)":"Sales Price(Optional)"} onChange={onSalesPrice} />
              </Col>
       
              <Col md="4" className='mb-3'>
                <Form.Control type="number" placeholder="Purchase tax(Optional)" onChange={onPurchaseTax} />
              </Col>

            </Form.Group>

            {type === "Service" ? "" :
              <Form.Group className="row" controlId="exampleForm.ControlInput1">

                <Col md="4" className='mb-3'>
                  <Form.Control type="number" placeholder="Purchase price(Optional)" onChange={onPurchasePrice} />
                </Col>

                <Col md="4" className='mb-3'>
                  <Form.Control type="number" placeholder="Quantity(Optional)" onChange={onOrderQty} />

                </Col>
              </Form.Group>}
            {type === "Service" ? "" :
              <div className='radioline mt-3 mb-3'><span>Track Inventory</span>
                <div>
                  <input type="radio" id="test1" name="radio-group" onClick={handleTogglesOff} />
                  <label for="test1">Yes</label>
                </div>
                <div>
                  <input type="radio" id="test2" name="radio-group" onClick={handleTogglesOn} />
                  <label for="test2">No</label>
                </div>

              </div>}

            <div className='rightBtn'>
              <button type='button' className='commonButton expBtn' onClick={onSaveservice}>Save</button>
            </div>




          </Form>

        </div>
      </Modal.Body>



    </Modal>


    <Modal size="lg" show={showPdf} backdrop="static" onHide={handleClosePdf} className="salesExport">
      <div className='salesExpCol fullHgt'>
        <Modal.Header>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        {/* <div className='explft'>
                      <div className='salesModtop'>
                          <div className='salesModLft'>
                              <img src={bclogo} alt='' className='mb-2' />
                              <h5>ABC Company</h5>
                              <small>15 / 12 Lorem ipsum lfjfghyti <br />Ney ypork. Hilton </small>
                          </div>
                          <div className='salesModRgt'>
                              <h5>PURCHASE ORDER</h5>
                              <small>no. ClNV-NZ</small><br />
                              <small>24/11/2023</small><br />
                              <strong>Due on receipt</strong>
                          </div>
                      </div>
                      <div className='bsOrder'>
                          <div className='bsOrderlft'>
                              <h5>Billing address</h5>
                              <h6>Test Company</h6>
                              <small>15 / 12 Lorem ipsum lfjfghyti<br />
                                  Ney ypork. Hilton </small>
                          </div>
                          <div className='bsOrderRgt'>
                              <h5>Shipping address</h5>
                              <h6>Test Company</h6>
                              <small>15 / 12 Lorem ipsum lfjfghyti<br />
                                  Ney ypork. Hilton </small>
                          </div>
                      </div>
                      <div className='bsTables'>
                          <table>
                              <thead>
                                  <tr>
                                      <th>Item name & description</th>
                                      <th>VAT</th>
                                      <th>Qty</th>
                                      <th>Unit Price</th>
                                      <th>Amount</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td>
                                          <h6>ABC Product</h6>
                                          em ipsum lfjfghyti
                                      </td>
                                      <td>20%</td>
                                      <td>1</td>
                                      <td>$20.00/price</td>
                                      <td>$20.00</td>
                                  </tr>
                                  <tr>
                                      <td>
                                          &nbsp;
                                      </td>
                                      <td colSpan="3">
                                          <h6>Subtotal</h6>
                                      </td>
                                      <td>$20.00</td>
                                  </tr>
                                  <tr>
                                      <td>
                                          &nbsp;
                                      </td>
                                      <td colSpan="3">
                                          standard rate($20.00)
                                      </td>
                                      <td>$4.00</td>
                                  </tr>
                              </tbody>
                              <tfoot>
                                  <tr>
                                      <td>&nbsp;</td>
                                      <td colSpan="3">Total</td>
                                      <td>$24</td>
                                  </tr>
                              </tfoot>
                          </table>
                      </div>
                  </div> */}
        {/* file:///C:/Users/BRAINIUM/Downloads/Example-PDF-document%20(4).htm */}
        {/* https://dev8.mydevfactory.com//1SimpleClick//public//quote_pdf//SQO20240226242.pdf */}


        <div className='modIframe'><iframe src={dataPdf?.data?.pdf} /></div>
        <div className='expRgt'>
          {/* <button className='commonButton expBtn btnshadow'><i class="fa-regular fa-envelope"></i> Email</button>
                      <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-print"></i> Print</button>
                      <button className='commonButton wtBtn btnshadow'><i class="fa-light fa-down-to-bracket"></i> Download</button> */}

          {!views ?
            <div>
              <button className='commonButton expBtn btnshadow' onClick={toggleHandlerb}><i class="fa-regular fa-envelope"></i> Email</button>
              <button className='commonButton wtBtn btnshadow' onClick={onButtonClick}><i class="fa-light fa-print"></i> Print</button>
              <button className='commonButton wtBtn btnshadow' onClick={onButtonClick}><i class="fa-light fa-down-to-bracket"></i> Download</button><br/><br/><br/><br/>
              <button className='commonButton expBtn btnshadow' onClick={vclose}><i class="fa-solid fa-xmark"></i> Close</button>

            </div> :
            <Form>
              <button className='commonButton backArrow' onClick={toggleHandlerc}><i class="fa-solid fa-arrow-left"></i> Back</button>
              <Form.Group className="mb-3 salesRform" controlId="exampleForm.ControlInput1">
                <Col md="12" className='mb-2'>
                  <label>To <span className='redColor'>*</span></label>
                  <Form.Control type="text" placeholder="Johndoe@gmail.com"  value={dataPdf?.data?.supplierEmail}/>
                </Col>
                <Col md="12" className='mb-2'>
                  <label>Subject :</label>
                  <Form.Control type="text" placeholder="ABC Company" value={`Vendor purchase order - ${companyFinal?.order_id}`} />
                </Col>
                <Col md="12" className='mb-3'>
                  <label>Message :</label>
                  <Form.Control as="textarea" placeholder="Enter your message" rows={3} className='mb-3' onChange={onAddressQuote} />
                </Col>
                <div className='salesCheck mb-3'><Form.Check type="checkbox" onClick={checkMe}/> Send me a copy</div>
                <button type="button" className='commonButton expBtn btnshadow' onClick={onSendPdf}>Send</button>

              </Form.Group>
            </Form>
          }


        </div>
      </div>
    </Modal>

  </div>
  )
}

export default ViewPurchaseOrder