import { PulseLoader } from "react-spinners";


const SpinnerComponent = ({ isLoading }) => {
    return (
        <>
           
            <PulseLoader color={'#202946'} loading={isLoading} className="spinner" size={30} />
        </>
    );
}

export default SpinnerComponent;