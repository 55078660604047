import React from 'react'
import Owlcarousel from './Owlcarousel'


const Managebusiness = () => {
    return (
        <div className='manageBusiness'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h2>1SimpleClick to ManageYour<br /> Business</h2>
                        <p>Rely on our CPAs and seasoned <br />
                            professionals that speak your language to give you a competitive advantage</p><br />
                        <Owlcarousel />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Managebusiness