import React from 'react';
import Navigation from '../layout/Navigation'
import Footer2 from '../layout/Footer2'
import Owlcarousel2 from './Owlcarousel2';

const Professionalservice = () => {
  return (
    <>
    <div className='authBg manageBusinessBlock'>
      <header>
        <Navigation/>
        </header>
        <div className='manageBusiness'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h2>1SimpleClick to ManageYour<br /> Business</h2>
                        <p>Rely on our CPAs and seasoned professionals that speak your language to give you a competitive advantage</p><br />
                        <Owlcarousel2 />
                    </div>
                </div>
            </div>
        </div>
        </div>
    <Footer2/>
    </>
  )
}

export default Professionalservice