import webApi, { baseURL } from "../../WebApi/WebApi";

//FOR LOGIN 

export const getForgotPassword = async (email, onSuccess, onFailure) => {
    console.log(email, "email")

    try {
        const res = await webApi.post(

            `/forgot-password-email`,
            {
                email: email,

            }
        );
        if (res.status === 200) {


            const r = res.data;
            console.log(r, "rerere")

            return onSuccess(r);

        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};
