import React from 'react';
import bsbnr from '../../images/bsbnr.jpg';
import bs1 from '../../images/bs1.jpg';
import Navigation from '../layout/Navigation';
import cfobanner from '../../images/cfobnr.png';
import cfo1 from '../../images/cfo1.jpg';
import cfo2 from '../../images/cfo2.jpg';
import Footer2 from '../layout/Footer2';

const Termscondition = (props) => {  


  return (
    <>
    <div className='authBg manageBusinessBlock'>
      <header>
        <Navigation/>
        </header>


        <div className='authLogo cfoLogo'>&nbsp;</div>
      <section className='bannerBg cfoBnr pt-0'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <h1 className='mb-0'>SimpleClick Terms Conditions</h1>
                    <h6>Last Updated: March 01, 2024</h6>
                    <p>Welcome to 1SimpleClick.com. This Terms Conditions is designed to help you understand how we collect, use, and safeguard your personal information. By accessing or using our Service, you agree to the terms of this Privacy Policy.</p>
                    {/* <button className='commonButton getStartbtn'><i class="fa-regular fa-paper-plane"></i> Get In Touch</button> */}
                </div>
                <div className='col-lg-6'>
                    <img src={cfobanner} alt='' />
                </div>
            </div>
        </div>
    </section>
    <section className='cfoBtm'>
        <div className='container'>
          {/* <div className='row mb-5'>
            <div className='col-lg-6 mb-3'>
              <img src={cfo1} alt='' />
            </div>
            <div className='col-lg-6'>              
              <h5 className='mb-3'>Option #A : Basic Bookkeeping Services</h5>
              <ul className='taxTxt'>
                <li>Focus on your business and let's the experts take care of the numbers for you</li>
                <li>Entering, Coding and Paying Bills</li>
                <li>Creating and Sending Customer Invoices</li>
                <li>Collecting Past Due Accounts Receivable</li>
                <li>Maintenance of Vendors and Customer Accounts</li>
                <li>Reconciling Bank and Credit Card Accounts Generate basic reports</li>
              </ul>              
            </div>
          </div> */}

            <div>
                <h5>1. Information We Collect</h5>
                <h6>a. Account Information:</h6>
                <p>When you sign up for 1SimpleClick, we collect personal information such as your name, email address, and company details.</p>
                <h6>b. Financial Information:</h6>
                <p>To provide our accounting services, we may collect financial information such as invoices, receipts, and transaction details. This information is securely stored and processed to deliver the functionality of our Service.</p>
                <h6>c. Usage Data:</h6>
                <p>We collect information about how you interact with our Service, including log files, device information, and usage patterns. This data helps us improve the performance and usability of our software.</p>
                <h5>2. How We Use Your Information</h5>
                <p>We use the information we collect for the following purposes:</p>
<h6>a. Providing and Improving the Service:</h6>
                <p>To deliver the core functionality of our Service and enhance its features based on user feedback.</p>
                <h6>b. Communication:</h6>
                <p>To communicate with you about your account, updates, and important information related to the Service.</p>
<h6>c. Legal Compliance:</h6>
                <p>To comply with applicable laws, regulations, or legal processes.</p>
<h6>d. Marketing:</h6>
                <p>With your consent, we may use your information to provide you with promotional content about our Service.</p>
                <h5>3. Information Sharing</h5>
                <p>We do not sell, trade, or rent your personal information to third parties. We may share information under the following circumstances:</p>
                <h6>a. Service Providers:</h6>
                <p>We may share information with third-party service providers who assist us in delivering and improving our Service.</p>
                <h6>b. Legal Compliance:</h6>
                <p>We may disclose information if required by law, court order, or government regulation.</p>
    <h6>c. Business Transfers:</h6>
                <p>In the event of a merger, acquisition, or sale of all or part of our assets, your information may be transferred as part of the transaction.</p>
                <h5>4. Security</h5>
                <p className='mb-4'>We prioritize the security of your personal information. We implement industry-standard security measures to protect against unauthorized access, disclosure, alteration, and destruction of your data.</p>
                <h5>5. Your Choices</h5>
                <p className='mb-4'>You have the right to access, correct, or delete your personal information. You can manage your preferences for communication and marketing through your account settings.</p>
                <h5>6. Updates to this Privacy Policy</h5>
                <p className='mb-4'>We may update this Privacy Policy to reflect changes in our practices or legal requirements. We will notify you of any material changes through the Service or other means.</p>
                <h6>Contact Us
If you have any questions or concerns about this Privacy Policy, please contact us at <a href='mailto:contact@1simpleclick.com'>contact@1simpleclick.com</a>.</h6>

                <p><small>By using 1SimpleClick, you acknowledge that you have read and understood this Privacy Policy and agree to the collection and use of your information as described herein.</small></p>





                
















                







                




            </div>



        </div>
    </section>

    </div>
    <Footer2/>
    </>
  )
}

export default Termscondition