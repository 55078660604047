import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card'
import { getCustomerList } from "../../../../Service/Customer/CustomerService";
const Gridviewsupplier = () => {
    const [customerList, setAllCustomerList] = useState([]);

    useEffect(() => {
        getInvoiceDataList();
    }, []);

    async function getInvoiceDataList() {
        getCustomerList().then(function (result) {
            const response = result?.data;
            console.log(response, "rrrr")
            setAllCustomerList(response?.data);
        });
    }

    return (
        <div className='gridView'>
            {customerList?.map((d) => (

                <Card>
                    <Card.Body>
                        <div className='cardBlock'>
                            <h6>Customer Type</h6>
                            {d?.type}
                        </div>
                        <div className='cardBlock'>
                            <h6>Customer Name</h6>
                            {d?.fname} {d?.lname}
                        </div>
                        <div className='cardBlock'>
                            <h6>Email</h6>
                            {d?.email}
                        </div>
                        <div className='cardBlock'>
                            <h6>Phone</h6>
                            {d?.phone}
                        </div>
                        <div className='cardBlock'>
                            <h6>Country</h6>
                            {d?.country}
                        </div>
                        <div className='cardBlock actn'>
                            <h6>Action</h6>
                            <div>
                                <span className='viewIcon'><i class="fa-light fa-eye"></i></span>
                                <span className='viewIcon orange'><i class="fa-light fa-pen"></i></span>
                                <span className='viewIcon'><i class="fa-regular fa-trash-can"></i></span>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            ))}

        </div>
    )
}


export default Gridviewsupplier