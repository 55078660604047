import React, { useState, useEffect } from 'react';
import Sidebar from '../../layout/Sidebar'
import Dbheader from '../../layout/Dbheader'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import Headtxt from '../Headtxt';
import Listview from './Listview';
import Gridview from './Gridview';
import Card from 'react-bootstrap/Card'
import { getExcelProduct, getExcelService, getProductList, getServiceList, deleteProduct, deleteService } from "../../../../Service/ProductService/ProductService"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal } from 'react-bootstrap';
import { CSVLink } from "react-csv";
import Swal from 'sweetalert2';

const Productservicelisting2 = () => {
    const [deleteProductId, setDeleteProductId] = useState("");
    const [deleteServiceId, setDeleteServiceId] = useState("");
    const [showProductDelete, setShowProductDelete] = useState(false);
    const [showServiceDelete, setShowServiceDelete] = useState(false);
    const handleCloseProductDelete = () => setShowProductDelete(false);
    const handleCloseServiceDelete = () => setShowServiceDelete(false);
    const [productExport, setProductExport] = useState([]);
    const [serviceExport, setServiceExport] = useState([]);
    const [searchProduct, setSearchProduct] = useState("");
    const [searchService, setSearchService] = useState("");



    const [supply, setSupply] = useState(false)
    const toggleProduct = (e) => {
        e.preventDefault();
        setSupply(false)
        getProductDataList()
    }
    const toggleService = (e) => {
        e.preventDefault();
        setSupply(true)
        getServiceDataList()
    }

    const [productServiceList, setAllProductServiceList] = useState([]);
    const [view, setView] = useState(false)
    const toggleHandler = (e) => {
        e.preventDefault();
        setView(true)
    }
    const toggleHandler1 = (e) => {
        e.preventDefault();
        setView(false)
    }

    useEffect(() => {
        {
            supply == false ?
                getProductDataList() :
                getServiceDataList()
        }
        onExcelServiceExport();
        onExcelProductExport();
    }, []);

    async function getProductDataList() {
        getProductList("").then(function (result) {
            const response = result?.data;
            // console.log(response, "rrrr")
            setAllProductServiceList(response?.data);
        });
    }

    async function getServiceDataList() {
        getServiceList("").then(function (result) {
            const response = result?.data;
            // console.log(response, "rrrr")
            setAllProductServiceList(response?.data);
        });
    }
    const onDelete = (data) => {
        setDeleteProductId(data);
        console.log(data, "datadatadatadatadatadata")
        setShowProductDelete(true)
    }
    const onDeleteService = (data) => {
        setDeleteServiceId(data);
        console.log(data, "datadatadatadatadatadata")
        setShowServiceDelete(true)
    }

    const onYesProduct = () => {
        deleteProduct(deleteProductId, (res) => {
            console.log(res, "response")
//             if (res.success === true) {
//                 toast.success(res.message)
//                 setShowProductDelete(false)
//                 getProductDataList();
// }
if (res.success === true) {
    setShowProductDelete(false);
    Swal.fire({
        title: res.message,
      //  text: 'The product has been added successfully!',
        icon: 'success',
        confirmButtonText: 'OK',
        customClass: {
            confirmButton: 'my-confirm-button'
        }
    }).then(() => {
        getProductDataList();
    });
}
else {
                toast.error(res.message)

            }
        })
    }

    const onNoProduct = () => {
        setShowProductDelete(false)

    }


    const onYesService = () => {
        deleteService(deleteServiceId, (res) => {
            console.log(res, "response")
//             if (res.success === true) {
//                 toast.success(res.message)
//                 setShowServiceDelete(false)
//                 getServiceDataList();
//  } 
 if (res.success === true) {
    Swal.fire({
        title: res.message,
      //  text: 'The product has been added successfully!',
        icon: 'success',
        confirmButtonText: 'OK',
        customClass: {
            confirmButton: 'my-confirm-button'
        }
    }).then(() => {
        setShowServiceDelete(false)
        getServiceDataList();
    });
}
 else {
                toast.error(res.message)

            }
        })
    }

    const onNoService = () => {
        setShowServiceDelete(false)

    }

    const onExcelProductExport = () => {
        getExcelProduct().then(function (result) {
            const response = result?.data?.data;
            console.log(response, "response")
            setProductExport(response);
        });

    }

    const onExcelServiceExport = () => {
        getExcelService().then(function (result) {
            const response = result?.data?.data;
            console.log(response, "response")
            setServiceExport(response);
        });

    }

    console.log(view, "viewview")


    // custom-select-drupdown
const [isOpen, setIsOpen] = useState(false);
const [selectedOption, setSelectedOption] = useState('More');

const toggleDropdown = () => {
  setIsOpen(!isOpen);
};

const handleOptionClick = (option) => {
  setSelectedOption(option);
  setIsOpen(false);
};

const onSearchProducts=(e)=>{
    setSearchProduct(e.target.value)
    getProductList(e.target.value).then(function (result) {
        const response = result?.data;
        // console.log(response, "rrrr")
        setAllProductServiceList(response?.data);
    });
}

const onSearchServices=(e)=>{
    setSearchProduct(e.target.value)
    getServiceList(e.target.value).then(function (result) {
        const response = result?.data;
        // console.log(response, "rrrr")
        setAllProductServiceList(response?.data);
    });
}








// FOR-LOAD-MORE
const [items, setItems] = useState(['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6', 'Item 7', 'Item 8', 'Item 9', 'Item 10', 'Item 11', 'Item 12', 'Item 13', 'Item 14', 'Item 15']);
const [visibleItems, setVisibleItems] = useState(0);
const [loading, setLoading] = useState(false);
const [hasMore, setHasMore] = useState(true);

useEffect(() => {
    // Initial load of items
    loadMoreItems();
}, []);

const loadMoreItems = () => {
    if (!hasMore || loading) return;
    setLoading(true);
    setTimeout(() => {
        const newItems = fetchMoreItems(visibleItems);
        if (newItems.length > 0) {
            setItems((prevItems) => [...prevItems, ...newItems]);
            setVisibleItems((prev) => prev + newItems.length);
        } else {
            setHasMore(false);
        }
        setLoading(false);
    }, 1000); // Adjust the delay as needed
};

const handleScroll = () => {
    if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 90
    ) {
        // Auto-load more items when scrolling close to the bottom
        loadMoreItems();
    }
};

useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, [loading, hasMore]);

const fetchMoreItems = (start) => {
    // Simulate fetching more items from an API
    const itemsPerPage = 5;
    const totalItems = 20; // Adjust as needed for your use case
    const newItems = [];

    for (let i = start; i < start + itemsPerPage; i++) {
        if (i < totalItems) {
            newItems.push(`Item ${i + 1}`);
        }
    }

    return newItems;
};



    return (
        <div className="customerListing pdt">
        <Dbheader />
        <Sidebar>
        <div className='customerMain'>
                <div className='custTop'>
            <Link to='/supplierlisting'>Supplier</Link>
            <Link to='/productservicelistings' className='active'>Product &amp; Service</Link>
            <Link to='/quotelisting'>Quote</Link>
            <Link to='/purchaseorderlisting'>Purchase Order</Link>
            <Link to='/bill'>Bill</Link>
            <div className="custom-dropdown-container customDropdown ms-4">
                            <div className="custom-dropdown-header" onClick={toggleDropdown}>
                                <span>{selectedOption}</span>
                                <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}> <i class="fa-regular fa-angle-down"></i></span>
                            </div>

                            {isOpen && (
                                <ul className="custom-dropdown-options">
                                    <li
                                        className={`option ${selectedOption === 'Option 1' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 1')} hidden
                                    >
                                        Option 1
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 2' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 2')}
                                    >
                                        <Link to="/billpayment"><i class="fa-light fa-calendar-lines-pen"></i> Bill Payment</Link>
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 3' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 3')} hidden
                                    >
                                        <Link to="/refundlist"><i className="fa-light fa-calendar-lines-pen"></i> Refunds</Link>
                                    </li>
                                    <li
                                        className={`option ${selectedOption === 'Option 4' ? 'selected' : ''}`}
                                        onClick={() => handleOptionClick('Option 4')}
                                    >
                                         <Link to="/purchaserefundlist"><i className="fa-light fa-calendar-lines-pen"></i> Refunds</Link>
                                    </li>
                                </ul>
                            )}

{/* <p>You selected: {selectedOption}</p> */}
                        </div>
          </div>
                    <div className='productServList'>
                    <div className='dbTopsec pb-2 noBackbtn'>
                            <Headtxt link="/" title="Product &amp; Service" />
                        </div>
                        <div className='dbTopsec pb-4'>
                            {/* <Headtxt link="/supplierlisting" title="New Supplier" /> */}
                            {supply?
                            <div className='customSrchfield'><span><i class="fa-solid fa-magnifying-glass"></i></span><input type='search' placeholder='Search for a specific Name' onChange={onSearchServices}/></div>:
                            <div className='customSrchfield'><span><i class="fa-solid fa-magnifying-glass"></i></span><input type='search' placeholder='Search for a specific Name' onChange={onSearchProducts}/></div>}
                            <div className='custButton'>
                                <button className='commonButton addBtn'><Link to="/addproductservice2"><i class="fa-regular fa-circle-plus"></i> Add Product/Service</Link></button>&nbsp;&nbsp;

                                {supply == false ?
                                    <CSVLink filename="product_list.csv" data={productExport}>
                                        <button className='commonButton expBtn'><i class="fa-light fa-upload"></i> Export Excel</button>

                                    </CSVLink> :
                                    <CSVLink filename="service_list.csv" data={serviceExport}>
                                        <button className='commonButton expBtn'><i class="fa-light fa-upload"></i> Export Excel</button>

                                    </CSVLink>}
                            </div>
                        </div>
                        <div className='addsuppBtn'>
                            <button className={`${!supply ? "commonButton active" : "commonButton "} `} onClick={toggleProduct}>Product</button>
                            <button className={`${supply ? "commonButton active" : "commonButton "} `} onClick={toggleService}>Service</button>
                        </div>
                        <div className='mt-2'>
                            <div className='gridList mb-3 text-end'>
                                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler1} className={!view ? "listactive" : ""}><i class="fa-solid fa-list"></i></button>
                                <button size="100%" style={{ backgroundColor: "#DCE5EC" }} onClick={toggleHandler} className={view ? "gridactive" : ""}><i class="fa-solid fa-grid"></i></button>
                            </div>
                            {!view ?
                                <div className='listViews'>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Purchase Price</th>
                                                <th scope="col">Unit</th>
                                                {supply ? "" :
                                                    <th scope="col">Quantity</th>}
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                        {/* {items.slice(0, visibleItems).map((item, index) => ( */}
                                               

                                            
                                            {productServiceList?.slice(0, visibleItems).map((d, index) => (

                                                <tr key={index} text={d}>
                                                     <td> 
                                                    {d?.type === "service" ?
                                                        <span><Link to={`/servicedetails?id=${d?.id}`}>{d?.name} </Link></span> :
                                                        <span ><Link to={`/productdetails2?id=${d?.id}`}>{d?.name} </Link></span>
                                                    }
                                                     </td>
                                                    <td>{d?.type === "service" ? d?.price : d?.purchase_price}</td>
                                                    <td>{d?.unit}</td>
                                                    {d?.type === "service" ? "" :
                                                        <td>{d?.quantity}</td>}
                                                    <td>{d?.type === "service" ?
                                                        <span className='viewIcon'><Link to={`/servicedetails?id=${d?.id}`}><i class="fa-light fa-eye"></i></Link></span> :
                                                        <span className='viewIcon'><Link to={`/productdetails?id=${d?.id}`}><i class="fa-light fa-eye"></i></Link></span>
                                                    }
                                                        {d?.type === "service" ?
                                                            <span className='viewIcon orange'><Link to={`/editservice?id=${d?.id}`}><i class="fa-light fa-pen"></i></Link></span> :
                                                            <span className='viewIcon orange'><Link to={`/editproduct?id=${d?.id}`}><i class="fa-light fa-pen"></i></Link></span>
                                                        }
                                                        {d?.type === "service" ?
                                                            <span className='viewIcon'><i class="fa-regular fa-trash-can" onClick={onDeleteService.bind(d?.id, `${d?.id}`)}></i></span> :
                                                            <span className='viewIcon'><i class="fa-regular fa-trash-can" onClick={onDelete.bind(d?.id, `${d?.id}`)}></i></span>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}


                                        </tbody>

                                    </table>


                                    {loading ?
                                    (
                                        <div className='loadItems'>                
                    <button className="commonButton addBtn" disabled>
                        Loading...
                    </button>
                    </div>
                ) : hasMore ? (
                    <div className='loadItems'>
                    <button onClick={loadMoreItems} className="commonButton addBtn">
                    No More Products or Services
                    </button>
                    </div>
                ) : (
                    <div className='loadItems'>
                    <button className="commonButton addBtn" disabled>
                        No More Products or Services
                    </button>
                    </div>
                )
                }

                                </div>
                                :
                                <div className='gridView'>
                                    {productServiceList?.map((d) => (

                                        <Card>
                                            <Card.Body>
                                                <div className='cardBlock'>
                                                    <h6>Name</h6>
                                                    <span>{d?.name}</span>

                                                </div>
                                                <div className='cardBlock'>
                                                    <h6>Price</h6>
                                                    <span>{d?.type === "service" ? d?.price : d?.sales_price}</span>
                                                </div>
                                                <div className='cardBlock'>
                                                    <h6>Unit</h6>
                                                    <span>{d?.unit}</span>
                                                </div>
                                                {d?.type === "service" ? "" :
                                                    <div className='cardBlock'>
                                                        <h6>Quantity</h6>
                                                        <span>{d?.quantity}</span>
                                                    </div>}
                                                <div className='cardBlock'>
                                                    <h6>Action</h6>

                                                </div>
                                                <div className='cardBlock actn'>
                                                    {/* <h6>Action</h6> */}
                                                    <div>
                                                        {d?.type === "service" ?
                                                            <span className='viewIcon'><Link to={`/servicedetails?id=${d?.id}`}><i class="fa-light fa-eye"></i></Link></span> :
                                                            <span className='viewIcon'><Link to={`/productdetails2?id=${d?.id}`}><i class="fa-light fa-eye"></i></Link></span>
                                                        }
                                                        {d?.type === "service" ?
                                                            <span className='viewIcon orange'><Link to={`/editservice?id=${d?.id}`}><i class="fa-light fa-pen"></i></Link></span> :
                                                            <span className='viewIcon orange'><Link to={`/editproduct?id=${d?.id}`}><i class="fa-light fa-pen"></i></Link></span>
                                                        }
                                                        {d?.type === "service" ?
                                                            <span className='viewIcon'><i class="fa-regular fa-trash-can" onClick={onDeleteService.bind(d?.id, `${d?.id}`)}></i></span> :
                                                            <span className='viewIcon'><i class="fa-regular fa-trash-can" onClick={onDelete.bind(d?.id, `${d?.id}`)}></i></span>
                                                        }
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    ))}


                                </div>
                            }
                            <Modal size="sm" show={showProductDelete} onHide={handleCloseProductDelete} className='delModal'>
                                <Modal.Header closeButton>
                                    <Modal.Title><h5>Are you sure to delete ?</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <button variant="primary" className='commonButton expBtn' onClick={onYesProduct} >
                                        Yes
                                    </button>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    <button variant="primary" className='commonButton expBtn' onClick={onNoProduct}>
                                        No
                                    </button>
                                </Modal.Body>
                            </Modal>

                            <Modal size="sm" show={showServiceDelete} onHide={handleCloseServiceDelete} className='delModal'>
                                <Modal.Header closeButton>
                                    <Modal.Title><h5>Are you sure to delete ?</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <button variant="primary" className='commonButton expBtn' onClick={onYesService} >
                                        Yes
                                    </button>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    <button variant="primary" className='commonButton expBtn' onClick={onNoService}>
                                        No
                                    </button>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
            </Sidebar>
        </div>
    )
}

export default Productservicelisting2