import webApi, { baseURL } from "../../WebApi/WebApi";

export const getUserAllList = async (data) => {
    return await webApi.get(`loggedin-user-details`, {

    });
}
export const getDashboardData = async (data) => {
    return await webApi.get(`user-dashboard`, {

    });
}