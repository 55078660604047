import webApi, { baseURL } from "../../WebApi/WebApi";



export const getRegister = async (fname,lname,company,email,phone,country,currency,password,confirmpassword,
     onSuccess, onFailure) => {
    console.log(fname,lname,company,email,phone,country,password,confirmpassword, "formData")
    try {
        const res = await webApi.post(
            `/registration`, 
            {
                "fname": fname,
                "lname": lname,
                "email":email,
                "password":password,
                "confirm_password":confirmpassword,
                "phone":phone,
                "country":country,
                'currency':currency,
                "company":company
            }
        );
        if (res.status === 200) {
            const r = res.data;
            console.log(r, "rerere")
            return onSuccess(r);
        } else {
            onFailure("Something Wrong! Please Try again later" + res.data);
        }
    } catch (error) {
        console.log("fdgdf")
    }
};